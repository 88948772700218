import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { LoaderService } from "./services/loader.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "./services/auth.service";
import { IExterneGebruiker } from "./model/externe-gebruiker.model";
import { IGebruiker } from "./model/gebruiker.model";

export let authenticatedUser: IGebruiker | null;
export let authenticatedExternalUser: IExterneGebruiker | null;

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
    title = "CheppOnline";

    constructor(
        private authService: AuthService,
        public loaderService: LoaderService,
        private changeDetector: ChangeDetectorRef,
        translate: TranslateService
    ) {
        authenticatedUser = this.authService.getAuthenticatedInterneGebruiker();
        authenticatedExternalUser = this.authService.getAuthenticatedExterneGebruiker();

        this.authService.currentInterneGebruikerSubject.subscribe((user: IGebruiker) => {
            authenticatedUser = user;
        });

        this.authService.currentExterneGebruikerSubject.subscribe((user: IExterneGebruiker) => {
            authenticatedExternalUser = user;
        });
    }

    ngOnInit(): void {
        // this.authservice.autoLogin();
    }

    ngAfterViewChecked() {
        this.changeDetector.detectChanges();
    }
}
