import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AnnotaterService } from "src/app/services/annotater.service";
import { Subscription } from "rxjs";
import { IDrawing } from "src/app/model/drawing-data.model";
import { IProperty } from "src/app/model/property.model";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: "app-pdf-annotator",
    templateUrl: "./pdf-annotator.component.html",
    styleUrls: ["./pdf-annotator.component.scss"],
})
export class PdfAnnotatorComponent implements OnInit {
    @Input("document-id") documentId: number = 0;
    @Input("deelplan-checklist-id") deelplanChecklistId: number = 0;
    @Input("page-number") pageNumber: number = 1;

    @ViewChild("canvas", { static: true }) canvas!: ElementRef<HTMLCanvasElement>;
    @ViewChild("canvasContainer", { static: true }) canvasContainer!: ElementRef;

    public ctx: CanvasRenderingContext2D | null = null;

    dossierId?: number;
    deelplanId?: number;
    checklistId?: number;

    dossierSubscription: Subscription = new Subscription();
    imageData: string = "";
    canvasWidth: number = 0;
    canvasHeight: number = 0;
    rotateDegree: number = 0;
    image: any;
    navigateUrl: string = "";
    totalPageNumber: number = 1;
    isDragble: boolean = false;
    allowRotate: boolean = true;
    editable: boolean = true;
    scale = 1;
    maxScaleTick = 2;
    minScaleTick = 0.3;

    loading = true;
    pos = { top: 0, left: 0, x: 0, y: 0 };

    constructor(
        private AnnotaterService: AnnotaterService,
        private snackbar: MatSnackBar,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        this.activatedRoute.params.subscribe((params) => {
            this.dossierId = params.dossierId;
            this.deelplanId = params.deelplanId;
            this.checklistId = params.checklistId;
        });
    }

    ngOnInit(): void {
        this.image = new Image();
        this.ctx = this.canvas.nativeElement.getContext("2d");
        this.dossierSubscription = this.AnnotaterService.getDrawingData(
            Number(this.documentId),
            Number(this.deelplanChecklistId),
            Number(this.pageNumber)
        ).subscribe((documentData: IDrawing) => {
            this.imageData = `data:image/png;base64,${documentData.documentUrl}`;
            this.canvasWidth = documentData.canvasWidth;
            this.canvasHeight = documentData.canvasHeight;
            this.rotateDegree = Number(documentData.rotateDegree);
            this.navigateUrl = documentData.navigateUrl;
            this.totalPageNumber = documentData.totalPagesNumber;
            this.allowRotate = documentData.allowRotate;
            this.editable = documentData.editable;

            this.renderImage();
            this.loading = false;
        });
    }

    renderImage() {
        this.transformCanvas();
        this.image.onload = () => {
            this.ctx?.save();
            this.ctx?.translate(this.canvas.nativeElement.width / 2, this.canvas.nativeElement.height / 2);
            this.ctx?.rotate((this.rotateDegree * Math.PI) / 180);
            this.ctx?.drawImage(this.image, -this.image.width / 2, -this.image.height / 2);
            this.ctx?.restore();
        };

        this.image.src = this.imageData;
    }

    transformCanvas() {
        if (this.rotateDegree == 90 || this.rotateDegree == 270) {
            this.canvas.nativeElement.width = this.canvasHeight * this.scale;
            this.canvas.nativeElement.height = this.canvasWidth * this.scale;
        } else {
            this.canvas.nativeElement.width = this.canvasWidth * this.scale;
            this.canvas.nativeElement.height = this.canvasHeight * this.scale;
        }
    }

    rotate() {
        this.scale = 1;
        this.rotateDegree += 90;
        this.transformCanvas();
        this.ctx?.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
        this.ctx?.save();
        this.ctx?.translate(this.canvas.nativeElement.width / 2, this.canvas.nativeElement.height / 2);
        this.ctx?.rotate((this.rotateDegree * Math.PI) / 180);
        this.ctx?.drawImage(this.image, -this.image.width / 2, -this.image.height / 2);
        this.ctx?.restore();

        if (this.rotateDegree == 360) {
            this.rotateDegree = 0;
        }

        this.savePageProperty("rotation", this.rotateDegree);
    }

    savePageProperty(propertyName: string, propertyValue: any) {
        this.loading = true;
        const propertyData: IProperty = {
            propertyName: propertyName,
            propertyValue: propertyValue,
        };

        this.AnnotaterService.savePageProperties(this.deelplanChecklistId, this.documentId, propertyData).subscribe(
            (result: any) => {
                if (result["success"]) {
                    this.snackbar.open(result["message"], undefined, {
                        panelClass: ["custom-snackbar-styling"],
                        duration: 4000,
                    });
                } else {
                    this.snackbar.open(result["message"], undefined, {
                        panelClass: ["error-snackbar"],
                        duration: 4000,
                    });
                }
                this.loading = false;
            }
        );
    }

    goToPage($event: Event) {
        const pageNumber = Number(($event.target as HTMLInputElement).value);
        if (pageNumber <= this.totalPageNumber) {
            let url = `${this.navigateUrl}${this.documentId}/${pageNumber}`;
            if (Number(this.deelplanChecklistId)) {
                url += `/${this.deelplanChecklistId}`;
            }
            window.location.href = url;
        }
    }

    openPreviousPage() {
        if (Number(this.pageNumber) > 1) {
            let url = `${this.navigateUrl}${this.documentId}/${Number(this.pageNumber) - 1}`;
            if (Number(this.deelplanChecklistId)) {
                url += `/${this.deelplanChecklistId}`;
            }
            window.location.href = url;
        }
    }

    openNextPage() {
        if (Number(this.pageNumber) + 1 <= this.totalPageNumber) {
            let url = `${this.navigateUrl}${this.documentId}/${Number(this.pageNumber) + 1}`;
            if (Number(this.deelplanChecklistId)) {
                url += `/${this.deelplanChecklistId}`;
            }
            window.location.href = url;
        }
    }

    close() {
        this.router.navigate([`/documents-overview/${this.dossierId}/${this.deelplanId}/${this.checklistId}`]);
    }

    mouseDownHandler($event: MouseEvent) {
        this.canvasContainer.nativeElement.style.cursor = "grabbing";
        this.pos = {
            left: this.canvasContainer.nativeElement.scrollLeft,
            top: this.canvasContainer.nativeElement.scrollTop,
            // Get the current mouse position
            x: $event.clientX,
            y: $event.clientY,
        };

        this.isDragble = true;
    }

    mouseUpHandler($event: MouseEvent) {
        this.canvasContainer.nativeElement.style.cursor = "grab";
        this.isDragble = false;
    }

    mouseMoveHandler($event: MouseEvent) {
        if (this.isDragble) {
            // How far the mouse has been moved
            const dx = $event.clientX - this.pos.x;
            const dy = $event.clientY - this.pos.y;

            // Scroll the element
            this.canvasContainer.nativeElement.scrollTop = this.pos.top - dy;
            this.canvasContainer.nativeElement.scrollLeft = this.pos.left - dx;
        }
    }

    mouseWheelHandler($event: WheelEvent) {
        $event.preventDefault();
        $event.stopPropagation();

        if ($event.deltaY > 0) {
            if (this.minScaleTick <= this.scale) {
                this.scale = Math.round((this.scale - 0.03) * 100) / 100;
            }
        } else {
            if (this.maxScaleTick >= this.scale) {
                this.scale = Math.round((this.scale + 0.03) * 100) / 100;
            }
        }

        this.transformCanvas();
        this.ctx?.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
        this.ctx?.save();
        this.ctx?.translate(this.canvas.nativeElement.width / 2, this.canvas.nativeElement.height / 2);
        this.ctx?.rotate((this.rotateDegree * Math.PI) / 180);
        this.ctx?.scale(this.scale, this.scale);
        this.ctx?.drawImage(this.image, -this.image.width / 2, -this.image.height / 2);
        this.ctx?.restore();
    }
}
