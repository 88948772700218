import { Component } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { DeleteMaatregelDialogComponent } from "./delete-maatregel-dialog/delete-maatregel-dialog.component";
import { NewMaatregelDialogComponent } from "./new-maatregel-dialog/new-maatregel-dialog.component";

@Component({
    selector: "app-maatregel-dialog.ts",
    templateUrl: "./maatregel-dialog.component.html",
    styleUrls: ["./maatregel-dialog.component.scss"],
})
export class MaatregelDialogComponent {
    maatregelTitleLabel: string = "Maatregelen";
    dateTitleLabel: string = "Per";
    creatorTitleLabel: string = "Door";
    deadlineTitleLabel: string = "Deadline";
    statusTitleLabel: string = "Status";
    contentLabel: string = "Temporary";
    maatregelToevoegenLabel: string = "Maatregel toevoegen";

    maatregel = Array.from({ length: 5 }).map((_, i) => `maatregel #${i}`);

    constructor(private dialog: MatDialog, private dialogRef: MatDialogRef<MaatregelDialogComponent>) {
        dialogRef.disableClose = true;
    }

    openNewMaatregel(mode: string) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            mode: mode,
        };
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = "new-maatregel-dialog";
        let dialogRef = this.dialog.open(NewMaatregelDialogComponent, dialogConfig);
    }

    openDeletePopup() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = "delete-maatregel-dialog";
        let dialogRef = this.dialog.open(DeleteMaatregelDialogComponent);
    }
}
