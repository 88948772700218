<div class="wrapper">
    <div class="dialog-button-container" g2sLayout="row" g2sLayoutAlign="start end">
        <span class="material-icons dialog-icon">color_lens</span>
        <p class="statuskleur-text">{{ statuskleurTextLabel }}</p>
        <span matRipple class="material-icons dialog-close-button" mat-dialog-close>close</span>
    </div>
    <div class="dialog-line"></div>
    <div g2sLayout="row" g2sLayoutAlign="space-between center">
        <button
            [ngClass]="{ disabledfilter: !colorFilters.includes('rood') }"
            (click)="onRedClick()"
            mat-button
            class="red-button"></button>
        <button
            [ngClass]="{ disabledfilter: !colorFilters.includes('oranje') }"
            (click)="onOrangeClick()"
            mat-button
            class="orange-button"></button>
        <button
            [ngClass]="{ disabledfilter: !colorFilters.includes('groen') }"
            (click)="onGreenClick()"
            mat-button
            class="green-button"></button>
        <button
            [ngClass]="{ disabledfilter: !colorFilters.includes('standaard') }"
            (click)="onStandaardClick()"
            mat-button
            class="standaard-button"></button>
    </div>

    <div class="dialog-button-container dialog-button-hercontrole-container" g2sLayout="row" g2sLayoutAlign="start end">
        <span class="material-icons dialog-icon">date_range</span>
        <p class="statuskleur-text">{{ hercontroleTextLabel }}</p>
        <mat-slide-toggle
            (change)="onHercontroleToggle()"
            [checked]="hercontroleFilter"
            class="dialog-slide-toggle"
            color="primary"></mat-slide-toggle>
    </div>
    <div class="dialog-line"></div>
    <div g2sLayout="row" g2sLayoutAlign="space-between center">
        <p g2sFlex="45" class="datepicker-label">{{ vanTextLabel }}</p>
        <p g2sFlex="45" class="datepicker-label">{{ totTextLabel }}</p>
    </div>
    <form #f="ngForm" [formGroup]="filterForm" (ngSubmit)="onSubmitFilterForm()">
        <div g2sLayout="row" g2sLayoutAlign="space-between center" class="dialog-datepicker-container">
            <mat-form-field appearance="standard" g2sFlex="45">
                <mat-label>{{ kiesDatumTextLabel }}</mat-label>
                <input
                    id="leftDate"
                    formControlName="leftDate"
                    required
                    matInput
                    [matDatepicker]="pickerleft"
                    (dateChange)="changeStartDate()"
                    (click)="pickerleft.open()" />
                <mat-datepicker-toggle matSuffix [for]="pickerleft"></mat-datepicker-toggle>
                <mat-datepicker #pickerleft></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="standard" g2sFlex="45">
                <mat-label>{{ kiesDatumTextLabel }}</mat-label>
                <input
                    id="rightDate"
                    formControlName="rightDate"
                    required
                    matInput
                    [matDatepicker]="pickerright"
                    (dateChange)="changeEindDate()"
                    (click)="pickerright.open()" />
                <mat-datepicker-toggle matSuffix [for]="pickerright"></mat-datepicker-toggle>
                <mat-datepicker #pickerright></mat-datepicker>
            </mat-form-field>
        </div>
        <div g2sLayout="row" g2sLayoutAlign="space-between center" class="dialog-bottom-button-container">
            <button type="button" (click)="onResetFilter()" class="dialog-reset-button" g2sFlex="45" mat-flat-button>
                <span class="material-icons">rotate_left</span>
            </button>
            <ng-container *ngIf="hercontroleFilter; else submitbutton">
                <button type="submit" [disabled]="!f.valid" class="dialog-filter-button" g2sFlex="45" mat-flat-button>
                    <span class="material-icons">filter_list</span>
                </button>
            </ng-container>
            <ng-template #submitbutton>
                <button
                    [disabled]="!colorFilters.includes('groen') && !colorFilters.includes('oranje') && !colorFilters.includes('rood') && !colorFilters.includes('standaard')"
                    class="dialog-filter-button"
                    g2sFlex="45"
                    mat-flat-button>
                    <span class="material-icons">filter_list</span>
                </button>
            </ng-template>
        </div>
    </form>
</div>
