import { ChangeDetectorRef, Component } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { PasswordModalComponent } from "./dialog/password-modal/password-modal.component";
import { HttpClientService } from "src/app/services/posts.service";
import { SnackbarService } from "src/app/services/snackbar.service";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
    passwordText = "Wachtwoord";
    emailText = "E-mail";
    passwordForgotText = "Wachtwoord vergeten?";
    loginText = "Inloggen";
    loginSnackbar = "Verkeerde email en/of wachtwoord";

    displayLoadingScreen: boolean = false;

    loginForm = new UntypedFormGroup({
        email: new UntypedFormControl("", [Validators.required]),
        password: new UntypedFormControl("", [
            Validators.required,
            Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}"),
        ]),
    });

    constructor(
        public dialog: MatDialog,
        private authService: AuthService,
        private router: Router,
        private snackBar: SnackbarService,
        private httpClient: HttpClientService,
        private cdRef: ChangeDetectorRef,
        private route: ActivatedRoute
    ) {}

    ngAfterViewInit(): void {
        this.route.queryParams.subscribe(async (params) => {
            if (params["error"]) {
                // ERROR OUT
            } else if (params["code"] && params["state"] && (params["uid"] || params["euid"])) {
                this.displayLoadingScreen = true;

                this.cdRef.detectChanges();

                let code = params["code"];
                let state = params["state"];
                let uid: number | undefined;
                let euid: number | undefined;

                if ("uid" in params) {
                    uid = params["uid"];
                }

                if ("euid" in params) {
                    euid = params["euid"];
                }

                await this.authService
                    .handleAuthorizationCallback(code, state)
                    .then(async (response) => {
                        if (response) {
                            if (response) {
                                if (uid) {
                                    await new Promise((resolve, reject) => {
                                        this.authService.authenticate(uid as number).subscribe({
                                            next: (response) => {
                                                if (response.success) {
                                                    resolve(true);
                                                } else {
                                                    this.authService.forceLogout();
                                                    reject(false);
                                                }
                                            },
                                            error: (error) => {
                                                this.authService.forceLogout();
                                                reject(false);
                                            },
                                        });
                                    });
                                }

                                if (euid) {
                                    await new Promise((resolve, reject) => {
                                        this.authService.authenticate(euid as number, true).subscribe({
                                            next: (response) => {
                                                if (response.success) {
                                                    resolve(true);
                                                } else {
                                                    this.authService.forceLogout();
                                                    resolve(false);
                                                }
                                            },
                                            error: (error) => {
                                                this.authService.forceLogout();
                                                resolve(false);
                                            },
                                        });
                                    });
                                }

                                this.displayLoadingScreen = false;
                                this.cdRef.detectChanges();

                                this.router.navigate([`checklistoverview/71712/19679`]);
                            } else {
                                this.authService.forceLogout();
                            }
                        }

                        this.displayLoadingScreen = false;

                        this.cdRef.detectChanges();
                    })
                    .catch((error) => {
                        this.authService.forceLogout();

                        this.displayLoadingScreen = false;
                        this.cdRef.detectChanges();
                    });
            } else {
                if (this.authService.isAuthenticated()) {
                    this.router.navigate([`checklistoverview/141825/71721/19682/intern`]);
                } else {
                    this.login();
                }

                this.displayLoadingScreen = false;

                this.cdRef.detectChanges();
            }
        });
    }

    login() {
        // Start the OAuth2 authentication
        this.authService.oauth2Authenticate();
    }
}
