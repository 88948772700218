import { Component, Input, OnInit, Type } from "@angular/core";
import { DocumentService } from "./services/document.service";
import { DocumentsOverviewComponent } from "./components/documents-overview/documents-overview.component";
import { Router } from "@angular/router";

@Component({
    selector: "app-documents-management",
    template: '<router-outlet name="mainContentOutlet"></router-outlet>',
})
export class DocumentsManagementComponent implements OnInit {
    current: Type<any> = DocumentsOverviewComponent;
    @Input("dossier-id") dossierId: number = 0;
    @Input("deelplan-id") deelplanId: number = 0;
    @Input("checklist-groep-id") checklistGroepId: number = 0;
    @Input("checklist-id") checklistId: number = 0;
    @Input("vraag-id") vraagId: number = 0;
    @Input("deelplan-checklist-id") deelplanChecklistId: number = 0;
    @Input("is-wki") isWki: number = 0;
    @Input() editable: number = 0;
    @Input("bouwnummer-id") bouwnummerId: number = 0;
    @Input("deelplan-vraag-id") deelplanVraagId: number = 0;
    @Input("bouwnummer") bouwnummer: any = "";

    constructor(private DocumentService: DocumentService, private router: Router) {}
    ngOnInit(): void {
        if (
            this.dossierId != null &&
            this.dossierId > 0 &&
            this.deelplanChecklistId != null &&
            this.deelplanChecklistId > 0
        ) {
            this.DocumentService.setEntryData({
                dossierId: this.dossierId,
                deelplanChecklistId: this.deelplanChecklistId,
                isWki: Number(this.isWki),
                editable: Number(this.editable),
                bouwnummerId: Number(this.bouwnummerId),
                deelplanVraagId: Number(this.deelplanVraagId),
                deelplanId: Number(this.deelplanId),
                checklistGroepId: Number(this.checklistGroepId),
                checklistId: Number(this.checklistId),
                vraagId: Number(this.vraagId),
            });

            this.router.navigate([{ outlets: { mainContentOutlet: "documents" } }], { skipLocationChange: true });
        }
    }
}
