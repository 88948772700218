<ng-container>
    <mat-toolbar color="primary">
        <button class="round-button" [matMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon class="mat-icon">account_circle</mat-icon>
        </button>
        <span class="spacer"></span>
        <button class="round-button" (click)="openAlerts()" mat-icon-button>
            <mat-icon matBadge="{{ homeAlerts }}" matBadgeColor="warn">notifications</mat-icon>
        </button>
    </mat-toolbar>
    <mat-menu #menu="matMenu">
        <div class="collapseMenu">
            <h3 style="margin-bottom: 0px; font-weight: 600">{{ txtUserName }}</h3>
            <h4 style="margin-bottom: 5px; font-weight: 400">{{ txtUserOrg }}</h4>
            <button class="resetButton" mat-flat-button (click)="openPasswordResetDialog()">
                {{ passwordResetLabel }}
            </button>
            <button class="language-change" mat-flat-button (click)="openLanguageDialog()">
                {{ changeLanguageTextLabel }}
            </button>
            <button class="logoutButton" mat-flat-button (click)="openLogoutDialog()">{{ userLogoutTextLabel }}</button>
        </div>
    </mat-menu>
</ng-container>
