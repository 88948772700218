import { Component } from "@angular/core";
import { State } from "src/app/model/enum.model";

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent {
    dossierAlerts = 22;
    progressValue = 17;

    stateEnum = State;
    state = State.None;

    filterButtonLabel: string = "Filteren";
    searchButtonLabel: string = "Zoeken";

    constructor() {}

    //selecteren/deselecteren van dossier search component
    searchSwitch() {
        if (this.state !== this.stateEnum.Search) {
            this.state = this.stateEnum.Search;
        } else {
            this.state = this.stateEnum.None;
        }
    }

    //selecteren/deselecteren van dossier filter component
    filterSwitch() {
        if (this.state !== this.stateEnum.Filter) {
            this.state = this.stateEnum.Filter;
        } else {
            this.state = this.stateEnum.None;
        }
    }
}
