<div class="wrapper">
    <div class="header" g2sLayout="row" g2sLayoutAlign="start center">
        <p class="header-text">{{ titelTextLabel }}</p>
        <mat-icon matRipple mat-dialog-close class="close-button">close</mat-icon>
    </div>
    <hr class="divider" />
    <div class="button-container" g2sLayoutAlign="space-between center">
        <mat-icon (click)="onOpenTimeline()" matRipple class="menu-icon">history</mat-icon>
        <mat-icon (click)="openDocumentsOverview()" matRipple class="menu-icon">insert_drive_file</mat-icon>
    </div>
    <ng-container *ngIf="vraagKostentable; else inactive">
        <div class="button-container" g2sLayoutAlign="space-between center">
            <mat-icon matRipple class="menu-icon">calculate</mat-icon>
        </div>
    </ng-container>
    <ng-template #inactive>
        <div class="button-container" g2sLayoutAlign="space-between center">
            <mat-icon class="menu-icon-inactive">calculate</mat-icon>
        </div>
    </ng-template>
</div>
