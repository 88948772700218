import { Component, Input, OnDestroy } from "@angular/core";
import { Location } from "@angular/common";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { FiltersDialogComponent } from "../filters-dialog/filters-dialog.component";
import { Subscription } from "rxjs";
import { DocumentsOverviewComponent } from "../documents-overview.component";
import { DocumentService } from "../../../services/document.service";
import { Overlay } from "@angular/cdk/overlay";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnDestroy {
    @Input("folder-name") folder: string = "Root";
    dossierId?: number;
    deelplanId?: number;
    checklistId?: number;

    filtersDataSubscription: Subscription = new Subscription();
    backButtonTextLabel = "Terug";
    constructor(
        private location: Location,
        public dialog: MatDialog,
        public readonly documentsOverviewComponent: DocumentsOverviewComponent,
        private DocumentService: DocumentService,
        public overlay: Overlay,
        private Router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        this.activatedRoute.params.subscribe((params) => {
            this.dossierId = params.dossierId;
            this.deelplanId = params.deelplanId;
            this.checklistId = params.checklistId;
        });
    }

    goBack() {
        this.Router.navigate([`/checklistoverview/${this.dossierId}/${this.deelplanId}/${this.checklistId}`]);
    }

    openDialog() {
        this.documentsOverviewComponent.loading = true;
        let checklistId = this.documentsOverviewComponent.entryData.deelplanChecklistId;
        this.filtersDataSubscription = this.DocumentService.getDocumentsFiltersData(checklistId).subscribe(
            (data: any) => {
                if (data) {
                    const dialogRef = this.dialog.open(FiltersDialogComponent, {
                        width: "100%",
                        height: "90%",
                        disableClose: true,
                        scrollStrategy: this.overlay.scrollStrategies.noop(),
                        data: {
                            bouwnummers: data.bouwnummers,
                            categories: data.categories,
                            selectedBouwnummers: this.documentsOverviewComponent.bouwnummersFilter,
                            selectedLabels: this.documentsOverviewComponent.labelsFilter,
                        },
                    });

                    dialogRef.afterClosed().subscribe((result) => {
                        if (result) {
                            this.documentsOverviewComponent.bouwnummersFilter = result.bouwnummers;
                            this.documentsOverviewComponent.labelsFilter = result.labels;
                            this.documentsOverviewComponent.updateList();
                        }
                    });
                }
                this.documentsOverviewComponent.loading = false;
            }
        );
    }

    ngOnDestroy() {
        this.filtersDataSubscription.unsubscribe();
    }
}
