<div class="wrapper">
    <div class="standaard-header" g2sLayout="row">
        <p class="header-text">{{ translations?.Chepp_ChecklistView_StandardOpdrachten }}</p>
        <span matDialogClose matRipple style="cursor: pointer" class="material-icons exit-button">close</span>
    </div>
    <div class="overflow-div">
        <ng-container *ngIf="opdrachten && opdrachten.length > 0; else showPlaceHolder">
            <div *ngFor="let opdracht of opdrachten" class="standaard-dialog-body">
                <div (click)="onclickStandaardOpdracht(opdracht)" class="standaard-opdrachten-item">
                    {{ opdracht.opdracht }}
                </div>
            </div>
        </ng-container>
        <ng-template #showPlaceHolder>
            <p class="placeholder-text">
                {{ translations?.Chepp_ChecklistView_ThereAreNoStandardOpdrachtenToDisplay }}
            </p>
        </ng-template>
    </div>
</div>
