<div>
    <div class="wki-document-header" g2sLayout="row" g2sLayoutAlign="start center">
        <p class="header-text">{{ headerTextLabel }}</p>
        <mat-icon matDialogClose matRipple class="search-icon">close</mat-icon>
    </div>
    <div class="bouwnummer-div">
        <div class="bouwnummer-header">
            <p class="bouwnummer-text">{{ bouwnummerTitleLabel }}</p>
            <div g2sLayout="row" g2sLayoutAlign="end center">
                <mat-icon (click)="setAllBouwnummersInactive()" class="select">check_box_outline_blank</mat-icon>
                <mat-icon (click)="setAllBouwnummersActive()" class="select">check_box</mat-icon>
            </div>
        </div>
        <ng-container *ngIf="bouwnummers && bouwnummers.length > 0">
            <mat-button-toggle-group
                class="bouwnummer-label"
                *ngFor="let bouwnummer of bouwnummers"
                multiple
                (change)="onBouwnummerToggleChanged($event)">
                <mat-button-toggle
                    (click)="bouwnummerLabelClick(bouwnummer)"
                    [checked]="determineIfBouwnummerIsSelected(bouwnummer)"
                    class="bouwnummer">
                    {{ bouwnummerLabel }}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </ng-container>
    </div>
    <div>
        <div class="labels-header">
            <p class="labels-text">{{ labelsTitleLabel }}</p>
            <div g2sLayout="row" g2sLayoutAlign="end center">
                <mat-icon (click)="setAllCategoriesInactive()" class="select">check_box_outline_blank</mat-icon>
                <mat-icon (click)="setAllCategoriesActive()" class="select">check_box</mat-icon>
            </div>
        </div>
        <ng-container *ngIf="categories && categories.length > 0">
            <div *ngFor="let Subcategory of categories">
                <div class="subcategories-header">
                    <p class="subcategories-text">{{ subcategoryTitleLabel }}</p>
                    <div g2sLayout="row" g2sLayoutAlign="end center">
                        <mat-icon (click)="setSubcategoryInactive()" class="select">check_box_outline_blank</mat-icon>
                        <mat-icon (click)="setSubcategoryActive()" class="select">check_box</mat-icon>
                    </div>
                </div>
                <ng-container *ngIf="subcategory && subcategory.length > 0">
                    <mat-button-toggle-group
                        class="subcategory-label"
                        *ngFor="let subcategory of subcategory"
                        multiple
                        (change)="onSubcategoryToggleChanged($event)">
                        <mat-button-toggle
                            (click)="subcategoryLabelClick(subcategory)"
                            [checked]="determineIfSubcategoryIsSelected(subcategory)"
                            class="subcategory">
                            {{ subcategoryLabel }}
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </ng-container>
            </div>
        </ng-container>
        <div class="footer">
            <div matRipple class="filter" (click)="filterDocuments()">
                <mat-icon class="doc-filter-icon">filter_list</mat-icon>
            </div>
        </div>
    </div>
</div>
