import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import { IDeelplanVraag } from "src/app/model/deelplan-vraag.model";
import { IDeelplan } from "src/app/model/deelplan.model";
import { IDeelplanChecklist } from "src/app/model/deelplan-checklist.model";
import { IDossier } from "src/app/model/dossier.model";
import { VraagType, WaardeoordeelColor } from "src/app/model/enum.model";
import { IHoofdgroep } from "src/app/model/hoofdgroep.model";
import { IWaardeoordeel } from "src/app/model/waardeoordeel.model";
import { DeelplanOpdrachtService } from "src/app/services/deelplan-opdracht.service";
import { DeelplanVraagService } from "src/app/services/deelplan-vraag.service";
import { SelectJudgementDialogComponent } from "../select-judgement-dialog/select-judgement-dialog.component";
import { IDeelplanOpdracht } from "src/app/model/deelplan-opdracht.model";
import { IHercontrole } from "src/app/model/hercontrole.model";
import { DatePipe } from "@angular/common";
import { UploadPhotoDialogComponent } from "../upload-photo-dialog/upload-photo-dialog.component";
import { NewOpdrachtDialogComponent } from "../new-opdracht-dialog/new-opdracht-dialog.component";
import { DossierService } from "src/app/services/dossier.service";
import { IKlantOrganisation } from "src/app/model/klant-organisation.model";
import { IBetrokkene } from "src/app/model/betrokkene.model";

@Component({
    selector: "app-invul-meerkeuze-vraag-dialog",
    templateUrl: "./invul-meerkeuze-vraag-dialog.component.html",
    styleUrls: ["./invul-meerkeuze-vraag-dialog.component.scss"],
})
export class InvulMeerkeuzeVraagDialogComponent implements OnInit {
    @Input() dossier!: IDossier;
    @Input() deelplan!: IDeelplan;
    @Input() deelplanChecklist!: IDeelplanChecklist;
    @Input() hoofdgroep!: IHoofdgroep;
    @Input() deelplanVraag!: IDeelplanVraag;
    @Input() translations!: any;

    // saveLabel: string = "Opslaan";
    // hercontroleFromTextLabel: string = "Van";
    // hercontroleTillTextLabel: string = "Tot";
    // hercontroleTextLabel: string = "Hercontrole:";
    // hercontroleLabel: string = "(Nieuwe) hercontrole";
    // deadlineTitleLabel: string = "Deadline:";
    // uitvoerendeTitleLabel: string = "Uitvoerende:";
    // opdrachttekstTitleLabel: string = "Opdrachttekst:";
    // vervolgopdrachtTitleLabel: string = "Vervolgopdracht:";
    // opdrachtTitleLabel: string = "Opdracht";
    // actueleToelichtingLabel = "Actuele toelichting";

    files: any[] = [];

    selectedWaardeoordeel?: IWaardeoordeel;
    defaultWaardeoordeel?: IWaardeoordeel;

    isSaving: boolean = false;
    displayLoadingScreen: boolean = false;

    previousToelichting: string = "";
    toelichting: string = "";

    actueleToelichtingOpen = false;

    createdHercontrole!: IHercontrole | null;
    createdOpdracht!: IDeelplanOpdracht | null;

    @Output("closeDialog") closeDialog: EventEmitter<any> = new EventEmitter();

    constructor(
        private dialog: MatDialog,
        private deelplanVraagService: DeelplanVraagService,
        private deelplanOpdrachtService: DeelplanOpdrachtService,
        private dossierService: DossierService,
        public datePipe: DatePipe
    ) {}

    ngOnInit(): void {
        // Set the previous toelichting if the deelplan vraag has a toelichting
        if (this.deelplanVraag.toelichting) {
            this.previousToelichting = JSON.parse(JSON.stringify(this.deelplanVraag.toelichting));
        }

        if (this.deelplanVraag.waardeoordeel) {
            this.selectedWaardeoordeel = JSON.parse(JSON.stringify(this.deelplanVraag.waardeoordeel));
        }

        if (this.deelplanVraag.defaultWaardeoordeel) {
            this.defaultWaardeoordeel = JSON.parse(JSON.stringify(this.deelplanVraag.defaultWaardeoordeel));
        }
    }

    public get VraagType(): typeof VraagType {
        return VraagType;
    }

    // Get waardeoordeel options of the given color
    getWaardeoordeelOptionsOfColor(color: WaardeoordeelColor) {
        return this.deelplanVraag.waardeoordeelOptions?.filter((w) => w.color == color);
    }

    // Get red waardeoordeel options
    getRedWaardeoordeelOptions() {
        return this.getWaardeoordeelOptionsOfColor(WaardeoordeelColor.RED) ?? [];
    }

    // Get orange waardeoordeel options
    getOrangeWaardeoordeelOptions() {
        return this.getWaardeoordeelOptionsOfColor(WaardeoordeelColor.ORANGE) ?? [];
    }

    // Get green waardeoordeel options
    getGreenWaardeoordeelOptions() {
        return this.getWaardeoordeelOptionsOfColor(WaardeoordeelColor.GREEN) ?? [];
    }

    // Handles the event of selecting a green waardeoordeel
    selectGreenWaardeoordeel() {
        this.selectWaardeoordeel(this.getGreenWaardeoordeelOptions());
    }

    // Handles the event of selecting a red waardeoordeel
    selectRedWaardeoordeel() {
        this.selectWaardeoordeel(this.getRedWaardeoordeelOptions());
    }

    // Handles the event of selecting a orange waardeoordeel
    selectOrangeWaardeoordeel() {
        this.selectWaardeoordeel(this.getOrangeWaardeoordeelOptions());
    }

    // Handles the event of selecting a waardeoordeel
    selectWaardeoordeel(waardeoordeelOptions: IWaardeoordeel[]) {
        let dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = "waardeoordeel-popup";
        dialogConfig.data = {
            currentWaardeoordeel: this.deelplanVraag.waardeoordeel,
            waardeoordeelOpties: waardeoordeelOptions,
            translations: this.translations,
            dossier: this.dossier,
            currentToelichting: this.toelichting
        };

        // Pass files to be used when a vervolg opdracht is created
        if (this.files) {
            dialogConfig.data.files = this.files;
        }

        let dialogRef = this.dialog.open(SelectJudgementDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(async (data) => {
            if (data != null) {
                this.selectedWaardeoordeel = data.waardeoordeel;

                // Reset the created hercontrole and opdracht.
                // This will be set again if passed in data.
                this.createdHercontrole = null;
                this.createdOpdracht = null;

                switch (data.type) {
                    case "hercontrole":
                        this.createdHercontrole = data.data;
                        break;
                    case "vervolgopdracht":
                        this.createdOpdracht = data.data;
                        break;
                    default:
                        break;
                }
            }
        });
    }

    // Determines whether the save button should be enabled
    isSaveButtonDisabled() {
        if (!this.selectedWaardeoordeel && !this.toelichting) {
            return true;
        }

        return false;
    }

    // Handles the save event
    async onSaveClicked() {
        this.isSaving = true;
        this.displayLoadingScreen = true;

        // Reset the hercontrole
        this.deelplanVraag.hercontrole = undefined;

        // If a hercontrole is created, set the deelplanvraag its hercontrole accordingly
        if (this.createdHercontrole != null) {
            this.deelplanVraag.hercontrole = this.createdHercontrole;
        }

        // Update the deelplan vraag its waardeoordeel and toelichting
        this.deelplanVraag.waardeoordeel = this.selectedWaardeoordeel!;
        this.deelplanVraag.toelichting = this.toelichting;

        // 1. Save organisation if new
        // 2. Save betrokkene if new
        // 3. Save vraag + files
        // 4. Save opdracht + files

        let klantOrganisation: IKlantOrganisation;
        let betrokkene: IBetrokkene;

        if (this.createdOpdracht) {
            if (this.createdOpdracht.betrokkene) {
                betrokkene = this.createdOpdracht.betrokkene;

                if (this.createdOpdracht.betrokkene.klantOrganisation) {
                    klantOrganisation = this.createdOpdracht.betrokkene.klantOrganisation;

                    if (this.createdOpdracht.betrokkene.klantOrganisation.isNew) {
                        klantOrganisation = await new Promise((resolve, reject) => {
                            this.dossierService
                                .addOrganisation(this.dossier.id, this.createdOpdracht!.betrokkene!.klantOrganisation!)
                                .subscribe({
                                    next: (value) => {
                                        resolve(value);
                                    },
                                });
                        });

                        if (!klantOrganisation) {
                            this.isSaving = false;
                            this.displayLoadingScreen = false;
                            return;
                        }
                    }
                }

                if (this.createdOpdracht.betrokkene.isNew) {
                    betrokkene = await new Promise((resolve, reject) => {
                        this.dossierService
                            .addBetrokkene(this.dossier.id, klantOrganisation.id, this.createdOpdracht!.betrokkene!)
                            .subscribe({
                                next: (value) => {
                                    resolve(value);
                                },
                            });
                    });

                    if (!betrokkene) {
                        this.isSaving = false;
                        this.displayLoadingScreen = false;
                        return;
                    }
                }
            }
        }

        // Save the vraag changes which results in a new deelplan vraag revision
        let result = await new Promise<boolean>((resolve, reject) => {
            this.deelplanVraagService.saveChanges(this.deelplanVraag).subscribe({
                next: async (response) => {
                    if (response) {
                        this.deelplanVraag = response;
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                },
                error: (error) => {
                    resolve(false);
                },
            });
        });

        if (!result) {
            this.isSaving = false;
            this.displayLoadingScreen = false;
            return;
        }

        // Check if any files are selected
        // If so, upload these by the new deelplan vraag revision
        if (this.files) {
            for (let file of this.files) {
                await new Promise((resolve, reject) => {
                    this.deelplanVraagService
                        .uploadFile(this.deelplanVraag.deelplanVraagRevisionId!, file.file)
                        .subscribe({
                            next: (response) => {
                                if (response) {
                                    resolve(true);
                                } else {
                                    resolve(false);
                                }
                            },
                            error: (error) => {
                                resolve(false);
                            },
                        });
                });
            }
        }

        let deelplanOpdracht: IDeelplanOpdracht;

        // Check if a (vervolg)opdracht is created
        // If so, save it by the new deelplan vraag revision.
        // Also check if the deelplan vraag revision was saved.
        if (this.createdOpdracht) {
            deelplanOpdracht = await new Promise((resolve, reject) => {
                this.deelplanOpdrachtService
                    .createVervolgOpdracht(
                        this.deelplanVraag.deelplanVraagRevisionId!,
                        betrokkene.id,
                        this.createdOpdracht!.omschrijving,
                        this.createdOpdracht!.deadline
                    )
                    .subscribe({
                        next: async (response) => {
                            resolve(response);
                        },
                    });
            });

            if (!deelplanOpdracht) {
                this.isSaving = false;
                this.displayLoadingScreen = false;
                return;
            }

            // Check if the created opdracht has any attachments
            if (this.createdOpdracht?.attachments) {
                // Loop over each attachment
                for (let attachment of this.createdOpdracht.attachments) {
                    // If the attachment is disabled, skip it.
                    // This means it is de-selected in the vervolg opdracht pop-up
                    if ("disabled" in attachment && attachment.disabled) {
                        continue;
                    }

                    await new Promise((resolve, reject) => {
                        this.deelplanOpdrachtService.addAttachment(deelplanOpdracht.id!, attachment.file).subscribe({
                            next: (response) => {
                                resolve(true);
                            },
                            complete() {
                                resolve(true);
                            },
                        });
                    });
                }
            }
        }

        this.isSaving = false;
        this.displayLoadingScreen = false;

        if (result) {
            this.closeDialog.emit(this.deelplanVraag);
        }
    }

    // Deletes the created hercontrole
    onDeleteCreatedHercontrole() {
        this.createdHercontrole = null;
    }

    // Re-opens the created (vervolg)opdracht
    onReopenOpdracht() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.data = {
            createdOpdracht: this.createdOpdracht,
            translations: this.translations,
            dossier: this.dossier,
        };
        let dialogRef = this.dialog.open(NewOpdrachtDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((createdOpdracht: IDeelplanOpdracht) => {
            if (createdOpdracht != null) {
                this.createdOpdracht = createdOpdracht;
            }
        });
    }

    // Handles the file upload
    onOpenFileUpload() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = "upload-photo-dialog";
        dialogConfig.data = { translations: this.translations };

        let dialogRef = this.dialog.open(UploadPhotoDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe({
            next: (data) => {
                this.processFiles(Array.from(data.files));
            },
        });
    }

    // Processes the given files
    processFiles(files: File[]) {
        let count = 0;

        for (let file of files) {
            // With each file processing, max. 12 files are allowed.
            // If we reached 12 files, we'll break the loop.
            if (count >= 12) {
                break;
            }

            // Skip the current file if size larger than 10MB
            if (file.size > 10485760) {
                continue;
            }

            // If we're dealing with an pdf
            // We'll use a placeholder for displaying instead of the original image
            if (file.type.includes("pdf")) {
                this.files.push({
                    file: file,
                    content: "/assets/pdf_placeholder.png",
                });
            } else {
                var reader = new FileReader();

                reader.onload = (event: any) => {
                    let addedFile = {
                        file: file,
                        content: event.target.result,
                        disabled: this.createdOpdracht ? true : false,
                    };
                    // If we have an created opdracht Ww want to set this file to disabled by default otherwise it is also added as attachment to the opdracht
                    // this has no consequences for the added file on vraag level itself

                    this.files.push(addedFile);
                };

                reader.readAsDataURL(file);
            }

            count++;
        }
    }

    onOpenModal() {
        throw Error("NOT IMPLEMENTED YET");
    }

    // Deletes the file at given index
    deleteFile(index: number) {
        this.files.splice(index, 1);
    }

    // Handles the event of clicking on a file.
    // Opens the file in a new tab
    onFileClicked(file: any) {
        if (file.file.type.includes("pdf")) {
            var fileURL = URL.createObjectURL(file.file);
            window.open(fileURL);
        } else {
            var image = new Image();
            image.src = file.content;

            var w = window.open("");
            w?.document.write(image.outerHTML);
        }
    }

    // Handles the dropping of files in the dropzone.
    onDropFiles(event: any) {
        this.processFiles(event.addedFiles);
    }
}
