import { Component } from "@angular/core";
import { FilterState } from "src/app/model/enum.model";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";

@Component({
    selector: "app-dossier-filter",
    templateUrl: "./dossier-filter.component.html",
    styleUrls: ["./dossier-filter.component.scss"],
})
export class DossierFilterComponent {
    dateSelectLabel: string = "Datum";
    noChecklistSelectLabel: string = "Zonder checklist";
    startDateLabel: string = "Begindatum";
    endDateLabel: string = "Einddatum";

    dateRange = new UntypedFormGroup({
        start: new UntypedFormControl(
            {
                value: "",
                disabled: true,
            },
            [Validators.required]
        ),
        end: new UntypedFormControl(
            {
                value: "",
                disabled: true,
            },
            [Validators.required]
        ),
    });

    startDate = new Date(2022, 2, 21);
    startDateLater = new Date(2023, 2, 21);

    StateEnum = FilterState;
    state = FilterState.Date;

    constructor() {}

    stateDate() {
        this.state = this.StateEnum.Date;
    }

    stateNone() {
        this.state = this.StateEnum.None;
    }

    submitFilter() {
        if (this.state === this.StateEnum.Date && this.dateRange.invalid) {
            //submit with date
        } else {
            //submit with dossier without checklist
        }
    }
}
