import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { IHercontrole } from "src/app/model/hercontrole.model";
import { StandaardOpdrachtDialogComponent } from "./standaard-opdrachten-dialog/standaard-opdrachten-dialog.component";
import { IDeelplanOpdracht } from "src/app/model/deelplan-opdracht.model";
import { IBetrokkene } from "src/app/model/betrokkene.model";
import { DossierService } from "src/app/services/dossier.service";
import { IFile } from "src/app/model/file.model";
import { IKlantOrganisation } from "src/app/model/klant-organisation.model";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MatLegacyOption as MatOption } from "@angular/material/legacy-core";
import { SearchBetrokkeneDialogComponent } from "../search-betrokkene-dialog/search-betrokkene-dialog.component";
import { SearchKlantOrganisationDialogComponent } from "../search-klant-organisation-dialog/search-klant-organisation-dialog.component";
import { authenticatedUser } from "src/app/app.component";
import { AddNewUitvoerendeDialogComponent } from "../add-new-uitvoerende-dialog/add-new-uitvoerende-dialog.component";
import { IDossier } from "src/app/model/dossier.model";

@Component({
    selector: "app-new-modal-dialog.ts",
    templateUrl: "./new-opdracht-dialog.component.html",
    styleUrls: ["./new-opdracht-dialog.component.scss"],
})
export class NewOpdrachtDialogComponent implements AfterViewInit {
    // opdrachtTitelLabel: string = "Opdracht uitgeven";
    // hercontroleDatumTextLabel: string = "Datum Gereed";
    // standaardOpdrachtLabel: string = "Selecteer uit standaardopdrachten";
    // opdrachtomschrijvingLabel: string = "Opdrachtomschrijving";
    // // uitvoerendeTitleLabel: string = "Uitvoerende";
    // uitvoerendeLabel: string = "Voeg nieuwe uitvoerende toe";
    // opdrachtButtonLabel: string = "Opdracht uitgeven";

    // opdrachtOmschrijving: string = "";

    // deadlineDate: Date = new Date();

    toggle = true;
    status = "Enable";

    starttijdDate: Date = new Date();
    eindtijdDate: Date = new Date();

    vraagRisicoCondition!: number;

    // opdrachtForm = this.formBuilder.group({
    //     deadlineDate: { value: "" },
    //     opdrachtOmschrijving: { value: "" },
    // });

    deadlineFormControl = new UntypedFormControl(new Date(), [Validators.required]);
    descriptionFormControl = new UntypedFormControl("", [Validators.required]);
    klantOrganisationFormControl = new UntypedFormControl("");
    betrokkeneSelectionFormControl = new UntypedFormControl("");

    betrokkenen: IBetrokkene[] = [];
    klantOrganisations: IKlantOrganisation[] = [];
    filteredKlantOrganisationOptions: Observable<IKlantOrganisation[]>;
    filteredBetrokkeneOptions: Observable<IBetrokkene[]>;

    selectedBetrokkene?: IBetrokkene;
    selectedKlantOrganisation?: IKlantOrganisation;

    files: any[] = [];

    translations: any;

    authenticatedUser = authenticatedUser;

    // forceAddNewUitvoerende: boolean = false;

    dossier?: IDossier;

    constructor(
        private dialogRef: MatDialogRef<NewOpdrachtDialogComponent>,
        private formBuilder: UntypedFormBuilder,
        public datePipe: DatePipe,
        private dialog: MatDialog,
        private dossierService: DossierService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private cdRef: ChangeDetectorRef
    ) {
        this.dialogRef.disableClose = true;

        this.translations = data.translations;

        this.files = data.files;

        this.dossier = data.dossier;

        if (data && "currentToelichting" in data) {
            this.descriptionFormControl.setValue(data.currentToelichting);
        }

        if (data && "createdOpdracht" in data) {
            this.descriptionFormControl.setValue(data.createdOpdracht.omschrijving);
            this.deadlineFormControl.setValue(data.createdOpdracht.deadline);
            this.betrokkenen = [data.createdOpdracht.betrokkene];
            this.selectedBetrokkene = data.createdOpdracht.betrokkene;
            this.betrokkeneSelectionFormControl.setValue(this.selectedBetrokkene);
            this.selectedKlantOrganisation = data.createdOpdracht.betrokkene.klantOrganisation;
            this.klantOrganisationFormControl.setValue(this.selectedKlantOrganisation);
            this.files = data.createdOpdracht.attachments;
        }

        this.dossierService.getBetrokkeneOrganisations(this.dossier!.id).subscribe({
            next: (response) => {
                this.klantOrganisations = response;
            },
        });

        this.filteredKlantOrganisationOptions = this.klantOrganisationFormControl.valueChanges.pipe(
            startWith(""),
            map((value) => {
                const name = typeof value === "string" ? value : value?.name;
                return name ? this._filter(name as string, this.klantOrganisations) : this.klantOrganisations.slice();
            })
        );

        this.filteredBetrokkeneOptions = this.betrokkeneSelectionFormControl.valueChanges.pipe(
            startWith(""),
            map((value) => {
                const name = typeof value === "string" ? value : value?.name;
                return name ? this._filter(name as string, this.betrokkenen) : this.betrokkenen.slice();
            })
        );
    }

    ngAfterViewInit(): void {}

    displayFn(klantOrganisation: IKlantOrganisation): string {
        return klantOrganisation && klantOrganisation.name ? klantOrganisation.name : "";
    }

    private _filter(
        name: string,
        collection: IKlantOrganisation[] | IBetrokkene[] | any
    ): IKlantOrganisation[] | IBetrokkene[] {
        const filterValue = this._normalizeValue(name);
        return collection.filter((option: IKlantOrganisation | IBetrokkene) =>
            this._normalizeValue(option.name).includes(filterValue)
        ) as IKlantOrganisation[] | IBetrokkene[];
    }

    private _normalizeValue(value: string): string {
        return value.toLowerCase().replace(/\s/g, "");
    }

    getKlantOrganisationBetrokkenen(option: MatOption) {
        this.selectedKlantOrganisation = option.value;

        this.dossierService.getExterneBetrokkenen(this.dossier!.id, this.selectedKlantOrganisation?.id).subscribe({
            next: (response) => {
                this.betrokkenen = response;
            },
        });
    }

    betrokkeneSelected(option: MatOption) {
        this.selectedBetrokkene = option.value;
    }

    // changeDate() {
    //     this.deadlineDate = this.opdrachtForm.controls["deadlineDate"].value;
    // }

    onOpenOpdracht() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = "standaard-opdrachten-dialog";
        dialogConfig.data = this.data;
        let dialogRef = this.dialog.open(StandaardOpdrachtDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((standaardOpdracht: string) => {
            if (standaardOpdracht != null) {
                let currentValue = this.descriptionFormControl.value;
                this.descriptionFormControl.setValue(currentValue + standaardOpdracht);
            }
        });
    }

    onSelectBetrokkene(event: any) {
        this.selectedBetrokkene = this.betrokkenen.filter((b) => b.id == event.target.value)[0];
    }

    submitOpdracht() {
        this.selectedBetrokkene!.klantOrganisation = this.selectedKlantOrganisation;

        let createdOpdracht: IDeelplanOpdracht = {
            omschrijving: this.descriptionFormControl.value,
            deadline: this.deadlineFormControl.value,
            betrokkene: this.selectedBetrokkene,
            attachments: this.files,
        };

        this.dialogRef.close(createdOpdracht);
    }

    enableDisableAttachment(file: any) {
        file.disabled = !file.disabled;
    }

    closeDialog() {
        this.dialogRef.close();
    }

    openSearchBetrokkeneDialog() {
        const dialogConfig = new MatDialogConfig();
        let dialogRef = this.dialog.open(SearchBetrokkeneDialogComponent, {
            disableClose: true,
            data: {
                translations: this.translations,
                dossier: this.dossier,
                klantOrganisation: this.klantOrganisationFormControl.value,
            },
        });

        dialogRef.beforeClosed().subscribe({
            next: (result) => {
                if (result) {
                    result.isNew = true;

                    this.betrokkenen.push(result);
                    this.selectedBetrokkene = result;
                    this.betrokkeneSelectionFormControl.setValue(result);
                }
            },
        });
    }

    klantOranisationInputChanged() {
        if (this.selectedKlantOrganisation) {
            if (this.selectedKlantOrganisation.isNew) {
                this.selectedBetrokkene = undefined;
                this.betrokkeneSelectionFormControl.reset();
                this.betrokkenen = [];
            }

            this.selectedKlantOrganisation = undefined;
        }
    }

    klantOrganisationOptionsClosed() {
        if (!this.selectedKlantOrganisation) {
            this.klantOrganisationFormControl.reset();
            this.selectedKlantOrganisation = undefined;
            // this.forceAddNewUitvoerende = false;
            this.betrokkenen = [];
        }
    }

    betrokkeneInputChanged() {
        if (this.selectedBetrokkene) {
            this.selectedBetrokkene = undefined;
        }
    }

    betrokkeneOptionsClosed() {
        if (!this.selectedBetrokkene) {
            this.betrokkeneSelectionFormControl.reset();
            this.selectedBetrokkene = undefined;
        }
    }

    openSearchKlantOrganisationDialog() {
        this.betrokkenen = [];

        let dialogRef = this.dialog.open(SearchKlantOrganisationDialogComponent, {
            disableClose: true,
            data: {
                translations: this.translations,
                dossier: this.data.dossier,
                input: this.klantOrganisationFormControl.value,
            },
        });

        dialogRef.beforeClosed().subscribe({
            next: (result) => {
                if (result) {
                    result.isNew = true;

                    this.selectedKlantOrganisation = result;
                    this.klantOrganisationFormControl.setValue(result);
                    this.selectedBetrokkene = undefined;
                    // this.forceAddNewUitvoerende = true;
                }
            },
        });
    }

    openAddNewUitvoerendeDialog() {
        let dialogRef = this.dialog.open(AddNewUitvoerendeDialogComponent, {
            disableClose: true,
            data: {
                translations: this.translations,
                klantOrganisations: this.selectedKlantOrganisation,
            },
        });

        dialogRef.beforeClosed().subscribe({
            next: (result) => {
                if (result) {
                    result.isNew = true;

                    this.betrokkenen = [result];
                    this.selectedBetrokkene = result;
                    this.betrokkeneSelectionFormControl.setValue(result);
                }
            },
        });
    }
}
