<app-loading-screen [displayLoadingScreen]="displayLoadingScreen"></app-loading-screen>

<div class="wrapper" g2sLayout="column" g2sLayoutGap="10px">
    <div class="betrokkenen-header" g2sLayout="row">
        <p class="betrokkenen-header-text">
            {{ translations?.Chepp_ChecklistView_SearchForKlantOrganisationByKVKorName }}
        </p>
        <mat-icon matDialogClose matRipple class="close-button">close</mat-icon>
    </div>
    <div class="uitvoerende" g2sLayout="column">
        <p g2sLayout="row" g2sLayoutAlign="start center">
            <span><mat-icon>info</mat-icon></span> &nbsp;&nbsp;
            <span>{{ translations?.BackOffice_UserManagement_ExternalOrganisationsEditSearchInfoLabel }}</span>
        </p>
        <form g2sFlex>
            <mat-form-field g2sFlex>
                <mat-label>{{ translations?.Organisation }}</mat-label>
                <input
                    type="text"
                    matInput
                    [formControl]="klantOrganisationFormControl"
                    [errorStateMatcher]="matcher"
                    placeholder="{{ translations?.Chepp_ChecklistView_EnterTheOrganisationNameOrKVK }}"
                    (change)="onOrganisationInputChange($event)" />
                <mat-error *ngIf="klantOrganisationFormControl.hasError('required')">
                    {{ translations?.Chepp_ChecklistView_ThisFieldIsRequired}}
                </mat-error>
            </mat-form-field>
        </form>
    </div>

    <button
        class="opdracht-uitgeven"
        (click)="performSearch()"
        mat-flat-button
        [disabled]="klantOrganisationFormControl.invalid"
        type="submit">
        {{translations?.Search}}
    </button>

    <ng-container *ngIf="searched">
        <ng-container *ngIf="klantOrganisations && klantOrganisations.length > 0; else noKlantOrganisationsFound">
            <div class="klant-organisations-wrapper" g2sLayout="column" g2sLayoutGap="10px">
                <p>{{ translations?.Chepp_ChecklistView_SelectAnOrganisation }}:</p>

                <button
                    mat-flat-button
                    type="button"
                    g2sLayoutAlign="start center"
                    *ngFor="let klantOrganisation of klantOrganisations"
                    (click)="onSelectKlantOrganisation(klantOrganisation)">
                    {{ klantOrganisation.name }} - <span class="kvk-label">{{ klantOrganisation.kvk }}</span>
                </button>
            </div>
        </ng-container>
        <ng-template #noKlantOrganisationsFound>
            <div class="klant-organisations-wrapper" g2sLayout="column" g2sLayoutGap="10px">
                <p>{{ translations?.Chepp_ChecklistView_NoKlantOrganisationsFound }}</p>
            </div>
        </ng-template>
    </ng-container>
</div>
