<div class="wrapper">
    <div class="header">
        <p class="header-text">{{ changeLanguageHeaderTextLabel }}</p>
        <mat-icon matRipple (click)="closeDialog()" class="close-icon">close</mat-icon>
    </div>
    <!-- Displays a panel where a language can be selected -->
    <div class="language-list">
        <mat-button-toggle-group class="toggle-group" g2sLayout="column" [(ngModel)]="selectedLanguage">
            <ng-container *ngFor="let language of languages">
                <mat-button-toggle
                    class="toggle-button"
                    [value]="language"
                    matRipple
                    [ngClass]="{ 'active-toggle': selectedLanguage === language }">
                    <div (click)="onToggleClick(language)"></div>
                    {{ language }}
                </mat-button-toggle>
            </ng-container>
        </mat-button-toggle-group>
    </div>
</div>
<button mat-flat-button (click)="submitSelectedLanguage()" class="save-button" [disabled]="!selectedLanguage">
    {{ saveBtnTextLabel }}
</button>
