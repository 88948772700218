<div g2sLayout="column">
    <div class="wki-document-header" g2sLayout="row" g2sLayoutAlign="center center">
        <p class="doc-header-text">{{ headerTextLabel }}</p>
        <mat-icon matDialogClose class="filter-icon">navigate_before</mat-icon>
        <span matDialogClose class="back">{{ backLabel }}</span>
        <mat-icon (click)="openFilter()" class="search-icon">search</mat-icon>
    </div>
    <div g2sLayout="row wrap" g2sLayoutAlign="start start" g2sFlex class="wki-mat-tab-container">
        <mat-tab-group mat-stretch-tabs style="width: 100%" class="content">
            <mat-tab label="Lijst">
                <ng-template mat-tab-label>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24">
                        <defs>
                            <clipPath id="clip-list_view_white">
                                <rect width="24" height="24" />
                            </clipPath>
                        </defs>
                        <g id="list_view_white" clip-path="url(#clip-list_view_white)">
                            <g id="list_white" transform="translate(1 -3.4)">
                                <g id="Group_3" data-name="Group 3" transform="translate(0 6.4)">
                                    <circle
                                        id="Ellipse_1"
                                        data-name="Ellipse 1"
                                        cx="2.425"
                                        cy="2.425"
                                        r="2.425"
                                        fill="#fff" />
                                    <circle
                                        id="Ellipse_2"
                                        data-name="Ellipse 2"
                                        cx="2.425"
                                        cy="2.425"
                                        r="2.425"
                                        transform="translate(0 6.674)"
                                        fill="#fff" />
                                    <circle
                                        id="Ellipse_3"
                                        data-name="Ellipse 3"
                                        cx="2.425"
                                        cy="2.425"
                                        r="2.425"
                                        transform="translate(0 13.549)"
                                        fill="#fff" />
                                    <g id="Group_2" data-name="Group 2" transform="translate(7.186 0.823)">
                                        <path
                                            id="Path_5"
                                            data-name="Path 5"
                                            d="M32.745,13.281H45.916a.456.456,0,0,0,.445-.445V10.545a.446.446,0,0,0-.445-.445H32.745a.446.446,0,0,0-.445.445v2.292A.446.446,0,0,0,32.745,13.281Z"
                                            transform="translate(-32.3 -10.1)"
                                            fill="#fff" />
                                        <path
                                            id="Path_6"
                                            data-name="Path 6"
                                            d="M45.916,40.2H32.745a.446.446,0,0,0-.445.445v2.292a.446.446,0,0,0,.445.445H45.916a.456.456,0,0,0,.445-.445V40.645A.446.446,0,0,0,45.916,40.2Z"
                                            transform="translate(-32.3 -33.503)"
                                            fill="#fff" />
                                        <path
                                            id="Path_7"
                                            data-name="Path 7"
                                            d="M45.916,71.1H32.745a.446.446,0,0,0-.445.445v2.291a.446.446,0,0,0,.445.445H45.916a.456.456,0,0,0,.445-.445V71.545A.446.446,0,0,0,45.916,71.1Z"
                                            transform="translate(-32.3 -57.529)"
                                            fill="#fff" />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    Lijst
                </ng-template>
                <div class="list">
                    <div g2sLayout="row" style="height: 20px">
                        <div g2sFlex="5"></div>
                        <div g2sFlex="20">
                            <p class="file-title">{{ filenameTitleLabel }}</p>
                        </div>
                        <div g2sFlex="18">
                            <p class="title-container">{{ categoryTitleLabel }}</p>
                        </div>
                        <div g2sFlex="22">
                            <p class="title-container">{{ labelsTitleLabel }}</p>
                        </div>
                        <div g2sFlex="8">
                            <p class="title-container">{{ versionTitleLabel }}</p>
                        </div>
                        <div g2sFlex="14">
                            <p class="title-container">{{ statusTitleLabel }}</p>
                        </div>
                    </div>
                    <div class="list-div">
                        <div *ngFor="let document of documents" g2sLayout="column" class="list-item">
                            <div g2sLayout="row" style="height: 55px !important" (click)="onSelectFile(file)">
                                <div g2sFlex="5" g2sLayoutAlign="center center">
                                    <mat-icon style="color: #336699; font-size: 24px">insert_drive_file</mat-icon>
                                </div>
                                <div g2sFlex="20" g2sLayoutAlign="start center">
                                    <p class="file-info">{{ filenameLabel }}</p>
                                </div>
                                <div g2sFlex="18" g2sLayoutAlign="center center">
                                    <p class="info-container">{{ categoryLabel }}</p>
                                </div>
                                <div g2sFlex="22" g2sLayoutAlign="center center">
                                    <p class="info-container">{{ labelsLabel }}</p>
                                </div>
                                <div g2sFlex="8" g2sLayoutAlign="center center">
                                    <p class="info-container">{{ versionTitleLabel }}</p>
                                </div>
                                <div style="margin: auto" g2sFlex="14">
                                    <ng-container *ngIf="versie == true; else blocked">
                                        <p class="info-container">
                                            <mat-icon style="color: green; margin-top: 2px">check_circle</mat-icon>
                                        </p>
                                    </ng-container>
                                    <ng-template #blocked>
                                        <p class="info-container"><mat-icon style="color: red">block</mat-icon></p>
                                    </ng-template>
                                </div>
                                <div g2sFlex g2sLayout="row" g2sLayoutGap="2px" g2sLayoutAlign="center center">
                                    <p class="list-status-marker red"></p>
                                    <div class="list-status-gap">{{ redStatusAmount }}</div>
                                </div>
                                <div g2sFlex g2sLayout="row" g2sLayoutGap="2px" g2sLayoutAlign="center center">
                                    <p class="list-status-marker yellow"></p>
                                    <div class="list-status-gap">{{ yellowStatusAmount }}</div>
                                </div>
                                <div g2sFlex g2sLayout="row" g2sLayoutGap="2px" g2sLayoutAlign="center center">
                                    <p class="list-status-marker green"></p>
                                    <div class="list-status-gap">{{ greenStatusAmount }}</div>
                                </div>
                            </div>
                            <hr class="horizontal-divider-content" />
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Thumbnails">
                <ng-template mat-tab-label>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24">
                        <defs>
                            <clipPath id="clip-thumbnail_view_white">
                                <rect width="24" height="24" />
                            </clipPath>
                        </defs>
                        <g id="thumbnail_view_white" clip-path="url(#clip-thumbnail_view_white)">
                            <g id="Group_1" data-name="Group 1" transform="translate(0 1)">
                                <rect
                                    id="Rectangle_3"
                                    data-name="Rectangle 3"
                                    width="9"
                                    height="9"
                                    rx="1"
                                    transform="translate(2 12)"
                                    fill="#fff" />
                                <rect
                                    id="Rectangle_5"
                                    data-name="Rectangle 5"
                                    width="9"
                                    height="9"
                                    rx="1"
                                    transform="translate(13 12)"
                                    fill="#fff" />
                                <rect
                                    id="Rectangle_6"
                                    data-name="Rectangle 6"
                                    width="9"
                                    height="9"
                                    rx="1"
                                    transform="translate(2 1)"
                                    fill="#fff" />
                                <rect
                                    id="Rectangle_7"
                                    data-name="Rectangle 7"
                                    width="9"
                                    height="9"
                                    rx="1"
                                    transform="translate(13 1)"
                                    fill="#fff" />
                            </g>
                        </g>
                    </svg>
                    Thumbnails
                </ng-template>
                <div class="document-header">
                    <p class="rec-document-header-text">{{ recommendedDocHeaderLabel }}</p>
                </div>
                <div class="wb-document">
                    <ng-container *ngIf="filteredFile; else noFilteredFiles">
                        <div class="document-background" g2sLayout="row wrap">
                            <div class="document-card" g2sLayout="column" (click)="onSelectFile(file)">
                                <div class="img">
                                    <a href="" target="_blank">
                                        <img
                                            src="https://www.agconnect.nl/sites/ag/files/2020-08/centric_gouda.png"
                                            class="image" />
                                    </a>
                                </div>
                                <div g2sFlex="15" g2sLayout="row" class="doc-ticket-count-wrapper">
                                    <div g2sFlex g2sLayout="row" g2sLayoutGap="2px" g2sLayoutAlign="center center">
                                        <p class="card-status-marker red"></p>
                                        <div class="status-gap">{{ redStatusAmount }}</div>
                                    </div>
                                    <div g2sFlex g2sLayout="row" g2sLayoutGap="2px" g2sLayoutAlign="center center">
                                        <p class="card-status-marker yellow"></p>
                                        <div class="status-gap">{{ yellowStatusAmount }}</div>
                                    </div>
                                    <div g2sFlex g2sLayout="row" g2sLayoutGap="2px" g2sLayoutAlign="center center">
                                        <p class="card-status-marker green"></p>
                                        <div class="status-gap">{{ greenStatusAmount }}</div>
                                    </div>
                                    <div g2sFlex g2sLayout="row" g2sLayoutGap="2px" g2sLayoutAlign="center center">
                                        <mat-icon class="icon">pin_drop</mat-icon>
                                        <div class="status-gap">{{ documentStatusAmount }}</div>
                                    </div>
                                </div>
                                <div>
                                    <p class="file-name">{{ documentFileName }}</p>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #noFilteredFiles>
                        <p class="empty-document">{{ noRecommendedDocLabel }}</p>
                    </ng-template>
                </div>

                <div class="document-header">
                    <p class="document-header-text">{{ docLabel }}</p>
                </div>
                <!-- folder card -->
                <div class="wb-document">
                    <ng-container *ngIf="folder != null">
                        <div class="document-background" g2sLayout="row wrap">
                            <div class="document-card" g2sLayout="column" (click)="onSelectFile(file)">
                                <mat-icon class="folder-style">folder</mat-icon>
                                <div>
                                    <p class="folder-name">{{ folderName }} - ({{ documentCount }})</p>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container>
                        <div class="document-background" g2sLayout="row wrap">
                            <div
                                *ngFor="let document of documents"
                                class="document-card"
                                g2sLayout="column"
                                (click)="onSelectFile(file)">
                                <div class="img">
                                    <a href="" target="_blank">
                                        <img
                                            src="https://www.agconnect.nl/sites/ag/files/2020-08/centric_gouda.png"
                                            class="image" />
                                    </a>
                                </div>
                                <div g2sFlex="15" g2sLayout="row" class="doc-ticket-count-wrapper">
                                    <div g2sFlex g2sLayout="row" g2sLayoutGap="2px" g2sLayoutAlign="center center">
                                        <p class="card-status-marker red"></p>
                                        <div class="status-gap">{{ redStatusAmount }}</div>
                                    </div>
                                    <div g2sFlex g2sLayout="row" g2sLayoutGap="2px" g2sLayoutAlign="center center">
                                        <p class="card-status-marker yellow"></p>
                                        <div class="status-gap">{{ yellowStatusAmount }}</div>
                                    </div>
                                    <div g2sFlex g2sLayout="row" g2sLayoutGap="2px" g2sLayoutAlign="center center">
                                        <p class="card-status-marker green"></p>
                                        <div class="status-gap">{{ greenStatusAmount }}</div>
                                    </div>
                                    <div g2sFlex g2sLayout="row" g2sLayoutGap="2px" g2sLayoutAlign="center center">
                                        <mat-icon class="icon">pin_drop</mat-icon>
                                        <div class="status-gap">{{ documentStatusAmount }}</div>
                                    </div>
                                </div>
                                <div>
                                    <p class="file-name">{{ documentFileName }}</p>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!documents && !folder">
                        <p class="empty-document">{{ noDocLabel }}</p>
                    </ng-container>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
