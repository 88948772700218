<div g2sLayout="column" class="flex-margin">
    <div>
        <mat-form-field class="filterSelect" g2sFlex appearance="fill">
            <mat-label>Filter</mat-label>
            <mat-select>
                <mat-option (click)="stateDate()">{{ dateSelectLabel }}</mat-option>
                <mat-option (click)="stateNone()">{{ noChecklistSelectLabel }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="state === StateEnum.Date" g2sLayout="row" g2sLayoutAlign="space-between center">
        <mat-form-field g2sFlex="49.5%" appearance="fill">
            <mat-label>{{ startDateLabel }}</mat-label>
            <input matInput autocomplete="off" [matDatepicker]="startPicker" />
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field g2sFlex="49.5%" appearance="fill">
            <mat-label>{{ endDateLabel }}</mat-label>
            <input matInput autocomplete="off" [matDatepicker]="endPicker" />
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
        </mat-form-field>
    </div>
    <div>
        <button class="click-styling" mat-flat-button color="primary" g2sFlex (click)="submitFilter()">
            <mat-icon>filter_list</mat-icon>
        </button>
    </div>
</div>
