<div class="wrapper" g2sLayout="column" g2sLayoutGap="10px">
    <div class="betrokkenen-header" g2sLayout="row">
        <p class="betrokkenen-header-text">{{ translations?.Chepp_ChecklistView_CreateNewUitvoerende }}</p>
        <mat-icon matDialogClose matRipple class="close-button">close</mat-icon>
    </div>

    <form g2sLayout="column" g2sLayoutGap="10px">
        <div g2sFlex class="uitvoerende" g2sLayout="column">
            <mat-form-field>
                <mat-label>{{ translations?.Emailadres }}</mat-label>
                <input
                    type="email"
                    matInput
                    [formControl]="emailFormControl"
                    [errorStateMatcher]="matcher"
                    placeholder="{{ translations?.Chepp_ChecklistView_EnterTheBetrokkeneEmail }}" />
                <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                    {{ translations?.Chepp_ChecklistView_EnterAValidEmailAddress}}
                </mat-error>
                <mat-error *ngIf="emailFormControl.hasError('required')">
                    {{ translations?.Chepp_ChecklistView_ThisFieldIsRequired}}
                </mat-error>
            </mat-form-field>
        </div>

        <div g2sFlex class="uitvoerende" g2sLayout="column">
            <mat-form-field>
                <mat-label>{{ translations?.FirstName }}</mat-label>
                <input
                    type="text"
                    matInput
                    [formControl]="firstNameFormControl"
                    [errorStateMatcher]="matcher"
                    placeholder="{{ translations?.FirstName }}" />
                <mat-error *ngIf="emailFormControl.hasError('required')">
                    {{ translations?.Chepp_ChecklistView_ThisFieldIsRequired}}
                </mat-error>
            </mat-form-field>
        </div>

        <div g2sFlex class="uitvoerende" g2sLayout="column">
            <mat-form-field>
                <mat-label>{{ translations?.LastName }}</mat-label>
                <input
                    type="text"
                    matInput
                    [formControl]="lastNameFormControl"
                    [errorStateMatcher]="matcher"
                    placeholder="{{ translations?.LastName }}" />
                <mat-error *ngIf="emailFormControl.hasError('required')">
                    {{ translations?.Chepp_ChecklistView_ThisFieldIsRequired}}
                </mat-error>
            </mat-form-field>
        </div>
    </form>

    <button
        class="opdracht-uitgeven"
        (click)="add()"
        mat-flat-button
        [disabled]="emailFormControl.invalid || lastNameFormControl.invalid || firstNameFormControl.invalid"
        type="submit">
        {{translations?.Create}}
    </button>
</div>
