import { Component, Inject } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { Router } from "@angular/router";
import { IHercontrole } from "src/app/model/hercontrole.model";
import { AnswerModalTimelineComponent } from "./answer-modal-timeline/answer-modal-timeline.component";

@Component({
    selector: "app-answer-modal-dialog.ts",
    templateUrl: "./answer-modal-dialog.component.html",
    styleUrls: ["./answer-modal-dialog.component.scss"],
})
export class AnswerModalDialogComponent {
    dossierId?: number;
    deelplanId?: number;
    checklistId?: number;
    titelTextLabel: string = "Menu";

    vraagRisicoCondition!: number;
    vraagKostentable: boolean = false;

    constructor(
        private dialogRef: MatDialogRef<AnswerModalDialogComponent>,
        private router: Router,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.dossierId = data.dossierId;
        this.deelplanId = data.deelplanId;
        this.checklistId = data.checklistId;
    }

    onOpenTimeline() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = "vraag-answer-modal-timeline-dialog";
        let dialogRef = this.dialog.open(AnswerModalTimelineComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((hercontrole: IHercontrole) => {});
    }

    openDocumentsOverview() {
        this.dialog.closeAll();
        this.router.navigate([`/documents-overview/${this.dossierId}/${this.deelplanId}/${this.checklistId}`]);
    }
}
