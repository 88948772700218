import { Component } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { TokenModalComponent } from "../token-modal/token-modal.component";
import { SnackbarService } from "src/app/services/snackbar.service";

@Component({
    selector: "app-password-modal",
    templateUrl: "./password-modal.component.html",
    styleUrls: ["./password-modal.component.scss"],
})
export class PasswordModalComponent {
    passwordDialogTitleText = "Wachtwoord resetten";
    passwordDialogContentText =
        "Voer onderstaand uw e-mailadres in. krijgt op dit e-mailadres een token waarmee vervolgens een nieuw wachtwoord kan worden aangemaakt";
    passwordResetBtnText = "Resetten";
    passwordTokenBtnText = "Ik heb al een token.";
    passwordSnackbar = "E-mail verstuurd";
    emailText = "E-mail";

    passwordModal = new UntypedFormGroup({
        email: new UntypedFormControl("", [Validators.required]),
    });

    constructor(public dialog: MatDialog, private snackBar: SnackbarService) {}

    onSubmit() {
        if (this.passwordModal.valid) {
            //dostuff
        }
    }

    openTokenDialog() {
        this.closeDialog();
        this.dialog.open(TokenModalComponent);
    }

    resetToken() {
        if (this.passwordModal.valid) {
            //dostuff
            this.openTokenDialog();
        }
    }

    closeDialog() {
        this.dialog.closeAll();
    }
}
