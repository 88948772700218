<div class="wrapper">
    <div class="header" g2sLayout="row">
        <p class="header-text">{{ translations?.Chepp_ChecklistView_AddNewHercontrole }}</p>
        <span (click)="closeDialog()" matRipple style="cursor: pointer" class="material-icons exit-button">close</span>
    </div>
    <div class="dialog-body">
        <form #f="ngForm" [formGroup]="hercontroleForm" (ngSubmit)="onSubmitHercontroleForm()">
            <div g2sLayout="row" g2sLayoutAlign="start start" class="date-time-section">
                <mat-datepicker-toggle #datetoggle (click)="picker.open()" matSuffix>
                    <mat-icon matDatepickerToggleIcon>today</mat-icon>
                </mat-datepicker-toggle>
                <div style="position: relative">
                    <p class="time-text">{{ translations?.Chepp_ChecklistView_HercontroleDate }}</p>
                    <p class="selected-date-text" style="cursor: pointer" (click)="picker.open()">
                        {{ (datePipe.transform(hercontroleForm.controls["initialDate"].value, 'EEEE, dd-MM-yyyy')) }}
                    </p>
                    <mat-form-field style="width: 1px; visibility: hidden; position: absolute; top: -1px">
                        <input
                            required
                            id="initialDate"
                            formControlName="initialDate"
                            matInput
                            [matDatepicker]="picker" />
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div g2sLayout="row" g2sLayoutAlign="start start" class="date-time-section">
                <mat-datepicker-toggle (click)="timepicker1.focus()" matSuffix>
                    <mat-icon matDatepickerToggleIcon>access_time</mat-icon>
                </mat-datepicker-toggle>
                <div>
                    <p class="time-text">{{ translations?.Chepp_ChecklistView_StartTime }}</p>
                    <input
                        (focus)="starttijdFocus(true)"
                        (blur)="changeStarttijd(); starttijdFocus(false)"
                        #time1
                        required
                        id="starttijdDate"
                        formControlName="starttijdDate"
                        matInput
                        type="time"
                        class="time-input"
                        #timepicker1
                        required />
                </div>
            </div>
            <div g2sLayout="row" g2sLayoutAlign="start start" class="date-time-section">
                <mat-datepicker-toggle (click)="timepicker2.focus()" matSuffix>
                    <mat-icon matDatepickerToggleIcon>access_time</mat-icon>
                </mat-datepicker-toggle>
                <div>
                    <p class="time-text">{{ translations?.Chepp_ChecklistView_EndTime }}</p>
                    <input
                        (focus)="eindtijdFocus(true)"
                        (blur)="changeEindtijd(); eindtijdFocus(false)"
                        #time2
                        required
                        id="eindtijdDate"
                        formControlName="eindtijdDate"
                        matInput
                        type="time"
                        class="time-input"
                        #timepicker2
                        required />
                </div>
            </div>
            <button
                [disabled]="starttijdFocussed || eindtijdFocussed || time1.value === '' || time2.value === '' || !f.valid"
                mat-flat-button
                type="submit"
                class="save-changes-button">
                <mat-icon class="add-icon">add</mat-icon>
            </button>
            <ng-container *ngIf="existingHercontroles && existingHercontroles.length > 0">
                <p class="blue-text">{{ translations?.Chepp_ChecklistView_ChooseFromExistingHercontroles }}</p>
                <div class="hercontroles-container">
                    <button
                        mat-flat-button
                        type="button"
                        class="hercontrole-date-button"
                        *ngFor="let hercontrole of existingHercontroles"
                        [ngClass]="{ 'hercontrole-date-button-enabled': hercontrole === selectedDate }"
                        (click)="onDateSelected(hercontrole)">
                        {{ datePipe.transform(hercontrole.date, 'dd-MM-yyyy') + ' ' + translations?.HercontroleTo.toLowerCase() + ' ' +
                        datePipe.transform(hercontrole.start, 'HH:mm') + ' ' + translations?.HercontroleFrom.toLowerCase() + ' ' +
                        datePipe.transform(hercontrole.end, 'HH:mm') }}
                    </button>
                </div>
            </ng-container>
        </form>
    </div>
</div>
