<app-loading-screen [displayLoadingScreen]="displayLoadingScreen"></app-loading-screen>

<ng-container *ngIf="dossier">
    <app-menu-bar-checklist [dossierId]="dossier.id"></app-menu-bar-checklist>
</ng-container>
<div style="height: 10vh"></div>
<div class="checklist-view-wrapper" g2sLayout.gt-md="row" g2sLayout.lt-md="column" g2sLayoutAlign="start">
    <!-- CHECKLIST INFO NOT SHOWN FOR NOW AS THIS IS NOT IMPLEMENTED YET IN THE BACK-END -->
    <!-- TESTERS ONLY WANT TO SEE WHAT IS ACTUALLY IMPLEMENTED -->
    <!-- REMOVE THE EMPTY DIV BELOW IF THE CHECKLIST INFO MUST BE DISPLAYED -->
    <!-- <div g2sFlex="25" g2sFlex.md="35" g2sFlex.lt-md="90"></div> -->
    <app-checklist-info
        *ngIf="dossier"
        class="wrapper"
        [checklist]="deelplanChecklist"
        [hoofdgroepen]="hoofdgroepen"
        [dossier]="dossier"
        [deelplan]="deelplan"
        g2sFlex="25"
        g2sFlex.md="35"
        g2sFlex.lt-md="90"></app-checklist-info>
    <div g2sFlex="3"></div>
    <div class="wrapper" g2sLayout="column" g2sFlex g2sFlex.md="65" g2sFlex.lt-md="90">
        <ng-container *ngIf="dossier?.heeftOpdrachten; else geenOpdrachten">
            <ng-container *ngFor="let hoofdgroep of hoofdgroepen">
                <ng-container *ngFor="let vraag of hoofdgroep.vragen">
                    <mat-card class="groep-card-opdrachten">
                        <div g2sLayout="row" g2sLayoutAlign="space-between center" class="txt-opdracht-hoofdgroep">
                            <mat-card-title class="opdracht-vraag-title">{{ hoofdgroep.name }}</mat-card-title>
                            <div
                                *ngIf="dossier?.heeftOpdrachten && hoofdgroep.notificationCount && hoofdgroep.notificationCount > 0">
                                <mat-icon class="hoofdgroep-notification">notifications</mat-icon>
                                <button class="notification">{{ hoofdgroep.notificationCount }}</button>
                            </div>
                        </div>
                    </mat-card>
                    <mat-card *ngIf="vraag.opdrachten" class="groep-card">
                        <div class="groep-content">
                            <div g2sLayout="row" g2sLayoutAlign="space-between center">
                                <mat-card-title class="opdracht-vraag-title">{{ vraag.type }}</mat-card-title>
                                <div
                                    *ngIf="dossier?.heeftOpdrachten && vraag.notificationCount && vraag.notificationCount > 0">
                                    <mat-icon class="vraag-notification">notifications</mat-icon>
                                    <button class="notification">{{ vraag.notificationCount }}</button>
                                </div>
                            </div>
                            <hr />
                            <mat-card-title class="txt-vraagnaam">{{ vraag.name }}</mat-card-title>
                            <!-- temporary date -->
                            <mat-card-subtitle class="txt-vraagdatum">{{ vraag.gereedDatum }}</mat-card-subtitle>
                            <mat-expansion-panel #panel class="hoofdgroepCollapse" hideToggle>
                                <mat-expansion-panel-header matRipple class="centerHeader">
                                    <ng-container *ngIf="!panel.expanded; else less">
                                        <span class="material-icons"> expand_more </span>
                                    </ng-container>
                                    <ng-template #less>
                                        <span class="material-icons"> expand_less </span>
                                    </ng-template>
                                </mat-expansion-panel-header>
                                <!-- forloop opdrachten met het openen van expansion panel -->
                                <ng-container *ngFor="let opdracht of vraag.opdrachten; index as i">
                                    <mat-card
                                        matRipple
                                        (click)="openOpdracht(hoofdgroep, vraag, opdracht)"
                                        class="opdrachtItemCard">
                                        <div g2sLayout="row">
                                            <p class="opdracht-label">{{ translations?.Opdracht }}</p>
                                            <div
                                                class="notification-information"
                                                *ngIf="dossier?.heeftOpdrachten && opdracht.notificationCount && opdracht.notificationCount > 0">
                                                <mat-icon class="vraag-notification">notifications</mat-icon>
                                            </div>
                                            <button
                                                class="notification"
                                                *ngIf="dossier?.heeftOpdrachten && opdracht.notificationCount && opdracht.notificationCount > 0">
                                                {{ opdracht?.notificationCount }}
                                            </button>
                                        </div>
                                        <hr class="opdracht-divider" />
                                        <mat-card-title class="txt-opdrachttitle">
                                            {{ opdracht.opdrachtText }}
                                        </mat-card-title>
                                        <mat-card-subtitle class="txt-vraagdatum"
                                            >{{ opdracht.gereedDatum }}</mat-card-subtitle
                                        >
                                    </mat-card>
                                </ng-container>
                            </mat-expansion-panel>
                        </div>
                    </mat-card>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-template #geenOpdrachten>
            <ng-container *ngFor="let hoofdgroep of hoofdgroepen">
                <ng-container>
                    <mat-card class="groep-card">
                        <mat-card-content>
                            <div g2sLayout="column" g2sLayoutGap="5px">
                                <div
                                    *ngIf="hoofdgroep.canAnswerOnHoofdgroepLevel"
                                    g2sLayout="row"
                                    g2sLayoutAlign="end start"
                                    class="menu-button">
                                    <mat-icon
                                        matRipple
                                        (click)="openHoofdgroep(hoofdgroep)"
                                        class="answerAll"
                                        color="primary">
                                        more_horiz
                                    </mat-icon>
                                </div>
                                <div class="groep-content">
                                    <div g2sLayout="row" g2sLayoutAlign="space-between start">
                                        <mat-card-title>{{ translations?.Hoofdgroep }}</mat-card-title>
                                        <div
                                            class="status-marker status-marker-hoofdgroep {{hoofdgroep.woColor}}"></div>
                                    </div>
                                    <hr />
                                    <mat-card-title class="txt-hoofdgroep">{{ hoofdgroep.name }}</mat-card-title>
                                    <br />
                                </div>
                            </div>
                        </mat-card-content>
                        <mat-divider inset></mat-divider>
                        <mat-expansion-panel
                            #panel
                            class="hoofdgroepCollapse"
                            hideToggle
                            [expanded]="hoofdgroep.panelExpanded"
                            (expandedChange)="onHoofdgroepExpandedChanged(hoofdgroep, $event)">
                            <mat-expansion-panel-header class="centerHeader">
                                <ng-container *ngIf="!panel.expanded; else less">
                                    <span class="material-icons"> expand_more </span>
                                </ng-container>
                                <ng-template #less>
                                    <span class="material-icons"> expand_less </span>
                                </ng-template>
                            </mat-expansion-panel-header>
                            <!-- forloop deelplannen met het openen van expansion panel -->
                            <ng-container *ngIf="hoofdgroep.vragen">
                                <ng-container *ngFor="let vraag of hoofdgroep.vragen; index as i">
                                    <mat-card
                                        (click)="openVraag(hoofdgroep, vraag)"
                                        class="vraagItemCard click-styling">
                                        <div
                                            g2sLayout="row"
                                            *ngIf="deelplanChecklist?.toonSamenvatting == true; else waardeoordeelTitle"
                                            g2sLayoutAlign="space-between start">
                                            <ng-container
                                                *ngIf="vraag.waardeoordeel || vraag.defaultWaardeoordeel; else showVraagType">
                                                <ng-container
                                                    *ngIf="vraag.waardeoordeel; else showDefaultWaardeoordeelText">
                                                    <mat-card-title>{{ vraag.waardeoordeel['text'] }}</mat-card-title>
                                                </ng-container>
                                                <ng-template #showDefaultWaardeoordeelText>
                                                    <mat-card-title
                                                        >{{ vraag.defaultWaardeoordeel['text'] }}</mat-card-title
                                                    >
                                                </ng-template>
                                            </ng-container>
                                            <ng-template #showVraagType>
                                                <mat-card-title>{{ vraag.type }}</mat-card-title>
                                            </ng-template>
                                            <ng-container
                                                *ngIf="vraag.waardeoordeel; else showCustomWaardeoordeelColor">
                                                <div class="status-marker {{ vraag.waardeoordeel['color'] }}"></div>
                                            </ng-container>
                                            <ng-template #showCustomWaardeoordeelColor>
                                                <ng-container
                                                    *ngIf="vraag.type == VraagType.DatumTijdPicker && vraag.toelichting; else tryOtherThanDatumTijdPicker">
                                                    <div class="status-marker green"></div>
                                                </ng-container>
                                                <ng-template #tryOtherThanDatumTijdPicker>
                                                    <ng-container
                                                        *ngIf="((vraag.type == VraagType.Invulvraag || vraag.type == VraagType.Handtekening) && vraag.toelichting); else showDefaultWaardeoordeelColor">
                                                        <div class="status-marker blue"></div>
                                                    </ng-container>
                                                    <ng-template #showDefaultWaardeoordeelColor>
                                                        <ng-container
                                                            *ngIf="(vraag.type != VraagType.Invulvraag && vraag.type != VraagType.Handtekening)">
                                                            <div class="status-marker standard"></div>
                                                        </ng-container>
                                                    </ng-template>
                                                </ng-template>
                                            </ng-template>
                                        </div>
                                        <!-- waardeoordeel title template -->
                                        <ng-template #waardeoordeelTitle>
                                            <div
                                                g2sLayout="row"
                                                [ngSwitch]="vraag.type"
                                                g2sLayoutAlign="space-between start">
                                                <mat-card-title *ngIf="vraag.type">{{ vraag.type }}</mat-card-title>
                                                <ng-container *ngIf="vraag.type != VraagType.Handtekening">
                                                    <div class="status-marker {{ vraag.waardeoordeel['color'] }}"></div>
                                                </ng-container>
                                            </div>
                                        </ng-template>
                                        <hr class="title-toelichting-hr" />
                                        <mat-card-content>
                                            <p class="question">{{ vraag.name }}</p>
                                            <!-- check voor het laten zien van een samenvatting -->
                                            <ng-container
                                                *ngIf="deelplanChecklist?.toonSamenvatting && vraag.toelichting">
                                                <hr class="title-toelichting-hr-small" />
                                                <p class="summary-txt">{{ translations?.Clarification }}:</p>
                                                <p class="summary-toelichting">{{ vraag.toelichting }}</p>
                                                <ng-container *ngIf="vraag.heeftTickets">
                                                    <h3 g2sLayout="row" g2sLayoutAlign="center center">
                                                        {{ translations?.Chepp_ChecklistView_AnsweringIsDoneByTickets }}
                                                    </h3>
                                                    <hr class="summary-divider" />
                                                </ng-container>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="vraag?.heeftTickets && deelplanChecklist?.toonSamenvatting">
                                                <div g2sLayout="row wrap" g2sLayoutAlign="space-between center">
                                                    <!-- vraagDocs moet uiteindelijk vervangen worden met vraag.bestanden -->
                                                    <ng-container *ngFor="let doc of vraagBestanden">
                                                        <button mat-button g2sFlexOffset="5" g2sFlex="70">
                                                            {{ doc }}
                                                        </button>
                                                        <ng-container>
                                                            <div g2sFlex="23" class="voortgang-bar">
                                                                <div g2sFlex="33.3" class="voortgang-rood">
                                                                    {{ voortgangRood }}
                                                                </div>
                                                                <div g2sFlex="33.3" class="voortgang-groen">
                                                                    {{ voortgangGroen }}
                                                                </div>
                                                                <div g2sFlex="33.3" class="voortgang-oranje">
                                                                    {{ voortgangOranje }}
                                                                </div>
                                                                <div g2sFlex="33.3" class="voortgang-grijs">
                                                                    {{ voortgangGrijs }}
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="!vraag?.heeftTickets && deelplanChecklist?.toonSamenvatting">
                                                <div
                                                    g2sLayout="column"
                                                    g2sLayoutAlign="center start"
                                                    *ngIf="vraag?.files">
                                                    <!-- <ng-container *ngIf="vraag.showContent == true">
                                                        <div g2sLayout="row wrap" g2sLayoutAlign="center center">
                                                            <ng-container *ngFor="let file of vraag.files">
                                                                <img class="ticket-image" src="{{file.thumbnail}}" />
                                                            </ng-container>
                                                        </div>
                                                    </ng-container> -->

                                                    <ng-container *ngIf="vraag.showContent">
                                                        <div g2sLayout="row" class="img-container" g2sLayoutGap="10px">
                                                            <ng-container
                                                                *ngFor="let file of vraag.files; let i = index">
                                                                <div
                                                                    class="file"
                                                                    [style.background-image]="'url(' + file.thumbnail + ')'"
                                                                    (click)="$event.stopPropagation(); onFileClicked(file)"></div>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>

                                                    <ng-container>
                                                        <!-- button die alleen gezien word als er geen bestanden nog opgehaald zijn en als de vraag bestanden heeft -->
                                                        <button
                                                            class="retrieve-files-button"
                                                            *ngIf="!vraag.showContent"
                                                            (click)="$event.stopPropagation();loadDeelplanUploads(vraag)"
                                                            g2sFlex="33"
                                                            mat-flat-button>
                                                            {{ translations?.Chepp_ChecklistView_RetrieveFiles }}
                                                        </button>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                        </mat-card-content>
                                    </mat-card>
                                </ng-container>
                            </ng-container>
                        </mat-expansion-panel>
                    </mat-card>
                </ng-container>
            </ng-container>
        </ng-template>
    </div>

    <div g2sFlex="25" g2sFlex.md="0" g2sFlex.lt-md="0"></div>
</div>
