import { Component, Input } from "@angular/core";
import { IimgFile } from "src/app/model/imgfile.model";

@Component({
    selector: "app-photos-overview-thumbnail",
    templateUrl: "./photos-overview-thumbnail.component.html",
    styleUrls: ["./photos-overview-thumbnail.component.scss"],
})
export class PhotosOverviewThumbnailComponent {
    @Input() file!: IimgFile;

    constructor() {}

    onSelectFile(file: IimgFile) {
        if (file.previewUrl) {
            window.open(file.previewUrl, "_blank");
        }
    }
}
