<div class="wrapper" g2sLayout.gt-md="row" g2sLayout.lt-md="column" g2sLayoutAlign.gt-sm="space-around start">
    <div g2sLayout="column" g2sFlex.gt-sm="72" g2sFlex.lt-sm="100" class="panel1">
        <div class="main-panel">
            <div class="maatregel-header" g2sLayout="row">
                <p class="header-text">{{ maatregelTitleLabel }}</p>
                <mat-icon matDialogClose matRipple class="exit-button">close</mat-icon>
            </div>
            <div g2sLayout="row">
                <div g2sFlex="30">
                    <p class="title-maatregel">{{ maatregelTitleLabel }}</p>
                </div>
                <div g2sFlex="15">
                    <p class="title-container">{{ dateTitleLabel }}</p>
                </div>
                <div g2sFlex="12">
                    <p class="title-container">{{ creatorTitleLabel }}</p>
                </div>
                <div g2sFlex="15">
                    <p class="title-container">{{ deadlineTitleLabel }}</p>
                </div>
                <div g2sFlex="15">
                    <p class="title-container">{{ statusTitleLabel }}</p>
                </div>
            </div>
            <hr class="horizontal-divider" />
            <div class="maatregel-div">
                <div *ngFor="let maatregel of maatregel" g2sLayout="column">
                    <div g2sLayout="row" class="maatregel-container">
                        <div g2sFlex="30">
                            <p class="info-maatregel">{{ contentLabel }}</p>
                        </div>
                        <div g2sFlex="15">
                            <p class="info-container">{{ contentLabel }}</p>
                        </div>
                        <div g2sFlex="12">
                            <p class="info-container">{{ contentLabel }}</p>
                        </div>
                        <div g2sFlex="15">
                            <p class="info-container">{{ contentLabel }}</p>
                        </div>
                        <div g2sFlex="15">
                            <p class="maatregel-status-marker"></p>
                        </div>
                        <div>
                            <mat-icon matRipple (click)="openNewMaatregel('edit')" class="edit">edit</mat-icon>
                            <mat-icon matRipple (click)="openDeletePopup()" class="delete">delete_outline</mat-icon>
                        </div>
                    </div>
                    <ng-container *ngIf="maatregel != null">
                        <hr class="horizontal-divider-content" />
                    </ng-container>
                </div>
            </div>
            <div matRipple (click)="openNewMaatregel('create')" class="add-icon">{{ maatregelToevoegenLabel }}</div>
        </div>
    </div>
    <div g2sHide.lt-md g2sFlex.gt-sm="25" g2sFlex.gt-md="24" class="panel2"></div>
</div>
