import { OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import {
    AfterViewInit,
    Component,
    DoCheck,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";
import { ThemePalette } from "@angular/material/core";
import { LegacyProgressSpinnerMode as ProgressSpinnerMode } from "@angular/material/legacy-progress-spinner";
import { OverlayService } from "src/app/services/overlay.service";

@Component({
    selector: "app-loading-screen",
    templateUrl: "./loading-screen.component.html",
    styleUrls: ["./loading-screen.component.scss"],
})
export class LoadingScreenComponent implements OnInit, DoCheck {
    @Input() backdropEnabled = true;
    @Input() positionGloballyCenter = true;
    @Input() displayLoadingScreen: boolean = false;

    @ViewChild("progressSpinnerRef")
    private progressSpinnerRef!: TemplateRef<any>;
    private progressSpinnerOverlayConfig!: OverlayConfig;
    private overlayRef!: OverlayRef;

    constructor(private vcRef: ViewContainerRef, private overlayService: OverlayService) {}
    ngOnInit(): void {
        // Config for Overlay Service
        this.progressSpinnerOverlayConfig = {
            hasBackdrop: this.backdropEnabled,
        };
        if (this.positionGloballyCenter) {
            this.progressSpinnerOverlayConfig["positionStrategy"] = this.overlayService.positionGloballyCenter();
        }
        // Create Overlay for progress spinner
        this.overlayRef = this.overlayService.createOverlay(this.progressSpinnerOverlayConfig);
    }
    ngDoCheck(): void {
        // Based on status of displayLoadingScreen attach/detach overlay to progress spinner template
        if (this.displayLoadingScreen && !this.overlayRef.hasAttached()) {
            this.overlayService.attachTemplatePortal(this.overlayRef, this.progressSpinnerRef, this.vcRef);
        } else if (!this.displayLoadingScreen && this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
        }
    }
}
