<div class="file-card" (click)="onSelectFile(file)">
    <div class="left-side-section" g2sLayout="column" g2sLayoutAlign="center center" g2sFlex="15"></div>
    <div class="file-content" g2sLayout="column" g2sLayoutAlign="start stretch" g2sFlex="85">
        <div class="file-preview" g2sFlex="83">
            <img class="file-preview-image" src="{{ file.thumbnail }}" />
        </div>
        <div class="file-name" g2sLayout="row" g2sLayoutAlign="center center" g2sFlex="17">
            <p class="file-name-text">{{ file.filename }}</p>
        </div>
    </div>
</div>
