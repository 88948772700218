import { Injector, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FlexLayoutModule } from "@angular/flex-layout";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AngularMaterialModule } from "./angular-material.module";
import { LoginComponent } from "./components/login/login.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { PasswordModalComponent } from "./components/login/dialog/password-modal/password-modal.component";
import { TokenModalComponent } from "./components/login/dialog/token-modal/token-modal.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { PasswordResetComponent } from "./components/login/dialog/password-reset/password-reset.component";
import { MenuBarComponent } from "./components/dashboard/menu-bar/menu-bar.component";
import { LogoutDialogComponent } from "./components/dashboard/menu-bar/dialog/logout-dialog/logout-dialog.component";
import { DossierFilterComponent } from "./components/dashboard/dossier-filter/dossier-filter.component";
import { DossierSearchComponent } from "./components/dashboard/dossier-search/dossier-search.component";
import { PasswordResetModalComponent } from "./components/dashboard/menu-bar/dialog/password-reset-modal/password-reset-modal.component";
import { DossierViewComponent } from "./components/dashboard/dossier-view/dossier-view.component";
import { AlertDialogComponent } from "./components/dashboard/menu-bar/dialog/alert-dialog/alert-dialog.component";
import { Providers } from "./provider.module";
import { MenuBarChecklistComponent } from "./components/checklistoverview/menu-bar-checklist/menu-bar-checklist.component";
import { ChecklistoverviewComponent } from "./components/checklistoverview/checklistoverview.component";
import { ChecklistInfoComponent } from "./components/checklistoverview/checklist-info/checklist-info.component";
import { RapportageDialogComponent } from "./components/checklistoverview/menu-bar-checklist/dialog/rapportage-dialog/rapportage-dialog.component";
import { ChecklistFilterDialogComponent } from "./components/checklistoverview/checklist-info/checklist-filter-dialog/checklist-filter-dialog.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { ChecklistHercontroleDialogComponent } from "./components/checklistoverview/checklist-info/checklist-hercontrole-dialog/checklist-hercontrole-dialog.component";
import { AddHercontroledatumDialogComponent } from "./components/checklistoverview/checklist-info/checklist-hercontrole-dialog/add-hercontroledatum-dialog/add-hercontroledatum-dialog.component";
import { AnswerDialogComponent } from "./components/checklistoverview/refactor-answer-dialog/answer-dialog.component";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { DossierMenuComponent } from "./components/dashboard/dossier-menu/dossier-menu.component";
import { DossierInformationComponent } from "./components/dashboard/dossier-menu/dossier-information/dossier-information.component";
import { SelectJudgementDialogComponent } from "./components/checklistoverview/checklist-member-dialogs/select-judgement-dialog/select-judgement-dialog.component";
import { DossierMemoComponent } from "./components/dashboard/dossier-menu/dossier-memo/dossier-memo.component";
import { AnswerHercontroleDialogComponent } from "./components/checklistoverview/refactor-answer-dialog/answer-hercontrole-dialog/answer-hercontrole-dialog.component";
import { APP_BASE_HREF, CommonModule } from "@angular/common";
import { DocumentsOverviewModule } from "./components/documents-overview/documents-overview.module";
import { DocumentsManagementComponent } from "./documents-management.component";
// import { createCustomElement } from "@angular/elements";
import { PdfAnnotatorComponent } from "./components/pdf-annotator/pdf-annotator.component";
import { PhotosOverviewHeaderComponent } from "./components/photos-overview/photos-overview-header/photos-overview-header.component";
import { PhotosOverviewComponent } from "./components/photos-overview/photos-overview.component";
import { PhotosOverviewThumbnailComponent } from "./components/photos-overview/photos-overview-thumbnail/photos-overview-thumbnail.component";
import { AnswerModalDialogComponent } from "./components/checklistoverview/refactor-answer-dialog/answer-modal-dialog/answer-modal-dialog.component";
import { AnswerModalTimelineComponent } from "./components/checklistoverview/refactor-answer-dialog/answer-modal-dialog/answer-modal-timeline/answer-modal-timeline.component";

import { ScrollingModule } from "@angular/cdk/scrolling";
import { NewOpdrachtDialogComponent } from "./components/checklistoverview/checklist-member-dialogs/new-opdracht-dialog/new-opdracht-dialog.component";
import { StandaardOpdrachtDialogComponent } from "./components/checklistoverview/checklist-member-dialogs/new-opdracht-dialog/standaard-opdrachten-dialog/standaard-opdrachten-dialog.component";
import { MaatregelDialogComponent } from "./components/checklistoverview/refactor-answer-dialog/maatregel-dialog/maatregel-dialog.component";
import { NewMaatregelDialogComponent } from "./components/checklistoverview/refactor-answer-dialog/maatregel-dialog/new-maatregel-dialog/new-maatregel-dialog.component";
import { DeleteMaatregelDialogComponent } from "./components/checklistoverview/refactor-answer-dialog/maatregel-dialog/delete-maatregel-dialog/delete-maatregel-dialog.component";
import { StandaardMaatregelDialogComponent } from "./components/checklistoverview/refactor-answer-dialog/maatregel-dialog/new-maatregel-dialog/standaard-maatregel-dialog/standaard-maatregel-dialog.component";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { UploadPhotoDialogComponent } from "./components/checklistoverview/checklist-member-dialogs/upload-photo-dialog/upload-photo-dialog.component";
import { HercontroleVervolgopdrachtDialogComponent } from "./components/checklistoverview/checklist-member-dialogs/select-judgement-dialog/hercontrole-vervolg-dialog/hercontrole-vervolg-dialog.component";
import { ExistingVervolgopdrachtDialogComponent } from "./components/checklistoverview/refactor-answer-dialog/existing-vervolgopdracht-dialog/existing-vervolgopdracht-dialog.component";
import { DelegateOpdrachtDialogComponent } from "./components/checklistoverview/refactor-answer-dialog/delegate-opdracht-dialog/delegate-opdracht-dialog.component";
import { DelegatedOpdrachtDialogComponent } from "./components/checklistoverview/refactor-answer-dialog/delegated-opdracht-dialog/delegated-opdracht-dialog.component";
import { ConfirmDelegatedOpdrachtDialogComponent } from "./components/checklistoverview/refactor-answer-dialog/delegated-opdracht-dialog/confirm-delegated-opdracht-dialog/confirm-delegated-opdracht-dialog.component";
import { WkiDocumentsOverviewDialogComponent } from "./components/checklistoverview/refactor-answer-dialog/wki-documents-overview-dialog/wki-documents-overview-dialog.component";
import { WkiDocumentsFilterDialogComponent } from "./components/checklistoverview/refactor-answer-dialog/wki-documents-overview-dialog/wki-documents-filter-dialog/wki-documents-filter-dialog.component";
import { SelectLanguageDialogComponent } from "./components/dashboard/menu-bar/language-select/select-language-dialog.component";
import { NgxDropzoneModule } from "ngx-dropzone";
import { LoadingScreenComponent } from "./components/loading-screen/loading-screen.component";
import { GenericPopupComponent } from "./components/generic-popup/generic-popup.component";
import { OpdrachtDialogComponent } from "./components/checklistoverview/checklist-member-dialogs/opdracht-dialog/opdracht-dialog.component";
import { ChecklistMemberWrapperDialogComponent } from "./components/checklistoverview/checklist-member-dialogs/checklist-member-wrapper-dialog/checklist-member-wrapper-dialog.component";
import { InvulMeerkeuzeVraagDialogComponent } from "./components/checklistoverview/checklist-member-dialogs/invul-meerkeuze-vraag-dialog/invul-meerkeuze-vraag-dialog.component";
import { HandtekeningVraagDialogComponent } from "./components/checklistoverview/checklist-member-dialogs/handtekening-vraag-dialog/handtekening-vraag-dialog.component";
import { DateTimePickerVraagDialogComponent } from "./components/checklistoverview/checklist-member-dialogs/date-time-picker-vraag-dialog/date-time-picker-vraag-dialog.component";
import { HoofdgroepDialogComponent } from "./components/checklistoverview/checklist-member-dialogs/hoofdgroep-dialog/hoofdgroep-dialog.component";
import { SearchBetrokkeneDialogComponent } from "./components/checklistoverview/checklist-member-dialogs/search-betrokkene-dialog/search-betrokkene-dialog.component";
import { SearchKlantOrganisationDialogComponent } from "./components/checklistoverview/checklist-member-dialogs/search-klant-organisation-dialog/search-klant-organisation-dialog.component";
import { AddNewUitvoerendeDialogComponent } from "./components/checklistoverview/checklist-member-dialogs/add-new-uitvoerende-dialog/add-new-uitvoerende-dialog.component";
import { G2SLayoutModule } from "@go2sure/angular-component-library";

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        PasswordModalComponent,
        TokenModalComponent,
        PasswordResetComponent,
        DashboardComponent,
        MenuBarComponent,
        LogoutDialogComponent,
        DossierFilterComponent,
        DossierSearchComponent,
        PasswordResetModalComponent,
        DossierViewComponent,
        AlertDialogComponent,
        MenuBarChecklistComponent,
        ChecklistoverviewComponent,
        ChecklistInfoComponent,
        RapportageDialogComponent,
        ChecklistFilterDialogComponent,
        ChecklistHercontroleDialogComponent,
        AddHercontroledatumDialogComponent,
        DossierMenuComponent,
        DossierInformationComponent,
        AnswerDialogComponent,
        SelectJudgementDialogComponent,
        DossierMemoComponent,
        AnswerHercontroleDialogComponent,
        DocumentsManagementComponent,
        PdfAnnotatorComponent,
        PhotosOverviewHeaderComponent,
        PhotosOverviewComponent,
        PhotosOverviewThumbnailComponent,
        AnswerModalDialogComponent,
        AnswerModalTimelineComponent,
        NewOpdrachtDialogComponent,
        StandaardOpdrachtDialogComponent,
        MaatregelDialogComponent,
        NewMaatregelDialogComponent,
        StandaardMaatregelDialogComponent,
        DeleteMaatregelDialogComponent,
        UploadPhotoDialogComponent,
        HercontroleVervolgopdrachtDialogComponent,
        ExistingVervolgopdrachtDialogComponent,
        DelegateOpdrachtDialogComponent,
        DelegatedOpdrachtDialogComponent,
        ConfirmDelegatedOpdrachtDialogComponent,
        WkiDocumentsOverviewDialogComponent,
        WkiDocumentsFilterDialogComponent,
        SelectLanguageDialogComponent,
        LoadingScreenComponent,
        OpdrachtDialogComponent,
        ChecklistMemberWrapperDialogComponent,
        GenericPopupComponent,
        InvulMeerkeuzeVraagDialogComponent,
        HandtekeningVraagDialogComponent,
        DateTimePickerVraagDialogComponent,
        HoofdgroepDialogComponent,
        SearchBetrokkeneDialogComponent,
        SearchKlantOrganisationDialogComponent,
        AddNewUitvoerendeDialogComponent,
    ],
    imports: [
        BrowserModule,
        FlexLayoutModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        AngularMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatDatepickerModule,
        BrowserModule,
        CommonModule,
        DocumentsOverviewModule,
        ScrollingModule,
        MatSlideToggleModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        NgxDropzoneModule,
        G2SLayoutModule,
    ],
    providers: [{ provide: APP_BASE_HREF, useValue: "/" }, Providers],
    bootstrap: [AppComponent],
})
export class AppModule {}
// constructor(injector: Injector) {
//     const assignmentManagementElement = createCustomElement(DocumentsManagementComponent, { injector: injector });
//     customElements.define("app-documents-management", assignmentManagementElement);
// }

// ngDoBootstrap() {}
// }
