import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { DataService } from "src/app/services/data.service";

@Component({
    selector: "app-dossier-search",
    templateUrl: "./dossier-search.component.html",
    styleUrls: ["./dossier-search.component.scss"],
})
export class DossierSearchComponent implements OnInit {
    searchTextLabel: string = "Kenmerk, naam, adres...";
    searchForm = new UntypedFormGroup({
        search: new UntypedFormControl(""),
    });

    constructor(public data: DataService) {}

    ngOnInit(): void {
        this.data.getTempDossier();
    }

    searchDossier() {}
}
