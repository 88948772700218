<ng-container>
    <mat-toolbar color="primary">
        <!-- home button -->
        <mat-icon class="home-button" mat-icon-button routerLink="/dashboard">home</mat-icon>
        <!-- </button> -->
        <span class="spacer"></span>
        <!-- button voor rapport -->
        <ng-container *ngIf="showRapportage">
            <mat-icon class="home-button" mat-icon-button (click)="openRapportage()" matBadgeColor="warn">
                description
            </mat-icon>
        </ng-container>
    </mat-toolbar>
</ng-container>
