<!-- timeline card -->
<div class="header-container">
    <p class="timeline-header-text">{{ subtitle }}</p>
</div>
<div class="cdk-virtual-scroll-data">
    <cdk-virtual-scroll-viewport orientation="horizontal" [itemSize]="500" class="viewport">
        <div *cdkVirtualFor="let card of cards" class="history-item">
            <div class="vraag">
                <div class="date-name" g2sLayout="column">
                    <p class="vraag-datum">{{ datePipe.transform(changedDate, 'dd-MM-yyyy HH:mm:ss') }}</p>
                    <p class="vraag-naam">{{ naamLabel }}</p>
                </div>
                <div g2sLayout="row">
                    <p class="vraag-card-status-marker"></p>
                    <p class="vraag-waardeoordeel">{{ waardeoordeelLabel }}</p>
                </div>
                <p class="vraag-toelichting-titel">{{ toelichtingTitleLabel }}</p>
                <p class="vraag-toelichting">{{ previousToelichting }}</p>
                <div class="img-style">
                    <a href="" target="_blank">
                        <img src="" class="image" />
                    </a>
                </div>
            </div>

            <div class="deskcontrol">
                <div g2sLayout="row">
                    <p class="desk-card-status-marker"></p>
                    <p class="desk-waardeoordeel">{{ waardeoordeelLabel }}</p>
                </div>
                <p class="desk-datum">{{ datePipe.transform(changedDate, 'dd-MM-yyyy HH:mm:ss') }}</p>
                <p class="desk-naam">{{ naamLabel }}</p>
            </div>

            <div class="opdracht">
                <div class="date-name" g2sLayout="column">
                    <div g2sLayout="row">
                        <p class="opdracht-datum">{{ datePipe.transform(changedDate, 'dd-MM-yyyy HH:mm:ss') }}</p>
                        <mat-icon class="opdracht-icon">assignment</mat-icon>
                    </div>
                    <ng-container *ngIf="!tempCondition">
                        <p class="opdracht-naam">{{ naamLabel }}</p>
                    </ng-container>
                </div>
                <div g2sLayout="column">
                    <p class="opdracht-titel">{{ opdrachtTitle }}</p>
                    <div class="opdracht-field">
                        <p>{{ opdrachtTitle }}</p>
                    </div>
                    <ng-container *ngIf="!tempCondition">
                        <div>
                            <p class="gereedmelding-time">
                                {{ gereedmelding }} {{ datePipe.transform(changedDate, 'dd-MM-yyyy HH:mm:ss') }}
                            </p>
                        </div>
                        <div g2sLayout="row">
                            <p class="opdracht-card-status-marker"></p>
                            <p class="opdracht-waardeoordeel">{{ waardeoordeelLabel }}</p>
                        </div>
                        <p class="vraag-toelichting-titel">{{ toelichtingTitleLabel }}</p>
                        <div class="opdracht-field">
                            <p>{{ previousToelichting }}</p>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="tempCondition">
                        <div>
                            <p class="deadline-text">
                                {{ deadlineTextLabel }}{{ datePipe.transform(changedDate, 'dd-MM-yyyy') }}
                            </p>
                            <p class="uitvoerende-text">{{ uitvoerendeLabel }}{{ naamLabel }}</p>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </cdk-virtual-scroll-viewport>
</div>

<!-- Footer -->
<div class="footer-container">
    <mat-icon matRipple mat-dialog-close class="close">cancel</mat-icon>
    <hr class="timeline-divider" />
    <div class="timeline" g2sLayout="row" g2sLayoutAlign="space-evenly center">
        <ng-container *ngFor="let card of cards">
            <a class="timeline-status" (click)="onTimelineClick(card)"></a>
        </ng-container>
    </div>
</div>
