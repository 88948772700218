<div style="padding: 15px" class="wrapper">
    <div g2sLayout="row" g2sLayoutAlign="space-between start">
        <h1 mat-dialog-title>{{ rapportageLabel }}</h1>
        <mat-icon matRipple mat-dialog-close aria-hidden="false" class="exit-dialog" aria-label="close">close</mat-icon>
    </div>
    <hr />
    <div g2sLayout="column" g2sLayoutAlign="center center">
        <ng-container *ngIf="rapportageFormats && rapportageFormats.length > 0; else noRapportage">
            <mat-list g2sFlex="100" *ngFor="let rapportageForm of rapportageFormats">
                <button g2sFlex="100" mat-button>{{ rapportageForm }}</button>
            </mat-list>
        </ng-container>
        <ng-template #noRapportage> {{ noRapportageLabel }} </ng-template>
    </div>
</div>
