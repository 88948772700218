import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import { ActivatedRoute } from "@angular/router";
import { IChecklistFilter } from "src/app/model/checklist-filter.model";
import { IDeelplan } from "src/app/model/deelplan.model";
import { IDeelplanChecklist } from "src/app/model/deelplan-checklist.model";
import { IDeelplanOpdracht } from "src/app/model/deelplan-opdracht.model";
import { IDeelplanVraag } from "src/app/model/deelplan-vraag.model";
import { IDossier } from "src/app/model/dossier.model";
import { ChecklistMemberType, VraagType } from "src/app/model/enum.model";
import { IHoofdgroep } from "src/app/model/hoofdgroep.model";
import { DataService } from "src/app/services/data.service";
import { HttpClientService } from "src/app/services/posts.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { DelegatedOpdrachtDialogComponent } from "./refactor-answer-dialog/delegated-opdracht-dialog/delegated-opdracht-dialog.component";
import { HoofdgroepService } from "src/app/services/hoofdgroep.service";
import { DeelplanVraagService } from "src/app/services/deelplan-vraag.service";
import { DeelplanOpdrachtService } from "src/app/services/deelplan-opdracht.service";
import { IFile } from "src/app/model/file.model";
import { FileService } from "src/app/services/file.service";
import { DeelplanChecklistService } from "src/app/services/deelplan-checklist.service";
import { OpdrachtDialogComponent } from "./checklist-member-dialogs/opdracht-dialog/opdracht-dialog.component";
import { ChecklistMemberWrapperDialogComponent } from "./checklist-member-dialogs/checklist-member-wrapper-dialog/checklist-member-wrapper-dialog.component";
import { TranslationService } from "src/app/services/translation.service";
import { DossierService } from "src/app/services/dossier.service";
import { DeelplanService } from "src/app/services/deelplan.service";

@Component({
    selector: "app-checklistoverview",
    templateUrl: "./checklistoverview.component.html",
    styleUrls: ["./checklistoverview.component.scss"],
})
export class ChecklistoverviewComponent implements OnInit {
    deelplanChecklist!: IDeelplanChecklist;
    dossier!: IDossier;
    deelplan!: IDeelplan;
    hoofdgroepen: IHoofdgroep[] = [];

    dossierId!: number;
    deelplanId!: number;
    checklistId!: number;
    internOrExtern!: string;

    // hoofdgroepLabel: string = "Hoofdgroep";
    // vraagLabel: string = "Vraag";
    // opdrachtLabel: string = "Opdracht";
    // toelichtingLabel: string = "Toelichting";
    // buttonOphalenLabel: string = "Bestanden ophalen";
    // antwoordTicketLabel: string = "Beantwoording vindt plaats aan de hand van tickets";
    // unkownErrorLabel: string = "Unknown error";

    displayLoadingScreen: boolean = false;

    // DUMMY DATA:
    voortgangGrijs = 1;
    voortgangGroen = 2;
    voortgangRood = 3;
    voortgangOranje = 5;
    dummyDate = "12-12-2012";
    vraagBestanden = ["123.pdf", "123.pdf", "123.pdf", "test.pdf"];

    translations: any;

    constructor(
        private snackbar: SnackbarService,
        private route: ActivatedRoute,
        private post: HttpClientService,
        private dialog: MatDialog,
        private hoofdgroepService: HoofdgroepService,
        private deelplanVraagService: DeelplanVraagService,
        private DeelplanOpdrachtService: DeelplanOpdrachtService,
        private fileService: FileService,
        private deelplanChecklistService: DeelplanChecklistService,
        private translationService: TranslationService,
        private dossierService: DossierService,
        private deelplanService: DeelplanService
    ) {
        this.dossierId = this.route.snapshot.params["dossierId"];
        this.deelplanId = this.route.snapshot.params["deelplanId"];
        this.checklistId = this.route.snapshot.params["checklistId"];
        this.internOrExtern = this.route.snapshot.params["internOrExtern"];

        this.translationService.getTranslationsForView(["ChecklistView"]).subscribe({
            next: (value) => {
                this.translations = value;
            },
            error: (err) => {
                this.snackbar.errorSnackBar(
                    "Pagina vertaling kon niet worden opgehaald, ververs de pagina of neem contact op met Go2Sure.",
                    2500
                );
            },
        });
    }

    async ngOnInit(): Promise<void> {
        this.dossier = await new Promise((resolve, reject) => {
            this.dossierService.getDossier(this.dossierId, this.internOrExtern).subscribe({
                next: (value) => {
                    value.heeftOpdrachten = (this.internOrExtern == 'extern');
                    resolve(value);
                },
            });
        });

        this.deelplan = await new Promise((resolve, reject) => {
            this.deelplanService.getDeelplan(this.deelplanId, this.internOrExtern).subscribe({
                next: (value) => {
                    resolve(value);
                },
            });
        });

        this.deelplanChecklist = await new Promise((resolve, reject) => {
            this.deelplanChecklistService
                .getDeelplanChecklist(this.deelplan.id, this.checklistId, this.internOrExtern)
                .subscribe({
                    next: (value) => {
                        resolve(value);
                    },
                });
        });

        // this.dossier = this.post.getDossier(141817, false);
        // this.deelplan = this.dossier.deelplannen![0];
        // this.deelplanChecklist = this.deelplan.deelplanChecklists![0];

        this.deelplanChecklistService.setDeelplanChecklist(this.deelplanChecklist);

        // Request hoofdgroepen by deelplan and checklist id
        // DeelplanId 71427, DeelplanChecklistId 19631
        this.hoofdgroepService
            .getHoofdgroepen(this.deelplanId, this.checklistId, this.dossier!.heeftOpdrachten!)
            .subscribe((response: IHoofdgroep[] | any) => {
                if (response) {
                    this.hoofdgroepen = response;
                } else {
                    this.snackbar.errorSnackBar(this.translations?.Chepp_ChecklistView_UnknownErrorOccurred, 3000);
                }
            });
    }

    public get VraagType(): typeof VraagType {
        return VraagType;
    }

    // Opens the hoofdgroep dialog to be able to answer on hoofdgroep level
    openHoofdgroep(hoofdgroep: IHoofdgroep) {
        this.deelplanVraagService
            .getDeelplanVragen(hoofdgroep.id, this.deelplanId, true)
            .subscribe((response: IDeelplanVraag[] | any) => {
                if (response) {
                    hoofdgroep.vragen = response as IDeelplanVraag[];

                    let dialogConfig = new MatDialogConfig();
                    dialogConfig.autoFocus = false;
                    dialogConfig.panelClass = "fullscreen-dialog";
                    dialogConfig.data = {
                        dossier: this.dossier,
                        deelplanId: this.deelplanId,
                        checklistId: this.checklistId,
                        hoofdgroep: hoofdgroep,
                        mode: ChecklistMemberType.HOOFDGROEP,
                        translations: this.translations,
                    };
                    const dialog = this.dialog.open(ChecklistMemberWrapperDialogComponent, dialogConfig);

                    dialog.beforeClosed().subscribe({
                        next: (value) => {
                            // If a value was passed, some changes have been made
                            // reload the hoofdgroep data
                            if (value) {
                                this.reloadHoofdgroep(hoofdgroep);
                            }
                        },
                    });
                } else {
                    this.snackbar.errorSnackBar(this.translations?.Chepp_ChecklistView_UnknownErrorOccurred, 3000);
                }
            });
    }

    // Opens the vraag dialog with the required data
    openVraag(hoofdgroep: IHoofdgroep, deelplanVraag: IDeelplanVraag) {
        this.deelplanVraagService.getDeelplanVraag(deelplanVraag.id).subscribe({
            next: (response) => {
                let dialogConfig = new MatDialogConfig();
                dialogConfig.autoFocus = false;
                dialogConfig.panelClass = "fullscreen-dialog";
                dialogConfig.data = {
                    dossier: this.dossier,
                    deelplanId: this.deelplanId,
                    checklistId: this.checklistId,
                    hoofdgroep: hoofdgroep,
                    deelplanVraag: response as IDeelplanVraag,
                    mode: ChecklistMemberType.VRAAG,
                    translations: this.translations,
                };
                const dialog = this.dialog.open(ChecklistMemberWrapperDialogComponent, dialogConfig);

                dialog.beforeClosed().subscribe({
                    next: (value) => {
                        // If a value was passed, some changes have been made
                        // reload the hoofdgroep data
                        if (value) {
                            this.reloadHoofdgroep(hoofdgroep);
                        }
                    },
                });
            },
        });
    }

    // Opens the opdracht dialog with the required data
    openOpdracht(hoofdgroep: IHoofdgroep, deelplanVraag: IDeelplanVraag, opdracht: IDeelplanOpdracht) {
        this.DeelplanOpdrachtService.getOpdracht(opdracht.id!).subscribe({
            next: (response) => {
                let dialogConfig = new MatDialogConfig();
                dialogConfig.autoFocus = false;
                dialogConfig.panelClass = "fullscreen-dialog";
                dialogConfig.data = {
                    dossier: this.dossier,
                    deelplan: this.deelplan,
                    deelplanChecklist: this.deelplanChecklist,
                    hoofdgroep: hoofdgroep,
                    deelplanVraag: deelplanVraag,
                    deelplanOpdracht: response,
                    mode: ChecklistMemberType.OPDRACHT,
                    translations: this.translations,
                };
                const dialog = this.dialog.open(ChecklistMemberWrapperDialogComponent, dialogConfig);

                dialog.beforeClosed().subscribe({
                    next: (value) => {
                        // If a value was passed, some changes have been made
                        // reload the hoofdgroep data
                        if (value) {
                            this.reloadHoofdgroep(hoofdgroep);
                        }
                    },
                });
            },
        });
    }

    // Handles the event of expanding and collapsing the given hoofdgroep
    onHoofdgroepExpandedChanged(hoofdgroep: IHoofdgroep, expanded: boolean) {
        hoofdgroep.panelExpanded = expanded;

        // If the hoofdgroep panel is expanded and the hoofdgroep has no vragen yet,
        // then we'll retrieve the vragen accordingly
        if (hoofdgroep && !hoofdgroep.vragen && expanded) {
            this.deelplanVraagService
                .getDeelplanVragen(hoofdgroep.id, this.deelplanId)
                .subscribe((response: IDeelplanVraag[] | any) => {
                    if (response) {
                        hoofdgroep.vragen = response as IDeelplanVraag[];
                    } else {
                        this.snackbar.errorSnackBar(this.translations?.Chepp_ChecklistView_UnknownErrorOccurred, 3000);
                    }
                });
        }
    }

    // Reloads the given hoofdgroep by refreshing the hoofdgroep data
    // or remoes the hoofdgroep if an empty response was given (in case of all opdrachten under a hoofdgroep being answered)
    reloadHoofdgroep(hoofdgroep: IHoofdgroep) {
        this.hoofdgroepService
            .getHoofdgroep(this.deelplanId, this.checklistId, hoofdgroep.id, this.dossier!.heeftOpdrachten!)
            .subscribe((response: IHoofdgroep | any) => {
                let index = this.hoofdgroepen.findIndex((h) => h.id == hoofdgroep.id);
                if (response) {
                    response.panelExpanded = true;
                    this.hoofdgroepen[index] = response;

                    if (!this.dossier!.heeftOpdrachten!) {
                        this.onHoofdgroepExpandedChanged(hoofdgroep, true);
                    }
                } else {
                    this.hoofdgroepen.splice(index, 1);
                }
            });
    }

    // Requests the deelplan uploads (thumbnails) for the given deelplan vraag
    async loadDeelplanUploads(vraag: IDeelplanVraag) {
        if (vraag.files) {
            this.displayLoadingScreen = true;

            setTimeout(async () => {
                for (let file of vraag.files!) {
                    await this.fileService
                        .getDeelplanUpload(file.id, true)
                        .then((response) => {
                            var reader = new FileReader();

                            reader.onload = (event: any) => {
                                file.thumbnail = event.target.result;
                            };

                            reader.readAsDataURL(response as Blob);
                        })
                        .catch((error) => {
                            if (file.mimetype.includes("pdf")) {
                                file.thumbnail = "/assets/pdf_placeholder.png";
                            } else {
                                // TODO: create image placeholder
                                file.thumbnail = "/assets/pdf_placeholder.png";
                            }
                        });
                }

                this.displayLoadingScreen = false;
                vraag.showContent = true;
            }, 250);
        }
    }

    // Handles the event of clicking on a deelplan upload thumbnail
    // requests the original file and displays it in a new tab.
    async onFileClicked(file: IFile) {
        // Check if the file already has an original.
        // if not, request the original
        if (!file.original) {
            await this.fileService
                .getDeelplanUpload(file.id, false)
                .then((response) => {
                    file.original = response as Blob;
                })
                .catch((error) => {});
        }

        if (!file.mimetype?.includes("pdf")) {
            var reader = new FileReader();

            reader.onload = (event: any) => {
                var image = new Image();
                image.src = event.target.result;

                var w = window.open("");
                w?.document.write(image.outerHTML);
            };

            reader.readAsDataURL(file.original!);
        } else {
            let blob = file.original!.slice(0, file.original!.size, "application/pdf");
            var fileURL = URL.createObjectURL(blob);
            window.open(fileURL);
        }
    }
}
