<form g2sLayout="column" style="padding: 15px" [formGroup]="resetForm" (ngSubmit)="onSubmit()">
    <div g2sLayout="row" g2sLayoutAlign="space-between start">
        <h1 style="margin-bottom: 0" mat-dialog-title>{{ resetDialogTitle }}</h1>
        <mat-icon class="exit-button" matRipple (click)="closeDialog()" aria-hidden="false" aria-label="close">
            close
        </mat-icon>
    </div>
    <hr />
    <div mat-dialog-content>{{ resetDialogContent }}</div>
    <div mat-dialog-actions>
        <div g2sLayout="column" g2sFlex>
            <mat-form-field>
                <mat-label>{{ newPassword }}</mat-label>
                <input type="password" formControlName="newPassword" matInput autocomplete="off" required />
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ newPasswordRepeat }}</mat-label>
                <input type="password" formControlName="newPasswordRepeat" matInput autocomplete="off" required />
            </mat-form-field>
        </div>
        <button mat-flat-button g2sFill color="primary" class="btn-block" type="submit">{{ resetSubmitBtn }}</button>
        <br />
    </div>
</form>
