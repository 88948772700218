<div class="wrapper">
    <div class="betrokkenen-header">
        <div g2sLayout="row">
            <p class="header-text">{{ opdrachtTitleLabel }}</p>
            <mat-icon matDialogClose matRipple class="close-button">close</mat-icon>
        </div>
        <mat-card-subtitle class="txt-opdrachtdatum">{{ createdOpdracht?.deadline + tempDummyDate }}</mat-card-subtitle>
        <p class="opdracht-opmerking">{{ opdrachtOpmerkingLabel }}</p>
    </div>
    <div class="betrokkenen">
        <hr class="divider" />
        <div class="delegation-toelichting">
            {{ delegatedToLabel }} {{ voornaam }} {{ achternaam }} ({{ email }}). {{ retrieveLabel }}
        </div>
        <button (click)="opdrachtTerughalen()" matRipple class="retrieve" type="submit">
            {{ opdrachtTerughalenLabel }}
        </button>
    </div>
</div>
