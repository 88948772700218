import { Component, Inject } from "@angular/core";
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";

@Component({
    selector: "app-rapportage-dialog",
    templateUrl: "./rapportage-dialog.component.html",
    styleUrls: ["./rapportage-dialog.component.scss"],
})
export class RapportageDialogComponent {
    //Rapportage-Dialog
    rapportageLabel: string = "Rapportage format";
    noRapportageLabel: string = "Geen rapportages gevonden...";

    constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public dossierId: Array<String>) {}

    //rapportage data vanuit menu-bar
    rapportageFormats = ["2", "3", "2"];
}
