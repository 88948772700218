import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { TokenService } from "./token.service";
import { SnackbarService } from "./snackbar.service";
import { IResponse } from "../model/response.model";

@Injectable({
    providedIn: "root",
})
export abstract class AbstractService {
    constructor(
        protected httpClient: HttpClient,
        protected tokenService: TokenService,
        protected snackbarService: SnackbarService
    ) {}

    /**
     * Forces the logout of the current logged in gebruiker
     * Clears all tokens, removes the current gebruiker from local storage and
     * navigates to the login screen
     */
    forceLogout() {
        this.tokenService.clear();
        localStorage.removeItem("currentGebruiker");

        if (window.location.pathname.includes("login")) {
            window.location.replace(window.location.origin + "/login");
        } else {
            setTimeout(() => {
                window.location.assign(window.location.origin + "/login");
            }, 2000);
        }
    }

    /**
     * Centralised function which should be called for executing POST requests.
     * This function automatically applies the required access token.
     * If the request failed due an expired access token a new access token will automatically be requested by the refresh token
     * and if successull, the original request will be executed once again.
     *
     * TODO: this function can probably use some optimisation + how to detect in NF when a request failed due expired access token. The current implementation checks on error code 401.
     *
     * @param endpoint
     * @param data
     * @param options
     * @returns
     */
    post(endpoint: string, data: any, options?: any, httpHeaders?: HttpHeaders, isFormData: boolean = false) {
        let headers = httpHeaders ? httpHeaders : new HttpHeaders();
        if (!httpHeaders) {
            headers = headers.append("Content-Type", "application/json");
        }

        headers = headers.append("Authorization", `Bearer ${this.tokenService.getAccessToken()}`);

        // Define the request body
        let body = data; // !isFormData && data ? JSON.stringify(data) :

        // Execute the post request using the url, body, headers and optionally options
        return this.httpClient
            .post<IResponse>(endpoint, body, {
                headers: headers,
                reportProgress: options ? options.reportProgress : undefined,
                params: options ? options.params : undefined,
            })
            .pipe(
                // Catch any (basic) http response errors
                catchError((httpErrorResponse: HttpErrorResponse) => {
                    // We check for the status code 401 here because it indicates that the access token has expired.
                    // In this case we'll try to refresh the access token and redo the request
                    if (httpErrorResponse.status == 401) {
                        return new Promise<IResponse>((resolve, reject) => {
                            // Refresh the access token
                            this.refreshToken()
                                .then((success) => {
                                    // Access token is successfully refreshed
                                    if (success) {
                                        // Initialise headers with updated access token
                                        let headers = {
                                            "Content-Type": "application/json",
                                            Authorization: `Bearer ${this.tokenService.getAccessToken()}`,
                                        };

                                        // Execute the same post request once again
                                        this.httpClient
                                            .post<IResponse>(endpoint, body, {
                                                headers: headers,
                                                reportProgress: options ? options.reportProgress : undefined,
                                                params: options ? options.params : undefined,
                                            })
                                            .pipe(
                                                // Catch any (basic) http response errors
                                                // If any http response error is thrown and error will be thrown
                                                // as response
                                                catchError((httpErrorResponse: HttpErrorResponse) => {
                                                    throw throwError(
                                                        () =>
                                                            "Er is een netwerkfout opgetreden met foutcode: " +
                                                            httpErrorResponse.status +
                                                            ". Probeer opnieuw."
                                                    );
                                                }),
                                                map((response: IResponse) => {
                                                    // If we got to this point the request itself was successfull, no (basic) http response errors where thrown.
                                                    // This part could be extended by adding extra error handling here
                                                    // by checking if the response is successfull or not.
                                                    // Now its done in the function itself which calls this post function.
                                                    
                                                    if (!response.success) {
                                                        this.handleErrorResponse(response);

                                                        throw throwError(() => {
                                                            response.message;
                                                        });
                                                    }

                                                    return response;
                                                })
                                            )
                                            .subscribe({
                                                next: (response: IResponse) => {
                                                    resolve(response);
                                                },
                                            });
                                    } else {
                                        // Failed to refresh the access token
                                        // Force logout the user
                                        this.forceLogout();
                                        throw throwError(
                                            () =>
                                                "Er is een netwerkfout opgetreden met foutcode: " +
                                                httpErrorResponse.status +
                                                ". Probeer opnieuw."
                                        );
                                    }
                                })
                                .catch((error) => {
                                    // Failed to refresh the access token
                                    // Force logout the userr
                                    this.forceLogout();
                                    throw throwError(
                                        () =>
                                            "Er is een netwerkfout opgetreden met foutcode: " +
                                            httpErrorResponse.status +
                                            ". Probeer opnieuw."
                                    );
                                });
                        }).then((response) => {
                            return response;
                        });
                    } else {
                        // The request failed but not due an expired access token
                        // throw an error accordingly
                        throw throwError(
                            () =>
                                "Er is een netwerkfout opgetreden met foutcode: " +
                                httpErrorResponse.status +
                                ". Probeer opnieuw."
                        );
                    }
                }),
                map((response: IResponse) => {
                    // If we got to this point the request itself was successfull, no (basic) http response errors where thrown.
                    // This part could be extended by adding extra error handling here
                    // by checking if the response is successfull or not.
                    // Now its done in the function itself which calls this post function.

                    if (!response.success) {
                        this.handleErrorResponse(response);
                    }

                    return response;
                })
            );
    }

    /**
     * Centralised function which should be called for executing GET requests.
     * This function automatically applies the required access token.
     * If the request failed due an expired access token a new access token will automatically be requested by the refresh token
     * and if successull, the original request will be executed once again.
     *
     * TODO: this function can probably use some optimisation + how to detect in NF when a request failed due expired access token. The current implementation checks on error code 401.
     *
     * @param endpoint
     * @returns
     */
    get(endpoint: string, options?: any, httpHeaders?: HttpHeaders) {
        // Define the request headers
        let headers = httpHeaders ? httpHeaders : new HttpHeaders();

        if (!httpHeaders) {
            headers = headers.append("Content-Type", "application/json");
        }

        headers = headers.append("Authorization", `Bearer ${this.tokenService.getAccessToken()}`);

        return this.httpClient
            .get<IResponse>(endpoint, {
                headers: headers,
                reportProgress: options ? options.reportProgress : undefined,
                params: options ? options.params : undefined,
            })
            .pipe(
                // Catch any (basic) http response errors
                catchError((httpErrorResponse: HttpErrorResponse) => {
                    // We check for the status code 401 here because it indicates that the access token has expired.
                    // In this case we'll try to refresh the access token and redo the request
                    if (httpErrorResponse.status == 401 || httpErrorResponse.status == 0) {
                        return new Promise<IResponse>((resolve, reject) => {
                            // Call the refresh token function in order to refresh the access token
                            this.refreshToken()
                                .then((success) => {
                                    // If success we'll redo the request with the updated access token
                                    if (success) {
                                        // Initialise header with updated access token
                                        let headers = {
                                            "Content-Type": "application/json",
                                            Authorization: `Bearer ${this.tokenService.getAccessToken()}`,
                                        };

                                        this.httpClient
                                            .get<IResponse>(endpoint, { headers: headers })
                                            .pipe(
                                                // Catch any (basic) http response errors
                                                // If any http response error is thrown and error will be thrown
                                                // as response
                                                catchError((httpErrorResponse: HttpErrorResponse) => {
                                                    throw throwError(
                                                        () =>
                                                            "Er is een netwerkfout opgetreden met foutcode: " +
                                                            httpErrorResponse.status +
                                                            ". Probeer opnieuw."
                                                    );
                                                }),
                                                map((response: IResponse) => {
                                                    // If we got to this point the request itself was successfull, no (basic) http response errors where thrown.
                                                    // This part could be extended by adding extra error handling here
                                                    // by checking if the response is successfull or not.
                                                    // Now its done in the function itself which calls this post function.

                                                    if (!response.success) {
                                                        this.handleErrorResponse(response);

                                                        throw throwError(() => {
                                                            response.message;
                                                        });
                                                    }

                                                    return response;
                                                })
                                            )
                                            .subscribe({
                                                next: (response: IResponse) => {
                                                    resolve(response);
                                                },
                                            });
                                    } else {
                                        // Small timeout so the user is able to read the error message before we force logout the user
                                        this.forceLogout();

                                        throw throwError(
                                            () =>
                                                "Er is een netwerkfout opgetreden met foutcode: " +
                                                httpErrorResponse.status +
                                                ". Probeer opnieuw."
                                        );
                                    }
                                })
                                .catch((error) => {
                                    // Small timeout so the user is able to read the error message before we force logout the user
                                    this.forceLogout();

                                    throw throwError(
                                        () =>
                                            "Er is een netwerkfout opgetreden met foutcode: " +
                                            httpErrorResponse.status +
                                            ". Probeer opnieuw."
                                    );
                                });
                        }).then((response) => {
                            return response;
                        });
                    } else {
                        // Just show the network error here
                        // Not needed to radically log the user out in this case.
                        throw throwError(
                            () =>
                                "Er is een netwerkfout opgetreden met foutcode: " +
                                httpErrorResponse.status +
                                ". Probeer opnieuw."
                        );
                    }
                }),
                map((response: IResponse) => {
                    // Could be extended by adding extra error handling here
                    // by checking if the response is successfull or not.
                    // Now its done in the function itself which calls this post function
                    // because we'll be able to enter a function specific error message

                    if (!response.success) {
                        this.handleErrorResponse(response);
                    }

                    return response;
                })
            );
    }

    /**
     * Centralised function which should be called for executing PATCH requests.
     * This function automatically applies the required access token.
     * If the request failed due an expired access token a new access token will automatically be requested by the refresh token
     * and if successull, the original request will be executed once again.
     *
     * TODO: this function can probably use some optimisation + how to detect in NF when a request failed due expired access token. The current implementation checks on error code 401.
     *
     * @param endpoint
     * @returns
     */
    patch(endpoint: string, data: any, options?: any, httpHeaders?: HttpHeaders, isFormData: boolean = false) {
        let headers = httpHeaders ? httpHeaders : new HttpHeaders();
        if (!httpHeaders) {
            headers = headers.append("Content-Type", "application/json");
        }

        headers = headers.append("Authorization", `Bearer ${this.tokenService.getAccessToken()}`);

        // Define the request body
        let body = data; // !isFormData && data ? JSON.stringify(data) :

        // Execute the post request using the url, body, headers and optionally options
        return this.httpClient
            .patch<IResponse>(endpoint, body, {
                headers: headers,
                reportProgress: options ? options.reportProgress : undefined,
                params: options ? options.params : undefined,
            })
            .pipe(
                // Catch any (basic) http response errors
                catchError((httpErrorResponse: HttpErrorResponse) => {
                    // We check for the status code 401 here because it indicates that the access token has expired.
                    // In this case we'll try to refresh the access token and redo the request
                    if (httpErrorResponse.status == 401) {
                        return new Promise<IResponse>((resolve, reject) => {
                            // Refresh the access token
                            this.refreshToken()
                                .then((success) => {
                                    // Access token is successfully refreshed
                                    if (success) {
                                        // Initialise headers with updated access token
                                        let headers = {
                                            "Content-Type": "application/json",
                                            Authorization: `Bearer ${this.tokenService.getAccessToken()}`,
                                        };

                                        // Execute the same post request once again
                                        this.httpClient
                                            .patch<IResponse>(endpoint, body, {
                                                headers: headers,
                                                reportProgress: options ? options.reportProgress : undefined,
                                                params: options ? options.params : undefined,
                                            })
                                            .pipe(
                                                // Catch any (basic) http response errors
                                                // If any http response error is thrown and error will be thrown
                                                // as response
                                                catchError((httpErrorResponse: HttpErrorResponse) => {
                                                    throw throwError(
                                                        () =>
                                                            "Er is een netwerkfout opgetreden met foutcode: " +
                                                            httpErrorResponse.status +
                                                            ". Probeer opnieuw."
                                                    );
                                                }),
                                                map((response: IResponse) => {
                                                    // If we got to this point the request itself was successfull, no (basic) http response errors where thrown.
                                                    // This part could be extended by adding extra error handling here
                                                    // by checking if the response is successfull or not.
                                                    // Now its done in the function itself which calls this post function.

                                                    if (!response.success) {
                                                        this.handleErrorResponse(response);

                                                        throw throwError(() => {
                                                            response.message;
                                                        });
                                                    }

                                                    return response;
                                                })
                                            )
                                            .subscribe({
                                                next: (response: IResponse) => {
                                                    resolve(response);
                                                },
                                            });
                                    } else {
                                        // Failed to refresh the access token
                                        // Force logout the user
                                        this.forceLogout();
                                        throw throwError(
                                            () =>
                                                "Er is een netwerkfout opgetreden met foutcode: " +
                                                httpErrorResponse.status +
                                                ". Probeer opnieuw."
                                        );
                                    }
                                })
                                .catch((error) => {
                                    // Failed to refresh the access token
                                    // Force logout the userr
                                    this.forceLogout();
                                    throw throwError(
                                        () =>
                                            "Er is een netwerkfout opgetreden met foutcode: " +
                                            httpErrorResponse.status +
                                            ". Probeer opnieuw."
                                    );
                                });
                        }).then((response) => {
                            return response;
                        });
                    } else {
                        // The request failed but not due an expired access token
                        // throw an error accordingly
                        throw throwError(
                            () =>
                                "Er is een netwerkfout opgetreden met foutcode: " +
                                httpErrorResponse.status +
                                ". Probeer opnieuw."
                        );
                    }
                }),
                map((response: IResponse) => {
                    // If we got to this point the request itself was successfull, no (basic) http response errors where thrown.
                    // This part could be extended by adding extra error handling here
                    // by checking if the response is successfull or not.
                    // Now its done in the function itself which calls this post function.
                    if (!response.success) {
                        this.handleErrorResponse(response);
                    }

                    return response;
                })
            );
    }

    /**
     * Centralised function which should be called for executing PUT requests.
     * This function automatically applies the required access token.
     * If the request failed due an expired access token a new access token will automatically be requested by the refresh token
     * and if successull, the original request will be executed once again.
     *
     * TODO: this function can probably use some optimisation + how to detect in NF when a request failed due expired access token. The current implementation checks on error code 401.
     *
     * @param endpoint
     * @returns
     */
    put(endpoint: string, data: any, options?: any, httpHeaders?: HttpHeaders, isFormData: boolean = false) {
        let headers = httpHeaders ? httpHeaders : new HttpHeaders();
        if (!httpHeaders) {
            headers = headers.append("Content-Type", "application/json");
        }

        headers = headers.append("Authorization", `Bearer ${this.tokenService.getAccessToken()}`);

        // Define the request body
        let body = data; // !isFormData && data ? JSON.stringify(data) :

        // Execute the PUT request using the url, body, headers and optionally options
        return this.httpClient
            .put<IResponse>(endpoint, body, {
                headers: headers,
                reportProgress: options ? options.reportProgress : undefined,
                params: options ? options.params : undefined,
            })
            .pipe(
                // Catch any (basic) http response errors
                catchError((httpErrorResponse: HttpErrorResponse) => {
                    // We check for the status code 401 here because it indicates that the access token has expired.
                    // In this case we'll try to refresh the access token and redo the request
                    if (httpErrorResponse.status == 401) {
                        return new Promise<IResponse>((resolve, reject) => {
                            // Refresh the access token
                            this.refreshToken()
                                .then((success) => {
                                    // Access token is successfully refreshed
                                    if (success) {
                                        // Initialise headers with updated access token
                                        let headers = {
                                            "Content-Type": "application/json",
                                            Authorization: `Bearer ${this.tokenService.getAccessToken()}`,
                                        };

                                        // Execute the same post request once again
                                        this.httpClient
                                            .put<IResponse>(endpoint, body, {
                                                headers: headers,
                                                reportProgress: options ? options.reportProgress : undefined,
                                                params: options ? options.params : undefined,
                                            })
                                            .pipe(
                                                // Catch any (basic) http response errors
                                                // If any http response error is thrown and error will be thrown
                                                // as response
                                                catchError((httpErrorResponse: HttpErrorResponse) => {
                                                    throw throwError(
                                                        () =>
                                                            "Er is een netwerkfout opgetreden met foutcode: " +
                                                            httpErrorResponse.status +
                                                            ". Probeer opnieuw."
                                                    );
                                                }),
                                                map((response: IResponse) => {
                                                    // If we got to this point the request itself was successfull, no (basic) http response errors where thrown.
                                                    // This part could be extended by adding extra error handling here
                                                    // by checking if the response is successfull or not.
                                                    // Now its done in the function itself which calls this post function.

                                                    if (!response.success) {
                                                        this.handleErrorResponse(response);

                                                        throw throwError(() => {
                                                            response.message;
                                                        });
                                                    }

                                                    return response;
                                                })
                                            )
                                            .subscribe({
                                                next: (response: IResponse) => {
                                                    resolve(response);
                                                },
                                            });
                                    } else {
                                        // Failed to refresh the access token
                                        // Force logout the user
                                        this.forceLogout();
                                        throw throwError(
                                            () =>
                                                "Er is een netwerkfout opgetreden met foutcode: " +
                                                httpErrorResponse.status +
                                                ". Probeer opnieuw."
                                        );
                                    }
                                })
                                .catch((error) => {
                                    // Failed to refresh the access token
                                    // Force logout the userr
                                    this.forceLogout();
                                    throw throwError(
                                        () =>
                                            "Er is een netwerkfout opgetreden met foutcode: " +
                                            httpErrorResponse.status +
                                            ". Probeer opnieuw."
                                    );
                                });
                        }).then((response) => {
                            return response;
                        });
                    } else {
                        // The request failed but not due an expired access token
                        // throw an error accordingly
                        throw throwError(
                            () =>
                                "Er is een netwerkfout opgetreden met foutcode: " +
                                httpErrorResponse.status +
                                ". Probeer opnieuw."
                        );
                    }
                }),
                map((response: IResponse) => {
                    // If we got to this point the request itself was successfull, no (basic) http response errors where thrown.
                    // This part could be extended by adding extra error handling here
                    // by checking if the response is successfull or not.
                    // Now its done in the function itself which calls this post function.
                    if (!response.success) {
                        this.handleErrorResponse(response);
                    }

                    return response;
                })
            );
    }

    delete(endpoint: string, data: any, options?: any, httpHeaders?: HttpHeaders, isFormData: boolean = false) {
        let headers = httpHeaders ? httpHeaders : new HttpHeaders();
        if (!httpHeaders) {
            headers = headers.append("Content-Type", "application/json");
        }

        headers = headers.append("Authorization", `Bearer ${this.tokenService.getAccessToken()}`);

        // Define the request body
        let body = data; // !isFormData && data ? JSON.stringify(data) :

        return this.httpClient
            .delete<IResponse>(endpoint, {
                body: body,
                headers: headers,
                reportProgress: options ? options.reportProgress : undefined,
                params: options ? options.params : undefined,
            })
            .pipe(
                // Catch any (basic) http response errors
                catchError((httpErrorResponse: HttpErrorResponse) => {
                    // We check for the status code 401 here because it indicates that the access token has expired.
                    // In this case we'll try to refresh the access token and redo the request
                    if (httpErrorResponse.status == 401) {
                        return new Promise<IResponse>((resolve, reject) => {
                            // Refresh the access token
                            this.refreshToken()
                                .then((success) => {
                                    // Access token is successfully refreshed
                                    if (success) {
                                        // Initialise headers with updated access token
                                        let headers = {
                                            "Content-Type": "application/json",
                                            Authorization: `Bearer ${this.tokenService.getAccessToken()}`,
                                        };

                                        // Execute the same post request once again
                                        this.httpClient
                                            .delete<IResponse>(endpoint, {
                                                body: body,
                                                headers: headers,
                                                reportProgress: options ? options.reportProgress : undefined,
                                                params: options ? options.params : undefined,
                                            })
                                            .pipe(
                                                // Catch any (basic) http response errors
                                                // If any http response error is thrown and error will be thrown
                                                // as response
                                                catchError((httpErrorResponse: HttpErrorResponse) => {
                                                    throw throwError(
                                                        () =>
                                                            "Er is een netwerkfout opgetreden met foutcode: " +
                                                            httpErrorResponse.status +
                                                            ". Probeer opnieuw."
                                                    );
                                                }),
                                                map((response: IResponse) => {
                                                    // If we got to this point the request itself was successfull, no (basic) http response errors where thrown.
                                                    // This part could be extended by adding extra error handling here
                                                    // by checking if the response is successfull or not.
                                                    // Now its done in the function itself which calls this post function.

                                                    if (!response.success) {
                                                        this.handleErrorResponse(response);

                                                        throw throwError(() => {
                                                            response.message;
                                                        });
                                                    }

                                                    return response;
                                                })
                                            )
                                            .subscribe({
                                                next: (response: IResponse) => {
                                                    resolve(response);
                                                },
                                            });
                                    } else {
                                        // Failed to refresh the access token
                                        // Force logout the user
                                        this.forceLogout();
                                        throw throwError(
                                            () =>
                                                "Er is een netwerkfout opgetreden met foutcode: " +
                                                httpErrorResponse.status +
                                                ". Probeer opnieuw."
                                        );
                                    }
                                })
                                .catch((error) => {
                                    // Failed to refresh the access token
                                    // Force logout the userr
                                    this.forceLogout();
                                    throw throwError(
                                        () =>
                                            "Er is een netwerkfout opgetreden met foutcode: " +
                                            httpErrorResponse.status +
                                            ". Probeer opnieuw."
                                    );
                                });
                        }).then((response) => {
                            return response;
                        });
                    } else {
                        // The request failed but not due an expired access token
                        // throw an error accordingly
                        throw throwError(
                            () =>
                                "Er is een netwerkfout opgetreden met foutcode: " +
                                httpErrorResponse.status +
                                ". Probeer opnieuw."
                        );
                    }
                }),
                map((response: IResponse) => {
                    // If we got to this point the request itself was successfull, no (basic) http response errors where thrown.
                    // This part could be extended by adding extra error handling here
                    // by checking if the response is successfull or not.
                    // Now its done in the function itself which calls this post function.
                    if (!response.success) {
                        this.handleErrorResponse(response);
                    }

                    return response;
                })
            );
    }

    /**
     * Refreshes the access token by the corresponding refresh token
     * @returns
     */
    refreshToken() {
        return new Promise(async (resolve, reject) => {
            if (localStorage.getItem("isRefreshingToken")) {
                await new Promise((f) => setTimeout(f, 1000));
                resolve(true);
                return;
            }

            localStorage.setItem("isRefreshingToken", "true");

            // If we don't have refresh token
            // return false, no need to continue
            if (!this.tokenService.getRefreshToken()) {
                localStorage.removeItem("isRefreshingToken");

                reject(false);
                return;
            }

            // Define the payload
            let payload = new HttpParams()
                .append("client_id", environment.clientId)
                .append("grant_type", "refresh_token")
                .append("refresh_token", this.tokenService.getRefreshToken() as string);

            // Execute a post request in order to request a new access token
            this.httpClient
                .post(environment.baseOAuth2Url + "/o/token/", payload, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        Authorization: `Bearer ${this.tokenService.getAccessToken()}`,
                    },
                })
                .subscribe({
                    next: (result: any) => {
                        // Only if the result contains an access token and refresh token we'll continue
                        if (result.access_token && result.refresh_token) {
                            // Save the tokens for use in requests
                            this.tokenService.saveAccessToken(result.access_token);
                            this.tokenService.saveRefreshToken(result.refresh_token);

                            localStorage.removeItem("isRefreshingToken");
                            resolve(true);
                            return;
                        }

                        localStorage.removeItem("isRefreshingToken");
                        reject(false);
                    },
                    error: (error) => {
                        localStorage.removeItem("isRefreshingToken");
                        reject(false);
                    },
                });
        });
    }

    handleErrorResponse(response: IResponse) {
        // TODO: Show snackbar with appropriate message, probably response.message
        this.snackbarService.errorSnackBar(response.message, 3000);
    }
}
