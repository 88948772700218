import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { IDrawing } from "../model/drawing-data.model";
import { IProperty } from "../model/property.model";

@Injectable({
    providedIn: "root",
})
export class AnnotaterService {
    //private readonly hostName = "http://2ts-develop.nl"
    private readonly hostName = "";
    constructor(private httpClient: HttpClient) {}

    getDrawingData(documentId: number, deelplanChecklistId?: number, pageNumber: number = 1) {
        let url = `${this.hostName}/pdfannotatornewstyle/getDocumentData/${documentId}/${pageNumber}/${deelplanChecklistId}`;
        if (!deelplanChecklistId) {
            url = `${this.hostName}/pdfannotatornewstyle/getDossierDocumentData/${documentId}/${pageNumber}`;
        }
        return this.httpClient.get<IDrawing>(url);
    }

    savePageProperties(deelplanChecklistId: number, documentId: number, property: IProperty) {
        let headers = new HttpHeaders();
        headers.set("Content-Type", "multipart/form-data");

        const formData = new FormData();
        formData.append("deelplanCheclistId", deelplanChecklistId.toString());
        formData.append("documentId", documentId.toString());
        formData.append("propertyName", property.propertyName);
        formData.append("propertyValue", property.propertyValue);

        return this.httpClient.post(`${this.hostName}/pdfannotatornewstyle/savePageProperties`, formData, {
            headers: headers,
        });
    }
}
