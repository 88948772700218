<form class="wrapper" g2sLayout="column" [formGroup]="resetForm" (ngSubmit)="onSubmit()">
    <div g2sLayout="row" g2sLayoutAlign="space-between start">
        <h1 style="margin-bottom: 0" mat-dialog-title>{{ resetPasswordTitleLabel }}</h1>
        <mat-icon class="exit-button" matRipple (click)="closeDialog()" aria-hidden="false" aria-label="close">
            close
        </mat-icon>
    </div>
    <hr />
    <div mat-dialog-content>{{ resetDialogContentLabel }}</div>
    <div mat-dialog-actions style="padding-top: 0px !important">
        <div g2sLayout="column" g2sFlex>
            <mat-form-field>
                <mat-label>{{ newPasswordLabel }}</mat-label>
                <input type="password" formControlName="newPassword" matInput autocomplete="off" required />
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ newPasswordRepeatLabel }}</mat-label>
                <input type="password" formControlName="newPasswordRepeat" matInput autocomplete="off" required />
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{ confirmCurrentPasswordLabel }}</mat-label>
                <input type="password" formControlName="currPassword" matInput autocomplete="off" required />
            </mat-form-field>
        </div>
        <button mat-flat-button g2sFill color="primary" class="btn-block" type="submit">
            {{ resetSubmitBtnLabel }}
        </button>
        <br />
    </div>
</form>
