<form g2sLayout="column" [formGroup]="tokenModal" (ngSubmit)="onSubmit()">
    <div g2sLayout="row" g2sLayoutAlign="space-between start">
        <h1 style="margin-bottom: 0px" mat-dialog-title>{{ tokenDialogTitle }}</h1>
        <mat-icon class="close-btn" (click)="closeDialog()" aria-hidden="false" matRipple aria-label="close">
            close
        </mat-icon>
    </div>
    <hr />
    <div mat-dialog-content>{{ tokenDialogContent }}</div>
    <div mat-dialog-actions>
        <mat-form-field g2sFlex appearance="fill">
            <mat-label>{{tokenText}}</mat-label>
            <input g2sFlex formControlName="token" matInput autocomplete="off" required />
        </mat-form-field>
        <button (click)="openPasswordResetDialog()" mat-flat-button g2sFill color="primary" class="btn-block">
            {{ tokenSubmitBtn }}
        </button>
        <br />
    </div>
</form>
