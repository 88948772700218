<button mat-flat-button color="primary" (click)="login()">Inloggen</button>
<!-- <app-loading-screen [displayLoadingScreen]="displayLoadingScreen"></app-loading-screen> -->

<!-- <div g2sLayout="column" class="header">
    <div class="login-wrapper" g2sLayout="row" g2sLayoutAlign="center end">
        <mat-card class="box" g2sFlex.gt-sm="30%" g2sFlex="100%">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login-form">
                <mat-card-content>
                    <div class="login-field">
                        <div class="image-field" g2sLayoutAlign="center center" g2sFlex="18%">
                            <img src="https://2ts-test.go2sure.nl/files/images/chepponline/user-ico.png" alt="" />
                        </div>
                        <mat-form-field g2sFlex="82%">
                            <input matInput placeholder="{{ emailText }}" formControlName="email" required />
                        </mat-form-field>
                    </div>
                    <div class="login-field">
                        <div class="image-field" g2sLayoutAlign="center center" g2sFlex="18%">
                            <img src="https://2ts-test.go2sure.nl/files/images/chepponline/password-ico.png" alt="" />
                        </div>
                        <mat-form-field g2sFlex="82%">
                            <input
                                matInput
                                type="password"
                                placeholder="{{ passwordText }}"
                                formControlName="password"
                                required />
                        </mat-form-field>
                    </div>
                    <div g2sLayout="row" g2sLayoutAlign="end end">
                        <button
                            style="padding-left: 5px !important; padding-right: 5px !important"
                            (click)="openDialog()"
                            mat-button
                            color="warn"
                            type="button">
                            {{ passwordForgotText }}
                        </button>
                    </div>
                </mat-card-content>
                <button
                    [disabled]="loginForm.invalid"
                    mat-flat-button
                    g2sFill
                    color="primary"
                    type="submit"
                    class="btn-block click-styling">
                    {{ loginText }}
                </button>
            </form>
        </mat-card>
    </div>
    <div g2sLayout="column" g2sLayoutAlign="center end" class="app">
        <a href="https://www.go2chepp.nl/">
            <img src="https://2ts-test.go2sure.nl/files/images/chepponline/google.png" alt="" />
        </a>
        <a href="https://www.go2chepp.nl/">
            <img src="https://2ts-test.go2sure.nl/files/images/chepponline/apple.png" alt="" />
        </a>
    </div>
</div> -->
