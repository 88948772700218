import { Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { NgForm } from "@angular/forms";

@Component({
    selector: "app-new-document-dialog",
    templateUrl: "./new-document-dialog.component.html",
    styleUrls: ["./new-document-dialog.component.scss"],
})
export class NewDocumentDialogComponent {
    pictureAsDocumentLabel: string = "Gebruik foto als document";
    btnCancelLabel: string = "Annuleren";
    btnSaveLabel: string = "Opslaan";

    constructor(
        private dialogRef: MatDialogRef<NewDocumentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {}

    onCancel() {
        this.dialogRef.close();
        this.data.fileInput.files.value = "";
    }

    onSubmit(f: NgForm) {
        if (this.data.fileInput.files && f.value["document-name"]) {
            const formData = new FormData();
            formData.append("documentName", f.value["document-name"]);
            formData.append("document", this.data.fileInput.files[0]);
            this.dialogRef.close({ submit: true, formData: formData });
        }

        if (this.data.fileInput.files && f.value["document-name"]) {
            const formData = new FormData();
            formData.append("documentName", f.value["document-name"]);
            formData.append("document", this.data.fileInput.files[0]);
        }
    }
}
