<div class="wrapper">
    <ng-container>
        <mat-toolbar color="primary">
            <!-- home button -->
            <button class="home-button" mat-icon-button routerLink="/dossiermenu/{{ dossierId }}">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <span class="spacer"></span>
        </mat-toolbar>
    </ng-container>
    <div style="height: 64px"></div>
    <ng-container *ngIf="loading; else finished">
        <mat-spinner class="main-spinner"></mat-spinner>
    </ng-container>
    <ng-template #finished>
        <ng-container *ngIf="dossierInformatiePanel; else opmerkingen">
            <form #f="ngForm" [formGroup]="dossierInformatieForm" (ngSubmit)="onSubmitDossierInformatieForm()">
                <div g2sLayout="row">
                    <div class="form-row" g2sFlex g2sLayout="column">
                        <div class="form-field">
                            <p class="labeltext">{{ dossierNameTextLabel }}<span class="required-field">*</span></p>
                            <input
                                formControlName="dossierNaam"
                                class="inputfield"
                                type="text"
                                placeholder="{{ dossierNameTextLabel }}" />
                            <span *ngIf="dossierInformatieForm.controls['dossierNaam'].invalid" class="invalid-text">
                                {{ dossierNameTextLabel }} {{ featureTextLabel }}
                            </span>
                        </div>
                        <div class="form-field">
                            <p class="labeltext">
                                {{ featureTextLabel}}
                                <span class="required-field">*</span>
                                <span class="kenmerk-text">{{ attributeNotEditableLabel }}</span>
                            </p>
                            <input
                                formControlName="kenmerk"
                                readonly
                                class="inputfield"
                                type="text"
                                placeholder="{{ featureTextLabel }}" />
                        </div>
                        <div class="form-field">
                            <p class="labeltext">
                                {{ locationStraatTextLabel }}
                                <span class="required-field">*</span>
                            </p>
                            <input
                                formControlName="locatieStraat"
                                class="inputfield"
                                type="text"
                                placeholder="{{ locationStraatTextLabel }}" />
                            <span *ngIf="dossierInformatieForm.controls['locatieStraat'].invalid" class="invalid-text">
                                {{ locationStraatTextLabel }} {{ requiredTextLabel }}
                            </span>
                        </div>
                        <div class="form-field">
                            <p class="labeltext">
                                {{ locationHouseNumberTextLabel }}
                                <span class="required-field">*</span>
                            </p>
                            <input
                                formControlName="locatieHuisnummer"
                                class="inputfield"
                                type="text"
                                placeholder="{{ locationHouseNumberTextLabel }}" />
                            <span
                                *ngIf="dossierInformatieForm.controls['locatieHuisnummer'].invalid"
                                class="invalid-text">
                                {{ locationHouseNumberTextLabel }} {{ requiredTextLabel }}
                            </span>
                        </div>
                        <div class="form-field">
                            <p class="labeltext">
                                {{ postalCodeTextLabel }}
                                <span class="required-field">*</span>
                            </p>
                            <input
                                #i
                                formControlName="postcode"
                                class="inputfield"
                                type="text"
                                placeholder="{{ postalCodeTextLabel }}" />
                            <span *ngIf="dossierInformatieForm.controls['postcode'].invalid" class="invalid-text">
                                {{ postalCodeTextLabel }} {{ invalidTextLabel }}
                            </span>
                        </div>
                        <div class="form-field">
                            <p class="labeltext">
                                {{ locationTextLabel }}
                                <span class="required-field">*</span>
                            </p>
                            <input
                                formControlName="plaats"
                                class="inputfield"
                                type="text"
                                placeholder="{{ locationTextLabel }}" />
                            <span *ngIf="dossierInformatieForm.controls['plaats'].invalid" class="invalid-text">
                                {{ locationTextLabel }} {{ requiredTextLabel }}
                            </span>
                        </div>
                        <div class="form-field">
                            <p class="labeltext">{{ townTextLabel }}</p>
                            <input
                                formControlName="gemeente"
                                class="inputfield"
                                type="text"
                                value="test"
                                placeholder="{{ townTextLabel }}" />
                        </div>
                    </div>

                    <div class="vertical-divider" g2sFlex="1px"></div>

                    <div class="form-row" g2sFlex g2sLayout="column">
                        <div class="form-field">
                            <p class="labeltext">{{ corStraatTextLabel }}</p>
                            <input
                                formControlName="correspondentieStraat"
                                class="inputfield"
                                type="text"
                                value="test"
                                placeholder="{{ corStraatTextLabel }}" />
                        </div>
                        <div class="form-field">
                            <p class="labeltext">{{ corHouseNumberTextLabel }}</p>
                            <input
                                formControlName="correspondentieHuisnummer"
                                class="inputfield"
                                type="text"
                                value=""
                                placeholder="{{ corHouseNumberTextLabel }}" />
                        </div>
                        <div class="form-field">
                            <p class="labeltext">{{ corPostalCodeTextLabel }}</p>
                            <input
                                pattern="(^[1-9]{1}[0-9]{3} ?[A-z]{2}$)|(^[A-z]{1}( |-)?[1-9]{1}[0-9]{3}$)"
                                formControlName="correspondentiePostcode"
                                class="inputfield"
                                type="text"
                                value=""
                                placeholder="{{ corPostalCodeTextLabel }}" />
                        </div>
                        <div class="form-field">
                            <p class="labeltext">{{ corPlaceTextLabel }}</p>
                            <input
                                formControlName="correspondentiePlaats"
                                class="inputfield"
                                type="text"
                                value=""
                                placeholder="{{ corPlaceTextLabel }}" />
                        </div>
                    </div>
                </div>
                <div g2sLayoutAlign="center start">
                    <button
                        [disabled]="!f.valid || isDossierInformatieButtonDisabled()"
                        type="submit"
                        mat-flat-button
                        class="save-button">
                        <ng-container *ngIf="!isSaving; else saving"> {{ saveTextLabel }} </ng-container>
                        <ng-template #saving>
                            <mat-spinner color="primary" class="spinner"></mat-spinner>
                        </ng-template>
                    </button>
                </div>
            </form>
        </ng-container>

        <ng-template #opmerkingen>
            <form #f="ngForm" [formGroup]="dossierOpmerkingenForm" (ngSubmit)="onSubmitOpmerkingenForm()">
                <div g2sLayout="column" g2sLayoutAlign="center center">
                    <h1 class="opmerkingen-title">{{ commentTextLabel }}</h1>
                    <textarea
                        formControlName="opmerkingen"
                        class="text-area"
                        placeholder="{{ commentTextLabel }}..."
                        value="hallo">
                    </textarea>
                    <ng-container *ngIf="showAanleidingen">
                        <h1 class="opmerkingen-title">{{ causeTextLabel }}</h1>
                        <mat-form-field class="dropdown" appearance="fill">
                            <mat-select formControlName="aanleiding">
                                <mat-option [value]="option" *ngFor="let option of options"> {{ option }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                    <button
                        disableRipple
                        [disabled]="!f.valid || isOpmerkingenButtonDisabled()"
                        type="submit"
                        mat-flat-button
                        class="save-button-opmerkingen">
                        <ng-container *ngIf="!isSaving; else saving"> {{ saveTextLabel }} </ng-container>
                        <ng-template #saving>
                            <mat-spinner color="accent" class="spinner"></mat-spinner>
                        </ng-template>
                    </button>
                </div>
            </form>
        </ng-template>
    </ng-template>

    <footer g2sLayout="row">
        <div
            (click)="dossierInformatiePanel = true"
            [ngClass]="{ 'footer-button-disabled': !dossierInformatiePanel }"
            matRipple
            class="footer-button"
            g2sLayoutAlign="center center"
            g2sFlex>
            <mat-icon class="material-symbols-outlined">info</mat-icon>
            <p class="footer-text">{{ dossierTextLabel }}</p>
        </div>
        <div
            (click)="dossierInformatiePanel = false"
            [ngClass]="{ 'footer-button-disabled': dossierInformatiePanel }"
            matRipple
            class="footer-button"
            g2sLayoutAlign="center center"
            g2sFlex>
            <mat-icon class="material-symbols-outlined">comment</mat-icon>
            <p class="footer-text">{{ commentTextLabel }}</p>
        </div>
    </footer>
</div>
