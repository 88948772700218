import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";

@Component({
    selector: "app-upload-photo-dialog",
    templateUrl: "./upload-photo-dialog.component.html",
    styleUrls: ["./upload-photo-dialog.component.scss"],
})
export class UploadPhotoDialogComponent {
    @ViewChild("pdfFileInput", { static: false }) pdfFileInput!: ElementRef;
    @ViewChild("imageFileInput", { static: false }) imageFileInput!: ElementRef;
    imageUrl!: string | null;

    fotoOptieLabel: string = "Foto opties";
    fotoKiezenLabel: string = "Foto kiezen";
    documentKiezenLabel: string = "Document kiezen";

    constructor(
        private dialogRef: MatDialogRef<UploadPhotoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    openFileInput() {
        this.imageFileInput.nativeElement.click();
    }

    openDocumentInput() {
        this.pdfFileInput.nativeElement.click();
    }

    filesSelected(event: any)
    {
        this.dialogRef.close({files: event.target.files});
    }
}
