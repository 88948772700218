import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { SnackbarService } from "src/app/services/snackbar.service";

@Component({
    selector: "app-checklist-filter-dialog",
    templateUrl: "./checklist-filter-dialog.component.html",
    styleUrls: ["./checklist-filter-dialog.component.scss"],
})
export class ChecklistFilterDialogComponent implements OnInit {
    startDatumChangeText = "Startdatum is later dan einddatum: Startdatum is veranderd";
    eindDatumChangeText = "Einddatum is eerder dan startdatum: Einddatum is veranderd";

    colorFilters: String[] = ["rood", "oranje", "groen", "standaard"];

    hercontroleFilter: boolean = false;

    hercontroleFromDate!: Date;
    hercontroleToDate!: Date;

    //checklist-filter-dialog translate binding
    statuskleurTextLabel: string = "Statuskleur";
    hercontroleTextLabel: string = "Hercontrole";
    vanTextLabel: string = "Van";
    totTextLabel: string = "Tot";
    kiesDatumTextLabel: string = "Kies een datum";

    filterForm = this.formBuilder.group({
        leftDate: { value: null, disabled: !this.hercontroleFilter },
        rightDate: { value: null, disabled: !this.hercontroleFilter },
    });

    constructor(
        private formBuilder: UntypedFormBuilder,
        private dialogRef: MatDialogRef<ChecklistFilterDialogComponent>,
        private snackbar: SnackbarService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data != null) {
            if (data.fromDate != null) {
                this.hercontroleFromDate = data.fromDate;
            }

            if (data.toDate != null) {
                this.hercontroleToDate = data.toDate;
            }

            if (data.colorFilters != null && data.colorFilters.length > 0) {
                this.colorFilters = data.colorFilters;
            }
        }
    }

    ngOnInit(): void {
        if (
            this.hercontroleFromDate != undefined &&
            this.hercontroleFromDate &&
            this.hercontroleToDate != undefined &&
            this.hercontroleToDate
        ) {
            this.filterForm.controls["leftDate"].setValue(this.hercontroleFromDate);
            this.filterForm.controls["rightDate"].setValue(this.hercontroleToDate);

            this.hercontroleFilter = true;
        } else {
            this.hercontroleFilter = false;
        }

        this.determineHercontroleStatus();
    }

    onRedClick() {
        if (this.colorFilters.includes("rood")) {
            this.colorFilters.splice(this.colorFilters.indexOf("rood"), 1);
        } else {
            this.colorFilters.push("rood");
        }
    }

    onOrangeClick() {
        if (this.colorFilters.includes("oranje")) {
            this.colorFilters.splice(this.colorFilters.indexOf("oranje"), 1);
        } else {
            this.colorFilters.push("oranje");
        }
    }

    onGreenClick() {
        if (this.colorFilters.includes("groen")) {
            this.colorFilters.splice(this.colorFilters.indexOf("groen"), 1);
        } else {
            this.colorFilters.push("groen");
        }
    }

    onStandaardClick() {
        if (this.colorFilters.includes("standaard")) {
            this.colorFilters.splice(this.colorFilters.indexOf("standaard"), 1);
        } else {
            this.colorFilters.push("standaard");
        }
    }

    onHercontroleToggle() {
        this.hercontroleFilter = !this.hercontroleFilter;
        this.determineHercontroleStatus();
    }

    determineHercontroleStatus(): void {
        this.hercontroleFilter ? this.filterForm.enable() : this.filterForm.disable();
        if (!this.hercontroleFilter) {
            this.filterForm.reset();
        }
    }

    onSubmitFilterForm() {
        this.hercontroleFromDate = this.filterForm.controls["leftDate"].value;
        this.hercontroleToDate = this.filterForm.controls["rightDate"].value;
        let hercontroleToDate: Date = this.hercontroleToDate;
        let hercontroleFromDate: Date = this.hercontroleFromDate;
        let colorFilter = this.colorFilters.slice();

        // Close the dialog with arguments to save in checklist info
        this.dialogRef.close({
            hercontroleFromDate,
            hercontroleToDate,
            colorFilter,
        });
    }

    onResetFilter() {
        this.colorFilters = ["rood", "oranje", "groen", "standaard"];
        this.hercontroleFilter = false;
        this.determineHercontroleStatus();
    }

    changeStartDate() {
        if (
            typeof this.filterForm.controls["rightDate"].value != "string" &&
            this.filterForm.controls["rightDate"].value != null
        ) {
            if (this.filterForm.controls["leftDate"].value > this.filterForm.controls["rightDate"].value) {
                this.filterForm.controls["leftDate"].setValue(this.filterForm.controls["rightDate"].value);
                this.snackbar.basicSnackBar(this.startDatumChangeText, 3000);
            }
        }
    }

    changeEindDate() {
        if (
            typeof this.filterForm.controls["leftDate"].value != "string" &&
            this.filterForm.controls["leftDate"].value != null
        ) {
            if (this.filterForm.controls["rightDate"].value < this.filterForm.controls["leftDate"].value) {
                this.filterForm.controls["rightDate"].setValue(this.filterForm.controls["leftDate"].value);
                this.snackbar.basicSnackBar(this.eindDatumChangeText, 3000);
            }
        }
    }
}
