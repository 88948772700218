<div class="p-relative">
    <div g2sLayout="column">
        <div class="filter-header" g2sFlex="nogrow" g2sLayout="row" g2sLayoutAlign="start center">
            <div g2sFlex g2sLayoutAlign="start center"></div>
            <div g2sFlex g2sLayoutAlign="center center">
                <span>{{ filterTextLabel }}</span>
            </div>
            <div g2sFlex g2sLayoutAlign="end end">
                <svg
                    style="cursor: pointer"
                    height="3vh"
                    (click)="onCancel()"
                    viewBox="0 0 512 512"
                    width="3vh"
                    xml:space="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <defs>
                        <style>
                            .cls-1 {
                                fill: #fff;
                            }
                        </style>
                    </defs>
                    <path
                        class="cls-1"
                        d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
                </svg>
            </div>
        </div>
        <div class="banner" g2sFlex="nogrow" g2sLayout="row" g2sLayoutAlign="start center">
            <div g2sFlex class="bouwnummers-wrapper" g2sLayoutAlign="start center">
                <span>{{ bouwnummersTitleLabel }}</span>
            </div>
            <div class="wb-mla wb-select-svg">
                <img
                    src="/files/images/chepponline/uncheck_all_white.svg"
                    alt="Uncheck icon"
                    (click)="deselectAllBouwnummers()" />
                <img
                    src="/files/images/chepponline/check_all_white.svg"
                    alt="Check icon"
                    (click)="selectAllBouwnummers()" />
            </div>
        </div>
        <ng-container *ngIf="data.bouwnummers && data.bouwnummers.length > 0 else noBouwnummers">
            <div class="bouwnummers-list" g2sFlex="nogrow" g2sLayout="wrap" g2sLayoutAlign="start center">
                <div g2sFlex="wrap" class="bouwnummers-wrapper" g2sLayoutAlign="start center">
                    <div
                        class="seleted-item"
                        *ngFor="let bouwnummer of data.bouwnummers; index as i;"
                        (click)="selectBouwnummer(bouwnummer)">
                        <div
                            class="wb-documents-filter-label wb-default-label"
                            [class.wb-active-label]="selectedBouwnummers.includes(bouwnummer.id)">
                            {{ bouwnummer.bouwnummer }}
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #noBouwnummers>
            <div g2sFlex="column" class="p-10" g2sLayoutAlign="center center">{{ noBouwnummersLabel }}</div>
        </ng-template>
        <div class="banner" g2sFlex="nogrow" g2sLayout="row" g2sLayoutAlign="start center">
            <div g2sFlex class="bouwnummers-wrapper" g2sLayoutAlign="start center">
                <span>Labels:</span>
            </div>
            <div class="wb-mla wb-select-svg">
                <img
                    src="/files/images/chepponline/uncheck_all_white.svg"
                    alt="Uncheck icon"
                    (click)="deselectAllLabels()" />
                <img src="/files/images/chepponline/check_all_white.svg" alt="Check icon" (click)="selectAllLabels()" />
            </div>
        </div>
        <div g2sLayout="column">
            <ng-container *ngIf="data.categories && data.categories.length > 0 else noCategories">
                <div g2sLayout="column" *ngFor="let category of data.categories; index as i;">
                    <div class="category-wrapper" g2sFlex g2sLayoutAlign="start center">
                        <span class="category-name">{{ category.name }}</span>
                        <div class="wb-mla wb-select-svg">
                            <img
                                src="/files/images/chepponline/uncheck_all_white.svg"
                                (click)="deselectCategoryLabels(category.id)" />
                            <img
                                src="/files/images/chepponline/check_all_white.svg"
                                (click)="selectCategoryLabels(category.id)" />
                        </div>
                    </div>
                    <div g2sFlex="wrap" class="bouwnummers-wrapper" g2sLayoutAlign="start center">
                        <div
                            class="seleted-item"
                            *ngFor="let label of category.categorisationLabels; index as i;"
                            (click)="selectLabel(label.id)">
                            <div
                                class="wb-documents-filter-label wb-default-label"
                                [class.wb-active-label]="selectedLabels.includes(label.id)">
                                {{ label.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #noCategories>
                <div g2sFlex="column" class="p-10" g2sLayoutAlign="center center">{{ noLabelsLabel }}</div>
            </ng-template>
        </div>
    </div>
    <div class="filter-button-container" (click)="filterDocuments()">
        <img src="/files/images/chepponline/chepp_filter_white.svg" alt="filter button" />
    </div>
</div>
