import { Component } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { IDossier } from "src/app/model/dossier.model";
import { HttpClientService } from "src/app/services/posts.service";
import { SnackbarService } from "src/app/services/snackbar.service";

@Component({
    selector: "app-dossier-information",
    templateUrl: "./dossier-information.component.html",
    styleUrls: ["./dossier-information.component.scss"],
})
export class DossierInformationComponent {
    dossierId: any;
    dossier!: IDossier;
    loading = true;
    loadingSubscription!: Subscription;

    dossierInformatiePanel = true;
    showAanleidingen = false;

    attributeNotEditableLabel: string = "(Kenmerk is niet aanpasbaar)";
    saveTextLabel: string = "Wijzigingen opslaan";

    dossierNameTextLabel: string = "Dossiernaam";
    requiredTextLabel: string = "Is verplicht";
    featureTextLabel: string = "Kenmerk";
    invalidTextLabel: string = "Is ongeldig";
    townTextLabel: string = "Gemeente";

    locationTextLabel: string = "Plaats";
    postalCodeTextLabel: string = "Postcode";
    locationStraatTextLabel: string = "Locatie straat";
    corHouseNumberTextLabel: string = "Correspondentie huisnummer";
    corPostalCodeTextLabel: string = "Correspondentie postcode";
    corPlaceTextLabel: string = "Correspondentie plaats";
    corStraatTextLabel: string = "Correspondentie straat";
    locationHouseNumberTextLabel: string = "Locatie huisnummer";

    commentTextLabel: string = "Opmerkingen";
    causeTextLabel: string = "Aanleiding";
    dossierTextLabel: string = "Dossier";

    opgeslagenText = "Dossier informatie is succesvol opgeslagen";

    options: string[] = ["Test1", "Test2", "Test3"];

    // boolean voor laadcirkel tijdens saven
    isSaving = false;

    dossierInformatieForm = this.formBuilder.group({
        dossierNaam: [{ value: "" }, [Validators.required]],
        kenmerk: [{ value: "", disabled: true }, [Validators.required]],
        locatieStraat: [{ value: "" }, [Validators.required]],
        locatieHuisnummer: [{ value: "" }, [Validators.required]],
        postcode: [
            { value: "" },
            [
                Validators.required,
                Validators.pattern("(^[1-9]{1}[0-9]{3} ?[A-z]{2}$)|(^[A-z]{1}( |-)?[1-9]{1}[0-9]{3}$)"),
            ],
        ],
        plaats: [{ value: "" }, [Validators.required]],
        gemeente: [{ value: "" }],
        correspondentieStraat: [{ value: "" }],
        correspondentieHuisnummer: [{ value: "" }],
        correspondentiePostcode: [
            { value: "" },
            [Validators.pattern("(^[1-9]{1}[0-9]{3} ?[A-z]{2}$)|(^[A-z]{1}( |-)?[1-9]{1}[0-9]{3}$)")],
        ],
        correspondentiePlaats: [{ value: "" }],
    });

    dossierOpmerkingenForm = this.formBuilder.group({
        opmerkingen: [{ value: "" }],
        aanleiding: [{ value: "" }],
    });

    constructor(
        public snackbar: SnackbarService,
        public formBuilder: UntypedFormBuilder,
        private router: Router,
        private posts: HttpClientService,
        private route: ActivatedRoute
    ) {
        // Haalt de dossier data op van de database
        this.dossierId = route.snapshot.paramMap.get("dossierId");
        if (this.dossierId != null) {
            this.loading = true;
            this.loadingSubscription = posts.getTempDossier(this.dossierId).subscribe(
                (response) => {
                    if (!response) {
                        router.navigate(["/dashboard"]);
                        return;
                    }
                    this.dossier = response;
                },
                (err) => {
                    this.loading = false;
                },
                () => {
                    this.loading = false;
                    this.setFormValues();
                }
            );
        }
    }

    setFormValues() {
        // Dossier informatie form
        this.dossierInformatieForm.controls["dossierNaam"].setValue(this.dossier?.naam);
        this.dossierInformatieForm.controls["kenmerk"].setValue(this.dossier?.kenmerk);
        this.dossierInformatieForm.controls["locatieStraat"].setValue(this.dossier?.locatieStraat);
        this.dossierInformatieForm.controls["locatieHuisnummer"].setValue(this.dossier?.locatieStraatNummer);
        this.dossierInformatieForm.controls["postcode"].setValue(this.dossier?.postCode);
        this.dossierInformatieForm.controls["plaats"].setValue(this.dossier?.locatie);
        this.dossierInformatieForm.controls["gemeente"].setValue(this.dossier?.gemeente);
        this.dossierInformatieForm.controls["correspondentieStraat"].setValue(this.dossier?.correspondentieStraat);
        this.dossierInformatieForm.controls["correspondentieHuisnummer"].setValue(
            this.dossier?.correspondentieHuisnummer
        );
        this.dossierInformatieForm.controls["correspondentiePostcode"].setValue(this.dossier?.correspondentiePostcode);
        this.dossierInformatieForm.controls["correspondentiePlaats"].setValue(this.dossier?.correspondentiePlaats);

        // Opmerkingen form
        this.dossierOpmerkingenForm.controls["opmerkingen"].setValue(this.dossier?.opmerkingen);
        this.dossierOpmerkingenForm.controls["aanleiding"].setValue(this.dossier?.aanleiding);
    }

    onSubmitDossierInformatieForm() {
        this.dossier.naam = this.dossierInformatieForm.controls["dossierNaam"].value;
        this.dossier.kenmerk = this.dossierInformatieForm.controls["kenmerk"].value;
        this.dossier.locatieStraat = this.dossierInformatieForm.controls["locatieStraat"].value;
        this.dossier.locatieStraatNummer = this.dossierInformatieForm.controls["locatieHuisnummer"].value;
        this.dossier.postCode = this.dossierInformatieForm.controls["postcode"].value;
        this.dossier.locatie = this.dossierInformatieForm.controls["plaats"].value;
        this.dossier.gemeente = this.dossierInformatieForm.controls["gemeente"].value;
        this.dossier.correspondentieStraat = this.dossierInformatieForm.controls["correspondentieStraat"].value;
        this.dossier.correspondentieHuisnummer = this.dossierInformatieForm.controls["correspondentieHuisnummer"].value;
        this.dossier.correspondentiePostcode = this.dossierInformatieForm.controls["correspondentiePostcode"].value;
        this.dossier.correspondentiePlaats = this.dossierInformatieForm.controls["correspondentiePlaats"].value;

        this.isSaving = true;
        this.posts.postDossierInformatie(this.dossier).subscribe(
            (responseData) => {},
            (error) => {
                this.isSaving = false;
            },
            () => {
                this.isSaving = false;
                this.snackbar.succesSnackBar(this.opgeslagenText, 3000);
            }
        );
    }

    onSubmitOpmerkingenForm() {
        this.dossier.opmerkingen = this.dossierOpmerkingenForm.controls["opmerkingen"].value;

        if (this.showAanleidingen) {
            this.dossier.aanleiding = this.dossierOpmerkingenForm.controls["aanleiding"].value;
        }

        this.isSaving = true;
        this.posts.postDossierInformatie(this.dossier).subscribe(
            (responseData) => {},
            (error) => {
                this.isSaving = false;
            },
            () => {
                this.isSaving = false;
                this.snackbar.succesSnackBar(this.opgeslagenText, 3000);
            }
        );
    }

    isDossierInformatieButtonDisabled(): boolean {
        if (
            this.dossier.naam === this.dossierInformatieForm.controls["dossierNaam"].value &&
            this.dossier.kenmerk === this.dossierInformatieForm.controls["kenmerk"].value &&
            this.dossier.locatieStraat === this.dossierInformatieForm.controls["locatieStraat"].value &&
            this.dossier.locatieStraatNummer === this.dossierInformatieForm.controls["locatieHuisnummer"].value &&
            this.dossier.postCode === this.dossierInformatieForm.controls["postcode"].value &&
            this.dossier.locatie === this.dossierInformatieForm.controls["plaats"].value &&
            this.dossier.gemeente === this.dossierInformatieForm.controls["gemeente"].value &&
            this.dossier.correspondentieStraat === this.dossierInformatieForm.controls["correspondentieStraat"].value &&
            this.dossier.correspondentieHuisnummer ===
                this.dossierInformatieForm.controls["correspondentieHuisnummer"].value &&
            this.dossier.correspondentiePostcode ===
                this.dossierInformatieForm.controls["correspondentiePostcode"].value &&
            this.dossier.correspondentiePlaats === this.dossierInformatieForm.controls["correspondentiePlaats"].value
        ) {
            return true;
        }
        return false;
    }

    isOpmerkingenButtonDisabled(): boolean {
        if (
            this.dossier.opmerkingen === this.dossierOpmerkingenForm.controls["opmerkingen"].value &&
            this.dossier.aanleiding === this.dossierOpmerkingenForm.controls["aanleiding"].value
        ) {
            return true;
        }
        return false;
    }
}
