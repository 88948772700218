<app-loading-screen [displayLoadingScreen]="displayLoadingScreen"></app-loading-screen>

<div class="header-title-container" g2sLayout="row" g2sLayoutAlign="start center">
    <p class="title">Hoofdgroep</p>
    <div g2sFlex g2sLayoutAlign="end center">
        <mat-icon matRipple mat-dialog-close class="close-button">close</mat-icon>
    </div>
</div>
<p class="subtitle">{{ hoofdgroep.name }}</p>

<div class="divider"></div>

<textarea class="toelichting-tekst" [(ngModel)]="toelichting" placeholder="Toelichting..."></textarea>

<ng-container *ngIf="greenWaardeoordeelOptions || redWaardeoordeelOptions || orangeWaardeoordeelOptions">
    <div
        [ngClass]="{ 'bottom-margin': selectedWaardeoordeel === undefined || selectedWaardeoordeel.waardeoordeelTekst === 'standard' }"
        class="button-container"
        g2sLayout="row"
        g2sLayoutAlign="space-between center">
        <ng-container *ngIf="greenWaardeoordeelOptions.length > 0">
            <button
                [ngClass]="{
                                'button-inactive': selectedWaardeoordeel !== undefined && selectedWaardeoordeel.color !== 'green' }"
                (click)="selectWaardeoordeel(greenWaardeoordeelOptions)"
                g2sFlex="100"
                mat-flat-button
                class="buttonGroen">
                <mat-icon style="font-weight: 1000; transform: scale(1.3)">check</mat-icon>
            </button>
        </ng-container>
        <ng-container *ngIf="orangeWaardeoordeelOptions.length > 0">
            <button
                [ngClass]="{
                                    'button-inactive': selectedWaardeoordeel !== undefined && selectedWaardeoordeel.color !== 'orange' }"
                (click)="selectWaardeoordeel(orangeWaardeoordeelOptions)"
                g2sFlex="100"
                mat-flat-button
                class="buttonOranje">
                <mat-icon style="transform: scale(1.15)">warning</mat-icon>
            </button>
        </ng-container>
        <ng-container *ngIf="redWaardeoordeelOptions.length > 0">
            <button
                [ngClass]="{'button-inactive': selectedWaardeoordeel !== undefined && selectedWaardeoordeel.color !== 'red'}"
                (click)="selectWaardeoordeel(redWaardeoordeelOptions)"
                g2sFlex="100"
                mat-flat-button
                class="buttonRood">
                <mat-icon style="font-weight: 1000; transform: scale(1.3)">close</mat-icon>
            </button>
        </ng-container>
    </div>
</ng-container>

<ng-container *ngIf="selectedWaardeoordeel != undefined && selectedWaardeoordeel.text !== 'Standaard'">
    <div g2sLayout="row" g2sLayoutAlign="start center">
        <div class="{{ selectedWaardeoordeel.color }}-dot"></div>
        <p class="status-text">{{ selectedWaardeoordeel.text }}</p>
    </div>
</ng-container>

<button [disabled]="isSaveButtonDisabled()" (click)="onSaveClicked()" class="save-button" mat-flat-button>
    <ng-container *ngIf="!isSaving; else saving">{{ saveLabel }}</ng-container>
    <ng-template #saving>
        <mat-spinner [diameter]="25" class="saving-spinner" color="accent"></mat-spinner>
    </ng-template>
</button>
