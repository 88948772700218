import { DatePipe, registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { LOCALE_ID } from "@angular/core";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { AuthGuard } from "./services/auth-guard.service";
import { AuthService } from "./services/auth.service";
import { DataService } from "./services/data.service";
import localenl from "@angular/common/locales/nl";
import { SnackbarService } from "./services/snackbar.service";
import { APIInterceptorService } from "./services/api-interceptor.service";

registerLocaleData(localenl);

export const Providers = [
    {
        provide: MAT_DATE_LOCALE,
        useValue: "nl-NL",
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: APIInterceptorService,
        multi: true,
    },
    {
        provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
        useValue: { useUtc: true },
    },
    {
        provide: LOCALE_ID,
        useValue: "nl-NL",
    },
    DatePipe,
    AuthService,
    AuthGuard,
    SnackbarService,
    DataService,
];
