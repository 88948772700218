import { Component, Inject, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { SearchBetrokkeneDialogComponent } from "../search-betrokkene-dialog/search-betrokkene-dialog.component";
import { UntypedFormControl, FormGroupDirective, NgForm, Validators } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { DossierService } from "src/app/services/dossier.service";
import { IDossier } from "src/app/model/dossier.model";
import { IKlantOrganisation } from "src/app/model/klant-organisation.model";

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
    selector: "app-search-klant-organisation-dialog",
    templateUrl: "./search-klant-organisation-dialog.component.html",
    styleUrls: ["./search-klant-organisation-dialog.component.scss"],
})
export class SearchKlantOrganisationDialogComponent implements OnInit {
    dossier?: IDossier;
    translations: any;
    klantOrganisations: IKlantOrganisation[] = [];
    searched: boolean = false;

    displayLoadingScreen: boolean = false;

    klantOrganisationFormControl = new UntypedFormControl("", [Validators.required]);
    matcher = new MyErrorStateMatcher();

    constructor(
        private dialogRef: MatDialogRef<SearchBetrokkeneDialogComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dossierService: DossierService
    ) {
        this.translations = data.translations;
        this.dossier = data.dossier;

        if (data.input) {
            this.klantOrganisationFormControl.setValue(data.input);
        }
    }

    ngOnInit(): void {}

    async performSearch() {
        // let result = [
        //     {
        //         id: 1,
        //         name: "Test 1",
        //         kvk: "00000001",
        //     },
        //     {
        //         id: 2,
        //         name: "Test 2",
        //         kvk: "00000002",
        //     },
        //     {
        //         id: 3,
        //         name: "Test 3",
        //         kvk: "00000003",
        //     },
        //     {
        //         id: 4,
        //         name: "Test 4",
        //         kvk: "00000004",
        //     },
        // ];

        this.displayLoadingScreen = true;

        let result = await new Promise((resolve, reject) => {
            this.dossierService
                .findBetrokkeneOrganisations(this.dossier!.id, this.klantOrganisationFormControl.value)
                .subscribe({
                    next: (result) => {
                        resolve(result ?? []);
                    },
                });
        });

        setTimeout(() => {
            this.klantOrganisations = result as IKlantOrganisation[];

            this.searched = true;

            this.displayLoadingScreen = false;
        }, 250);
    }

    onSelectKlantOrganisation(klantOrganisation: IKlantOrganisation) {
        this.dialogRef.close(klantOrganisation);
    }

    onOrganisationInputChange(event: any) {
        this.searched = false;
        this.klantOrganisations = [];
    }
}
