<div class="wrapper" g2sLayout="column" g2sLayoutAlign="center center">
    <div class="header">{{ waardeoordeelText }}</div>
    <div class="divider"></div>
    <ng-container *ngFor="let waardeoordeel of waardeoordeelOpties; let i = index">
        <button (click)="onClickWaardeoordeel(waardeoordeel)" class="button" mat-flat-button>
            {{ waardeoordeel.text }}
        </button>
        <ng-container *ngIf="i + 1 !== waardeoordeelOpties.length">
            <div class="divider"></div>
        </ng-container>
    </ng-container>
</div>
