<div g2sLayout="column">
    <div class="banner" g2sFlex="nogrow" g2sLayout="row" g2sLayoutAlign="start center">
        <div g2sFlex class="back-button-wrapper" g2sLayoutAlign="start center" (click)="goBack()">
            <button class="back-button" mat-icon-button aria-label="Back">
                <mat-icon>navigate_before</mat-icon>
            </button>
            <span>{{ backButtonLabel }}</span>
        </div>
        <div g2sFlex g2sLayoutAlign="center center"></div>
        <div g2sFlex g2sLayoutAlign="end end"></div>
    </div>
</div>
