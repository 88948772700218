import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { IDeelplanChecklist } from "src/app/model/deelplan-checklist.model";
import { IGebruiker } from "src/app/model/gebruiker.model";
import { IHoofdgroep } from "src/app/model/hoofdgroep.model";
import { ImageSnippet } from "src/app/model/imagesnippet.model";
import { DataService } from "src/app/services/data.service";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import { ChecklistFilterDialogComponent } from "./checklist-filter-dialog/checklist-filter-dialog.component";
import { Subscription } from "rxjs";
import { DatePipe } from "@angular/common";
import { ChecklistHercontroleDialogComponent } from "./checklist-hercontrole-dialog/checklist-hercontrole-dialog.component";
import { IDossier } from "src/app/model/dossier.model";
import { AuthService } from "src/app/services/auth.service";
import { IDeelplan } from "src/app/model/deelplan.model";
import { IChecklistFilter } from "src/app/model/checklist-filter.model";
import { Router } from "@angular/router";

@Component({
    selector: "app-checklist-info",
    templateUrl: "./checklist-info.component.html",
    styleUrls: ["./checklist-info.component.scss"],
})
export class ChecklistInfoComponent {
    @Input() hoofdgroepen!: IHoofdgroep[];
    @Input() checklist!: IDeelplanChecklist;
    @Input() dossier!: IDossier;
    @Input() deelplan!: IDeelplan;

    user!: IGebruiker;
    selectedFile!: ImageSnippet;

    barGreen: number = 0;
    barOrange: number = 0;
    barRed: number = 0;

    greenStatus = 0;
    orangeStatus = 0;
    redStatus = 0;
    grayStatus = 0;

    amountStatus!: number;

    voortgangPercentage!: number;
    oldVoortgangPercentage!: number;

    // Properties Saved for filter popup
    filterPopupFromDate: any;
    filterPopupToDate: any;
    filterPopupColorFilters: string[] = [];
    filterPopupFromDateString: any;
    filterPopupToDateString: any;
    // Properties for what kind of filters to show
    showColorFilters = false;
    showDateFilters = false;
    opslaanOnStart = false;

    vraagSubscription!: Subscription;

    //Checklist-info translate binding
    voortgangLabel: string = "Voortgang";
    statusLabel: string = "Statusverdeling";

    activeFiltersTextLabel: string = "Actieve Filters";
    statusColorTextLabel: string = "Statuskleur:";
    hercontrolePeriodTextLabel: string = "Hercontrole periode";

    fromTextLabel: string = "Van:";
    untilTextLabel: string = "Tot:";

    constructor(
        private dataService: DataService,
        private dialog: MatDialog,
        private datePipe: DatePipe,
        public authService: AuthService,
        private router: Router
    ) {
        // if (this.authService.user != undefined) {
        //     this.user = this.authService.user;
        // }
    }

    //inladen van input image
    processFile(imageInput: any) {
        const file: File = imageInput.files[0];
        const reader = new FileReader();
        reader.addEventListener("load", (event: any) => {
            this.selectedFile = new ImageSnippet(event.target.result, file);
            this.dossier.foto = this.selectedFile.src;
        });
        reader.readAsDataURL(file);
    }

    // Filter popup
    onOpenFilters() {
        let dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.restoreFocus = false;
        dialogConfig.data = {
            fromDate: this.filterPopupFromDate,
            toDate: this.filterPopupToDate,
        };

        if (this.filterPopupColorFilters != null && this.filterPopupColorFilters.length !== 0) {
            dialogConfig.data.colorFilters = this.filterPopupColorFilters.slice();
        }

        let dialogRef = this.dialog.open(ChecklistFilterDialogComponent, dialogConfig);

        // If the popup gets closed
        dialogRef.afterClosed().subscribe((data: any) => {
            if (data != undefined && data != null) {
                this.filterPopupFromDate = data.hercontroleFromDate;
                this.filterPopupToDate = data.hercontroleToDate;
                this.filterPopupColorFilters = data.colorFilter;

                // Checks if the active filter panel needs to show the colors
                if (
                    this.filterPopupColorFilters != null &&
                    this.filterPopupColorFilters.length > 0 &&
                    this.filterPopupColorFilters.includes("rood") &&
                    this.filterPopupColorFilters.includes("oranje") &&
                    this.filterPopupColorFilters.includes("groen") &&
                    this.filterPopupColorFilters.includes("standaard")
                ) {
                    this.showColorFilters = false;
                } else if (this.filterPopupColorFilters != null && this.filterPopupColorFilters.length !== 0) {
                    this.showColorFilters = true;
                }

                // Checks if the active filter panel needs to show the date of the hercontrole
                if (
                    typeof this.filterPopupFromDate !== "string" &&
                    this.filterPopupFromDate != undefined &&
                    typeof this.filterPopupToDate !== "string" &&
                    this.filterPopupToDate != undefined
                ) {
                    this.showDateFilters = true;

                    this.filterPopupFromDateString = this.datePipe.transform(this.filterPopupFromDate, "dd-MM-yyyy");
                    this.filterPopupToDateString = this.datePipe.transform(this.filterPopupToDate, "dd-MM-yyyy");
                } else {
                    this.showDateFilters = false;
                }

                let activeFilterColors: any[] = [];

                if (this.filterPopupColorFilters != null && this.filterPopupColorFilters.length > 0) {
                    activeFilterColors = this.filterPopupColorFilters.slice();
                }

                let activeFilterFromDate = this.filterPopupFromDate;
                let activeFilterToDate = this.filterPopupToDate;

                let checklistFilter: IChecklistFilter = {
                    colors: activeFilterColors,
                };

                if (activeFilterFromDate != null) {
                    checklistFilter.fromDate = activeFilterFromDate;
                } else {
                    checklistFilter.fromDate = null;
                }

                if (activeFilterToDate != null) {
                    checklistFilter.toDate = activeFilterToDate;
                } else {
                    checklistFilter.toDate = null;
                }

                this.dataService.setChecklistFilters(checklistFilter);
            }
        });
    }

    onResetFilters() {
        this.showColorFilters = false;
        this.showDateFilters = false;
        this.filterPopupColorFilters = ["rood", "oranje", "groen", "standaard"];
        this.filterPopupFromDate = undefined;
        this.filterPopupToDate = undefined;
        this.dataService.setChecklistFilters(undefined);
    }

    onOpenHercontrole() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = "hercontrole-dialog";
        dialogConfig.data = this.checklist;
        let dialogRef = this.dialog.open(ChecklistHercontroleDialogComponent, dialogConfig);
    }

    openDocumentsOverview() {
        this.router.navigate([`/documents-overview/${this.dossier.id}/${this.deelplan.id}/${this.checklist.id}`]);
    }

    openPhotosOverview() {
        this.router.navigate([`/photos-overview/${this.dossier.id}/${this.deelplan.id}/${this.checklist.id}`]);
    }
}
