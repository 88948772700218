import { Component } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { IDossier } from "src/app/model/dossier.model";
import { HttpClientService } from "src/app/services/posts.service";
import { SnackbarService } from "src/app/services/snackbar.service";

@Component({
    selector: "app-dossier-memo",
    templateUrl: "./dossier-memo.component.html",
    styleUrls: ["./dossier-memo.component.scss"],
})
export class DossierMemoComponent {
    dossierId!: any;
    loading = false;
    saveLoading = false;
    dossier!: IDossier;

    saveTextLabel: string = "Wijzigignen opslaan";
    memoTextLabel: string = "Memo";

    snackbarErrorLabel: string = "Error";
    snackbarSuccessfullySavedLabel: string = "Succesvol opgeslagen";

    loadingSubscription!: Subscription;

    memoForm = this.formBuilder.group({
        memo: { value: "" },
    });

    constructor(
        public snackbar: SnackbarService,
        public formBuilder: UntypedFormBuilder,
        private router: Router,
        private posts: HttpClientService,
        private route: ActivatedRoute
    ) {
        // Haalt dossier data op van database
        this.dossierId = route.snapshot.paramMap.get("dossierId");
        if (this.dossierId != null) {
            this.loading = true;
            this.loadingSubscription = posts.getTempDossier(this.dossierId).subscribe(
                (response) => {
                    if (!response) {
                        router.navigate(["/dashboard"]);
                        return;
                    }
                    this.dossier = response;
                },
                (err) => {
                    this.loading = false;
                },
                () => {
                    this.memoForm.controls["memo"].setValue(this.dossier?.memo);
                    this.loading = false;
                }
            );
        }
    }

    onSubmitMemoForm() {
        // Dossier memo word opgeslagen in de database
        let memoText = this.memoForm.controls["memo"].value.toString();
        this.dossier.memo = memoText;
        this.saveLoading = true;
        this.posts.postDossierMemo(memoText, this.dossier.id).subscribe(
            (response) => {},
            (error) => {
                this.saveLoading = false;
                this.snackbar.errorSnackBar(this.snackbarErrorLabel, 10000);
            },
            () => {
                this.saveLoading = false;
                this.snackbar.succesSnackBar(this.snackbarSuccessfullySavedLabel, 3000);
            }
        );
    }

    isSaveDisabled(): boolean {
        // Checked of de save knop uit moet staan
        if (this.loading) {
            return true;
        }
        if (this.memoForm.controls["memo"].value === this.dossier?.memo) {
            return true;
        }
        return false;
    }
}
