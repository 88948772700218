import { Component } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import Validation from "src/app/utils/validation";
import { SnackbarService } from "src/app/services/snackbar.service";

@Component({
    selector: "app-password-reset",
    templateUrl: "./password-reset.component.html",
    styleUrls: ["./password-reset.component.scss"],
})
export class PasswordResetComponent {
    resetDialogTitle = "Nieuw wachtwoord";
    resetDialogContent =
        "Uw wachtwoord dient tenminste 8 karakters te bevatten waaronder een hoofdletter, kleine letter, cijfer en een bijzonder leesteken";
    newPassword = "Uw nieuwe wachtwoord";
    newPasswordRepeat = "Uw nieuwe wachtwoord nogmaals";
    resetSubmitBtn = "Voltooien";
    resetSnackbar = "Wachtwoord gereset";
    resetMatchError = "Wachtwoorden komen niet overeen";

    constructor(public dialog: MatDialog, private snackBar: SnackbarService) {}

    resetForm = new UntypedFormGroup(
        {
            newPassword: new UntypedFormControl("", [
                Validators.required,
                Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}"),
            ]),
            newPasswordRepeat: new UntypedFormControl("", [
                Validators.required,
                Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}"),
            ]),
        },
        {
            validators: [Validation.match("newPassword", "newPasswordRepeat")],
        }
    );

    onSubmit() {
        if (this.resetForm.valid) {
            this.dialog.closeAll();
        } else {
            this.snackBar.errorSnackBar(this.resetMatchError, 3000);
        }
    }

    closeDialog() {
        this.dialog.closeAll();
    }
}
