import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl, FormGroupDirective, NgForm, Validators } from "@angular/forms";
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { IKlantOrganisation } from "src/app/model/klant-organisation.model";
import { DossierService } from "src/app/services/dossier.service";
import { SearchBetrokkeneDialogComponent } from "../search-betrokkene-dialog/search-betrokkene-dialog.component";
import { ErrorStateMatcher } from "@angular/material/core";
import { IBetrokkene } from "src/app/model/betrokkene.model";

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
    selector: "app-add-new-uitvoerende-dialog",
    templateUrl: "./add-new-uitvoerende-dialog.component.html",
    styleUrls: ["./add-new-uitvoerende-dialog.component.scss"],
})
export class AddNewUitvoerendeDialogComponent implements OnInit {
    translations: any;
    klantOrganisations?: IKlantOrganisation;

    emailFormControl = new UntypedFormControl("", [Validators.required, Validators.email]);
    firstNameFormControl = new UntypedFormControl("", [Validators.required]);
    lastNameFormControl = new UntypedFormControl("", [Validators.required]);

    matcher = new MyErrorStateMatcher();

    constructor(
        private dialogRef: MatDialogRef<SearchBetrokkeneDialogComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.translations = data.translations;

        if ("email" in data) {
            this.emailFormControl.setValue(data.email);
        }
    }

    ngOnInit(): void {}

    add() {
        this.dialogRef.close({
            email: this.emailFormControl.value,
            firstName: this.firstNameFormControl.value,
            lastName: this.lastNameFormControl.value,
            name: `${this.firstNameFormControl.value} ${this.lastNameFormControl.value}`,
            availableForOpdrachten: true
        } as IBetrokkene);
    }
}
