import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { IChecklistFilter } from "../model/checklist-filter.model";
import { IHercontrole } from "../model/hercontrole.model";
import { AuthService } from "./auth.service";
import { HttpClientService } from "./posts.service";
import { IGebruiker } from "../model/gebruiker.model";

@Injectable({
    providedIn: "root",
})
export class DataService {
    public vraagAnswered = new Subject<any>();
    public user!: IGebruiker;
    public hercontroles: IHercontrole[] = [];

    private _checklistFilter = new Subject<IChecklistFilter | undefined>();
    public readonly checklistFilter: Observable<IChecklistFilter | undefined> = this._checklistFilter.asObservable();

    constructor(public post: HttpClientService, private auth: AuthService) {}

    getTempDossier() {
        return this.post.getDossiers();
    }

    setChecklistFilters(checklistFilter: IChecklistFilter | undefined): void {
        this._checklistFilter.next(checklistFilter);
    }
}
