import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, retry } from "rxjs/operators";
import { IDeelplan } from "../model/deelplan.model";
import { IDeelplanChecklist } from "../model/deelplan-checklist.model";
import { IDeelplanVraag } from "../model/deelplan-vraag.model";
import { IDossier } from "../model/dossier.model";
import { IHoofdgroep } from "../model/hoofdgroep.model";
import { IResponse } from "../model/response.model";
import { SnackbarService } from "./snackbar.service";
import { AbstractService } from "./abstract.service";
import { TokenService } from "./token.service";
import { DatePipe } from "@angular/common";
import { IDeelplanOpdracht } from "../model/deelplan-opdracht.model";

@Injectable({
    providedIn: "root",
})
export class FileService extends AbstractService {
    constructor(public http: HttpClient, public snackbar: SnackbarService, tokenService: TokenService) {
        super(http, tokenService, snackbar);
    }

    getDeelplanUpload(deelplanUploadId: number, thumbnail: boolean) {
        return new Promise((resolve, reject) => {
            this.httpClient
                .get(`/chepp/checklist/v1/deelplan/files/${deelplanUploadId}` + (thumbnail ? `/thumbnail` : ``), {
                    headers: new HttpHeaders({
                        Authorization: `Bearer ${this.tokenService.getAccessToken()}`,
                    }),
                    responseType: "blob",
                    reportProgress: true,
                    observe: "events",
                })
                .subscribe(
                    (event: HttpEvent<Blob>) => {
                        if (event.type === HttpEventType.Response) {
                            const blob = new Blob([event.body!], { type: "application/octet-stream" });
                            resolve(blob);
                        }
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }
}
