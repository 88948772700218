import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, retry } from "rxjs/operators";
import { IDeelplan } from "../model/deelplan.model";
import { IDeelplanChecklist } from "../model/deelplan-checklist.model";
import { IDeelplanVraag } from "../model/deelplan-vraag.model";
import { IDossier } from "../model/dossier.model";
import { IHoofdgroep } from "../model/hoofdgroep.model";
import { IResponse } from "../model/response.model";
import { SnackbarService } from "./snackbar.service";
import { AbstractService } from "./abstract.service";
import { TokenService } from "./token.service";
import { DatePipe } from "@angular/common";
import { BehaviorSubject } from "rxjs";
import { IFile } from "../model/file.model";

@Injectable({
    providedIn: "root",
})
export class DeelplanVraagService extends AbstractService {
    private deelplanVraag$ = new BehaviorSubject<IDeelplanVraag>({} as IDeelplanVraag);
    selectedDeelplanVraag = this.deelplanVraag$.asObservable();

    constructor(public http: HttpClient, public snackbar: SnackbarService, tokenService: TokenService) {
        super(http, tokenService, snackbar);
    }

    setDeelplanVraag(deelplanVraag: IDeelplanVraag) {
        this.deelplanVraag$.next(deelplanVraag);
    }

    getDeelplanVragen(hoofdgroepId: number, deelplanId: number, includeWaardeoordeelOptions: boolean = false) {
        return this.post(`/chepp/checklist/v1/hoofdgroepen/${hoofdgroepId}/vragen`, {
            deelplanId: Number(deelplanId),
            includeSummary: true,
            includeWaardeoordeelOptions: includeWaardeoordeelOptions
        }).pipe(map((res) => res.returnData as IDeelplanVraag[]));
    }

    getDeelplanVraag(deelplanVraagId: number) {
        return this.get(`/chepp/checklist/v1/vragen/${deelplanVraagId}`).pipe(
            map((res) => res.returnData as IDeelplanVraag)
        );
    }

    saveChanges(deelplanVraag: IDeelplanVraag) {
        let data: any = {
            toelichting: deelplanVraag.toelichting ?? "",
            origineleBeantwoordingsDatum: new DatePipe("nl_NL").transform(Date.now(), "dd-MM-yyyy HH:mm:ss"),
        };

        if (deelplanVraag.waardeoordeel) {
            data.waardeoordeelId = deelplanVraag.waardeoordeel.id;
        }

        if (deelplanVraag.hercontrole) {
            data.hercontrole = {
                date: new DatePipe("nl_NL").transform(deelplanVraag.hercontrole.date, "dd-MM-yyyy"),
                start: new DatePipe("nl_NL").transform(deelplanVraag.hercontrole.start, "HH:mm"),
                end: new DatePipe("nl_NL").transform(deelplanVraag.hercontrole.end, "HH:mm"),
            };
        }

        if(deelplanVraag.dateTimePicker)
        {
            data.datumTijdPicker = {
                status: deelplanVraag.dateTimePicker.status,
                startDate: new DatePipe("nl_NL").transform(deelplanVraag.dateTimePicker.startDate, "dd-MM-yyyy"),
                endDate: new DatePipe("nl_NL").transform(deelplanVraag.dateTimePicker.endDate, "dd-MM-yyyy"),
                startTime: deelplanVraag.dateTimePicker.startTime,
                endTime: deelplanVraag.dateTimePicker.endTime
            }
        }

        return this.post(`/chepp/checklist/v1/vragen/${deelplanVraag.id}`, data).pipe(
            map((res) => res.returnData as IDeelplanVraag)
        );
    }

    uploadFile(deelplanVraagRevision: number, file: File) {
        let formData: FormData = new FormData();
        formData.append("file", file, file.name);

        let headers = new HttpHeaders();
        headers.append("Content-Type", "multipart/form-data");
        headers.append("Accept", "application/json");

        return this.put(`/chepp/checklist/v1/vragen/${deelplanVraagRevision}/files`, formData, {}, headers).pipe(
            map((res) => res.returnData as IFile)
        );
    }

    getDefaultOpdrachten(deelplanVraagId: number) {
        return this.get(`/chepp/checklist/v1/vragen/${deelplanVraagId}/standaard-opdrachten`).pipe(
            map((res) => res.returnData as any)
        );
    }
}
