<div class="custom-dialog-header" g2sLayout="row">
    <h3 class="header-text">{{ titleLabel }}</h3>
</div>
<div class="custom-dialog-body" mat-dialog-content>
    <div g2sLayout="column" g2sLayoutGap="0.5vh">
        <div g2sLayout="row" g2sLayoutAlign="start center">
            <p>{{ textLabel }}</p>
        </div>
        <div g2sLayout="row" g2sLayoutGap="0.5vw" g2sLayoutAlign="end center">
            <button *ngIf="cancelButtonLabel" class="generic-button-cancel" (click)="close()">
                {{ cancelButtonLabel }}
            </button>
            <button
                *ngIf="!closeOnly"
                [ngClass]="{'regular-button-continue': theme == 'regular', 'warning-button-continue': theme == 'warning' }"
                (click)="continue()">
                {{ continueButtonLabel }}
            </button>
        </div>
    </div>
</div>
