import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";

@Component({
    selector: "app-logout-dialog",
    templateUrl: "./logout-dialog.component.html",
    styleUrls: ["./logout-dialog.component.scss"],
})
export class LogoutDialogComponent {
    titleTextLabel: string = "Weet u zeker dat u wilt uitloggen?";
    btnNoLabel: string = "Nee";
    btnYesLabel: string = "Ja";

    constructor(public authService: AuthService, public router: Router) {}

    //roept authservice aan om een logout te simuleren, en redirect terug naar de inlog pagina
    logOut() {
        this.authService.logout();
        this.router.navigate(["/inloggen"]);
    }
}
