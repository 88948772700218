<div class="wrapper">
    <ng-container>
        <mat-toolbar color="primary">
            <!-- home button -->
            <button class="home-button" mat-icon-button routerLink="/dossiermenu/{{ dossierId }}">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <span class="spacer"></span>
        </mat-toolbar>
    </ng-container>
    <div style="height: 64px"></div>
    <ng-container *ngIf="loading; else finished">
        <mat-spinner class="main-spinner"></mat-spinner>
    </ng-container>
    <ng-template #finished>
        <form #f="ngForm" [formGroup]="memoForm" (ngSubmit)="onSubmitMemoForm()">
            <div g2sLayout="column" g2sLayoutAlign="start center">
                <p class="title">{{ memoTextLabel }}</p>
                <textarea formControlName="memo" class="text-area"></textarea>
                <button [disabled]="isSaveDisabled()" type="submit" class="save-button" mat-flat-button>
                    <ng-container *ngIf="!saveLoading; else saving"> {{ saveTextLabel }} </ng-container>
                    <ng-template #saving>
                        <mat-spinner class="saving-spinner"></mat-spinner>
                    </ng-template>
                </button>
            </div>
        </form>
    </ng-template>
</div>
