import { DatePipe } from "@angular/common";
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatDatepicker } from "@angular/material/datepicker";
import * as moment from "moment";
import { IDatumtijdPicker } from "src/app/model/datum-tijd-picker.model";
import { IDeelplanVraag } from "src/app/model/deelplan-vraag.model";
import { IDeelplan } from "src/app/model/deelplan.model";
import { IDeelplanChecklist } from "src/app/model/deelplan-checklist.model";
import { IDossier } from "src/app/model/dossier.model";
import { IHoofdgroep } from "src/app/model/hoofdgroep.model";
import { DeelplanVraagService } from "src/app/services/deelplan-vraag.service";
import { SnackbarService } from "src/app/services/snackbar.service";

@Component({
    selector: "app-date-time-picker-vraag-dialog",
    templateUrl: "./date-time-picker-vraag-dialog.component.html",
    styleUrls: ["./date-time-picker-vraag-dialog.component.scss"],
})
export class DateTimePickerVraagDialogComponent implements OnInit {
    @Input() dossier!: IDossier;
    @Input() deelplan!: IDeelplan;
    @Input() deelplanChecklist!: IDeelplanChecklist;
    @Input() hoofdgroep!: IHoofdgroep;
    @Input() deelplanVraag!: IDeelplanVraag;
    @Input() translations!: any;

    @Output("closeDialog") closeDialog: EventEmitter<any> = new EventEmitter();

    saveLabel: string = "Opslaan";
    snackbarChangedStartDateLabel: string = "Datum gewijzigd: Ingevulde startdatum is later dan einddatum";
    snackbarChangedEndDateLabel: string = "Datum gewijzigd: Ingevulde einddatum is eerder dan startdatum";
    snackbarChangedStartTimeLabel: string = "Tijd gewijzigd: Ingevulde starttijd is later dan eindtijd";
    snackbarChangeEndTimeLabel: string = "Tijd gewijzigd: Ingevulde eindtijd is eerder dan starttijd";

    isSaving: boolean = false;
    displayLoadingScreen: boolean = false;

    startDatumText = "Startdatum";
    eindDatumText = "Einddatum";
    startTijdText = "Starttijd (optioneel)";
    eindTijdText = "Eindtijd (optioneel)";
    hercontroleLabel: string = "(Nieuwe) hercontrole";
    starttijdFocussed = false;
    eindtijdFocussed = false;
    datumTijdPickerToggle = true;

    datumTijdForm = this.formBuilder.group({
        startDatum: { value: "" },
        eindDatum: { value: "" },
        starttijdDatum: { value: "" },
        eindtijdDatum: { value: "" },
    });

    constructor(
        private formBuilder: UntypedFormBuilder,
        public datePipe: DatePipe,
        private snackbar: SnackbarService,
        public cdRef: ChangeDetectorRef,
        private deelplanVraagService: DeelplanVraagService
    ) {}

    ngOnInit(): void {
        let startDate = new Date();
        let endDate = new Date();
        let startTime = new Date();
        let endTime = new Date();

        startTime.setHours(8, 0, 0, 0);
        endTime.setHours(9, 0, 0, 0);
        
        if (this.deelplanVraag && this.deelplanVraag.dateTimePicker) {
            if (this.deelplanVraag.dateTimePicker.startDate) {
                startDate = moment(this.deelplanVraag.dateTimePicker.startDate, "DD-MM-YYYY").toDate();
            }

            if (this.deelplanVraag.dateTimePicker.endDate) {
                endDate = moment(this.deelplanVraag.dateTimePicker.endDate, "DD-MM-YYYY").toDate();
            }

            if (this.deelplanVraag.dateTimePicker.startTime) {
                startTime = moment(this.deelplanVraag.dateTimePicker.startTime, "HH:mm").toDate();
            }

            if (this.deelplanVraag.dateTimePicker.endTime) {
                endTime = moment(this.deelplanVraag.dateTimePicker.endTime, "HH:mm").toDate();
            }

            if (
                this.deelplanVraag.dateTimePicker.status &&
                (this.deelplanVraag.dateTimePicker.status.toLowerCase() == "open" ||
                    this.deelplanVraag.dateTimePicker.status.toLowerCase() == "niet van toepassing")
            ) {
                if (this.deelplanVraag.dateTimePicker.status.toLowerCase() == "open") {
                    this.datumTijdForm.enable();
                    this.datumTijdPickerToggle = true;
                } else {
                    this.datumTijdForm.disable();
                    this.datumTijdPickerToggle = false;
                }
            }
        }

        // Zet de tijden goed van de timepickers
        this.datumTijdForm.controls["startDatum"].setValue(startDate);
        this.datumTijdForm.controls["eindDatum"].setValue(endDate);
        this.datumTijdForm.controls["starttijdDatum"].setValue(this.datePipe.transform(startTime, "HH:mm"));
        this.datumTijdForm.controls["eindtijdDatum"].setValue(this.datePipe.transform(endTime, "HH:mm"));

        this.cdRef.detectChanges();
    }

    isSaveButtonDisabled() {
        return false;
    }

    async onSaveClicked() {
        this.isSaving = true;
        this.displayLoadingScreen = true;

        this.deelplanVraag.dateTimePicker = {
            status: this.datumTijdPickerToggle ? "Open" : "Niet van toepassing",
            startDate: this.datumTijdForm.controls["startDatum"].value,
            endDate: this.datumTijdForm.controls["eindDatum"].value,
            startTime: this.datumTijdForm.controls["starttijdDatum"].value,
            endTime: this.datumTijdForm.controls["eindtijdDatum"].value,
        } as IDatumtijdPicker;

        // Save the vraag changes which results in a new deelplan vraag revision
        let result = await new Promise<boolean>((resolve, reject) => {
            this.deelplanVraagService.saveChanges(this.deelplanVraag).subscribe({
                next: async (response) => {
                    if (response) {
                        this.deelplanVraag = response;
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                },
                error: (error) => {
                    reject(false);
                },
            });
        });

        this.isSaving = false;
        this.displayLoadingScreen = false;

        if (result) {
            this.closeDialog.emit(this.deelplanVraag);
        }
    }

    //Datumtijdpicker methods
    changeStartDatum() {
        if (this.datumTijdForm.controls["startDatum"].value > this.datumTijdForm.controls["eindDatum"].value) {
            this.datumTijdForm.controls["eindDatum"].setValue(this.datumTijdForm.controls["startDatum"].value);
        }

        // Check of de tijden nu nog kloppen voor als eventueel dezelfde dagen zijn geselecteerd
        this.changeStarttijd();
        this.changeEindtijd();

        this.cdRef.detectChanges();
    }

    changeEindDatum() {
        if (this.datumTijdForm.controls["eindDatum"].value < this.datumTijdForm.controls["startDatum"].value) {
            this.datumTijdForm.controls["startDatum"].setValue(this.datumTijdForm.controls["eindDatum"].value);
        }

        // Check of de tijden nu nog kloppen voor als eventueel dezelfde dagen zijn geselecteerd
        this.changeStarttijd();
        this.changeEindtijd();

        this.cdRef.detectChanges();
    }

    changeStarttijd() {
        let startTime = this.datumTijdForm.controls["starttijdDatum"].value;
        let endTime = this.datumTijdForm.controls["eindtijdDatum"].value;

        let timeNumbers = startTime.split(":");

        let startDateTime = new Date(this.datumTijdForm.controls["startDatum"].value);
        startDateTime.setHours(+timeNumbers[0]);
        startDateTime.setMinutes(+timeNumbers[1]);

        timeNumbers = endTime.split(":");

        let endDateTime = new Date(this.datumTijdForm.controls["eindDatum"].value);
        endDateTime.setHours(+timeNumbers[0]);
        endDateTime.setMinutes(+timeNumbers[1]);

        if (startDateTime > endDateTime) {
            this.datumTijdForm.controls["eindtijdDatum"].setValue(this.datePipe.transform(startDateTime, "HH:mm"));
        }
    }

    changeEindtijd() {
        let startTime = this.datumTijdForm.controls["starttijdDatum"].value;
        let endTime = this.datumTijdForm.controls["eindtijdDatum"].value;

        let timeNumbers = startTime.split(":");

        let startDateTime = new Date(this.datumTijdForm.controls["startDatum"].value);
        startDateTime.setHours(+timeNumbers[0]);
        startDateTime.setMinutes(+timeNumbers[1]);

        timeNumbers = endTime.split(":");

        let endDateTime = new Date(this.datumTijdForm.controls["eindDatum"].value);
        endDateTime.setHours(+timeNumbers[0]);
        endDateTime.setMinutes(+timeNumbers[1]);

        if (endDateTime < startDateTime) {
            this.datumTijdForm.controls["starttijdDatum"].setValue(this.datePipe.transform(endDateTime, "HH:mm"));
        }
    }

    changeDatepickerToggle() {
        if (this.datumTijdPickerToggle) {
            this.datumTijdForm.enable();
        } else {
            this.datumTijdForm.disable();
        }
    }
}
