import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IDossier } from "src/app/model/dossier.model";
import { HttpClientService } from "src/app/services/posts.service";

@Component({
    selector: "app-dossier-menu",
    templateUrl: "./dossier-menu.component.html",
    styleUrls: ["./dossier-menu.component.scss"],
})
export class DossierMenuComponent {
    dossierId: any;
    dossier!: IDossier;

    dossierInformationTextLabel: string = "Dossierinformatie";
    betrokkenenTextLabel: string = "Betrokkenen";
    deelplannenTextLabel: string = "Deelplannen";
    memoTextLabel: string = "Memo";

    constructor(private router: Router, private posts: HttpClientService, private route: ActivatedRoute) {
        this.dossierId = route.snapshot.paramMap.get("dossierId");
        if (this.dossierId != null) {
            posts.getTempDossier(this.dossierId).subscribe((data) => {
                if (!data) {
                    router.navigate(["/dashboard"]);
                    return;
                }
                this.dossier = data;
            });
        }
    }

    onOpenDossierInformatie() {
        this.router.navigate(["/dossierinformation", this.dossierId]);
    }

    onOpenBetrokkenen() {}

    onOpenDeelplannen() {}

    onOpenMemo() {
        this.router.navigate(["/memo", this.dossierId]);
    }
}
