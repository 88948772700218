import { Component } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

@Component({
    selector: "select-language-dialog",
    templateUrl: "./select-language-dialog.component.html",
    styleUrls: ["./select-language-dialog.component.scss"],
})
export class SelectLanguageDialogComponent {
    languages = Array.from({ length: 10 }).map((_, i) => `Taal #${i}`);

    changeLanguageHeaderTextLabel: string = "Taal wijzigen";
    saveBtnTextLabel: string = "Opslaan";
    selectableLanguageLabel: string = "Nederlands";

    selectedLanguage: string | null = null;

    constructor(public dialogRef: MatDialogRef<SelectLanguageDialogComponent>) {}

    onToggleClick(language: string): void {
        if (this.selectedLanguage === language) {
            this.selectedLanguage = null;
        } else {
            this.selectedLanguage = language;
        }
    }

    submitSelectedLanguage(): void {
        this.dialogRef.close(this.selectedLanguage);
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
