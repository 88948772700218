import { SignaturePadComponent } from "@almothafar/angular-signature-pad";
import { DatePipe } from "@angular/common";
import { Component, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { Router } from "@angular/router";
import { IDeelplan } from "src/app/model/deelplan.model";
import { IDeelplanChecklist } from "src/app/model/deelplan-checklist.model";
import { IDeelplanOpdracht } from "src/app/model/deelplan-opdracht.model";
import { IDeelplanVraag } from "src/app/model/deelplan-vraag.model";
import { IDossier } from "src/app/model/dossier.model";
import { VraagType, Waardeoordeel, WaardeoordeelColor } from "src/app/model/enum.model";
import { IHercontrole } from "src/app/model/hercontrole.model";
import { IWaardeoordeel } from "src/app/model/waardeoordeel.model";
import { AuthService } from "src/app/services/auth.service";
import { DataService } from "src/app/services/data.service";
import { HttpClientService } from "src/app/services/posts.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { AnswerHercontroleDialogComponent } from "./answer-hercontrole-dialog/answer-hercontrole-dialog.component";
import { AnswerModalDialogComponent } from "./answer-modal-dialog/answer-modal-dialog.component";
import { DelegateOpdrachtDialogComponent } from "./delegate-opdracht-dialog/delegate-opdracht-dialog.component";
import { ExistingVervolgopdrachtDialogComponent } from "./existing-vervolgopdracht-dialog/existing-vervolgopdracht-dialog.component";
import { MaatregelDialogComponent } from "./maatregel-dialog/maatregel-dialog.component";
import { NewOpdrachtDialogComponent } from "../checklist-member-dialogs/new-opdracht-dialog/new-opdracht-dialog.component";
import { SelectJudgementDialogComponent } from "../checklist-member-dialogs/select-judgement-dialog/select-judgement-dialog.component";
import { UploadPhotoDialogComponent } from "../checklist-member-dialogs/upload-photo-dialog/upload-photo-dialog.component";
import { WkiDocumentsOverviewDialogComponent } from "./wki-documents-overview-dialog/wki-documents-overview-dialog.component";
import { DeelplanVraagService } from "src/app/services/deelplan-vraag.service";
import { DeelplanOpdrachtService } from "src/app/services/deelplan-opdracht.service";
import { DomSanitizer } from "@angular/platform-browser";
import { DeelplanChecklistService } from "src/app/services/deelplan-checklist.service";
import * as moment from "moment";

@Component({
    selector: "app-answer-dialog",
    templateUrl: "./answer-dialog.component.html",
    styleUrls: ["./answer-dialog.component.scss"],
})
export class AnswerDialogComponent implements OnInit {
    @Input() checklist!: IDeelplanChecklist;
    @Input() deelplan!: IDeelplan;
    @Input() dossier!: IDossier;

    saveText = "Opslaan";
    titleText = "Hoofdgroep";
    opslaanText = "Succesvol opgeslagen";

    isSaving = false;

    dossierId!: number;
    deelplanId!: number;
    checklistId!: number;

    selectedWO!: IWaardeoordeel;

    vraagType = VraagType.Invulvraag;
    hoofdgroep: any;
    vragen: any;
    vraag!: IDeelplanVraag;
    vraagTypeNaam: any;
    handtekening: any;

    efffectMessageLabel = "";
    waarschijnlijkheidLabel = "";
    blootstellingLabel = "";

    snackbarErrorLabel: string = "Error";
    snackbarChangedStartDateLabel: string = "Datum veranderd: Ingevulde startdatum is later dan einddatum";
    snackbarChangedEndDateLabel: string = "Datum veranderd: Ingevulde einddatum is eerder dan startdatum";
    snackbarChangedStartTimeLabel: string = "Tijd veranderd: Ingevulde starttijd is later dan eindtijd";
    snackbarChangeEndTimeLabel: string = "Tijd veranderd: Ingevulde eindtijd is eerder dan starttijd";
    deadlineTitleLabel: string = "Deadline:";
    uitvoerendeTitleLabel: string = "Uitvoerende:";
    opdrachttekstTitleLabel: string = "Opdrachttekst:";
    vervolgopdrachtTitleLabel: string = "Vervolgopdracht:";
    opdrachtTitleLabel: string = "Opdracht";
    tempDummyDate = "12-12-2012";
    opdrachtOpmerkingLabel: string = "Please fix this WKI test opdracht";

    verantwoordingstekstLabel: string = "Verantwoordingsteksten";
    termijnTitleLabel: string = "Termijn:";
    conclusieTitleLabel: string = "Conclusie:";
    prioriteitLabel: string = "Prioriteit:";
    risicoLabel: string = "Risico:";
    effectLabel: string = "Effect:";
    waarschijnlijkheidTitleLabel: string = "Waarschijnlijkheid:";
    blootstellingTitleLabel: string = "Blootstelling:";

    actueleToelichtingOpen = false;
    actueleToelichtingText = "Actuele toelichting";

    previousToelichting!: string;
    toelichting: string = "";

    risicoValueLabel: string = "10000";
    risicoDescriptionLabel: string = "Zeer Hoog";
    prioriteitValueLabel: string = "P1";
    conclusieLabel: string = "Overweeg stopzetten van de activiteit";
    termijnLabel: string = "Direct";

    showDeleteIcon = true;

    startDatum: Date = new Date();
    eindDatum: Date = new Date();
    starttijdDatum: Date = new Date();
    eindtijdDatum: Date = new Date();

    // Properties voor datumtijdpicker
    startDatumText = "Startdatum";
    eindDatumText = "Einddatum";
    startTijdText = "Starttijd (optioneel)";
    eindTijdText = "Eindtijd (optioneel)";
    hercontroleLabel: string = "(Nieuwe) hercontrole";
    starttijdFocussed = false;
    eindtijdFocussed = false;
    datumTijdPickerToggle = true;

    attentionTextLabel: string = "Aandachtspunten";
    guidelinesTextLabel: string = "Richtlijnen";
    grondslagTextLabel: string = "Grondslagen";
    maatregelButtonTextLabel: string = "M";
    riskAndPriorityTextLabel: string = "Risico en prioriteit";
    hercontroleFromTextLabel: string = "Van";
    hercontroleTillTextLabel: string = "Tot";
    hercontroleTextLabel: string = "Hercontrole:";

    // Properties voor Verantwoordingen
    verantwoordingsteksten: string = "Standaard bij niet van toepassing (automatisch)";
    deelplanvraag!: IDeelplanVraag;

    // Properties voor meerkeuze
    waardeoordeelOpties: IWaardeoordeel[] = [];
    waardeoordeelOptieKleuren: string[] = [];

    // waardeoordelenGroen: IWaardeoordeel[] = [
    //     {
    //         waardeoordeelCode: "w01",
    //         waardeoordeelKleur: "groen",
    //         leidtTotHercontrole: false,
    //         leidtTotVervolgopdracht: false,
    //         waardeoordeelTekst: "Voldoet",
    //     },
    //     {
    //         waardeoordeelCode: "w02",
    //         waardeoordeelKleur: "groen",
    //         leidtTotHercontrole: false,
    //         leidtTotVervolgopdracht: false,
    //         waardeoordeelTekst: "Niet van toepassing",
    //     },
    // ];
    // waardeoordelenOranje: IWaardeoordeel[] = [
    //     {
    //         waardeoordeelCode: "w10",
    //         waardeoordeelKleur: "oranje",
    //         leidtTotHercontrole: true,
    //         leidtTotVervolgopdracht: true,
    //         waardeoordeelTekst: "Geen Wo",
    //     },
    //     {
    //         waardeoordeelCode: "w11",
    //         waardeoordeelKleur: "oranje",
    //         leidtTotHercontrole: true,
    //         leidtTotVervolgopdracht: false,
    //         waardeoordeelTekst: "Planning bijwerken",
    //     },
    // ];
    // waardeoordelenRood: IWaardeoordeel[] = [
    //     {
    //         waardeoordeelCode: "w20",
    //         waardeoordeelKleur: "rood",
    //         leidtTotHercontrole: false,
    //         leidtTotVervolgopdracht: true,
    //         waardeoordeelTekst: "Voldoet Niet",
    //     },
    //     {
    //         waardeoordeelCode: "w22",
    //         waardeoordeelKleur: "rood",
    //         leidtTotHercontrole: true,
    //         leidtTotVervolgopdracht: true,
    //         waardeoordeelTekst: "Voldoet Niet (Herstelbaar)",
    //     },
    // ];

    vraagRisicoCondition!: number;

    aandachtspunten = [
        { text: "Dit is een test met prio 5", priority: 5 },
        { text: "Dit is een test met prio 4", priority: 4 },
        { text: "Dit is een test met prio 5", priority: 5 },
        { text: "Dit is een test met prio 1", priority: 1 },
        { text: "Dit is een test met prio 2", priority: 2 },
    ];

    richtlijnen = ["test.pdf", "document.pdf", "richtlijn.pdf"];

    grondslagen = [
        {
            id: "1.2",
            name: "Grondslag test 1",
            text: "Dit is een test grondslag artikel om te testen hoe de tekst werkt",
        },
        {
            id: "1.45",
            name: "Grondslag test 2",
            text: "Dit is een test grondslag artikel om te testen hoe de tekst werkt\nDit is een test grondslag artikel om te testen hoe de tekst werkt\nDit is een test grondslag artikel om te testen hoe de tekst werkt",
        },
    ];

    signaturePadOptions = {
        minWidth: 2,
        canvasWidth: 100,
        canvasHeight: 400,
    };

    datumTijdForm = this.formBuilder.group({
        startDatum: { value: "" },
        eindDatum: { value: "" },
        starttijdDatum: { value: "" },
        eindtijdDatum: { value: "" },
    });

    imageUrl: string = "";

    createdHercontrole!: IHercontrole | null;
    createdOpdracht!: IDeelplanOpdracht | null;
    tempCreatedHercontrole!: IHercontrole | null;
    tempCreatedOpdracht!: IDeelplanOpdracht | null;

    opdrachtDelegated: boolean = false;

    @ViewChild(SignaturePadComponent) signaturePad!: SignaturePadComponent;

    files: any[] = [];

    displayLoadingScreen = false;

    constructor(
        public authService: AuthService,
        private router: Router,
        private dataservice: DataService,
        private dialogRef: MatDialogRef<AnswerDialogComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private posts: HttpClientService,
        public datePipe: DatePipe,
        public formBuilder: UntypedFormBuilder,
        private snackbar: SnackbarService,
        private deelplanVraagService: DeelplanVraagService,
        private DeelplanOpdrachtService: DeelplanOpdrachtService,
        private sanitizer: DomSanitizer
    ) {
        this.dossierId = data.dossierId;
        this.deelplanId = data.deelplanId;
        this.checklistId = data.checklistId;

        this.hoofdgroep = data.hoofdgroep;
        this.vragen = data.hoofdgroep.vragen;
        this.vraag = data.vraag;

        this.deelplanVraagService.setDeelplanVraag(this.vraag);

        if (this.vraag.waardeoordeel) {
            this.selectedWO = this.vraag.waardeoordeel;
        }

        if (this.vraag && this.hoofdgroep == null && !this.hoofdgroep.canAnswerOnHoofdgroepLevel) {
            // Als er een enkele vraag is geselecteerd
            // Haal alle informatie van de vraag op
            this.hoofdgroep = data.hoofdgroep;

            this.vraagType = this.vraag.type;
            if (this.vraag.toelichting != undefined) {
                this.previousToelichting = this.vraag.toelichting;
            }

            this.selectedWO = data.vraag.waardeoordeel;
            this.handtekening = this.vraag.handtekening;

            switch (this.vraag.type) {
                // Als de vraag een datumtijdpicker is
                case VraagType.DatumTijdPicker: {
                    this.vraagTypeNaam = "Datum/tijdpicker";

                    // Zet de form uit als deze al uitstond in de database
                    if (this.vraag.dateTimePicker && this.vraag.dateTimePicker.status === "Niet van toepassing") {
                        this.datumTijdPickerToggle = false;
                        this.changeDatepickerToggle();
                    }
                    // Datumtijdpicker data
                    else {
                        this.startDatum = new Date(data.vraag.dateTimePicker.startDatum);
                        this.eindDatum = new Date(data.vraag.dateTimePicker.eindDatum);
                        this.starttijdDatum = new Date(data.vraag.dateTimePicker.starttijdDatum);
                        this.eindtijdDatum = new Date(data.vraag.dateTimePicker.eindtijdDatum);
                    }
                    break;
                }
                // Als de vraag een invulvraag is
                case VraagType.Invulvraag: {
                    this.vraagTypeNaam = "Invulvraag";
                    break;
                }
                // Als de vraag een handtekening vraag is
                case VraagType.Handtekening: {
                    this.vraagTypeNaam = "Handtekening";
                    break;
                }
                // Als de vraag een meerkeuze vraag is
                case VraagType.Meerkeuzevraag: {
                    this.vraagTypeNaam = "Meerkeuzevraag";
                    break;
                }
            }
        }
    }

    ngOnInit(): void {
        // Resize de handtekening pad
        if (this.vraag.type === VraagType.Handtekening && !this.hoofdgroep.canAnswerOnHoofdgroepLevel) {
            setTimeout(() => {
                this.resizeSignaturePad();
            }, 0);
        }

        if (this.vraag.type === VraagType.DatumTijdPicker && !this.hoofdgroep.canAnswerOnHoofdgroepLevel) {
            // Zet de tijden goed van de timepickers
            this.datumTijdForm.controls["startDatum"].setValue(this.startDatum);
            this.datumTijdForm.controls["eindDatum"].setValue(this.eindDatum);
            if (this.data.vraag.dateTimePicker == undefined) {
                this.starttijdDatum.setHours(0);
                this.starttijdDatum.setMinutes(0);
                this.eindtijdDatum.setHours(23);
                this.eindtijdDatum.setMinutes(59);
            }
            this.datumTijdForm.controls["starttijdDatum"].setValue(
                this.datePipe.transform(this.starttijdDatum, "HH:mm")
            );
            this.datumTijdForm.controls["eindtijdDatum"].setValue(this.datePipe.transform(this.eindtijdDatum, "HH:mm"));
        }
    }

    // Als de groene waardeoordeelknop wordt ingedrukt
    onClickGreen() {
        let greenWaardeoordeelOptions = this.getGreenWaardeoordeelOptions();

        let dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = "waardeoordeel-popup";
        dialogConfig.data = { hoofdgroepNiveau: true, waardeoordeelOpties: greenWaardeoordeelOptions };

        if (this.files) {
            dialogConfig.data.files = this.files;
        }

        let dialogRef = this.dialog.open(SelectJudgementDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(async (data) => {
            if (data != null) {
                this.selectedWO = data.waardeoordeel;

                this.createdHercontrole = null;
                this.createdOpdracht = null;

                switch (data.type) {
                    case "hercontrole":
                        this.createdHercontrole = data.data;
                        break;
                    case "vervolgopdracht":
                        this.createdOpdracht = data.data;
                        break;
                    default:
                        break;
                }
            }
        });
    }

    // Als de oranje waardeoordeel knop wordt ingedrukt
    onClickOrange() {
        let orangeWaardeoordeelOptions = this.getOrangeWaardeoordeelOptions();

        let dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = "waardeoordeel-popup";
        dialogConfig.data = { hoofdgroepNiveau: true, waardeoordeelOpties: orangeWaardeoordeelOptions };

        if (this.files) {
            dialogConfig.data.files = this.files;
        }

        let dialogRef = this.dialog.open(SelectJudgementDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(async (data) => {
            if (data != null) {
                this.selectedWO = data.waardeoordeel;

                this.createdHercontrole = null;
                this.createdOpdracht = null;

                switch (data.type) {
                    case "hercontrole":
                        this.createdHercontrole = data.data;
                        break;
                    case "vervolgopdracht":
                        this.createdOpdracht = data.data;
                        break;
                    default:
                        break;
                }
            }
        });
    }

    getWaardeoordeelOptionsOfColor(color: WaardeoordeelColor) {
        return this.vraag.waardeoordeelOptions?.filter((w) => w.color == color);
    }

    getRedWaardeoordeelOptions() {
        return this.getWaardeoordeelOptionsOfColor(WaardeoordeelColor.RED);
    }

    getOrangeWaardeoordeelOptions() {
        return this.getWaardeoordeelOptionsOfColor(WaardeoordeelColor.ORANGE);
    }

    getGreenWaardeoordeelOptions() {
        return this.getWaardeoordeelOptionsOfColor(WaardeoordeelColor.GREEN);
    }

    // Als de rode waardeoordeel knop wordt ingedrukt
    onClickRed() {
        let redWaardeoordeelOptions = this.getRedWaardeoordeelOptions();

        let dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = "waardeoordeel-popup";
        dialogConfig.data = { hoofdgroepNiveau: true, waardeoordeelOpties: redWaardeoordeelOptions };

        if (this.files) {
            dialogConfig.data.files = this.files;
        }

        let dialogRef = this.dialog.open(SelectJudgementDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(async (data) => {
            if (data != null) {
                this.selectedWO = data.waardeoordeel;

                this.createdHercontrole = null;
                this.createdOpdracht = null;

                switch (data.type) {
                    case "hercontrole":
                        this.createdHercontrole = data.data;
                        break;
                    case "vervolgopdracht":
                        this.createdOpdracht = data.data;
                        break;
                    default:
                        break;
                }
            }
        });
    }

    // Sorteer de aandachtspunten op prioriteit
    getFilteredAandachtspunten() {
        let filteredAandachtspunten = this.aandachtspunten.sort((a, b) => (a.priority > b.priority ? 1 : -1));
        return filteredAandachtspunten;
    }

    // Als er word opgeslagen bij een lege datum/tijdpicker
    onSaveEmptyDate() {
        if (this.vraag.dateTimePicker) {
            this.vraag.dateTimePicker.status = "Niet van toepassing";
        }
        this.isSaving = true;
        this.posts
            .postVraagData(
                this.vraag,
                this.dossierId,
                this.deelplanId,
                this.checklistId,
                this.hoofdgroep.id,
                this.vraag.id
            )
            .subscribe(
                (response) => {},
                (error) => {
                    this.isSaving = false;
                    this.snackbar.errorSnackBar(this.snackbarErrorLabel, 10000);
                    this.dialogRef.close();
                },
                () => {
                    this.isSaving = false;
                    this.snackbar.succesSnackBar(this.opslaanText, 3000);
                    this.dialogRef.close();
                }
            );
    }

    async onSave() {
        this.displayLoadingScreen = true;

        // Sla de data op naar firebase bij het drukken van de opslaan knop
        if (!this.hoofdgroep.canAnswerOnHoofdgroepLevel) {
            switch (this.vraag.type) {
                // Als de vraag een DatumTijdpicker is
                case VraagType.DatumTijdPicker: {
                    // this.vraag.waardeoordeel = {
                    //     waardeoordeelCode: "1",
                    //     waardeoordeelKleur: "groen",
                    //     waardeoordeelTekst: "Voldoet",
                    //     leidtTotHercontrole: false,
                    //     leidtTotVervolgopdracht: false,
                    // };
                    // this.vraag.dateTimePicker = {
                    //     status: "Open",
                    //     startDatum: this.startDatum,
                    //     eindDatum: this.eindDatum,
                    //     starttijdDatum: this.starttijdDatum,
                    //     eindtijdDatum: this.eindtijdDatum,
                    // };
                    break;
                }
                // Als de vraag een invulvraag is
                case VraagType.Invulvraag: {
                    if (this.vraag.toelichting != null) {
                        // if (this.vraag.toelichting == "") {
                        // this.vraag.toelichting = this.vraag.toelichting;
                        // this.vraag.waardeoordeel = {
                        //     waardeoordeelCode: "1",
                        //     waardeoordeelKleur: "groen",
                        //     waardeoordeelTekst: "Voldoet",
                        //     leidtTotHercontrole: false,
                        //     leidtTotVervolgopdracht: false,
                        // };
                        // } else {
                        // this.vraag.toelichting = this.vraag.toelichting;
                        // this.vraag.waardeoordeel = {
                        //     waardeoordeelCode: "1",
                        //     waardeoordeelKleur: "groen",
                        //     waardeoordeelTekst: "Voldoet",
                        //     leidtTotHercontrole: false,
                        //     leidtTotVervolgopdracht: false,
                        // };
                        // }
                    }
                    break;
                }
                // Als de vraag een handtekening is
                case VraagType.Handtekening: {
                    await new Promise((resolve, reject) => {
                        this.signaturePad.getCanvas().toBlob((signature) => {
                            let file = new File(
                                [signature as Blob],
                                "signature_" + moment(Date.now()).format("DD-MM-YYYY") + "_" + this.vraag.id + ".png"
                            );
                            this.files.push({ file: file });

                            resolve(true);
                        });
                    });
                    //this.vraag.handtekening = this.signaturePad.toDataURL();
                    // this.vraag.waardeoordeel = {
                    //     waardeoordeelCode: "1",
                    //     waardeoordeelKleur: "groen",
                    //     waardeoordeelTekst: "Voldoet",
                    //     leidtTotHercontrole: false,
                    //     leidtTotVervolgopdracht: false,
                    // };
                    break;
                }
                // Als de vraag een meerkeuze is
                case VraagType.Meerkeuzevraag: {
                    // if (this.vraag.toelichting != null) {
                    //     this.vraag.toelichting = this.vraag.toelichting;
                    // }
                    this.vraag.waardeoordeel = this.selectedWO;
                    break;
                }
            }

            this.vraag.hercontrole = undefined;

            if (this.createdHercontrole != null) {
                this.vraag.hercontroleDatum = this.createdHercontrole.datum;
                this.vraag.hercontroleEindTijd = this.createdHercontrole.eindTijd;
                this.vraag.hercontroleStartTijd = this.createdHercontrole.startTijd;

                this.vraag.hercontrole = this.createdHercontrole;
            } else {
                if (
                    this.vraag.hercontroleDatum != null &&
                    this.vraag.hercontroleStartTijd != null &&
                    this.vraag.hercontroleEindTijd != null
                ) {
                    this.vraag.hercontroleDatum = undefined;
                    this.vraag.hercontroleStartTijd = undefined;
                    this.vraag.hercontroleEindTijd = undefined;
                }
            }

            this.isSaving = true;

            await new Promise((resolve, reject) => {
                this.deelplanVraagService.saveChanges(this.vraag).subscribe({
                    next: async (response) => {
                        this.vraag = response;
                        resolve(response);
                    },
                    error: (error) => {
                        reject(false);
                    },
                });
            });

            if (this.files) {
                for (let file of this.files) {
                    await new Promise((resolve, reject) => {
                        this.deelplanVraagService.uploadFile(this.vraag.deelplanVraagRevisionId!, file.file).subscribe({
                            next: (response) => {
                                resolve(true);
                            },
                            error: (error) => {
                                reject(false);
                            },
                        });
                    });
                }
            }

            if (this.createdOpdracht) {
                await new Promise((resolve, reject) => {
                    this.DeelplanOpdrachtService
                        .createVervolgOpdracht(
                            this.vraag.deelplanVraagRevisionId!,
                            this.createdOpdracht!.betrokkene!.id,
                            this.createdOpdracht!.omschrijving,
                            this.createdOpdracht!.deadline
                        )
                        .subscribe({
                            next: async (response) => {
                                if (this.createdOpdracht?.attachments) {
                                    for (let attachment of this.createdOpdracht.attachments) {
                                        await new Promise((resolve, reject) => {
                                            this.DeelplanOpdrachtService
                                                .addAttachment(response.id!, attachment.file)
                                                .subscribe({
                                                    next: (response) => {
                                                        resolve(true);
                                                    },
                                                    complete() {
                                                        resolve(true);
                                                    },
                                                });
                                        });
                                    }
                                }

                                resolve(response);
                            },
                            error: (error) => {
                                reject(false);
                            },
                        });
                });
            }

            this.isSaving = false;

            // Sla de informatie op
            // this.isSaving = true;
            // this.posts
            //     .postVraagData(
            //         this.vraag,
            //         this.dossierId,
            //         this.deelplanId,
            //         this.checklistId,
            //         this.hoofdgroep.id,
            //         this.vraag.id
            //     )
            //     .subscribe(
            //         (response) => {},
            //         (error) => {
            //             this.isSaving = false;
            //             this.snackbar.errorSnackBar(this.snackbarErrorLabel, 10000);
            //             this.dialogRef.close();
            //         },
            //         () => {
            //             this.isSaving = false;
            //             this.snackbar.succesSnackBar(this.opslaanText, 3000);
            //             this.dataservice.vraagAnswered.next();
            //             this.dialogRef.close();
            //         }
            //     );
        } else {
            // Voeg toelichting toe aan alle vragen in hoofdgroep
            for (let vraag of this.hoofdgroep.vragen) {
                if (this.hoofdgroep.canAnswerOnHoofdgroepLevel) {
                    vraag.waardeoordeel = this.selectedWO;
                } else {
                    vraag.waardeoordeel = {
                        waardeoordeelCode: "1",
                        waardeoordeelKleur: "groen",
                        waardeoordeelTekst: "Voldoet",
                    };
                }
                vraag.toelichting = this.vraag.toelichting;
            }

            // // Post de hoofdgroepdata
            // this.posts
            //     .posthoofdgroepData(
            //         this.hoofdgroep,
            //         this.dossierId,
            //         this.deelplanId,
            //         this.checklistId,
            //         this.hoofdgroep.id
            //     )
            //     .subscribe(
            //         (next) => (data: any) => {},
            //         (error) => {
            //             this.isSaving = false;
            //             this.snackbar.errorSnackBar(this.snackbarErrorLabel, 10000);
            //             this.dialogRef.close();
            //         },
            //         () => {
            //             this.isSaving = false;
            //             this.snackbar.succesSnackBar(this.opslaanText, 3000);
            //             this.dataservice.vraagAnswered.next();
            //             this.dialogRef.close();
            //         }
            //     );
        }
        this.displayLoadingScreen = false;
    }

    // Veranderd de grootte van het handtekeningveld om in het scherm te passen
    resizeSignaturePad() {
        if (this.handtekening == null || this.handtekening === "") {
            this.signaturePad.set("canvasWidth", document.getElementById("sign_canvas")?.offsetWidth);
            this.signaturePad.set("canvasHeight", 300);
            this.signaturePad.clear();
        }
    }

    // Cleared het handtekeningveld
    clearSignaturePad() {
        this.signaturePad.clear();
    }

    isSaveButtonDisabled(): boolean {
        let result: boolean = false;
        // Als de gebruiker op hoofdniveau beantwoord
        if (this.isSaving) {
            result = true;
        }
        if (this.createdHercontrole) {
            return !this.createdHercontrole;
        }

        if (this.hoofdgroep.canAnswerOnHoofdgroepLevel) {
            // Als het geen meerkeuze is
            if (!this.hoofdgroep.canAnswerOnHoofdgroepLevel) {
                if (this.vraag.toelichting === this.previousToelichting) {
                    result = true;
                } else {
                    result = false;
                }
            } else {
                if (
                    this.vraag.toelichting === this.previousToelichting &&
                    this.vraag.waardeoordeel === this.selectedWO
                ) {
                    result = true;
                } else {
                    result = false;
                }
            }
        }
        // Als de gebruiker op vraagniveau beantwoord
        else {
            switch (this.vraag.type) {
                // Invulvraag
                case VraagType.Invulvraag: {
                    if (!this.vraag.toelichting) {
                        result = true;
                        break;
                    }
                    if (this.vraag.toelichting === this.previousToelichting) {
                        result = true;
                        break;
                    } else {
                        result = false;
                        break;
                    }
                }
                // Handtekeningvraag
                case VraagType.Handtekening: {
                    result = false;
                    break;
                }
                // Meerkeuzevraag
                case VraagType.Meerkeuzevraag: {
                    if (
                        this.vraag.toelichting === this.previousToelichting &&
                        this.vraag.waardeoordeel === this.selectedWO
                    ) {
                        result = true;
                        break;
                    } else {
                        result = false;
                        break;
                    }
                }
                // Datum/tijdpicker vraag
                case VraagType.DatumTijdPicker: {
                    // Als de tijdpicker uitstaat en ook in de database uitstaat is knop disabled
                    if (this.datumTijdPickerToggle === false) {
                        result = true;
                        break;
                    }
                }
            }
        }

        return result;
    }

    //Datumtijdpicker methods
    changeStartDatum() {
        // Check of de startdatum niet later is dan de einddatum
        if (this.datumTijdForm.controls["startDatum"].value > this.datumTijdForm.controls["eindDatum"].value) {
            this.snackbar.basicSnackBar(this.snackbarChangedStartDateLabel, 3000);
            this.startDatum = this.eindDatum;
        } else {
            this.startDatum = this.datumTijdForm.controls["startDatum"].value;
        }
        // Check of de tijden nu nog kloppen voor als eventueel dezelfde dagen zijn geselecteerd
        this.changeStarttijd();
        this.changeEindtijd();
    }

    changeEindDatum() {
        // Check of de einddatum niet eerder is dan de startdatum
        if (this.datumTijdForm.controls["eindDatum"].value < this.datumTijdForm.controls["startDatum"].value) {
            this.snackbar.basicSnackBar(this.snackbarChangedEndDateLabel, 3000);
            this.eindDatum = this.startDatum;
        } else {
            this.eindDatum = this.datumTijdForm.controls["eindDatum"].value;
        }
        // Check of de tijden nu nog kloppen voor als eventueel dezelfde dagen zijn geselecteerd
        this.changeStarttijd();
        this.changeEindtijd();
    }

    changeStarttijd() {
        if (
            this.datePipe.transform(this.startDatum, "shortDate") ===
            this.datePipe.transform(this.eindDatum, "shortDate")
        ) {
            if (this.datumTijdForm.controls["starttijdDatum"].value.value !== "") {
                let time: string = this.datumTijdForm.controls["starttijdDatum"].value;
                let timeNumbers = time.split(":");
                this.starttijdDatum.setHours(+timeNumbers[0]);
                this.starttijdDatum.setMinutes(+timeNumbers[1]);

                if (this.starttijdDatum > this.eindtijdDatum) {
                    this.snackbar.basicSnackBar(this.snackbarChangedStartTimeLabel, 3000);
                    this.starttijdDatum = new Date(this.eindtijdDatum.getTime());
                    this.datumTijdForm.controls["starttijdDatum"].setValue(
                        this.datePipe.transform(this.starttijdDatum, "HH:mm")
                    );
                }
            }
        } else {
            let time: string = this.datumTijdForm.controls["starttijdDatum"].value;
            let timeNumbers = time.split(":");
            this.starttijdDatum.setHours(+timeNumbers[0]);
            this.starttijdDatum.setMinutes(+timeNumbers[1]);
        }
    }

    changeEindtijd() {
        if (
            this.datePipe.transform(this.startDatum, "shortDate") ===
            this.datePipe.transform(this.eindDatum, "shortDate")
        ) {
            if (this.datumTijdForm.controls["eindtijdDatum"].value.value !== "") {
                let time: string = this.datumTijdForm.controls["eindtijdDatum"].value;
                let timeNumbers = time.split(":");
                this.eindtijdDatum.setHours(+timeNumbers[0]);
                this.eindtijdDatum.setMinutes(+timeNumbers[1]);

                if (this.eindtijdDatum < this.starttijdDatum) {
                    this.snackbar.basicSnackBar(this.snackbarChangeEndTimeLabel, 3000);
                    this.eindtijdDatum = new Date(this.starttijdDatum.getTime() + 60 * 60 * 1000);
                    this.datumTijdForm.controls["eindtijdDatum"].setValue(
                        this.datePipe.transform(this.eindtijdDatum, "HH:mm")
                    );
                }
            }
        } else {
            let time: string = this.datumTijdForm.controls["eindtijdDatum"].value;
            let timeNumbers = time.split(":");
            this.eindtijdDatum.setHours(+timeNumbers[0]);
            this.eindtijdDatum.setMinutes(+timeNumbers[1]);
        }
    }

    starttijdFocus(focussed: boolean) {
        this.starttijdFocussed = focussed;
    }

    eindtijdFocus(focussed: boolean) {
        this.eindtijdFocussed = focussed;
    }

    changeDatepickerToggle() {
        if (this.datumTijdPickerToggle) {
            this.datumTijdForm.enable();
        } else {
            this.datumTijdForm.disable();
        }
    }

    onOpenActueleToelichting() {
        this.actueleToelichtingOpen = !this.actueleToelichtingOpen;
    }

    // Controleerd of elke vraag in een hoofdgroep een waardeoordeeloptie bevat om deze te tonen bij het beantwoorden op hoofdgroepniveau
    checkForWaardeoordeelOptie(): IWaardeoordeel[] {
        let waardeoordeelOpties: IWaardeoordeel[] = [];

        let allWaardeoordelen: IWaardeoordeel[] = [];
        // Pak alle waardeoordelen van de hoofdgroep
        for (let vraag of this.hoofdgroep.vragen) {
            for (let waardeoordeel of vraag.waardeoordeelOpties) {
                // Check of de waardeoordeel moet worden toegevoegd aan de array
                if (allWaardeoordelen.length === 0) {
                    allWaardeoordelen.push(waardeoordeel);
                } else if (
                    allWaardeoordelen.every((wo: any) => {
                        if (wo.waardeoordeelCode !== waardeoordeel.waardeoordeelCode) {
                            return true;
                        }
                        return false;
                    })
                ) {
                    // Voeg de waardeoordeel toe
                    allWaardeoordelen.push(waardeoordeel);
                }
            }
        }
        // Check voor of elke vraag de waardeoordelen heeft
        for (let waardeoordeel of allWaardeoordelen) {
            if (
                this.hoofdgroep.vragen.every(function (vraag: any) {
                    if (
                        vraag.waardeoordeelOpties.some((wo: any) => {
                            if (wo.waardeoordeelCode === waardeoordeel.waardeoordeelCode) {
                                return true;
                            }
                            return false;
                        })
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                })
            ) {
                waardeoordeelOpties.push(waardeoordeel);
            }
        }
        return waardeoordeelOpties;
    }

    // filterWaardeoordelen() {
    //     // Check welke kleuren er moeten worden getoont
    //     // En sorteer deze kleuren
    //     for (let waardeoordeel of this.waardeoordeelOpties) {
    //         switch (waardeoordeel.waardeoordeelKleur) {
    //             case "groen": {
    //                 this.waardeoordelenGroen.push(waardeoordeel);
    //                 break;
    //             }
    //             case "oranje": {
    //                 this.waardeoordelenOranje.push(waardeoordeel);
    //                 break;
    //             }
    //             case "rood": {
    //                 this.waardeoordelenRood.push(waardeoordeel);
    //             }
    //         }
    //     }
    // }

    public get Waardeoordeel(): typeof Waardeoordeel {
        return Waardeoordeel;
    }

    public get VraagType(): typeof VraagType {
        return VraagType;
    }

    onOpenAnswerHercontrole() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = "vraag-hercontrole-dialog";
        let dialogRef = this.dialog.open(AnswerHercontroleDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((hercontrole: IHercontrole) => {
            if (hercontrole != null) {
                this.createdHercontrole = hercontrole;
            }
        });
    }

    onOpenModal() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = "vraag-hercontrole-dialog";
        dialogConfig.data = {
            dossierId: this.dossierId,
            deelplanId: this.deelplanId,
            checklistId: this.checklistId,
        };
        dialogConfig.closeOnNavigation = true;
        let dialogRef = this.dialog.open(AnswerModalDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((createdOpdracht: IDeelplanOpdracht) => {
            if (createdOpdracht != null) {
                this.createdOpdracht = createdOpdracht;
            }
        });
    }

    onDeleteCreatedHercontrole(): void {
        if (this.createdHercontrole != null) {
            this.createdHercontrole = null;
        }
    }

    onOpenMaatregelen() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = "vraag-maatregel-dialog";
        let dialogRef = this.dialog.open(MaatregelDialogComponent, dialogConfig);
    }

    onReopenOpdracht() {
        const dialogConfig = new MatDialogConfig();
        let dialogRef = this.dialog.open(NewOpdrachtDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((createdOpdracht: IDeelplanOpdracht) => {
            if (createdOpdracht != null) {
                this.createdOpdracht = createdOpdracht;
            }
        });
    }

    // Check if Vraag has FollowUpAction
    // When it does it will open the pop-up to decide to continue new WO or cancel to the old selected Waardeoordeel
    determineIfVraagHasFollowUpAction(waardeoordeel: IWaardeoordeel): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (this.vraag) {
                if (
                    this.vraag.waardeoordeel &&
                    this.vraag.waardeoordeel.waardeoordeelCode != waardeoordeel.waardeoordeelCode
                ) {
                    const dialogConfig = new MatDialogConfig();
                    dialogConfig.autoFocus = false;
                    dialogConfig.panelClass = "existing-vervolgopdracht-dialog";
                    if (this.vraag.waardeoordeel.leidtTotHercontrole && this.createdHercontrole != null) {
                        let dialogRef = this.dialog.open(ExistingVervolgopdrachtDialogComponent, dialogConfig);
                        dialogRef.afterClosed().subscribe((confirm: boolean) => {
                            if (confirm) {
                                this.createdHercontrole = null;
                                this.selectedWO = waardeoordeel;

                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        });
                    } else if (this.vraag.waardeoordeel.leidtTotVervolgopdracht && this.createdOpdracht != null) {
                        let dialogRef = this.dialog.open(ExistingVervolgopdrachtDialogComponent, dialogConfig);
                        dialogRef.afterClosed().subscribe((confirm: boolean) => {
                            if (confirm) {
                                this.createdOpdracht = null;
                                this.selectedWO = waardeoordeel;
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        });
                    } else {
                        this.selectedWO = waardeoordeel;
                        resolve(true);
                    }
                }
            }
        });
    }

    openOpdrachtDoorgeven() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = "opdracht-doorgeven-dialog";
        let dialogRef = this.dialog.open(DelegateOpdrachtDialogComponent, dialogConfig);
    }

    openDocumentsOverview() {
        this.router.navigate([`/documents-overview/${this.dossierId}/${this.deelplanId}/${this.checklistId}`]);
        this.dialog.closeAll();
    }

    openWkiDocumentsOverview() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = "wki-doc-overview";
        dialogConfig.data = {
            dossierId: this.dossierId,
            deelplanId: this.deelplanId,
            checklistId: this.checklistId,
        };
        let dialogRef = this.dialog.open(WkiDocumentsOverviewDialogComponent, {
            width: "100vw",
            maxWidth: "100vw",
            height: "100vh",
            maxHeight: "100vh",
            panelClass: "wki-doc-overview",
        });
    }

    onDropFiles(event: any) {
        this.processFiles(event.addedFiles);
    }

    deleteFile(index: number) {
        this.files.splice(index, 1);
    }

    onOpenFileUpload() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = "upload-photo-dialog";
        let dialogRef = this.dialog.open(UploadPhotoDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe({
            next: (data) => {
                this.processFiles(Array.from(data.files));
            },
        });
    }

    processFiles(files: File[]) {
        let count = 0;

        for (let file of files) {
            // With each file processing, max. 12 files are allowed.
            // If we reached 12 files, we'll break the loop.
            if (count >= 12) {
                break;
            }

            // Skip the current file if size larger than 10MB
            if (file.size > 10485760) {
                continue;
            }

            // If we're dealing with an pdf
            // We'll use a placeholder for displaying instead of the original image
            if (file.type.includes("pdf")) {
                this.files.push({
                    file: file,
                    content: "/assets/pdf_placeholder.png",
                });
            } else {
                var reader = new FileReader();

                reader.onload = (event: any) => {
                    this.files.push({
                        file: file,
                        content: event.target.result,
                    });
                };

                reader.readAsDataURL(file);
            }

            count++;
        }
    }
}
