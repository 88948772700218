import { Overlay, OverlayConfig, OverlayRef, PositionStrategy } from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import { Injectable, TemplateRef, ViewContainerRef } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class OverlayService {
    constructor(private overlay: Overlay) {}

    /**
     * Create a new overlay based on the passed config
     * @param config
     * @returns
     */
    createOverlay(config: OverlayConfig): OverlayRef {
        return this.overlay.create(config);
    }

    /**
     * Attach Ng Template to the overlay
     * @param overlayRef
     * @param templateRef
     * @param vcRef
     */
    attachTemplatePortal(overlayRef: OverlayRef, templateRef: TemplateRef<any>, vcRef: ViewContainerRef): void {
        let templatePortal = new TemplatePortal(templateRef, vcRef);
        overlayRef.attach(templatePortal);
    }

    /**
     * Center the overlay horizontally and vertically
     * @returns
     */
    positionGloballyCenter(): PositionStrategy {
        return this.overlay.position().global().centerHorizontally().centerVertically();
    }
}
