import { Component, Inject } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";

@Component({
    selector: "app-generic-popup",
    templateUrl: "./generic-popup.component.html",
    styleUrls: ["./generic-popup.component.scss"],
})
export class GenericPopupComponent {
    titleLabel: string = "Titel"; // Title, default fallback
    cancelButtonLabel: string = "Annuleren"; // Cancel button text, default fallback
    continueButtonLabel: string = "Opslaan"; // Submit button text, default fallback
    textLabel: string = "Tekst"; // Content text, default fallback
    closeOnly: boolean = false; // Bool to check if we only want to display the cancel button, more of an informative display not to process an action
    theme: "regular" | "warning" = "regular";

    constructor(public dialogRef: MatDialogRef<GenericPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        // Check if not null
        if (data) {
            // Set the label texts
            this.titleLabel = data.title;
            this.cancelButtonLabel = data.cancel;
            this.continueButtonLabel = data.continue;
            this.textLabel = data.text;

            // Check if closeOnly is present
            if (data.closeOnly) {
                // If so set it else we use the default
                this.closeOnly = data.closeOnly;
            }

            // Check if theme is present
            if (data.theme) {
                // If so set it else we use the default
                this.theme = data.theme;
            }
        }
    }

    /**
     * WTT-128
     * Fires when the User clicks on the cancel button and passes a long a false boolean to indicate we dont want to continue
     */
    close(): void {
        this.dialogRef.close(false);
    }

    /**
     * WTT-128
     * Fires when the User clicks on the continue button and passes a long a true boolean to indicate we want to continue
     */
    continue(): void {
        this.dialogRef.close(true);
    }
}
