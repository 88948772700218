import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";

import { AuthService } from "../services/auth.service";

@Injectable({
    providedIn: "root",
})
export class AuthGuard implements CanActivate {
    canActivateVal: boolean = false;

    constructor(private authService: AuthService, private router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
        // const isAuth = !!this.authService.user?.token;
        const isAuth = true;

        if (isAuth) {
            return true;
        } else {
            return this.router.createUrlTree(["/inloggen"]);
        }
    }
}
