import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, HostListener, Inject, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";

@Component({
    selector: "app-answer-modal-timeline",
    templateUrl: "./answer-modal-timeline.component.html",
    styleUrls: ["./answer-modal-timeline.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnswerModalTimelineComponent {
    @ViewChild(CdkVirtualScrollViewport) viewPort!: CdkVirtualScrollViewport;
    tempCondition!: number;

    subtitle: string = "Vraag 2";
    toelichtingTitleLabel: string = "Toelichting:";
    opdrachtTitle: string = "Opdracht";
    previousToelichting: string =
        "Dit is afgekeurd Dit is afgekeurd Dit is afgekeurd Dit is afgekeurd Dit is afgekeurd ";
    naamLabel: string = "Test Naam";
    gereedmelding: string = "Datum/tijd gereedmelding: ";
    uitvoerendeLabel: string = "Uitvoerende: ";
    deadlineTextLabel: string = "Deadline: ";
    waardeoordeelLabel: string = "Voldoet";
    waardeoordeelKleur: string = "wa";
    changedDate: Date = new Date();
    cards = Array.from({ length: 25 }).map((_, i) => `Card #${i}`);

    mouseDown = false;
    startX: number = 0;
    scrollLeft: number = 0;

    constructor(
        private dialogRef: MatDialogRef<AnswerModalTimelineComponent>,
        public datePipe: DatePipe,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        dialogRef.disableClose = true;
    }

    @HostListener("document:mousedown", ["$event"])
    onMouseDown(e: MouseEvent) {
        if (this.viewPort) {
            this.mouseDown = true;
            document.body.style.cursor = "grabbing";
            this.startX = e.clientX;
            this.scrollLeft = this.viewPort.measureScrollOffset();
        }
    }

    @HostListener("document:touchstart", ["$event"])
    onTouchStart(e: TouchEvent) {
        if (this.viewPort) {
            this.mouseDown = true;
            document.body.style.cursor = "grabbing";
            this.startX = e.touches[0].clientX;
            this.scrollLeft = this.viewPort.measureScrollOffset();
            this.viewPort.setRenderedContentOffset(this.scrollLeft);
        }
    }

    @HostListener("document:mousemove", ["$event"])
    @HostListener("document:touchmove", ["$event"])
    dragScroll(e: MouseEvent | TouchEvent) {
        if (this.viewPort && this.mouseDown) {
            const x = e instanceof MouseEvent ? e.clientX : e.touches[0].clientX;
            const distance = x - this.startX;
            const scrollX = this.scrollLeft - distance;
            this.viewPort.scrollToOffset(scrollX);
        }
    }

    @HostListener("document:mouseup", ["$event"])
    @HostListener("document:touchend", ["$event"])
    stopDragScroll() {
        this.mouseDown = false;
        document.body.style.cursor = "default";
        const gridPx = 500;
        const currentOffset = this.viewPort.measureScrollOffset();
        const snappedOffset = Math.round(currentOffset / gridPx) * gridPx;
        this.viewPort.scrollToOffset(snappedOffset, "smooth");
    }

    onTimelineClick(card: any): void {
        if (this.viewPort != null && card != null) {
            const selectedIndex = this.cards.findIndex((el) => el == card);
            if (selectedIndex > -1) {
                const gridPx = 500;
                const snappedOffset = Math.round(selectedIndex * gridPx);
                this.viewPort.scrollToOffset(snappedOffset, "smooth");
            }
        }
    }
}
