<div class="wrapper">
    <div class="header" g2sLayout="row">
        <p class="titel-text">{{ translations?.Chepp_ChecklistView_IssueOpdracht }}</p>
        <mat-icon matRipple (click)="closeDialog()" class="close-button">close</mat-icon>
    </div>
    <div class="gereed-datum">
        <form>
            <p class="hercontrole-time-text">{{ translations?.Chepp_ChecklistView_DateFinished }}</p>
            <div g2sLayout="row" g2sLayoutAlign="start start" class="gereed-datum-select">
                <mat-datepicker-toggle #datetoggle (click)="picker.open()" matSuffix>
                    <mat-icon class="selected-date-icon" matDatepickerToggleIcon>today</mat-icon>
                </mat-datepicker-toggle>
                <div style="position: relative">
                    <p class="selected-date capitalize" style="cursor: pointer" (click)="picker.open()">
                        {{ datePipe.transform(deadlineFormControl.value, "EEEE, dd-MM-yyyy") }}
                    </p>
                    <mat-form-field style="width: 1px; visibility: hidden; position: absolute; top: -1px">
                        <input
                            required
                            id="deadlineDate"
                            [formControl]="deadlineFormControl"
                            matInput
                            [matDatepicker]="picker" />
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
    <form>
        <div class="uitvoerende" g2sLayout="column" g2sLayoutGap="10px">
            <p class="uitvoerende-title-text">{{ translations?.Chepp_ChecklistView_SelectAnOrganisation }}</p>
            <input
                class="uitvoerende-select"
                type="text"
                placeholder="{{ translations?.Chepp_ChecklistView_OrganisationName }}"
                [formControl]="klantOrganisationFormControl"
                [matAutocomplete]="auto"
                (change)="klantOranisationInputChanged()" />
            <mat-autocomplete
                class="auto-complete-wrapper"
                #auto="matAutocomplete"
                [displayWith]="displayFn"
                (optionSelected)="getKlantOrganisationBetrokkenen($event.option)"
                (closed)="klantOrganisationOptionsClosed()">
                <mat-option class="auto-complete-option" *ngFor="let option of filteredKlantOrganisationOptions | async" [value]="option">
                    {{option.name}}
                </mat-option>
            </mat-autocomplete>
        </div>

        <ng-container *ngIf="!authenticatedUser?.klant?.identifiers?.isWki">
            <ng-container
                *ngIf="(filteredKlantOrganisationOptions | async)!.length == 0 && klantOrganisationFormControl.value.length > 0">
                <div
                    class="open-search-betrokkene"
                    g2sLayout="row"
                    g2sLayoutAlign="start center"
                    (click)="openSearchKlantOrganisationDialog()">
                    <mat-icon class="add-icon">search</mat-icon>
                    <p class="opdracht-uitvoerende-text">
                        {{ translations?.Chepp_ChecklistView_SearchForKlantOrganisationByKVKorName }}
                    </p>
                </div>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="selectedKlantOrganisation">
            <ng-container *ngIf="betrokkenen && betrokkenen.length > 0">
                <div class="uitvoerende" g2sLayout="column" g2sLayoutGap="10px">
                    <p class="uitvoerende-title-text">{{ translations?.Chepp_ChecklistView_ChooseAnUitvoerende }}</p>
                    <input
                        class="uitvoerende-select"
                        type="text"
                        placeholder="{{ translations?.Uitvoerende }}"
                        [formControl]="betrokkeneSelectionFormControl"
                        [matAutocomplete]="autoBetrokkene"
                        (change)="betrokkeneInputChanged()" />
                    <mat-autocomplete
                        class="auto-complete-wrapper"
                        #autoBetrokkene="matAutocomplete"
                        [displayWith]="displayFn"
                        (optionSelected)="betrokkeneSelected($event.option)"
                        (closed)="betrokkeneOptionsClosed()">
                        <mat-option class="auto-complete-option" *ngFor="let betrokkene of filteredBetrokkeneOptions | async" [value]="betrokkene">
                            {{betrokkene.name}}
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </ng-container>

            <div class="new-uitvoerende" g2sLayout="column">
                <ng-container
                    *ngIf="!selectedKlantOrganisation.isNew && betrokkenen && betrokkenen.length > 0; else showWithoutOR">
                    <div g2sLayout="row" g2sLayoutAlign="start center" (click)="openSearchBetrokkeneDialog()">
                        <mat-icon class="add-icon">search</mat-icon>
                        <p class="opdracht-uitvoerende-text">
                            {{ translations?.Or }} {{
                            translations?.Chepp_ChecklistView_SearchForUitvoerendeByEmail.toLowerCase() }}
                        </p>
                    </div>
                </ng-container>

                <ng-template #showWithoutOR>
                    <ng-container
                        *ngIf="selectedKlantOrganisation.isNew && !selectedBetrokkene; else showSearchBetrokkene">
                        <span g2sLayout="row" g2sLayoutAlign="start center" (click)="openAddNewUitvoerendeDialog()">
                            <mat-icon class="add-icon">add</mat-icon>
                            <p class="opdracht-uitvoerende-text">
                                {{ translations?.Chepp_ChecklistView_CreateNewUitvoerende }}
                            </p>
                        </span>
                    </ng-container>
                    <ng-template #showSearchBetrokkene>
                        <ng-container *ngIf="!selectedBetrokkene">
                            <div g2sLayout="column">
                                <p>
                                    {{translations?.Chepp_ChecklistView_NoExistingUitvoerendenFoundByDossierAndOrganisation}}
                                </p>
                                <span
                                    g2sLayout="row"
                                    g2sLayoutAlign="start center"
                                    (click)="openSearchBetrokkeneDialog()">
                                    <mat-icon class="add-icon">search</mat-icon>
                                    <p class="opdracht-uitvoerende-text">
                                        {{ translations?.Chepp_ChecklistView_SearchForUitvoerendeByEmail }}
                                    </p>
                                </span>
                            </div>
                        </ng-container>
                    </ng-template>
                </ng-template>
            </div>
        </ng-container>

        <div class="opdracht-omschrijving" g2sLayout="column">
            <p class="omschrijving-text-title">{{ translations?.Chepp_ChecklistView_OpdrachtDescription }}</p>
            <textarea
                name="opdrachtOmschrijving"
                [formControl]="descriptionFormControl"
                class="omschrijving-textarea"
                placeholder="Opdracht"></textarea>
        </div>

        <!-- <div *ngIf="imageUrl" class="opdracht-attachments-container">
            <img
                (click)="enableDisableAttachment()"
                [ngClass]="{'default' : toggle, 'grey': !toggle}"
                [src]="imageUrl"
                class="img" />
        </div> -->
        <ng-container *ngIf="files">
            <div g2sLayout="row" class="img-container" g2sLayoutGap="10px">
                <ng-container *ngFor="let file of files; let i = index">
                    <div
                        (click)="enableDisableAttachment(file)"
                        class="file"
                        [class.file-disabled]="file.disabled"
                        [style.background-image]="'url(' + file.content + ')'"></div>
                </ng-container>
            </div>
        </ng-container>

        <div matRipple class="standaard-opdracht" (click)="onOpenOpdracht()">
            <p class="standaard-text">{{ translations?.Chepp_ChecklistView_SelectFromStandardOpdrachten }}</p>
        </div>
        <button
            class="opdracht-uitgeven"
            (click)="submitOpdracht()"
            mat-flat-button
            matDialogClose
            [disabled]="!selectedBetrokkene || !selectedKlantOrganisation || descriptionFormControl.invalid || deadlineFormControl.invalid"
            type="submit">
            {{translations?.Chepp_ChecklistView_IssueOpdracht}}
        </button>
    </form>
</div>
