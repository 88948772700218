import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, retry } from "rxjs/operators";
import { IDeelplan } from "../model/deelplan.model";
import { IDeelplanChecklist } from "../model/deelplan-checklist.model";
import { IDeelplanVraag } from "../model/deelplan-vraag.model";
import { IDossier } from "../model/dossier.model";
import { IHoofdgroep } from "../model/hoofdgroep.model";
import { IResponse } from "../model/response.model";
import { SnackbarService } from "./snackbar.service";
import { AbstractService } from "./abstract.service";
import { TokenService } from "./token.service";

@Injectable({
    providedIn: "root",
})
export class HttpClientService extends AbstractService {
    public userId!: string;
    public dossiers: IDossier[] = [];

    unkownErrorLabel: string = "Unknown error";

    url = "";

    constructor(public http: HttpClient, public snackbar: SnackbarService, tokenService: TokenService) {
        super(http, tokenService, snackbar);
    }

    getDossier(dossierId: number, hasOpdrachten: boolean = false)
    {
        return {
            id: dossierId,
            naam: "TEST DOSSIER",
            foto: "",
            kenmerk: "Test dossier kenmerk",
            locatie: "string",
            locatieStraat: "Welbergweg",
            locatieStraatNummer: "75",
            postCode: "7685PV",
            gemeente: "Gemeente Hengelo",
            correspondentieStraat: "",
            correspondentieHuisnummer: "",
            correspondentiePostcode: "",
            correspondentiePlaats: "",
            opmerkingen: "",
            aanleiding: "",
            memo: "MEMO",
            heeftOpdrachten: hasOpdrachten,
            deelplannen: [
                {
                    id: 1,
                    dossierId: dossierId,
                    naam: "Deelplan A",
                    deelplanChecklists: [
                        {
                            id: 72482,
                            deelplanId: 1,
                            bouwnummer: "",
                            naam: "Checklist 1",
                            checklistGroepNaam: "",
                            voortgangPercentage: 50,
                            toonSamenvatting: true,
                            initieleDatum: new Date()
                        } as IDeelplanChecklist
                    ]
                } as IDeelplan
            ]
        } as IDossier
    }

    //getter voor alle dossiers
    getDossiers() {
        return this.dossiers;
        // let url = this.url + "/gebruikers/" + this.userId + "/dossiers.json";
        // return this.http
        //     .get<{
        //         [key: string]: IDossier;
        //     }>(url)
        //     .pipe(
        //         map((responeData) => {
        //             const postArray: IDossier[] = [];
        //             for (const key in responeData) {
        //                 if (responeData.hasOwnProperty(key)) {
        //                     postArray.push({
        //                         ...responeData[key],
        //                     });
        //                 }
        //             }
        //             return postArray;
        //         })
        //     );
    }

    //getter voor deelplanchecklisten binnen een deelplan van een dossier
    getDeelplanChecklisten(dossierId: number, deelplanId: number) {
        let url =
            this.url +
            "/gebruikers/" +
            this.userId +
            "/dossiers/" +
            (dossierId - 1) +
            "/deelplannen/" +
            (deelplanId - 1) +
            "/deelplanChecklists.json";
        return this.http
            .get<{
                [key: string]: IDeelplanChecklist;
            }>(url)
            .pipe(
                map((responeData) => {
                    const postArray: IDeelplanChecklist[] = [];
                    for (const key in responeData) {
                        if (responeData.hasOwnProperty(key)) {
                            postArray.push({
                                ...responeData[key],
                            });
                        }
                    }
                    return postArray;
                })
            );
    }

    getHoofdgroepen(deelplanId: number, checklistId: number) {
        return this.post(`/chepp/checklist/v1/hoofdgroepen`, {
            deelplanId: Number(deelplanId),
            checklistId: Number(checklistId),
            includeHoofdgroepColor: true,
            includeCanAnswerOnHoofdgroepLevel: false,
        }).pipe(
            map((res) => res.returnData as IHoofdgroep[])
        );

        // return this.post(`/chepp/checklist/v1/hoofdgroepen`, {
        //         deelplanId: Number(deelplanId),
        //         checklistId: Number(checklistId),
        //         includeHoofdgroepColor: true,
        //         includeCanAnswerOnHoofdgroepLevel: false,
        //     })
        //     .pipe(
        //         map((response: IResponse) => {
        //             // if (response) {
        //             //     if (response.success) {
        //             //         return response.returnData as IHoofdgroep[];
        //             //     } else {
        //             //         let s = `${response.errorCode}: ${response.message}`;
        //             //         this.snackbar.errorSnackBar(s, 3000);
        //             //     }
        //             // } else {
        //             //     this.snackbar.errorSnackBar(this.unkownErrorLabel, 3000);
        //             // }
        //             // return response;
        //         })
        //     );
    }

    getVragen(hoofdgroepId: number, deelplanId: number) {
        return this.post(`/chepp/checklist/v1/hoofdgroepen/${hoofdgroepId}/vragen`, {
                deelplanId: Number(deelplanId),
                includeSummary: true,
            })
            .pipe(
                map((res) => res.returnData as IDeelplanVraag[])
            );
    }

    getHoofdgroep(dossierId: number, deelplanId: number, checklistId: number) {
        let url =
            this.url +
            "/gebruikers/" +
            this.userId +
            "/dossiers/" +
            (dossierId - 1) +
            "/deelplannen/" +
            (deelplanId - 1) +
            "/deelplanChecklists/" +
            (checklistId - 1) +
            "/hoofdgroepen.json";
        return this.http
            .get<{
                [key: string]: IHoofdgroep;
            }>(url)
            .pipe(
                map((responeData) => {
                    const postArray: IHoofdgroep[] = [];
                    for (const key in responeData) {
                        if (responeData.hasOwnProperty(key)) {
                            postArray.push({
                                ...responeData[key],
                            });
                        }
                    }
                    return postArray;
                })
            );
    }

    //getter voor het ophalen van dossier data in een deelplanchecklist
    getTempDossier(dossierId: number) {
        const url = this.url + "/gebruikers/" + this.userId + "/dossiers/" + (dossierId - 1) + ".json";
        return this.http.get<IDossier>(url).pipe(retry(3));
    }

    /**
     * Get a Deelplan object by dossierId and deelplanId
     * @param dossierId
     * @param deelplanId
     * @returns
     */
    GetDeelplan(dossierId: number, deelplanId: number) {
        const url =
            this.url +
            "/gebruikers/" +
            this.userId +
            "/dossiers/" +
            (dossierId - 1) +
            "/deelplannen/" +
            (deelplanId - 1) +
            ".json";
        return this.http.get<IDeelplan>(url).pipe(retry(3));
    }

    //getter voor specifiek deelplanchecklist, hier moet je een dossier en deelplan mee geven
    getDeelplanChecklist(dossierId: number, deelplanId: number, checklistId: number) {
        const url =
            this.url +
            "/gebruikers/" +
            this.userId +
            "/dossiers/" +
            (dossierId - 1) +
            "/deelplannen/" +
            (deelplanId - 1) +
            "/deelplanChecklists/" +
            (checklistId - 1) +
            ".json";
        return this.http.get<IDeelplanChecklist>(url).pipe(retry(3));
    }

    postDeelplanChecklistFoto() {}

    // Post de voortgang van de geopende
    postDeelplanChecklistVoortgang(data: number, dossierId: number, deelplanId: number, checklistId: number) {
        let url =
            this.url +
            "/gebruikers/" +
            this.userId +
            "/dossiers/" +
            (dossierId - 1) +
            "/deelplannen/" +
            (deelplanId - 1) +
            "/deelplanChecklists/" +
            (checklistId - 1) +
            "/voortgangPercentage.json";
        this.http.put(url, data).subscribe((responseData) => {});
    }

    // Post de vraag met antwoorden naar de database
    postVraagData(
        data: any,
        dossierId: number,
        deelplanId: number,
        checklistId: number,
        hoofdgroepId: number,
        vraagId: number
    ) {
        let url =
            this.url +
            "/gebruikers/" +
            this.userId +
            "/dossiers/" +
            (dossierId - 1) +
            "/deelplannen/" +
            (deelplanId - 1) +
            "/deelplanChecklists/" +
            (checklistId - 1) +
            "/hoofdgroepen/" +
            (hoofdgroepId - 1) +
            "/vragen/" +
            (vraagId - 1) +
            ".json";
        return this.http.put(url, data);
    }

    // Post de hoofdgroep data bij het beantwoorden op hoofdgroepniveau
    postHoofdgroepData(data: any, dossierId: number, deelplanId: number, checklistId: number, hoofdgroepId: number) {
        let url =
            this.url +
            "/gebruikers/" +
            this.userId +
            "/dossiers/" +
            dossierId +
            "/deelplannen/" +
            deelplanId +
            "/deelplanChecklists/" +
            checklistId +
            "/hoofdgroepen/" +
            hoofdgroepId +
            ".json";
        return this.http.put(url, data);
    }

    // Post de dossierinformatie naar de user
    postDossierInformatie(data: IDossier) {
        let url = this.url + "/gebruikers/" + this.userId + "/dossiers/" + (data.id - 1) + ".json";
        return this.http.put(url, data);
    }

    // Post dossier memo
    postDossierMemo(data: string, dossierId: number) {
        let url = this.url + "/gebruikers/" + this.userId + "/dossiers/" + (dossierId - 1) + "/memo.json";
        return this.http.put(url, JSON.stringify(data));
    }
}
