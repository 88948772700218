import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { IHercontrole } from "src/app/model/hercontrole.model";
import { DataService } from "src/app/services/data.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { AddHercontroledatumDialogComponent } from "./add-hercontroledatum-dialog/add-hercontroledatum-dialog.component";

@Component({
    selector: "app-checklist-hercontrole-dialog",
    templateUrl: "./checklist-hercontrole-dialog.component.html",
    styleUrls: ["./checklist-hercontrole-dialog.component.scss"],
})
export class ChecklistHercontroleDialogComponent implements OnInit {
    // Checked slide toggles
    roodIsChecked = false;
    oranjeIsChecked = false;
    groenIsChecked = false;

    hercontroleDates: IHercontrole[] = [
        {
            datum: new Date(),
            startTijd: new Date(),
            eindTijd: new Date(),
        },
    ];
    selectedDate!: IHercontrole;
    initialDate: Date = new Date();
    initialDateText!: string;

    starttijdDate: Date = new Date();
    eindtijdDate: Date = new Date();

    //Checklist-hercontrole-dialog translate binding
    titelTextLabel: string = "Initiële datum en hercontrole";
    datumWijzigenTextLabel: string = "Initiële datum wijzigen";
    initieleDatumTextLabel: string = "Initiële datum ";
    startTijdTextLabel: string = "Starttijd";
    eindTijdTextLabel: string = "Eindtijd";
    wijzigingenOpslaanTextLabel: string = "Wijzigingen opslaan";
    hercontroleInplannenTextLabel: string = "Hercontrole inplannen";
    inplannenTextLabel: string = "Inplannen";
    redCardTextLabel: string = "Voldoet niet";
    orangeCardTextLabel: string = "Geen wo";
    greenCardTextLabel: string = "Voldoet";
    kiesBestaandeTextLabel: string = "Kies uit bestaande hercontroles";
    newDateTextLabel: string = "Nieuwe datum inplannen";

    snackbarChangedStartTimeLabel: string = "Tijd veranderd: Ingevulde starttijd is later dan eindtijd";
    snackbarChangeEndTimeLabel: string = "Tijd veranderd: Ingevulde eindtijd is eerder dan starttijd";

    constructor(
        private dateAdapter: DateAdapter<Date>,
        public datePipe: DatePipe,
        private dialog: MatDialog,
        private formBuilder: UntypedFormBuilder,
        private snackbar: SnackbarService,
        private dataService: DataService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        dateAdapter.setLocale("nl-NL");
    }

    timeForm = this.formBuilder.group({
        starttijdDate: { value: "" },
        eindtijdDate: { value: "" },
    });

    ngOnInit(): void {
        // Set all the starting values for the forms
        this.starttijdDate.setHours(0);
        this.starttijdDate.setMinutes(0);
        this.eindtijdDate.setHours(23);
        this.eindtijdDate.setMinutes(59);
        this.starttijdDate = this.data.initieleStarttijd;
        this.eindtijdDate = this.data.initieleEindtijd;
        this.initialDate = this.data.initieleDatum;
        this.timeForm.controls["starttijdDate"].setValue(this.datePipe.transform(this.starttijdDate, "HH:mm"));
        this.timeForm.controls["eindtijdDate"].setValue(this.datePipe.transform(this.eindtijdDate, "HH:mm"));

        this.hercontroleDates = this.dataService.hercontroles;
    }

    onAddDate() {
        let dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = "hercontrole-dialog";
        dialogConfig.autoFocus = false;
        let dialogRef = this.dialog.open(AddHercontroledatumDialogComponent, dialogConfig);

        // Retrieve data from the add hercontrole dialog
        dialogRef.afterClosed().subscribe((data) => {
            if (data !== undefined && data.submitPressed) {
                this.dataService.hercontroles.push({
                    datum: data.newHercontrole.hercontroleDatum,
                    startTijd: data.newHercontrole.starttijdDate,
                    eindTijd: data.newHercontrole.eindtijdDate,
                });
                this.hercontroleDates = this.dataService.hercontroles;
            }
        });
    }

    onDateSelected(date: IHercontrole) {
        this.selectedDate = date;
    }

    // Check if the starttime is earlier than the endtime
    changeStarttijd() {
        if (this.timeForm.controls["starttijdDate"].value.value !== "") {
            let time: string = this.timeForm.controls["starttijdDate"].value;
            let timeNumbers = time.split(":");
            this.starttijdDate.setHours(+timeNumbers[0]);
            this.starttijdDate.setMinutes(+timeNumbers[1]);

            if (this.starttijdDate > this.eindtijdDate) {
                this.snackbar.basicSnackBar(this.snackbarChangedStartTimeLabel, 3000);
                this.starttijdDate = new Date(this.eindtijdDate.getTime());
                this.timeForm.controls["starttijdDate"].setValue(this.datePipe.transform(this.starttijdDate, "HH:mm"));
            }
        }
    }

    // Check if the endtime is later than the startime
    changeEindtijd() {
        if (this.timeForm.controls["eindtijdDate"].value.value !== "") {
            let time: string = this.timeForm.controls["eindtijdDate"].value;
            let timeNumbers = time.split(":");
            this.eindtijdDate.setHours(+timeNumbers[0]);
            this.eindtijdDate.setMinutes(+timeNumbers[1]);

            if (this.eindtijdDate < this.starttijdDate) {
                this.snackbar.basicSnackBar(this.snackbarChangeEndTimeLabel, 3000);
                this.eindtijdDate = new Date(this.starttijdDate.getTime() + 30 * 60 * 1000);
                this.timeForm.controls["eindtijdDate"].setValue(this.datePipe.transform(this.eindtijdDate, "HH:mm"));
            }
        }
    }
}
