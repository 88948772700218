<app-loading-screen [displayLoadingScreen]="displayLoadingScreen"></app-loading-screen>

<div class="header-title-container" g2sLayout="row" g2sLayoutAlign="start center">
    <p class="title">{{ deelplanVraag.type }}</p>
    <mat-slide-toggle
        [(ngModel)]="datumTijdPickerToggle"
        (change)="changeDatepickerToggle()"
        class="slide-toggle"></mat-slide-toggle>
    <div g2sFlex g2sLayoutAlign="end center">
        <mat-icon matRipple mat-dialog-close class="close-button">close</mat-icon>
    </div>
</div>
<p class="subtitle">{{ deelplanVraag.name }}</p>

<div class="divider"></div>

<div class="datecontainer">
    <div class="dialog-body">
        <form #f="ngForm" [formGroup]="datumTijdForm">
            <div g2sLayout="row" g2sLayoutAlign="start start" class="date-time-section">
                <mat-datepicker-toggle #datetoggle (click)="startDatePicker.open()" matSuffix>
                    <mat-icon [ngClass]="{ 'disabled-text': !datumTijdPickerToggle }" matDatepickerToggleIcon>
                        today
                    </mat-icon>
                </mat-datepicker-toggle>
                <div style="position: relative">
                    <p [ngClass]="{ 'disabled-text': !datumTijdPickerToggle }" class="time-text">
                        {{ startDatumText }}
                    </p>
                    <p
                        [ngClass]="{ 'date-disabled': !datumTijdPickerToggle }"
                        style="cursor: pointer"
                        (click)="startDatePicker.open()">
                        {{ datePipe.transform(datumTijdForm.controls["startDatum"].value, 'EEEE dd-MM-yyyy') }}
                    </p>
                    <mat-form-field style="width: 1px; visibility: hidden; position: absolute; top: -1px">
                        <input
                            required
                            id="startDatum"
                            formControlName="startDatum"
                            (dateChange)="changeStartDatum()"
                            matInput
                            [matDatepicker]="startDatePicker" />
                        <mat-datepicker #startDatePicker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div g2sLayout="row" g2sLayoutAlign="start start" class="date-time-section">
                <mat-datepicker-toggle #datetoggle (click)="endDatePicker.open()" matSuffix>
                    <mat-icon [ngClass]="{ 'disabled-text': !datumTijdPickerToggle }" matDatepickerToggleIcon>
                        today
                    </mat-icon>
                </mat-datepicker-toggle>
                <div style="position: relative">
                    <p [ngClass]="{ 'disabled-text': !datumTijdPickerToggle }" class="time-text">{{ eindDatumText }}</p>
                    <p
                        [ngClass]="{ 'date-disabled': !datumTijdPickerToggle }"
                        style="cursor: pointer"
                        (click)="endDatePicker.open()">
                        {{ datePipe.transform(datumTijdForm.controls["eindDatum"].value, 'EEEE dd-MM-yyyy') }}
                    </p>
                    <mat-form-field style="width: 1px; visibility: hidden; position: absolute; top: -1px">
                        <input
                            required
                            id="eindDatum"
                            formControlName="eindDatum"
                            (dateChange)="changeEindDatum()"
                            matInput
                            [matDatepicker]="endDatePicker" />
                        <mat-datepicker #endDatePicker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <!--Tijd-->
            <div g2sLayout="row" g2sLayoutAlign="start start" class="date-time-section">
                <mat-datepicker-toggle matSuffix>
                    <mat-icon [ngClass]="{ 'disabled-text': !datumTijdPickerToggle }" matDatepickerToggleIcon>
                        access_time
                    </mat-icon>
                </mat-datepicker-toggle>
                <div>
                    <p [ngClass]="{ 'disabled-text': !datumTijdPickerToggle }" class="time-text">{{ startTijdText }}</p>
                    <input
                        #time1
                        required
                        id="starttijdDatum"
                        formControlName="starttijdDatum"
                        matInput
                        type="time"
                        class="time-input"
                        #timepicker1
                        (change)="changeStarttijd()"
                        required />
                </div>
            </div>
            <div g2sLayout="row" g2sLayoutAlign="start start" class="date-time-section">
                <mat-datepicker-toggle matSuffix>
                    <mat-icon [ngClass]="{ 'disabled-text': !datumTijdPickerToggle }" matDatepickerToggleIcon>
                        access_time
                    </mat-icon>
                </mat-datepicker-toggle>
                <div>
                    <p [ngClass]="{ 'disabled-text': !datumTijdPickerToggle }" class="time-text">{{ eindTijdText }}</p>
                    <input
                        #time2
                        required
                        id="eindtijdDatum"
                        formControlName="eindtijdDatum"
                        matInput
                        type="time"
                        class="time-input"
                        (change)="changeEindtijd()"
                        #timepicker2
                        required />
                </div>
            </div>

            <button (click)="onSaveClicked()" class="save-button" mat-flat-button>
                <ng-container *ngIf="!isSaving; else saving">{{ saveLabel }}</ng-container>
                <ng-template #saving>
                    <mat-spinner [diameter]="25" class="saving-spinner" color="accent"></mat-spinner>
                </ng-template>
            </button>
        </form>
    </div>
</div>
