import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AbstractService } from "./abstract.service";
import { SnackbarService } from "./snackbar.service";
import { TokenService } from "./token.service";
import { IUILabelTranslation } from "../model/ui-label-translation.model";
import { IUILabelView } from "../model/ui-label-view.model";
import { IUILabel } from "../model/ui-label.model";
import { IUILanguage } from "../model/ui-language";
import { IResponse } from "../model/response.model";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ILanguage } from "../model/language.model";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: "root",
})
export class TranslationService extends AbstractService {
    currentLanguageSubject?: BehaviorSubject<IUILanguage>;
    availableLanguagesSubject: BehaviorSubject<IUILanguage[]>;

    constructor(
        httpClient: HttpClient,
        tokenService: TokenService,
        snackbarService: SnackbarService,
        private authService: AuthService
    ) {
        super(httpClient, tokenService, snackbarService);

        this.availableLanguagesSubject = new BehaviorSubject<IUILanguage[]>([]);

        this.setupLangauges();
    }

    // NFP-17
    async setupLangauges() {
        // Try to get available languages from local storage
        let availableLanguages: any = localStorage.getItem("availableLanguages");

        // Try to get the current language from local storage
        let currentLanguage: any = localStorage.getItem("currentLanguage");

        // If no available languages where found
        // Try to request available languages from the backend
        if (!availableLanguages) {
            availableLanguages = await new Promise((resolve, reject) => {
                this.getAvailableLanguages().subscribe({
                    next: (availableLanguages) => {
                        // If available languages where found
                        // add them to local storage
                        if (availableLanguages) {
                            localStorage.setItem("availableLanguages", JSON.stringify(availableLanguages));
                        }

                        resolve(availableLanguages);
                    },
                });
            });
        }
        // If we have a availableLanguages retrieved from local storage
        // we'll parse it to JSON
        else {
            availableLanguages = JSON.parse(availableLanguages);
        }

        // If we've got available languages but no current language set
        // We've to try to set the current language based on the authenticated user it's preferred language
        if (availableLanguages && !currentLanguage) {
            if (this.authService.getAuthenticatedGebruiker()?.appLanguageId) {
                currentLanguage = availableLanguages.filter(
                    (language: ILanguage) => language.id == this.authService.getAuthenticatedGebruiker()?.appLanguageId
                );

                // Since we're filtering above we want to extract the first element
                // of its result set
                if (currentLanguage) {
                    currentLanguage = currentLanguage[0];
                }
            }
            // The authenticated user has no language set
            // pick the first one from the available (active) languages list, which is nl-NL
            else {
                currentLanguage = availableLanguages[0];
            }
        }
        // In case we don't have available languages and no current language set
        // we'll set the current language to nl-NL by default
        else if (!availableLanguages && !currentLanguage) {
            currentLanguage = JSON.parse('{ id: 1, iso31662: "nl-NL", name: "Dutch" }');
        } else {
            currentLanguage = JSON.parse(currentLanguage);
        }

        this.setLanguage(currentLanguage);

        this.currentLanguageSubject = new BehaviorSubject<IUILanguage>(currentLanguage ? currentLanguage : null);

        this.availableLanguagesSubject.next(availableLanguages);
    }

    // NFP-17: returns the current language
    getCurrentLanguage(): IUILanguage | undefined {
        return this.currentLanguageSubject?.getValue();
    }

    // NFP-17: requests a list of available languages
    getAvailableLanguages(active_only: boolean = true): Observable<IUILanguage[]> {
        return this.get(`/translations/v1/languages/${active_only ? 1 : 0}`).pipe(
            map((response: IResponse) => response.returnData as IUILanguage[])
        );
    }

    // NFP-17: updates the user language in local storage
    setLanguage(language: IUILanguage) {
        this.currentLanguageSubject?.next(language);
        localStorage.setItem("currentLanguage", JSON.stringify(language));
    }

    // NFP-40: request views
    getViews(): Observable<IUILabelView[]> {
        return this.get(`/translations/v1/translations/views`).pipe(
            map((response: IResponse) => response.returnData as IUILabelView[])
        );
    }

    // NFP-40: request translation count by given/filtered search and view ids
    getTranslationsCount(search: string, viewIds: number[]): Observable<number> {
        let url = `/translations/v1/translations/count`;

        if (viewIds && viewIds.length > 0) {
            url += `/`;

            viewIds.forEach((id) => {
                url += id + (id == viewIds[viewIds.length - 1] ? "" : "&");
            });
        }

        if (search && search.length > 0) {
            url += `/${encodeURIComponent(search)}`;
        }

        return this.get(url).pipe(map((response: IResponse) => response.returnData as number));
    }

    // NFP-40: request translations for specific views
    getTranslationsForView(views: string[], includeGlobals: boolean = false): Observable<any[]> {
        let url = `/translations/v1/translations/views/language`;

        let data = {
            views: views,
            languageId: this.getCurrentLanguage()?.id ?? 1,
            includeGlobals: includeGlobals,
        };

        return this.post(url, data).pipe(map((response: IResponse) => response.returnData as any[]));
    }

    // NFP-40: request translations by given / filteren search and view ids
    getTranslations(offset: number, limit: number, search: string, viewIds: Number[]): Observable<IUILabel[]> {
        let url = `/translations/v1/translations/${offset}/${limit}`;

        if (viewIds && viewIds.length > 0) {
            url += `/`;

            viewIds.forEach((id) => {
                url += id + (id == viewIds[viewIds.length - 1] ? "" : "&");
            });
        }

        if (search && search.length > 0) {
            url += `/${encodeURIComponent(search)}`;
        }

        return this.get(url).pipe(map((response: IResponse) => response.returnData as IUILabel[]));
    }

    // NFP-40: update translation
    updateTranslation(uiLabel: IUILabel) {
        return this.patch(`/translations/v1/translations`, uiLabel);
    }

    // NFP-40: add a new translation
    addTranslation(labelName: string, views: IUILabelView[], iUILabelTranslation: IUILabelTranslation[]) {
        let label: IUILabel = {
            label: labelName,
            views: views,
            translations: iUILabelTranslation,
        };

        return this.put(`/translations/v1/translations`, label);
    }

    // NFP-40: delete translation
    deleteTranslation(label: IUILabel) {
        return this.delete(`/translations/v1/translations`, label);
    }

    // NFP-40: delete view
    deleteView(view: IUILabelView) {
        return this.delete(`/translations/v1/translations/views`, view);
    }

    // NFP-40: add a new view
    addView(view: IUILabelView) {
        return this.put(`/translations/v1/translations/views`, view);
    }

    // NFP-40: update an existing view
    updateView(view: IUILabelView) {
        return this.patch(`/translations/v1/translations/views`, view);
    }

    // NFP-17: update user language
    updateUserLanguage(language: IUILanguage) {
        return this.patch(`/translations/v1/language/user`, language);
    }
}
