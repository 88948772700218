<app-loading-screen [displayLoadingScreen]="displayLoadingScreen"></app-loading-screen>

<div class="header-title-container" g2sLayout="row" g2sLayoutAlign="start center">
    <p class="title">{{ deelplanVraag.type }}</p>

    <div g2sFlex g2sLayoutAlign="end center">
        <mat-icon matRipple mat-dialog-close class="close-button">close</mat-icon>
    </div>
</div>
<p class="subtitle">{{ deelplanVraag.name }}</p>

<div class="divider"></div>

<div g2sLayout="row" g2sLayoutAlign="end center">
    <div g2sLayout="row" g2sLayoutAlign="center center" (click)="clearSignaturePad()" class="clearbutton">
        <mat-icon>delete</mat-icon>
    </div>
</div>

<ng-container class="toelichting" *ngIf="deelplanVraag.toelichting">
    <mat-expansion-panel #panel class="toelichting-Collapse" hideToggle>
        <mat-expansion-panel-header class="centerHeader">
            <ng-container *ngIf="!panel.expanded; else opened">
                <p class="expand-text">{{ translations?.Chepp_ChecklistView_ActualClarification }}</p>
                <mat-icon class="expand-icon">expand_more</mat-icon>
            </ng-container>
            <ng-template #opened>
                <p class="expand-text">{{ translations?.Chepp_ChecklistView_ActualClarification }}</p>
                <mat-icon class="expand-icon">expand_less</mat-icon>
            </ng-template>
        </mat-expansion-panel-header>
        <mat-card class="toelichting-card" hideToggle>
            <mat-panel-title style="color: white">{{ deelplanVraag.toelichting }}</mat-panel-title>
        </mat-card>
    </mat-expansion-panel>
</ng-container>

<signature-pad
    (window:resize)="resizeSignaturePad()"
    class="signature"
    id="sign_canvas"
    g2sFlexAlign.xs="center"></signature-pad>

<button [disabled]="isSaveButtonDisabled()" (click)="onSaveClicked()" class="save-button" mat-flat-button>
    <ng-container *ngIf="!isSaving; else saving">{{ saveLabel }}</ng-container>
    <ng-template #saving>
        <mat-spinner [diameter]="25" class="saving-spinner" color="accent"></mat-spinner>
    </ng-template>
</button>
