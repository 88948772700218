import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { IResponse } from "../model/response.model";
import { Observable, of } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class PhotosOverviewService {
    private readonly hostName = "";
    constructor(private httpClient: HttpClient) {}

    getDocumentsFiltersData(deelplanChecklistId: number): Observable<IResponse> {
        // const url = `${this.hostName}/photosoverview/getQuestions/${deelplanChecklistId}`;
        // return this.httpClient.get<IResponse>(url);
        return of({ returnData: files.returnData, message: "", success: true });
    }

    getQuestionFiles(deelplanVraagId: number): Observable<IResponse> {
        // const url = `${this.hostName}/photosoverview/getQuestionFiles/${deelplanVraagId}`;
        // return this.httpClient.get<IResponse>(url);
        return of({ returnData: file.returnData, message: "", success: true });
    }
}

const file = {
    success: true,
    message: "",
    returnData: [
        {
            id: "404927",
            filename: "02-02-2023 11.15.55 #_6.jpg",
            type: "image",
            mimetype: "image/jpeg",
            previewUrl: "/deelplannen/deelplanupload_display/404927",
            thumbnail: "=",
            fileContent: null,
        },
    ],
};

const files = {
    success: true,
    message: "",
    returnData: [
        {
            deelplanVraagId: 5492757,
            questionName: "Vraag 1",
            uploadsCount: 0,
        },
        {
            deelplanVraagId: 5492758,
            questionName: "Vraag 2",
            uploadsCount: 1,
        },
        {
            deelplanVraagId: 5492759,
            questionName: "Vraag 3",
            uploadsCount: 0,
        },
        {
            deelplanVraagId: 5492760,
            questionName: "Open vraag",
            uploadsCount: 0,
        },
        {
            deelplanVraagId: 5492761,
            questionName: "Vraag 4",
            uploadsCount: 0,
        },
        {
            deelplanVraagId: 5492764,
            questionName: "Vraag 7",
            uploadsCount: 0,
        },
        {
            deelplanVraagId: 5492762,
            questionName: "Vraag 5",
            uploadsCount: 0,
        },
        {
            deelplanVraagId: 5492765,
            questionName: "Vraag 8",
            uploadsCount: 0,
        },
        {
            deelplanVraagId: 5492763,
            questionName: "Vraag 6",
            uploadsCount: 0,
        },
        {
            deelplanVraagId: 5492766,
            questionName: "Vraag 9",
            uploadsCount: 0,
        },
        {
            deelplanVraagId: 5492767,
            questionName: "Handtekeningvraag",
            uploadsCount: 5,
        },
        {
            deelplanVraagId: 5492769,
            questionName: "normale vraag",
            uploadsCount: 0,
        },
        {
            deelplanVraagId: 5492771,
            questionName: "Testen tweede vraag",
            uploadsCount: 0,
        },
    ],
};
