import { Component } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

@Component({
    selector: "app-standaard-maatregel-dialog.ts",
    templateUrl: "./standaard-maatregel-dialog.component.html",
    styleUrls: ["./standaard-maatregel-dialog.component.scss"],
})
export class StandaardMaatregelDialogComponent {
    maatregelen = Array.from({ length: 2 }).map((_, i) => `maatregel #${i}`);
    standaardMaatregel: string = "Standaard maatregel";

    constructor(private dialogRef: MatDialogRef<StandaardMaatregelDialogComponent>) {
        dialogRef.disableClose = true;
    }

    onClickStandaardMaatregel() {
        this.dialogRef.close(this.standaardMaatregel);
    }
}
