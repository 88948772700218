import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class APIInterceptorService implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let apiReq = req.clone({ url: `${environment.baseUrl}${req.url}` });
        if (environment.baseOAuth2Url && req.url.includes(environment.baseOAuth2Url)) {
            apiReq = req.clone({ url: `${req.url}` });
        }

        return next.handle(apiReq);
    }
}
