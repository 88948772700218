import { Component, Input } from "@angular/core";
import { DocumentsOverviewComponent } from "../documents-overview.component";

@Component({
    selector: "app-documents-list",
    templateUrl: "./documents-list.component.html",
    styleUrls: ["./documents-list.component.scss"],
})
export class DocumentsListComponent {
    @Input() files: any;
    @Input() isWki!: number;

    filenameTextLabel: string = "Bestandsnaam";
    headcategoryTextLabel: string = "Hoofdcategorie";
    labelsTextLabel: string = "labels";
    versionTextLabel: string = "Versie";
    statusTextLabel: string = "status";

    constructor(private readonly parent: DocumentsOverviewComponent) {}

    onSelectFile(file: any) {
        return this.parent.onSelectFile(file);
    }
}
