<div class="component-wrapper">
    <div g2sLayout="column" g2sFlex="grow">
        <app-photos-overview-header> </app-photos-overview-header>
        <div class="main-container" g2sLayout="column" g2sLayoutAlign="space-evenly center">
            <div class="breadcrumb-divider">
                <hr class="horizontal-divider" />
            </div>
            <div class="photos-container" g2sLayout="row" g2sLayoutAlign="space-between start">
                <div class="questions-list-container" g2sLayout="column" g2sLayoutAlign="start start" g2sFlex="25">
                    <div
                        class="question-item-container"
                        *ngFor="let question of questions; let i = index"
                        [class.question-item-selected]="(selectedQuestionId === question.deelplanVraagId) || (i === 0 && selectedQuestionId === null)">
                        <div class="question-item" (click)="loadFiles(question.deelplanVraagId)">
                            {{ question.questionName }}
                        </div>
                    </div>
                </div>
                <div g2sLayout="row wrap" g2sLayoutAlign="start start" g2sFlex class="mat-tab-container">
                    <ng-container *ngIf="files.length; else noFiles">
                        <div class="files-container" g2sLayout="row wrap" g2sLayoutAlign="start start" g2sFlex>
                            <app-photos-overview-thumbnail
                                *ngFor="let file of files"
                                [file]="file"
                                g2sLayout="row"
                                g2sLayoutAlign="start stretch"
                                g2sFlex="20"></app-photos-overview-thumbnail>
                        </div>
                    </ng-container>
                    <ng-template #noFiles>
                        <div g2sLayout="row wrap" g2sLayoutAlign="center center" g2sFlex class="no-documents">
                            <span>{{ noDocumentsLabel }}</span>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <app-spinner *ngIf="loading"></app-spinner> -->
