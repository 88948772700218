import { Component, OnDestroy } from "@angular/core";
import { DocumentsOverviewComponent } from "../documents-overview.component";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { NewDocumentDialogComponent } from "./new-document-dialog/new-document-dialog.component";
import { Subscription } from "rxjs";
import { DocumentService } from "../../../services/document.service";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";

@Component({
    selector: "app-document-uploader",
    templateUrl: "./document-uploader.component.html",
    styleUrls: ["./document-uploader.component.scss"],
})
export class DocumentUploaderComponent implements OnDestroy {
    fileSubscription: Subscription = new Subscription();

    snackbarAttentionWrongFileLabel: string =
        "Let op! U heeft een verkeerd bestand toegevoegd, alleen foto's in het bestandsformaat png of jpg zijn toegestaan.";

    constructor(
        public readonly documentsOverviewComponent: DocumentsOverviewComponent,
        private DocumentService: DocumentService,
        public dialog: MatDialog,
        private snackbar: MatSnackBar
    ) {}

    uploadFile(event: Event) {
        const fileInput: HTMLInputElement = event.target as HTMLInputElement;
        const validTypes = ["image/gif", "image/jpeg", "image/png", "image/jpg"];
        let fileType = fileInput.files![0]["type"];

        if (validTypes.indexOf(fileType) == -1) {
            this.snackbar.open(this.snackbarAttentionWrongFileLabel, undefined, {
                panelClass: ["error-snackbar"],
                duration: 4000,
            });

            this.resetUploadInput(fileInput);
        }

        const dialogRef = this.dialog.open(NewDocumentDialogComponent, {
            data: {
                fileInput: fileInput,
                deelplanChecklistId: this.documentsOverviewComponent.entryData?.deelplanChecklistId,
            },
            width: "550px",
            height: "200px",
            panelClass: "document-add-dialog-styling",
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (typeof result != "undefined") {
                this.fileSubscription = this.DocumentService.addFiles(
                    this.documentsOverviewComponent.entryData?.deelplanChecklistId,
                    result.formData
                ).subscribe((result: any) => {
                    if (result["success"]) {
                        this.snackbar.open(result["message"], undefined, {
                            panelClass: ["custom-snackbar-styling"],
                            duration: 4000,
                        });

                        fileInput.value = "";
                        this.documentsOverviewComponent.updateList();
                    } else {
                        this.snackbar.open(result["message"], undefined, {
                            panelClass: ["error-snackbar"],
                            duration: 4000,
                        });
                    }
                });
            }

            this.resetUploadInput(fileInput);
        });
    }

    ngOnDestroy() {
        this.fileSubscription.unsubscribe();
    }

    resetUploadInput(fileInput: HTMLInputElement) {
        fileInput.value = "";
    }
}
