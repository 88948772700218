import { Component, Input, OnInit } from "@angular/core";
import { PhotosOverviewService } from "src/app/services/photos-overview.service";
import { IResponse } from "src/app/model/response.model";
import { IQuestion } from "src/app/model/question.model";
import { IimgFile } from "src/app/model/imgfile.model";
import { EMPTY, of, Subscription, zip } from "rxjs";
import { switchMap } from "rxjs/operators";

@Component({
    selector: "app-photos-overview",
    templateUrl: "./photos-overview.component.html",
    styleUrls: ["./photos-overview.component.scss"],
})
export class PhotosOverviewComponent implements OnInit {
    @Input("deelplan-checklist-id") deelplanChecklistId!: number;
    questions!: IQuestion[];
    selectedQuestionId: number | null = null;
    files: IimgFile[] = [];
    loading = true;
    noDocumentsLabel = "Geen documenten";

    questionData: Subscription = new Subscription();
    photosData: Subscription = new Subscription();

    constructor(private PhotosOverviewService: PhotosOverviewService) {}

    ngOnInit(): void {
        this.questionData = this.PhotosOverviewService.getDocumentsFiltersData(this.deelplanChecklistId)
            .pipe(
                switchMap((response: IResponse) =>
                    response.success
                        ? zip(
                              of(response),
                              this.PhotosOverviewService.getQuestionFiles(response.returnData[0].deelplanVraagId)
                          )
                        : EMPTY
                )
            )
            .subscribe(([questionsResponse, filesResponse]) => {
                this.questions = questionsResponse.returnData;
                this.files = filesResponse.returnData;
                this.loading = false;
            });
    }

    loadFiles(deelplanVraagId: number) {
        this.loading = true;
        this.selectedQuestionId = deelplanVraagId;
        this.photosData = this.PhotosOverviewService.getQuestionFiles(deelplanVraagId).subscribe(
            (response: IResponse) => {
                if (response.success) {
                    this.files = response.returnData;
                    this.loading = false;
                }
            }
        );
    }

    ngOnDestroy() {
        this.questionData.unsubscribe();
        this.photosData.unsubscribe();
    }
}
