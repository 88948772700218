import { Component } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

@Component({
    selector: "app-confirm-delegated-opdracht-dialog.ts",
    templateUrl: "./confirm-delegated-opdracht-dialog.component.html",
    styleUrls: ["./confirm-delegated-opdracht-dialog.component.scss"],
})
export class ConfirmDelegatedOpdrachtDialogComponent {
    notificationLabel: string = "Weet u zeker dat u deze opdracht wilt terughalen?";
    jaAnswerLabel: string = "Ja";
    neeAnswerLabel: string = "nee";

    constructor(private dialogRef: MatDialogRef<ConfirmDelegatedOpdrachtDialogComponent>) {
        dialogRef.disableClose = true;
    }

    onAnswerJa() {
        this.dialogRef.close(true);
    }

    onAnswerNee() {
        this.dialogRef.close(false);
    }
}
