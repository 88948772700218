import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import { IDeelplanOpdracht } from "src/app/model/deelplan-opdracht.model";
import { IDeelplanVraag } from "src/app/model/deelplan-vraag.model";
import { IDeelplan } from "src/app/model/deelplan.model";
import { IDeelplanChecklist } from "src/app/model/deelplan-checklist.model";
import { IDossier } from "src/app/model/dossier.model";
import { WaardeoordeelColor } from "src/app/model/enum.model";
import { IHoofdgroep } from "src/app/model/hoofdgroep.model";
import { IWaardeoordeel } from "src/app/model/waardeoordeel.model";
import { SelectJudgementDialogComponent } from "../select-judgement-dialog/select-judgement-dialog.component";
import { UploadPhotoDialogComponent } from "../upload-photo-dialog/upload-photo-dialog.component";
import { DeelplanOpdrachtService } from "src/app/services/deelplan-opdracht.service";
import { IDeelplanOpdrachtAttachment } from "src/app/model/deelplan-opdracht-attachment";
import { GenericPopupComponent } from "../../../generic-popup/generic-popup.component";

@Component({
    selector: "app-opdracht-dialog",
    templateUrl: "./opdracht-dialog.component.html",
    styleUrls: ["./opdracht-dialog.component.scss"],
})
export class OpdrachtDialogComponent implements OnInit {
    @Input() dossier!: IDossier;
    @Input() deelplan!: IDeelplan;
    @Input() deelplanChecklist!: IDeelplanChecklist;
    @Input() hoofdgroep!: IHoofdgroep;
    @Input() deelplanVraag!: IDeelplanVraag;
    @Input() deelplanOpdracht!: IDeelplanOpdracht;
    @Input() translations!: any;

    saveLabel: string = "Opslaan";

    files: any[] = [];

    selectedWaardeoordeel?: IWaardeoordeel;

    isSaving: boolean = false;
    displayLoadingScreen: boolean = false;

    @Output("closeDialog") closeDialog: EventEmitter<any> = new EventEmitter();

    constructor(private dialog: MatDialog, private deelplanDeelplanOpdrachtService: DeelplanOpdrachtService) {}

    ngOnInit(): void {
        this.loadAttachments();
    }

    // Loads the opdracht attachments
    async loadAttachments() {
        // Check if the deelplan opdracht has attachments
        if (this.deelplanOpdracht.attachments) {
            // Loop over each attachment
            for (let attachment of this.deelplanOpdracht.attachments) {
                // Request the attachment thumbnail by deelplan opdracht id and attachment id
                await new Promise((resolve, reject) => {
                    this.deelplanDeelplanOpdrachtService
                        .getAttachment(this.deelplanOpdracht.id!, attachment.id, true)
                        .then((response) => {
                            var reader = new FileReader();

                            reader.onload = (event: any) => {
                                attachment.thumbnail = event.target.result;

                                resolve(true);
                            };

                            reader.readAsDataURL(response as Blob);
                        })
                        .catch((error) => {
                            if (attachment.mimetype.includes("pdf")) {
                                attachment.thumbnail = "/assets/pdf_placeholder.png";
                            } else {
                                // TODO: create image placeholder
                                attachment.thumbnail = "/assets/pdf_placeholder.png";
                            }

                            reject(false);
                        });
                });
            }
        }
    }

    // Handles the event of clicking on an attachment.
    // The attachment will be opened in a new tab
    async onAttachmentClick(attachment: IDeelplanOpdrachtAttachment) {
        if (!attachment.original) {
            await this.deelplanDeelplanOpdrachtService
                .getAttachment(this.deelplanOpdracht.id!, attachment.id)
                .then((response) => {
                    attachment.original = response as Blob;
                })
                .catch((error) => {});
        }

        if (!attachment.mimetype?.includes("pdf")) {
            var reader = new FileReader();

            reader.onload = (event: any) => {
                var image = new Image();
                image.src = event.target.result;

                var w = window.open("");
                w?.document.write(image.outerHTML);
            };

            reader.readAsDataURL(attachment.original!);
        } else {
            let blob = attachment.original!.slice(0, attachment.original!.size, "application/pdf");
            var fileURL = URL.createObjectURL(blob);
            window.open(fileURL);
        }
    }

    // Get waardeoordeel options of the given color
    getWaardeoordeelOptionsOfColor(color: WaardeoordeelColor) {
        return this.deelplanOpdracht.waardeoordeelOptions?.filter((w) => w.color == color);
    }

    // Get red waardeoordeel options
    getRedWaardeoordeelOptions() {
        return this.getWaardeoordeelOptionsOfColor(WaardeoordeelColor.RED) ?? [];
    }

    // Get orange waardeoordeel options
    getOrangeWaardeoordeelOptions() {
        return this.getWaardeoordeelOptionsOfColor(WaardeoordeelColor.ORANGE) ?? [];
    }

    // Get green waardeoordeel options
    getGreenWaardeoordeelOptions() {
        return this.getWaardeoordeelOptionsOfColor(WaardeoordeelColor.GREEN) ?? [];
    }

    // Handles the event of selecting a green waardeoordeel
    selectGreenWaardeoordeel() {
        this.selectWaardeoordeel(this.getGreenWaardeoordeelOptions());
    }

    // Handles the event of selecting a red waardeoordeel
    selectRedWaardeoordeel() {
        this.selectWaardeoordeel(this.getRedWaardeoordeelOptions());
    }

    // Handles the event of selecting a orange waardeoordeel
    selectOrangeWaardeoordeel() {
        this.selectWaardeoordeel(this.getOrangeWaardeoordeelOptions());
    }

    // Handles the event of selecting a waardeoordeel
    selectWaardeoordeel(waardeoordeelOptions: IWaardeoordeel[]) {
        let dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = "waardeoordeel-popup";
        dialogConfig.data = {
            waardeoordeelOpties: waardeoordeelOptions,
            ignoreFollowUpActions: true,
            translations: this.translations,
            currentWaardeoordeel: this.selectedWaardeoordeel
                ? this.selectedWaardeoordeel
                : this.deelplanOpdracht.defaultWaardeoordeel,
        };

        let dialogRef = this.dialog.open(SelectJudgementDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(async (data) => {
            if (data != null) {
                this.selectedWaardeoordeel = data.waardeoordeel;
            }
        });
    }

    // Handles the dropping of files in the dropzone.
    onDropFiles(event: any) {
        this.processFiles(event.addedFiles);
    }

    // Deletes the file at given index
    deleteFile(index: number) {
        this.files.splice(index, 1);
    }

    // Handles the file upload
    onOpenFileUpload() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = "upload-photo-dialog";
        let dialogRef = this.dialog.open(UploadPhotoDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe({
            next: (data) => {
                this.processFiles(Array.from(data.files));
            },
        });
    }

    // Processes the given files
    processFiles(files: File[]) {
        let count = 0;

        for (let file of files) {
            // With each file processing, max. 12 files are allowed.
            // If we reached 12 files, we'll break the loop.
            if (count >= 12) {
                break;
            }

            // Skip the current file if size larger than 10MB
            if (file.size > 10485760) {
                continue;
            }

            // If we're dealing with an pdf
            // We'll use a placeholder for displaying instead of the original image
            if (file.type.includes("pdf")) {
                this.files.push({
                    file: file,
                    content: "/assets/pdf_placeholder.png",
                });
            } else {
                var reader = new FileReader();

                reader.onload = (event: any) => {
                    this.files.push({
                        file: file,
                        content: event.target.result,
                    });
                };

                reader.readAsDataURL(file);
            }

            count++;
        }
    }

    // Determines whether the save button should be enabled or disabled
    isSaveButtonDisabled() {
        if (!this.selectedWaardeoordeel && !this.deelplanOpdracht.toelichting) {
            return true;
        }

        return false;
    }

    // Handles the save event
    onSaveClicked() {
        // Asks for confirmation
        // No changes can be made after confirming as the opdracht will disappear
        const dialogRef = this.dialog.open(GenericPopupComponent, {
            width: "95vw",
            maxWidth: "600px",
            panelClass: "generic-dialog",
            backdropClass: "pop-up-background-dim",
            data: {
                title: "Opdracht opslaan",
                continue: "Doorgaan",
                cancel: "Cancel",
                text: "Weet u zeker dat u wilt opslaan? Afhankelijk van het waardeoordeel kunnen wijzigingen niet ongedaan worden gemaakt!",
            },
        });

        dialogRef.afterClosed().subscribe({
            next: async (result: boolean) => {
                if (result) {
                    this.displayLoadingScreen = true;
                    this.isSaving = true;

                    // Set the deelplan opdracht its waardeoordeel
                    this.deelplanOpdracht.waardeoordeel = this.selectedWaardeoordeel;

                    // Save the deelplan opdracht changes
                    let result = await new Promise<boolean>((resolve, reject) => {
                        this.deelplanDeelplanOpdrachtService.saveChanges(this.deelplanOpdracht).subscribe({
                            next: async (response) => {
                                this.deelplanOpdracht = response;

                                // Check if files are added
                                if (this.files) {
                                    // Loop over each file and upload these according to the deelplan opdracht id
                                    for (let file of this.files) {
                                        await new Promise((resolve, reject) => {
                                            this.deelplanDeelplanOpdrachtService
                                                .uploadFile(this.deelplanOpdracht.id!, file.file)
                                                .subscribe({
                                                    next: (response) => {
                                                        resolve(true);
                                                    },
                                                    error: (error) => {
                                                        reject(false);
                                                    },
                                                });
                                        });
                                    }
                                }

                                resolve(true);
                            },
                            error: (error) => {
                                reject(false);
                            },
                        });
                    });

                    this.displayLoadingScreen = false;
                    this.isSaving = false;

                    if (result) {
                        this.closeDialog.emit(this.deelplanOpdracht);
                    }
                }
            },
        });
    }

    // Handles the event of clicking on a file
    onFileClicked(file: any) {
        if (file.file.type.includes("pdf")) {
            var fileURL = URL.createObjectURL(file.file);
            window.open(fileURL);
        } else {
            var image = new Image();
            image.src = file.content;

            var w = window.open("");
            w?.document.write(image.outerHTML);
        }
    }
}
