import { Component } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { PasswordResetComponent } from "../password-reset/password-reset.component";
import { SnackbarService } from "src/app/services/snackbar.service";

@Component({
    selector: "app-token-modal",
    templateUrl: "./token-modal.component.html",
    styleUrls: ["./token-modal.component.scss"],
})
export class TokenModalComponent {
    tokenDialogTitle = "Nieuw wachtwoord";
    tokenDialogContent =
        "Voer onderstaand het door u ontvangen token in en klik op 'Bevestigen' om een nieuw wachtwoord aan te maken. ";
    tokenText = "Token";
    tokenSubmitBtn = "Bevestigen";
    tokenSnackbox = "Token geaccepteerd";

    constructor(private snackBar: SnackbarService, public dialog: MatDialog) {}

    tokenModal = new UntypedFormGroup({
        token: new UntypedFormControl("", [Validators.required]),
    });

    openPasswordResetDialog() {
        if (this.tokenModal.valid) {
            this.closeDialog();
            this.dialog.open(PasswordResetComponent);
        }
    }

    onSubmit() {
        if (this.tokenModal.valid) {
            //dostuff
        }
    }

    closeDialog() {
        this.dialog.closeAll();
    }
}
