//enum voor switchen van filter of zoeken in het dossieroverzicht
export enum State {
    None,
    Filter,
    Search,
}

//enum voor filter state voor het veranderen van geen filter of naar datum
export enum FilterState {
    None,
    Date,
}

//enum voor vraagtype
export enum VraagType {
    DatumTijdPicker = "Datum/tijdpicker",
    Invulvraag = "Invulvraag",
    Handtekening = "Handtekening",
    Meerkeuzevraag = "Meerkeuzevraag",
}

export enum VraagStatus {
    Groen,
    Oranje,
    Rood,
    Grijs,
}

export enum Waardeoordeel {
    NietVanToepassing,
    Voldoet,
    GeenWO,
    VoldoetNiet,
}

export enum WaardeoordeelColor {
    GREEN = "green",
    ORANGE = "orange",
    RED = "red",
    STANDARD = "standard",
}

export enum ChecklistMemberType {
    HOOFDGROEP = "hoofdgroep",
    VRAAG = "vraag",
    OPDRACHT = "opdracht"
}
