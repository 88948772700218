<app-loading-screen [displayLoadingScreen]="displayLoadingScreen"></app-loading-screen>

<div class="wrapper" g2sLayout="column" g2sLayoutGap="10px">
    <div class="betrokkenen-header" g2sLayout="row">
        <p class="betrokkenen-header-text">{{ translations?.Chepp_ChecklistView_SearchForUitvoerendeByEmail }}</p>
        <mat-icon matDialogClose matRipple class="close-button">close</mat-icon>
    </div>
    <p class="betrokkenen">{{ klantOrganisation?.name }}</p>

    <div class="uitvoerende">
        <form g2sFlex>
            <mat-form-field class="search-form-field">
                <mat-label>{{ translations?.Emailadres }}</mat-label>
                <input
                    type="email"
                    matInput
                    [formControl]="emailFormControl"
                    [errorStateMatcher]="matcher"
                    placeholder="{{ translations?.Chepp_ChecklistView_EnterTheBetrokkeneEmail }}" />
                <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                    {{ translations?.Chepp_ChecklistView_EnterAValidEmailAddress}}
                </mat-error>
                <mat-error *ngIf="emailFormControl.hasError('required')">
                    {{ translations?.Chepp_ChecklistView_EmailAddressIsRequired}}
                </mat-error>
            </mat-form-field>
        </form>
    </div>
    <button
        class="opdracht-uitgeven"
        (click)="performSearch()"
        mat-flat-button
        [disabled]="emailFormControl.invalid"
        type="submit">
        {{translations?.Search}}
    </button>
</div>
