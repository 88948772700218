import { Component } from "@angular/core";

@Component({
    selector: "app-spinner",
    templateUrl: "./spinner.component.html",
    styleUrls: ["./spinner.component.scss"],
})
export class SpinnerComponent {
    messageTextLabel = "Even geduld. Dit plan is aan het laden.";
    constructor() {}
}
