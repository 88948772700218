<app-loading-screen [displayLoadingScreen]="displayLoadingScreen"></app-loading-screen>
<div class="wrapper" g2sLayout.gt-md="row" g2sLayout.lt-md="column" g2sLayoutAlign.gt-sm="space-evenly start">
    <!--Hide on answer all-->
    <ng-container *ngIf="!hoofdgroep.canAnswerOnHoofdgroepLevel">
        <div g2sLayout="column" g2sFlex.gt-sm="26" g2sFlex.lt-sm="100" class="panel1">
            <div class="leftside-panel">
                <div class="leftside-panel-title">{{ attentionTextLabel }}</div>
                <div class="leftside-panel-overflow">
                    <div
                        *ngFor="let aandachtspunt of getFilteredAandachtspunten()"
                        g2sLayout="row"
                        g2sLayoutAlign="start center"
                        class="lefside-panel-aandachtspunt">
                        <mat-icon>priority_high</mat-icon>
                        <p>{{ aandachtspunt.text }}</p>
                    </div>
                </div>
            </div>
            <div class="leftside-panel">
                <div class="leftside-panel-title">{{ guidelinesTextLabel }}</div>
                <div class="leftside-panel-overflow">
                    <div *ngFor="let richtlijn of richtlijnen" class="leftside-panel-richtlijnen">{{ richtlijn }}</div>
                </div>
            </div>
            <div class="leftside-panel">
                <div class="leftside-panel-title">{{ grondslagTextLabel }}</div>
                <div class="leftside-panel-overflow">
                    <div *ngFor="let grondslag of grondslagen" class="leftside-panel-grondslagen">
                        <p>{{ grondslag.id }}</p>
                        <p>{{ grondslag.name }}</p>
                        <div class="leftside-panel-divider"></div>
                        <mat-accordion>
                            <mat-expansion-panel #f hideToggle>
                                <mat-expansion-panel-header class="expansion-header">
                                    <mat-panel-title g2sLayout="row" g2sLayoutAlign="center center">
                                        <ng-container>
                                            <mat-icon *ngIf="!f.expanded; else collapse" class="expansion-icon">
                                                expand_more
                                            </mat-icon>
                                        </ng-container>
                                        <ng-template #collapse>
                                            <mat-icon class="expansion-icon">expand_less</mat-icon>
                                        </ng-template>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p>{{ grondslag.text }}</p>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <!--Main Panel-->
    <div
        class="main-panel"
        [ngClass]="{ 'answerall-margin': hoofdgroep.canAnswerOnHoofdgroepLevel }"
        g2sFlex.gt-sm="45"
        class="panel2">
        <ngx-dropzone
            class="main-panel-dropzone"
            [disableClick]="true"
            accept="image/jpeg,image/jpg,image/png,application/pdf"
            (change)="onDropFiles($event)"></ngx-dropzone>
        <div class="main-panel-content">
            <ng-container *ngIf="!hoofdgroep.canAnswerOnHoofdgroepLevel; else answerAllTitle">
                <div class="header-title-container" g2sLayout="row" g2sLayoutAlign="start center">
                    <ng-container *ngIf="!dossier?.heeftOpdrachten">
                        <p class="title">{{ vraag.type }}</p>
                    </ng-container>
                    <ng-container *ngIf="dossier?.heeftOpdrachten">
                        <p class="title">{{ opdrachtTitleLabel }}</p>
                    </ng-container>
                    <ng-container *ngIf="vraag && vraag.hercontrole">
                        <div class="createdhercontrole">
                            {{ hercontroleTextLabel }} {{ vraag.hercontrole.date }} ({{ hercontroleFromTextLabel }} {{
                            vraag.hercontrole.start }} {{ hercontroleTillTextLabel}} {{ vraag.hercontrole.end }})
                        </div>
                    </ng-container>
                    <ng-container *ngIf="vraag.type === VraagType.DatumTijdPicker">
                        <mat-slide-toggle
                            [(ngModel)]="datumTijdPickerToggle"
                            (change)="changeDatepickerToggle()"
                            class="slide-toggle"></mat-slide-toggle>
                    </ng-container>

                    <!--Main Panel Meerkeuze + Invul-->
                    <div g2sFlex g2sLayoutAlign="end center">
                        <ng-container
                            *ngIf="(!dossier?.heeftOpdrachten && vraag.type === VraagType.Meerkeuzevraag) || vraag.type === VraagType.Invulvraag">
                            <mat-icon matRipple class="dots" (click)="onOpenModal()" color="primary"
                                >more_horiz</mat-icon
                            >
                        </ng-container>
                        <ng-container *ngIf="dossier?.heeftOpdrachten && vraag.type === VraagType.Meerkeuzevraag">
                            <mat-icon (click)="openOpdrachtDoorgeven()" matRipple class="opdracht-icon">send</mat-icon>
                        </ng-container>
                        <mat-icon matRipple mat-dialog-close class="close-button">close</mat-icon>
                    </div>
                </div>
                <ng-container *ngIf="dossier?.heeftOpdrachten && vraag.type === VraagType.Meerkeuzevraag">
                    <mat-card-subtitle class="txt-opdrachtdatum">
                        {{ createdOpdracht?.deadline + tempDummyDate }}
                    </mat-card-subtitle>
                    <p class="opdracht-opmerking">{{ opdrachtOpmerkingLabel }}</p>
                </ng-container>
                <ng-container *ngIf="!dossier?.heeftOpdrachten">
                    <p class="subtitle">{{ vraag.name }}</p>
                </ng-container>
            </ng-container>

            <ng-template #answerAllTitle>
                <div g2sLayout="row">
                    <p class="title">{{ titleText }}</p>
                    <mat-icon matRipple mat-dialog-close class="exit-button">close</mat-icon>
                </div>
                <p class="subtitle">{{ hoofdgroep.name }}</p>
            </ng-template>

            <div [ngClass]="{ 'divider-small': vraag.type === VraagType.DatumTijdPicker }" class="divider"></div>
            <!--Text area for Meerkeuze and Invul-->
            <ng-container *ngIf="vraag.type === VraagType.Meerkeuzevraag || vraag.type === VraagType.Invulvraag">
                <ng-container *ngIf="!hoofdgroep.canAnswerOnHoofdgroepLevel">
                    <div class="button-div">
                        <div g2sLayout="row" class="button-holder">
                            <ng-container *ngIf="dossier?.heeftOpdrachten">
                                <ng-container *ngIf="!authService.isWki(); else wki">
                                    <button
                                        (click)="openDocumentsOverview()"
                                        matRipple
                                        g2sLayout="row"
                                        g2sLayoutAlign="center center"
                                        class="round-buttons">
                                        <mat-icon>file_copy</mat-icon>
                                    </button>
                                </ng-container>
                                <ng-template #wki>
                                    <button
                                        (click)="openWkiDocumentsOverview()"
                                        matRipple
                                        g2sLayout="row"
                                        g2sLayoutAlign="center center"
                                        class="round-buttons">
                                        <mat-icon>file_copy</mat-icon>
                                    </button>
                                </ng-template>
                            </ng-container>
                            <ng-container *ngIf="vraagRisicoCondition">
                                <button
                                    matRipple
                                    g2sLayout="row"
                                    g2sLayoutAlign="center center"
                                    class="round-buttons"
                                    (click)="onOpenMaatregelen()">
                                    {{ maatregelButtonTextLabel }}
                                </button>
                            </ng-container>
                            <button
                                (click)="onOpenFileUpload()"
                                matRipple
                                g2sLayout="row"
                                g2sLayoutAlign="center center"
                                class="round-buttons">
                                <mat-icon>add_a_photo</mat-icon>
                            </button>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="files">
                    <div g2sLayout="row" class="img-container" g2sLayoutGap="10px">
                        <ng-container *ngFor="let file of files; let i = index">
                            <div class="file" [style.background-image]="'url(' + file.content + ')'"></div>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container
                    *ngIf="createdOpdracht && !hoofdgroep.canAnswerOnHoofdgroepLevel && vraag.type != VraagType.Invulvraag">
                    <p class="vervolgopdracht">{{ vervolgopdrachtTitleLabel }}</p>
                    <div (click)="onReopenOpdracht()" class="createdopdracht-popup">
                        <div g2sFlex="90" g2sLayout="column">
                            <div g2sLayout="row">
                                <p class="vervolgopdracht">{{ deadlineTitleLabel }}</p>
                                <p class="data-text">
                                    {{ datePipe.transform(createdOpdracht.deadline, 'dd-MM-yyyy') }}
                                </p>
                            </div>
                            <div g2sLayout="row">
                                <p class="vervolgopdracht">{{ uitvoerendeTitleLabel }}</p>
                                <p class="data-text">{{ createdOpdracht.uitvoerendeNaam }}</p>
                            </div>
                            <div g2sLayout="row">
                                <p class="vervolgopdracht">{{ opdrachttekstTitleLabel }}</p>
                                <p class="data-text">
                                    {{ createdOpdracht.omschrijving.length > 15 ? (createdOpdracht.omschrijving | slice
                                    : 0 : 15) + '...' : createdOpdracht.omschrijving }}
                                </p>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="createdHercontrole">
                    <mat-card class="hercontrole-popup">
                        <div g2sFlex="90" g2sLayout="column">
                            <p class="leftside-panel-title">{{ hercontroleLabel }}</p>
                            {{ createdHercontrole.date | date : 'dd-MM-yyyy' }} {{ hercontroleFromTextLabel }} {{
                            createdHercontrole.start | date : 'HH:mm' }} {{ hercontroleTillTextLabel }} {{
                            createdHercontrole.end | date : 'HH:mm' }}
                        </div>
                        <div g2sFlex g2sLayout="row" g2sLayoutAlign="end center">
                            <button class="delete-button">
                                <mat-icon (click)="onDeleteCreatedHercontrole()">delete</mat-icon>
                            </button>
                        </div>
                    </mat-card>
                </ng-container>
                <!-- mat expansion panel displays when there is content of vraag.toelichting  -->
                <ng-container
                    class="toelichting"
                    *ngIf="previousToelichting != '' && !hoofdgroep.canAnswerOnHoofdgroepLevel && vraag.toelichting ">
                    <mat-expansion-panel #panel class="toelichting-Collapse" hideToggle>
                        <mat-expansion-panel-header class="centerHeader">
                            <ng-container *ngIf="!panel.expanded; else opened">
                                <p class="expand-text">{{ actueleToelichtingText }}</p>
                                <mat-icon class="expand-icon">expand_more</mat-icon>
                            </ng-container>
                            <ng-template #opened>
                                <p class="expand-text">{{ actueleToelichtingText }}</p>
                                <mat-icon class="expand-icon">expand_less</mat-icon>
                            </ng-template>
                        </mat-expansion-panel-header>
                        <mat-card class="toelichting-card" hideToggle>
                            <mat-panel-title style="color: white">{{ vraag.toelichting }}</mat-panel-title>
                        </mat-card>
                    </mat-expansion-panel>
                </ng-container>

                <textarea
                    [ngClass]="{ 'textarea-margin': previousToelichting == '' }"
                    class="toelichting-tekst"
                    [(ngModel)]="vraag.toelichting"
                    placeholder="Toelichting..."></textarea>
            </ng-container>
            <!--Signature pad for vraagtype handtekening-->
            <ng-container *ngIf="vraag.type === VraagType.Handtekening">
                <ng-container
                    *ngIf="vraag.type === VraagType.Handtekening && (handtekening == null || handtekening == '')">
                    <div g2sLayout="row" g2sLayoutAlign="end center">
                        <div
                            g2sLayout="row"
                            g2sLayoutAlign="center center"
                            (click)="clearSignaturePad()"
                            class="clearbutton">
                            <mat-icon>delete</mat-icon>
                        </div>
                    </div>
                    <signature-pad
                        (window:resize)="resizeSignaturePad()"
                        class="signature"
                        id="sign_canvas"
                        g2sFlexAlign.xs="center"></signature-pad>
                </ng-container>
            </ng-container>

            <!--Datumtijdpicker-->
            <ng-container *ngIf="vraag.type === VraagType.DatumTijdPicker">
                <div class="datecontainer">
                    <div class="dialog-body">
                        <form #f="ngForm" [formGroup]="datumTijdForm" (ngSubmit)="onSave()">
                            <div g2sLayout="row" g2sLayoutAlign="start start" class="date-time-section">
                                <mat-datepicker-toggle #datetoggle (click)="picker1.open()" matSuffix>
                                    <mat-icon
                                        [ngClass]="{ 'disabled-text': !datumTijdPickerToggle }"
                                        matDatepickerToggleIcon>
                                        today
                                    </mat-icon>
                                </mat-datepicker-toggle>
                                <div style="position: relative">
                                    <p [ngClass]="{ 'disabled-text': !datumTijdPickerToggle }" class="time-text">
                                        {{ startDatumText }}
                                    </p>
                                    <p
                                        [ngClass]="{ 'date-disabled': !datumTijdPickerToggle }"
                                        style="cursor: pointer"
                                        (click)="picker1.open()">
                                        {{ datePipe.transform(startDatum, 'EEEE dd-MM-yyyy') }}
                                    </p>
                                    <mat-form-field
                                        style="width: 1px; visibility: hidden; position: absolute; top: -1px">
                                        <input
                                            required
                                            id="startDatum"
                                            formControlName="startDatum"
                                            (dateChange)="changeStartDatum()"
                                            matInput
                                            [matDatepicker]="picker1" />
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div g2sLayout="row" g2sLayoutAlign="start start" class="date-time-section">
                                <mat-datepicker-toggle #datetoggle (click)="picker2.open()" matSuffix>
                                    <mat-icon
                                        [ngClass]="{ 'disabled-text': !datumTijdPickerToggle }"
                                        matDatepickerToggleIcon>
                                        today
                                    </mat-icon>
                                </mat-datepicker-toggle>
                                <div style="position: relative">
                                    <p [ngClass]="{ 'disabled-text': !datumTijdPickerToggle }" class="time-text">
                                        {{ eindDatumText }}
                                    </p>
                                    <p
                                        [ngClass]="{ 'date-disabled': !datumTijdPickerToggle }"
                                        style="cursor: pointer"
                                        (click)="picker2.open()">
                                        {{ datePipe.transform(eindDatum, 'EEEE dd-MM-yyyy') }}
                                    </p>
                                    <mat-form-field
                                        style="width: 1px; visibility: hidden; position: absolute; top: -1px">
                                        <input
                                            required
                                            id="eindDatum"
                                            formControlName="eindDatum"
                                            (dateChange)="changeEindDatum()"
                                            matInput
                                            [matDatepicker]="picker2" />
                                        <mat-datepicker #picker2></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <!--Tijd-->
                            <div g2sLayout="row" g2sLayoutAlign="start start" class="date-time-section">
                                <mat-datepicker-toggle (click)="timepicker1.focus()" matSuffix>
                                    <mat-icon
                                        [ngClass]="{ 'disabled-text': !datumTijdPickerToggle }"
                                        matDatepickerToggleIcon>
                                        access_time
                                    </mat-icon>
                                </mat-datepicker-toggle>
                                <div>
                                    <p [ngClass]="{ 'disabled-text': !datumTijdPickerToggle }" class="time-text">
                                        {{ startTijdText }}
                                    </p>
                                    <input
                                        (focus)="starttijdFocus(true)"
                                        (blur)="changeStarttijd(); starttijdFocus(false)"
                                        #time1
                                        required
                                        id="starttijdDatum"
                                        formControlName="starttijdDatum"
                                        matInput
                                        type="time"
                                        class="time-input"
                                        #timepicker1
                                        required />
                                </div>
                            </div>
                            <div g2sLayout="row" g2sLayoutAlign="start start" class="date-time-section">
                                <mat-datepicker-toggle (click)="timepicker2.focus()" matSuffix>
                                    <mat-icon
                                        [ngClass]="{ 'disabled-text': !datumTijdPickerToggle }"
                                        matDatepickerToggleIcon>
                                        access_time
                                    </mat-icon>
                                </mat-datepicker-toggle>
                                <div>
                                    <p [ngClass]="{ 'disabled-text': !datumTijdPickerToggle }" class="time-text">
                                        {{ eindTijdText }}
                                    </p>
                                    <input
                                        (focus)="eindtijdFocus(true)"
                                        (blur)="changeEindtijd(); eindtijdFocus(false)"
                                        #time2
                                        required
                                        id="eindtijdDatum"
                                        formControlName="eindtijdDatum"
                                        matInput
                                        type="time"
                                        class="time-input"
                                        #timepicker2
                                        required />
                                </div>
                            </div>
                            <ng-container *ngIf="datumTijdPickerToggle; else datumTijdPickerOff">
                                <button
                                    [disabled]="starttijdFocussed || eindtijdFocussed || time1.value === '' || time2.value === '' || !f.valid || isSaveButtonDisabled()"
                                    mat-flat-button
                                    mat-dialog-close
                                    type="submit"
                                    class="save-button">
                                    <ng-container *ngIf="!isSaving; else saving">{{ saveText }}</ng-container>
                                    <ng-template #saving>
                                        <mat-spinner
                                            [diameter]="25"
                                            class="saving-spinner"
                                            color="primary"></mat-spinner>
                                    </ng-template>
                                </button>
                            </ng-container>
                            <ng-template #datumTijdPickerOff>
                                <button
                                    [disabled]="isSaveButtonDisabled()"
                                    (click)="onSaveEmptyDate()"
                                    mat-flat-button
                                    type="button"
                                    class="save-button">
                                    <ng-container *ngIf="!isSaving; else saving">{{ saveText }}</ng-container>
                                    <ng-template #saving>
                                        <mat-spinner
                                            [diameter]="25"
                                            class="saving-spinner"
                                            color="primary"></mat-spinner>
                                    </ng-template>
                                </button>
                            </ng-template>
                        </form>
                    </div>
                </div>
            </ng-container>

            <!--Panel for meerkeuze-->
            <ng-container
                *ngIf="vraag.type === VraagType.Meerkeuzevraag || (hoofdgroep.canAnswerOnHoofdgroepLevel && hoofdgroep.canAnswerOnHoofdgroepLevel)">
                <div
                    [ngClass]="{ 'bottom-margin': selectedWO === undefined || selectedWO.waardeoordeelTekst === 'standard' }"
                    class="button-container"
                    g2sLayout="row"
                    g2sLayoutAlign="space-between center">
                    <ng-container *ngIf="vraag.waardeoordeelOptions && getGreenWaardeoordeelOptions()">
                        <button
                            [ngClass]="{
                                'button-inactive': selectedWO !== undefined && selectedWO.color !== 'green' }"
                            (click)="onClickGreen()"
                            g2sFlex="100"
                            mat-flat-button
                            class="buttonGroen">
                            <mat-icon style="font-weight: 1000; transform: scale(1.3)">check</mat-icon>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="vraag.waardeoordeelOptions && getOrangeWaardeoordeelOptions()">
                        <ng-container *ngIf="!vraagRisicoCondition">
                            <button
                                [ngClass]="{
                                    'button-inactive': selectedWO !== undefined && selectedWO.color !== 'orange' }"
                                (click)="onClickOrange()"
                                g2sFlex="100"
                                mat-flat-button
                                class="buttonOranje">
                                <mat-icon style="transform: scale(1.15)">warning</mat-icon>
                            </button>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="vraag.waardeoordeelOptions && getRedWaardeoordeelOptions()">
                        <ng-container *ngIf="!vraagRisicoCondition">
                            <button
                                [ngClass]="{'button-inactive': selectedWO !== undefined && selectedWO.color !== 'red'}"
                                (click)="onClickRed()"
                                g2sFlex="100"
                                mat-flat-button
                                class="buttonRood">
                                <mat-icon style="font-weight: 1000; transform: scale(1.3)">close</mat-icon>
                            </button>
                        </ng-container>
                    </ng-container>
                </div>
                <ng-container *ngIf="selectedWO != undefined && selectedWO.text !== 'Standaard'">
                    <div g2sLayout="row" g2sLayoutAlign="start center">
                        <div class="{{ selectedWO.color }}-dot"></div>
                        <p class="status-text">{{ selectedWO.text }}</p>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="vraag.type !== VraagType.DatumTijdPicker">
                <button [disabled]="isSaveButtonDisabled()" (click)="onSave()" class="save-button" mat-flat-button>
                    <ng-container *ngIf="!isSaving; else saving">{{ saveText }}</ng-container>
                    <ng-template #saving>
                        <mat-spinner [diameter]="25" class="saving-spinner" color="primary"></mat-spinner>
                    </ng-template>
                </button>
            </ng-container>
        </div>
    </div>
    <!--Right panel-->
    <ng-container *ngIf="!hoofdgroep.canAnswerOnHoofdgroepLevel">
        <ng-container
            *ngIf="vraag.type == VraagType.DatumTijdPicker || vraag.type == VraagType.Handtekening || vraag.type == VraagType.Invulvraag">
            <div
                *ngIf="vraag.type == VraagType.DatumTijdPicker || vraag.type == VraagType.Handtekening"
                g2sHide.lt-md
                g2sFlex.gt-sm="26"
                g2sFlex.gt-md="25"
                class="panel3"></div>
        </ng-container>
        <ng-container *ngIf="!deelplanvraag?.toonVerantwoordingsteksten">
            <ng-container *ngIf="vraag.type !== VraagType.DatumTijdPicker && vraag.type !== VraagType.Handtekening">
                <div g2sFlex.gt-sm="27" g2sFlex.gt-md="26" g2sLayout="column">
                    <ng-container *ngIf="!vraagRisicoCondition && dossier?.heeftOpdrachten">
                        <div class="risk-priority-panel">
                            <p class="risk-title">{{ riskAndPriorityTextLabel }}</p>
                            <p class="slider-title">{{ effectLabel }}</p>
                            <mat-slider class="slider-style" step="1" min="0" max="6"></mat-slider>
                            <p class="slider-sub-title">{{ efffectMessageLabel }}</p>

                            <p class="slider-title">{{ waarschijnlijkheidTitleLabel }}</p>
                            <mat-slider class="slider-style" step="1" min="0" max="7"></mat-slider>
                            <p class="waarschijnlijkheid-sub-title">{{ waarschijnlijkheidLabel }}</p>

                            <p class="slider-title">{{ blootstellingTitleLabel }}</p>
                            <mat-slider class="slider-style" step="1" min="0" max="6"></mat-slider>
                            <p class="waarschijnlijkheid-sub-title">{{ blootstellingLabel }}</p>

                            <hr class="risk-horizontal-divider" />
                            <div g2sLayout="row">
                                <div g2sFlex="45">
                                    <p class="main-title">{{ risicoLabel }}</p>
                                    <p class="prioriteit-value">{{ risicoValueLabel }}</p>
                                    <p class="sub-title">{{ risicoDescriptionLabel }}</p>
                                </div>
                                <div g2sFlex="45" class="prio-column">
                                    <p class="main-title">{{ prioriteitLabel }}</p>
                                    <p class="prioriteit-value">{{ prioriteitValueLabel }}</p>
                                </div>
                            </div>
                            <hr class="risk-horizontal-divider" />
                            <p class="conclusion-title">{{ conclusieTitleLabel }}</p>
                            <p class="sub-title">{{ conclusieLabel }}</p>
                            <p class="conclusion-title">{{ termijnTitleLabel }}</p>
                            <p class="sub-title">{{ termijnLabel }}</p>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="vraag.type !== VraagType.Invulvraag">
                        <div class="rightside-panel">
                            <div class="rightside-panel-title">{{ verantwoordingstekstLabel }}</div>
                            <textarea class="form-control" wrap="off" placeholder="Zoeken..."></textarea>
                            <mat-icon class="search-icon">search</mat-icon>
                            <div
                                g2sLayout="row"
                                g2sLayoutAlign="start center"
                                (click)="onClickOrange()"
                                class="verantwoording-button">
                                <mat-icon class="msg-icon">message</mat-icon>
                                <div class="text">{{ verantwoordingsteksten }}</div>
                            </div>
                            <div g2sLayout="row" g2sLayoutAlign="start center" class="verantwoording-button">
                                <mat-icon class="msg-icon">message</mat-icon>
                                <div class="text">{{ verantwoordingsteksten }}</div>
                            </div>
                            <div g2sLayout="row" g2sLayoutAlign="start center" class="verantwoording-button">
                                <mat-icon class="msg-icon">message</mat-icon>
                                <div class="text">{{ verantwoordingsteksten }}</div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="deelplanvraag?.toonVerantwoordingsteksten">
            <div g2sHide.lt-md g2sFlex.gt-sm="26" g2sFlex.gt-md="25" class="panel3"></div>
        </ng-container>
    </ng-container>
</div>
