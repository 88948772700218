import { Component, Input } from "@angular/core";
import { DocumentsOverviewComponent } from "../documents-overview.component";

@Component({
    selector: "app-documents-thumbnails",
    templateUrl: "./documents-thumbnails.component.html",
    styleUrls: ["./documents-thumbnails.component.scss"],
})
export class DocumentsThumbnailsComponent {
    @Input() file: any;

    constructor(private readonly parent: DocumentsOverviewComponent) {}

    onSelectFile(file: any) {
        return this.parent.onSelectFile(file);
    }
}
