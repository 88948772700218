<mat-card class="info-card">
    <div class="img-banner" g2sLayout="row" g2sLayoutAlign="start">
        <!-- change image button -->
        <!-- <label *ngIf="!dossier?.heeftOpdrachten" for="file-upload">
            <span class="material-icons btn-banner"> photo_camera </span>
        </label>
        <input
            #imageInput
            id="file-upload"
            type="file"
            accept="image/x-png,image/jpg"
            (change)="processFile(imageInput)" /> -->
        <!-- info image -->
        <img mat-card-image name="image" src="{{ dossier.foto }}" *ngIf="dossier?.foto; else fillerImage" />
        <ng-template #fillerImage>
            <img mat-card-image src="../../../../assets/bg_fullsize.png" />
        </ng-template>
    </div>
    <div g2sLayout="row" g2sLayoutAlign="space-between start">
        <!-- checklist info -->
        <ng-container *ngIf="!authService.isWki() && dossier; else wki">
            <div g2sFlex>
                <mat-card-title>{{ dossier.kenmerk }}</mat-card-title>
                <mat-card-content g2sLayout="column">
                    <h4 *ngIf="!dossier?.heeftOpdrachten && dossier">{{ dossier.naam }}</h4>
                    <ng-container *ngIf="deelplan">
                        <h4>{{ deelplan.naam }}</h4>
                    </ng-container>
                    <h4 *ngIf="!dossier?.heeftOpdrachten && checklist">{{ checklist.naam }}</h4>
                    <div *ngIf="dossier" class="dossier-address">
                        <p>{{ dossier.locatieStraat }} {{ dossier.locatieHuisnummer }}</p>
                        <p>{{ dossier.locatiePostCode }} {{ dossier.locatieWoonplaats }}</p>
                    </div>
                </mat-card-content>
            </div>
        </ng-container>
        <!-- (WKI-Lease) dossier gegevens -->
        <ng-template #wki>
            <ng-container *ngIf="dossier">
                <div g2sFlex>
                    <mat-card-title>{{ dossier.kenmerk }}</mat-card-title>
                    <mat-card-content g2sLayout="column">
                        <ng-container *ngIf="deelplan">
                            <h4>{{ deelplan.naam }}</h4>
                        </ng-container>
                        <h4 *ngIf="!dossier?.heeftOpdrachten">{{ dossier.naam }}</h4>
                        <ng-container *ngIf="checklist && checklist.bouwnummer && !dossier?.heeftOpdrachten">
                            <h4>{{ checklist.bouwnummer }}</h4>
                        </ng-container>
                        <ng-container *ngIf="checklist && !dossier?.heeftOpdrachten">
                            <h4>{{ checklist.checklistGroepNaam }}</h4>
                        </ng-container>
                        <div class="dossier-address">
                            <p>{{ dossier.locatieStraat }} {{ dossier.locatieStraatNummer }}</p>
                            <p>{{ dossier.postCode }} {{ dossier.locatie }}</p>
                        </div>
                    </mat-card-content>
                </div>
            </ng-container>
        </ng-template>

        <!-- info buttons rechts onder de image -->
        <div
            *ngIf="!dossier?.heeftOpdrachten && false"
            class="info-buttons"
            g2sLayoutGap="0.5vw"
            g2sLayoutAlign="end start"
            g2sFlex>
            <button class="round-button" (click)="onOpenFilters()" mat-mini-fab>
                <mat-icon>filter_list</mat-icon>
            </button>
            <button class="round-button" (click)="onOpenHercontrole()" mat-mini-fab>
                <mat-icon>date_range</mat-icon>
            </button>
            <button class="round-button" (click)="openPhotosOverview()" mat-mini-fab>
                <mat-icon>collections</mat-icon>
            </button>
            <button class="round-button" (click)="openDocumentsOverview()" mat-mini-fab>
                <mat-icon>folder</mat-icon>
            </button>
        </div>
    </div>

    <!-- voortgangspercentage -->
    <mat-card-content *ngIf="!dossier?.heeftOpdrachten && false">
        <div class="voortgang">
            <p>{{ voortgangLabel }} {{ voortgangPercentage }}%</p>
            <mat-progress-bar class="voortgang-bar" value="{{ checklist.voortgang }}"></mat-progress-bar>
        </div>
        <!-- Statusverdeling bar values -->
        <div g2sLayout="row" class="statusverdeling" g2sLayoutAlign="start center">
            <p>{{ statusLabel }}</p>
            <p class="status-nummer voortgang-rood"></p>
            <p>{{ redStatus }}</p>
            <p class="status-nummer voortgang-oranje"></p>
            <p>{{ orangeStatus }}</p>
            <p class="status-nummer voortgang-groen"></p>
            <p>{{ greenStatus }}</p>
            <p class="status-nummer voortgang-grijs"></p>
            <p>{{ grayStatus }}</p>
        </div>
        <!-- Statusverdeling bar visualisatie -->
        <div g2sLayout="row" class="voortgang-bar">
            <div [hidden]="barRed == 0" g2sFlex="{{ barRed }}" class="voortgang-rood"></div>
            <div [hidden]="barOrange == 0" g2sFlex="{{ barOrange }}" class="voortgang-oranje"></div>
            <div [hidden]="barGreen == 0" g2sFlex="{{ barGreen }}" class="voortgang-groen"></div>
        </div>
        <!-- Actieve filters -->
        <ng-container *ngIf="showColorFilters || showDateFilters">
            <div class="active-filters-panel" g2sLayout="row" g2sLayoutAlign="start center">
                <div>
                    <p class="active-filters-title">{{ activeFiltersTextLabel }}:</p>
                    <ng-container *ngIf="showColorFilters">
                        <div g2sLayout="row" g2sLayoutAlign="start center">
                            <p class="active-filters-subtitle">{{ statusColorTextLabel }}</p>
                            <div
                                g2sLayout="row"
                                g2sLayoutAlign="start start"
                                *ngFor="let color of filterPopupColorFilters; let i = index"
                                class="active-filters-text">
                                <p class="active-filters-text">{{ color | uppercase }}</p>
                                <p class="active-filters-text-comma" *ngIf="i !== filterPopupColorFilters.length - 1">
                                    ,
                                </p>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="showDateFilters">
                        <div g2sLayout="row" g2sLayoutAlign="start center">
                            <p class="active-filters-date">{{ hercontrolePeriodTextLabel }}</p>
                            <p class="active-filters-date-text">{{ fromTextLabel }}</p>
                            <p class="active-filters-date">{{ filterPopupFromDateString }}</p>
                            <p class="active-filters-date-text">{{ untilTextLabel }}</p>
                            <p class="active-filters-date">{{ filterPopupToDateString }}</p>
                        </div>
                    </ng-container>
                </div>
                <button (click)="onResetFilters()" mat-mini-fab class="active-filters-reset-button">
                    <span class="material-icons">rotate_left</span>
                </button>
            </div>
        </ng-container>
    </mat-card-content>
</mat-card>
