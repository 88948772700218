import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DocumentsOverviewComponent } from "./documents-overview.component";
import { HeaderComponent } from "./header/header.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatTreeModule } from "@angular/material/tree";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { HttpClientModule } from "@angular/common/http";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { DocumentUploaderComponent } from "./document-uploader/document-uploader.component";
import { NewDocumentDialogComponent } from "./document-uploader/new-document-dialog/new-document-dialog.component";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacySnackBarModule as MatSnackBarModule } from "@angular/material/legacy-snack-bar";
import { FormsModule } from "@angular/forms";
import { SpinnerComponent } from "../spinner/spinner.component";
import { FiltersDialogComponent } from "./filters-dialog/filters-dialog.component";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { DocumentsThumbnailsComponent } from "./documents-thumbnails/documents-thumbnails.component";
import { DocumentsListComponent } from "./documents-list/documents-list.component";

@NgModule({
    declarations: [
        DocumentsOverviewComponent,
        HeaderComponent,
        DocumentUploaderComponent,
        NewDocumentDialogComponent,
        SpinnerComponent,
        FiltersDialogComponent,
        DocumentsThumbnailsComponent,
        DocumentsListComponent,
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatButtonModule,
        MatIconModule,
        MatSlideToggleModule,
        MatTreeModule,
        DragDropModule,
        HttpClientModule,
        MatCheckboxModule,
        MatDialogModule,
        FormsModule,
        MatSnackBarModule,
        MatTabsModule,
    ],
    exports: [DocumentsOverviewComponent],
})
export class DocumentsOverviewModule {}
