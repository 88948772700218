<div g2sLayout="column" class="logout-panel">
    <div>
        <h1 class="header-text" mat-dialog-title>{{ titleTextLabel }}</h1>
    </div>
    <div mat-dialog-actions g2sLayoutAlign="center center">
        <button (click)="logOut()" mat-flat-button color="primary" mat-dialog-close class="btn-block">
            {{ btnYesLabel }}
        </button>
        <button mat-flat-button color="warn" mat-dialog-close class="btn-block">{{ btnNoLabel }}</button>
        <br />
    </div>
</div>
