import { Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { IDeelplanOpdracht } from "src/app/model/deelplan-opdracht.model";
import { IHercontrole } from "src/app/model/hercontrole.model";
import { AnswerHercontroleDialogComponent } from "../../../refactor-answer-dialog/answer-hercontrole-dialog/answer-hercontrole-dialog.component";
import { NewOpdrachtDialogComponent } from "../../new-opdracht-dialog/new-opdracht-dialog.component";

@Component({
    selector: "app-select-judgement-dialog",
    templateUrl: "./hercontrole-vervolg-dialog.component.html",
    styleUrls: ["./hercontrole-vervolg-dialog.component.scss"],
})
export class HercontroleVervolgopdrachtDialogComponent {
    vervolgactieTitleLabel: string = "Vervolgactie";
    hercontroleTitleLabel: string = "Hercontrole";
    vervolgopdrachtTitleLabel: string = "Vervolgopdracht";

    translations: any;

    constructor(
        private dialogRef: MatDialogRef<HercontroleVervolgopdrachtDialogComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.translations = data.translations;
    }

    openHercontrole() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = "vraag-hercontrole-dialog";
        dialogConfig.data = { translations: this.translations };

        let dialogRef = this.dialog.open(AnswerHercontroleDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((hercontrole: IHercontrole) => {
            if (hercontrole != null) {
                this.dialogRef.close({ data: hercontrole, type: "hercontrole" });
            }
        });
    }

    openVervolgOpdracht() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = this.data;
        let dialogRef = this.dialog.open(NewOpdrachtDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((createdOpdracht: IDeelplanOpdracht) => {
            if (createdOpdracht != null) {
                this.dialogRef.close({ data: createdOpdracht, type: "vervolgopdracht" });
            }
        });
    }

    closeDialog(): void {
        this.dialogRef.close(null);
    }
}
