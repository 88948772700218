import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/services/auth.service";
import { DataService } from "src/app/services/data.service";
import { HttpClientService } from "src/app/services/posts.service";
import { AlertDialogComponent } from "./dialog/alert-dialog/alert-dialog.component";
import { LogoutDialogComponent } from "./dialog/logout-dialog/logout-dialog.component";
import { PasswordResetModalComponent } from "./dialog/password-reset-modal/password-reset-modal.component";
import { SelectLanguageDialogComponent } from "./language-select/select-language-dialog.component";

@Component({
    selector: "app-menu-bar",
    templateUrl: "./menu-bar.component.html",
    styleUrls: ["./menu-bar.component.scss"],
})
export class MenuBarComponent implements OnInit, OnDestroy {
    constructor(
        public dialog: MatDialog,
        public user: DataService,
        public http: HttpClientService,
        private auth: AuthService
    ) {}

    passwordResetLabel: string = "Wachtwoord resetten";
    userLogoutTextLabel: string = "Uitloggen";
    changeLanguageTextLabel: string = "Taal wijzigen";

    // userData = this.auth.user;

    // txtUserName = this.auth.user?.email;
    // txtUserOrg = this.auth.user?.organisatie;

    userData = "";

    txtUserName = "";
    txtUserOrg = "";

    alerts = [];
    homeAlerts = this.alerts.length;

    userDataSub!: Subscription;

    selectedLanguage: string | null = null;

    ngOnInit() {
        // this.userDataSub = this.auth.userDataLoaded.subscribe((data) => {
        //     this.txtUserName = data.email;
        //     this.txtUserOrg = data.organisatie;
        // });
    }

    ngOnDestroy(): void {
        this.userDataSub.unsubscribe();
    }

    openLogoutDialog() {
        this.dialog.open(LogoutDialogComponent, {
            restoreFocus: false,
            autoFocus: false,
        });
    }

    openPasswordResetDialog() {
        this.dialog.open(PasswordResetModalComponent, {
            restoreFocus: false,
            autoFocus: false,
        });
    }
    //openen van meldingen dialog, deze krijgt een lijst met meldingen mee
    openAlerts() {
        if (this.alerts.length >= 1) {
            this.dialog.open(AlertDialogComponent, {
                data: this.alerts,
                restoreFocus: false,
                autoFocus: false,
            });
        }
    }

    openLanguageDialog() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        let dialogRef = this.dialog.open(SelectLanguageDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((selectedLanguage: string) => {
            if (selectedLanguage != null && selectedLanguage != "") {
                this.selectedLanguage = selectedLanguage;
            }
        });
    }
}
