<div class="wrapper">
    <div class="header" g2sLayout="row">
        <div g2sLayout="row" class="headerstyle">
            <p class="header-text">{{ titelTextLabel }}</p>
            <mat-icon matDialogClose class="material-icons exit-button">close</mat-icon>
        </div>
    </div>
    <div class="dialog-body">
        <div class="grey-area">
            <p class="grey-area-text">{{ datumWijzigenTextLabel }}</p>
        </div>
        <div g2sLayout="row" g2sLayoutAlign="start start" class="date-time-section">
            <mat-datepicker-toggle #datetoggle (click)="picker.open()" matSuffix>
                <mat-icon matDatepickerToggleIcon>today</mat-icon>
            </mat-datepicker-toggle>
            <div style="position: relative">
                <p class="time-text">{{ initieleDatumTextLabel }}</p>
                <p class="initial-date" style="cursor: pointer" (click)="picker.open()">
                    {{ datePipe.transform(initialDate, 'EEEE dd-MM-yyyy') }}
                </p>
                <mat-form-field style="width: 1px; visibility: hidden; position: absolute; top: -1px">
                    <input [(ngModel)]="initialDate" matInput [matDatepicker]="picker" />
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <form #f="ngForm" [formGroup]="timeForm">
            <div g2sLayout="row" g2sLayoutAlign="start start" class="date-time-section">
                <mat-datepicker-toggle (click)="timepicker1.focus()" matSuffix>
                    <mat-icon matDatepickerToggleIcon>access_time</mat-icon>
                </mat-datepicker-toggle>
                <div>
                    <p class="time-text">{{ startTijdTextLabel }}</p>
                    <input
                        formControlName="starttijdDate"
                        (blur)="changeStarttijd()"
                        matInput
                        type="time"
                        class="time-input"
                        #timepicker1
                        required />
                </div>
            </div>
            <div g2sLayout="row" g2sLayoutAlign="start start" class="date-time-section">
                <mat-datepicker-toggle (click)="timepicker2.focus()" matSuffix>
                    <mat-icon matDatepickerToggleIcon>access_time</mat-icon>
                </mat-datepicker-toggle>
                <div>
                    <p class="time-text">{{ eindTijdTextLabel }}</p>
                    <input
                        formControlName="eindtijdDate"
                        (blur)="changeEindtijd()"
                        matInput
                        type="time"
                        class="time-input"
                        #timepicker2
                        required />
                </div>
            </div>
            <button mat-flat-button type="button" class="save-changes-button">{{ wijzigingenOpslaanTextLabel }}</button>
            <div class="grey-area">
                <p class="grey-area-text">{{ hercontroleInplannenTextLabel }}</p>
            </div>
        </form>
        <div class="color-card">
            <div class="card-header-red"></div>
            <div class="card-body" g2sLayout="row" g2sLayoutAlign="start center">
                <p class="card-text">{{ redCardTextLabel }}</p>
                <mat-slide-toggle [(ngModel)]="roodIsChecked" color="primary" class="card-body-slider">
                </mat-slide-toggle>
            </div>
        </div>
        <div class="color-card">
            <div class="card-header-orange"></div>
            <div class="card-body" g2sLayout="row" g2sLayoutAlign="start center">
                <p class="card-text">{{ orangeCardTextLabel }}</p>
                <mat-slide-toggle [(ngModel)]="oranjeIsChecked" color="primary" class="card-body-slider">
                </mat-slide-toggle>
            </div>
        </div>
        <div class="color-card">
            <div class="card-header-green"></div>
            <div class="card-body" g2sLayout="row" g2sLayoutAlign="start center">
                <p class="card-text">{{ greenCardTextLabel }}</p>
                <mat-slide-toggle [(ngModel)]="groenIsChecked" color="primary" class="card-body-slider">
                </mat-slide-toggle>
            </div>
        </div>
        <p class="blue-text">{{ kiesBestaandeTextLabel }}</p>
        <div class="hercontroles-container">
            <button
                mat-flat-button
                class="hercontrole-date-button"
                *ngFor="let date of hercontroleDates"
                [ngClass]="{ 'hercontrole-date-button-enabled': date === selectedDate }"
                (click)="onDateSelected(date)">
                {{ datePipe.transform(date.datum, 'dd-MM-yyyy') + ' van ' + datePipe.transform(date.startTijd, 'HH:mm')
                + ' tot ' + datePipe.transform(date.eindTijd, 'HH:mm') }}
            </button>
        </div>
        <div class="new-date-button" (click)="onAddDate()" g2sLayout="row" g2sLayoutAlign="start center">
            <mat-icon class="add-button">add</mat-icon>
            <p class="new-date-text">{{ newDateTextLabel }}</p>
        </div>
        <button
            [disabled]="!roodIsChecked && !oranjeIsChecked && !groenIsChecked"
            matDialogClose
            mat-flat-button
            type="button"
            class="plan-button">
            {{ inplannenTextLabel }}
        </button>
    </div>
</div>
