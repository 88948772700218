<div class="docs-file-card" (click)="onSelectFile(file)" g2sFlex>
    <div class="docs-file-categorise-button" g2sLayout="column" g2sLayoutAlign="center center" g2sFlex="15"></div>
    <div class="docs-file-content" g2sLayout="column" g2sLayoutAlign="start stretch" g2sFlex="85">
        <div class="docs-file-preview" g2sFlex="70">
            <img class="docs-file-preview-image" width="100%" height="100%" />
        </div>
        <div class="docs-file-name" g2sLayout="row" g2sLayoutAlign="center center" g2sFlex="15">
            <p class="file-name-text">{{ file.filename }}</p>
        </div>
        <div g2sFlex="15" g2sLayout="row" class="ticket-count-wrapper">
            <div g2sFlex g2sLayout="row" g2sLayoutGap="2px" g2sLayoutAlign="center center">
                <div g2sFlex="grow" class="ticket-status red"></div>
                <div>{{ file.bescheidenTicketKleurenTellingen.Rood }}</div>
            </div>
            <div g2sFlex g2sLayout="row" g2sLayoutGap="2px" g2sLayoutAlign="center center">
                <div g2sFlex="grow" class="ticket-status yellow"></div>
                <div>{{ file.bescheidenTicketKleurenTellingen.Oranje }}</div>
            </div>
            <div g2sFlex g2sLayout="row" g2sLayoutGap="2px" g2sLayoutAlign="center center">
                <div g2sFlex="grow" class="ticket-status green"></div>
                <div>{{ file.bescheidenTicketKleurenTellingen.Groen }}</div>
            </div>
        </div>
    </div>
</div>
