<div class="wrapper">
    <div class="header">
        <div g2sLayout="row" g2sLayoutAlign="space-between start">
            <h1 class="header-text" mat-dialog-title>{{ notificationTextLabel }}</h1>
            <mat-icon matRipple (click)="closeDialog()" aria-hidden="false" aria-label="close" class="close-button">
                close
            </mat-icon>
        </div>
        <hr />
    </div>
    <button class="btnClear" (click)="clearAllAlert()" mat-button color="warn" type="button">
        {{ clearAlertLabel }}
    </button>
    <div class="alerts">
        <ng-container *ngIf="alerts && alerts.length > 0; else noAlerts">
            <div *ngFor="let item of tempAlerts" class="alertCard" g2sLayout="row">
                {{ alertMessageTextLabel }}
                <div class="close-div">
                    <mat-icon matRipple (click)="deleteSelectedAlert()" class="delete-icon">close</mat-icon>
                </div>
            </div>
        </ng-container>
        <ng-template #noAlerts>
            <p class="no-alert-message">{{ noAlertLabel }}</p>
        </ng-template>
    </div>
</div>
