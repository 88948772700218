import { Component } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import Validation from "src/app/utils/validation";

@Component({
    selector: "app-password-reset-modal",
    templateUrl: "./password-reset-modal.component.html",
    styleUrls: ["./password-reset-modal.component.scss"],
})
export class PasswordResetModalComponent {
    resetSubmitBtnLabel: string = "Opslaan";
    confirmCurrentPasswordLabel: string = "Bevestig uw huidige wachtwoord";
    newPasswordRepeatLabel: string = "Uw nieuwe wachtwoord nogmaals";
    newPasswordLabel: string = "Uw nieuwe wachtwoord";
    resetDialogContentLabel: string =
        "Uw wachtwoord dient tenminste 8 karakters te bevatten waaronder een hoofdletter, kleine letter, cijfer en een bijzonder leesteken";
    resetPasswordTitleLabel: string = "Wachtwoord wijzigen";

    resetForm = new UntypedFormGroup(
        {
            currPassword: new UntypedFormControl("", [
                Validators.required,
                Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}"),
            ]),
            newPassword: new UntypedFormControl("", [
                Validators.required,
                Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}"),
            ]),
            newPasswordRepeat: new UntypedFormControl("", [
                Validators.required,
                Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}"),
            ]),
        },
        {
            validators: [Validation.match("newPassword", "newPasswordRepeat")],
        }
    );

    constructor(private dialog: MatDialog) {}

    closeDialog() {
        this.dialog.closeAll();
    }

    onSubmit() {}
}
