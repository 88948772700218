<!-- forloop dossiers -->
<ng-container *ngIf="!dossiersLoaded">
    <div g2sLayout="row" g2sLayoutAlign="center start">
        <mat-spinner class="dossier-spinner"></mat-spinner>
    </div>
</ng-container>

<ng-container *ngFor="let dossierItem of dossiers">
    <mat-card class="dashboard">
        <div g2sLayout="row">
            <mat-card-subtitle class="openOpdrachten" *ngIf="dossierItem.heeftOpdrachten">
                ({{ openOpdrachtLabel }})
            </mat-card-subtitle>
            <span class="spacer"></span>
            <mat-icon class="notification" *ngIf="dossierItem.heeftOpdrachten && notification > 0">
                notifications
            </mat-icon>
            <!-- dossiermenu knop -->
            <button
                class="answer-all-button"
                *ngIf="!dossierItem.heeftOpdrachten"
                [routerLink]="['/dossiermenu', dossierItem.id]"
                mat-icon-button
                g2sLayoutAlign="center center">
                <mat-icon color="primary">more_horiz</mat-icon>
            </button>
            <button *ngIf="dossierItem.heeftOpdrachten" mat-icon-button g2sLayoutAlign="center center">
                <span matBadge="{{ dossierItem.deelplannen?.length }}"></span>
            </button>
        </div>
        <mat-card-content>
            <div class="locationDossier">
                <mat-card-title>{{ dossierItem.naam }}</mat-card-title>
                <ng-container *ngIf="authService.isWki() && dossierItem.heeftOpdrachten && dossierItem.kenmerk != null">
                    <p class="wki-dossier-kenmerk">{{ dossierItem.kenmerk }}</p>
                </ng-container>
                <hr />
            </div>
            <mat-card-title>{{ dossierItem.locatieStraat }} {{ dossierItem.locatieStraatNummer }}</mat-card-title>
            <mat-card-title>{{ dossierItem.postCode }} {{ dossierItem.locatie }}</mat-card-title>
        </mat-card-content>
        <mat-divider inset></mat-divider>
        <mat-expansion-panel #panel class="dossierCollapse" hideToggle>
            <mat-expansion-panel-header class="centerHeader">
                <ng-container *ngIf="!panel.expanded; else opened">
                    <span class="material-icons">expand_more</span>
                </ng-container>
                <ng-template #opened>
                    <span class="material-icons">expand_less</span>
                </ng-template>
            </mat-expansion-panel-header>
            <!-- forloop deelplannen met het openen van expansion panel -->
            <ng-container *ngFor="let planItem of dossierItem.deelplannen">
                <mat-expansion-panel class="deelplanItemCard" hideToggle>
                    <mat-expansion-panel-header class="deelplanItemTitle click-styling" g2sLayoutAlign="center center">
                        <mat-panel-title class="deelplanTitle"> {{ planItem.naam }} </mat-panel-title>
                        <span class="spacer"></span>
                        <mat-icon *ngIf="dossierItem.heeftOpdrachten && notification > 0">notifications</mat-icon>
                        <button
                            class="answer-all-button"
                            *ngIf="!dossierItem.heeftOpdrachten"
                            mat-icon-button
                            g2sLayoutAlign="center center">
                            <mat-icon color="primary">more_horiz</mat-icon>
                        </button>
                    </mat-expansion-panel-header>
                    <!-- forloop deelplanchecklist met het openen van expansion panel -->
                    <ng-container *ngFor="let checkItem of planItem.deelplanChecklists">
                        <a class="click-styling" [routerLink]="['../checklistoverview', 71427, 19631]">
                            <mat-card class="checkItemCard">
                                <mat-card-subtitle class="checklistItem">
                                    <ng-container *ngIf="checklist != null">
                                        <h4 style="margin-bottom: 5px">{{ checklist.checklistGroepNaam }}</h4>
                                    </ng-container>
                                    <p style="font-size: 15px">{{ checkItem.naam }}</p>
                                    <mat-icon
                                        class="checklist-notification"
                                        *ngIf="dossierItem.heeftOpdrachten && notification > 0">
                                        notifications
                                    </mat-icon>
                                </mat-card-subtitle>
                                <ng-container
                                    *ngIf="authService.isWki() && checklist && checklist.bouwnummer && !dossierItem.heeftOpdrachten">
                                    <h4 class="bouwnummer">{{ checklist.bouwnummer }}</h4>
                                </ng-container>
                                <hr class="card-hr" />
                                <mat-card-subtitle class="checklistItem" g2sLayoutAlign="end center">
                                    {{ progressTextLabel }}: {{ checkItem.voortgangPercentage }}%
                                </mat-card-subtitle>
                            </mat-card>
                        </a>
                    </ng-container>
                </mat-expansion-panel>
            </ng-container>
        </mat-expansion-panel>
    </mat-card>
</ng-container>
<footer style="height: 100px"></footer>
