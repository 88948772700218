import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./services/auth-guard.service";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { LoginComponent } from "./components/login/login.component";
import { ChecklistoverviewComponent } from "./components/checklistoverview/checklistoverview.component";
import { DossierMenuComponent } from "./components/dashboard/dossier-menu/dossier-menu.component";
import { DossierInformationComponent } from "./components/dashboard/dossier-menu/dossier-information/dossier-information.component";
import { DossierMemoComponent } from "./components/dashboard/dossier-menu/dossier-memo/dossier-memo.component";
import { DocumentsOverviewComponent } from "./components/documents-overview/documents-overview.component";
import { PdfAnnotatorComponent } from "./components/pdf-annotator/pdf-annotator.component";
import { PhotosOverviewComponent } from "./components/photos-overview/photos-overview.component";

const routes: Routes = [
    { path: "", pathMatch: "full", redirectTo: "/login" },
    { path: "login", component: LoginComponent },
    // { path: "dashboard", canActivate: [AuthGuard], component: DashboardComponent },
    {
        path: "checklistoverview/:dossierId/:deelplanId/:checklistId/:internOrExtern",
        canActivate: [AuthGuard],
        component: ChecklistoverviewComponent,
    },
    // // { path: 'checklistoverview/:dossierId/:deelplanId/:checklistId', canActivate: [AuthGuard], component: ChecklistoverviewComponent },
    // { path: "dossiermenu/:dossierId", canActivate: [AuthGuard], component: DossierMenuComponent },
    // { path: "dossierinformation/:dossierId", canActivate: [AuthGuard], component: DossierInformationComponent },
    // { path: "memo/:dossierId", canActivate: [AuthGuard], component: DossierMemoComponent },
    // { path: "documents-overview/:dossierId/:deelplanId/:checklistId", component: DocumentsOverviewComponent },
    // { path: "documents-overview", canActivate: [AuthGuard], component: DocumentsOverviewComponent },
    // { path: "pdf-annotator/:dossierId/:deelplanId/:checklistId", component: PdfAnnotatorComponent },
    // { path: "photos-overview/:dossierId/:deelplanId/:checklistId", component: PhotosOverviewComponent },
    { path: "**", redirectTo: "/login", pathMatch: "full" },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: false,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
