import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Subject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { IAuthResponseData } from "../model/authresponsedata.model";
import { HttpClientService } from "./posts.service";
import { IResponse } from "../model/response.model";
import { AbstractService } from "./abstract.service";
import { IGebruiker } from "../model/gebruiker.model";
import { TokenService } from "./token.service";
import { SnackbarService } from "./snackbar.service";
import { environment } from "src/environments/environment";
import { IExterneGebruiker } from "../model/externe-gebruiker.model";

@Injectable({
    providedIn: "root",
})
export class AuthService extends AbstractService {
    currentInterneGebruikerSubject: BehaviorSubject<IGebruiker>;
    currentExterneGebruikerSubject: BehaviorSubject<IExterneGebruiker>;
    // static get authenticatedUser(): IGebruiker | null {
    //     return this.currentInterneGebruikerSubject.getValue();
    // }

    constructor(httpClient: HttpClient, tokenService: TokenService, snackbarService: SnackbarService) {
        super(httpClient, tokenService, snackbarService);

        let internalUser = localStorage.getItem("currentInterneGebruiker");
        this.currentInterneGebruikerSubject = new BehaviorSubject<IGebruiker>(
            internalUser ? JSON.parse(internalUser) : null
        );

        let externalUser = localStorage.getItem("currentExterneGebruiker");
        this.currentExterneGebruikerSubject = new BehaviorSubject<IExterneGebruiker>(
            externalUser ? JSON.parse(externalUser) : null
        );
    }

    isWki() {
        // TEMP FUNCTION TO HANDLE authService.isWki()
        return false;
    }

    getAuthenticatedGebruiker(prefer: "intern" | "extern" | "" = ""): IGebruiker | IExterneGebruiker | null {
        let gebruiker = this.currentInterneGebruikerSubject.getValue();
        let externe_gebruiker = this.currentExterneGebruikerSubject.getValue();

        if ((gebruiker && !externe_gebruiker) || (gebruiker && externe_gebruiker && prefer == "intern")) {
            return gebruiker;
        } else if ((externe_gebruiker && !gebruiker) || (externe_gebruiker && gebruiker && prefer == "extern")) {
            return externe_gebruiker;
        }

        return null;
    }

    getAuthenticatedInterneGebruiker(): IGebruiker | null {
        return this.currentInterneGebruikerSubject.getValue();
    }

    getAuthenticatedExterneGebruiker(): IExterneGebruiker | null {
        return this.currentExterneGebruikerSubject.getValue();
    }

    /**
     * Determine if there's an authenticated gebruiker
     * @returns
     */
    isAuthenticated(): boolean {
        let gebruiker = this.currentInterneGebruikerSubject.getValue();
        let externe_gebruiker = this.currentExterneGebruikerSubject.getValue();

        if (
            (gebruiker || externe_gebruiker) &&
            this.tokenService.getAccessToken() &&
            this.tokenService.getRefreshToken()
        ) {
            return true;
        }

        return false;
    }

    /**
     * Logout the current authenticated gebruiker
     * by resetting the tokens, removing the gebruiker from local storage
     * and resetting the BehaviourSubject
     */
    logout() {
        this.tokenService.clear();

        localStorage.removeItem("currentGebruiker");
        localStorage.removeItem("currentExterneGebruiker");
        this.currentInterneGebruikerSubject.unsubscribe();
    }

    /**
     * Start an OAuth2 authentication process
     * by setting up the required parameters
     * and navigating to the login module
     */
    oauth2Authenticate() {
        this.tokenService.clear();

        let url =
            environment.loginModuleUrl +
            "/login" +
            "?origin=backoffice" +
            "&code_challenge=" +
            this.tokenService.generateCodeChallenge() +
            "&state=" +
            this.tokenService.generateState();

        window.location.assign(url);
    }

    /**
     * Handle the authorization callback.
     * This function is called from the login component when the URL contained an code and a state param
     * which means the user got redirected from the OAuth2 process with an authorization code.
     * @param code
     * @param state
     * @returns
     */
    handleAuthorizationCallback(code: string, state: string) {
        return new Promise(async (resolve, reject) => {
            let success: boolean = false;

            // Check if the code and state are set
            if (code && state) {
                // Check if the state matches the state we generated and saved before starting the oauth process
                if (state == this.tokenService.getState()) {
                    // Request an access token by the given authorization code
                    await this.requestAccessToken(code).then(async (response) => {
                        // If response is true
                        if (response) {
                            success = true;
                            resolve(true);
                            return;
                        }
                    });
                }
            }

            if (!success) {
                // reject(noUserFoundWithEmailAndPasswordCombination);
                reject(false);

                this.forceLogout();
            }
        });
    }

    /**
     * Requests access token with using the given authorization code
     * @param code
     * @returns
     */
    requestAccessToken(code: string) {
        return new Promise((resolve, reject) => {
            // Define payload with the required parameters
            let payload = new HttpParams()
                .append("client_id", environment.clientId)
                .append("grant_type", "authorization_code")
                .append("code", code)
                .append("code_verifier", this.tokenService.getCodeVerifier() as string);

            this.httpClient
                .post(environment.baseOAuth2Url + "/o/token/", payload, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                })
                .subscribe({
                    next: (result: any) => {
                        // Only if the result contains an access token and refresh token we'll continue
                        if (result.access_token && result.refresh_token) {
                            // Save the tokens for use in requests
                            this.tokenService.saveAccessToken(result.access_token);
                            this.tokenService.saveRefreshToken(result.refresh_token);

                            resolve(true);
                        }

                        reject(false);
                    },
                    error: (error) => {
                        reject(false);
                    },
                });
        });
    }

    /**
     * This function is used to get the corresponding user instance by access token
     * @returns
     */
    authenticate(uid: number, extern: boolean = false) {
        return super.post("/backoffice/login/v1/authenticate", { uid: uid, extern: extern }).pipe(
            map((response: IResponse) => {
                if (!response.success || !response.returnData) {
                    let message = response.message ? response.message : "Inloggen is mislukt, probeer opnieuw!";
                    let code = response.errorCode ? response.errorCode : -3000;
                    throw new Error(message + " Foutcode: " + code);
                }
                if (response.success) {
                    if (response.returnData) {
                        if (extern) {
                            let user: IExterneGebruiker = response.returnData;
                            if (user) {
                                localStorage.setItem("currentExterneGebruiker", JSON.stringify(user));
                                this.currentExterneGebruikerSubject.next(user);
                            }
                        } else {
                            let user: IGebruiker = response.returnData;
                            if (user) {
                                localStorage.setItem("currentInterneGebruiker", JSON.stringify(user));
                                this.currentInterneGebruikerSubject.next(user);
                            }
                        }
                    }

                    // NFP-17
                    // On login only we want to request the available languages
                    // which are then saved to local storage.
                    // So after the user successfully logged in we'll remove the current available languages from local storage
                    // to request an updated set in the TranslationService
                    localStorage.removeItem("availableLanguages");
                }
                return response;
            })
        );
    }

    updateLocalUser(user: IGebruiker) {
        localStorage.setItem("currentInterneGebruiker", JSON.stringify(user));
        this.currentInterneGebruikerSubject.next(user);
    }
}
