import { Component } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

@Component({
    selector: "app-existing-vervolgopdracht-dialog.ts",
    templateUrl: "./existing-vervolgopdracht-dialog.component.html",
    styleUrls: ["./existing-vervolgopdracht-dialog.component.scss"],
})
export class ExistingVervolgopdrachtDialogComponent {
    notificationLabel: string =
        "Er is voor dit ticket een vervolgactie gecreëerd. Bij het wijzigen van het waardeoordeel wordt deze vervolgactie verwijderd. Wilt u doorgaan?";
    jaAnswerLabel: string = "Ja";
    annulerenAnswerLabel: string = "Annuleren";

    constructor(private dialogRef: MatDialogRef<ExistingVervolgopdrachtDialogComponent>) {
        dialogRef.disableClose = true;
    }

    onAnswerJa() {
        this.dialogRef.close(true);
    }

    onAnswerAnnuleren() {
        this.dialogRef.close(false);
    }
}
