import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IDeelplan } from "src/app/model/deelplan.model";
import { IDeelplanChecklist } from "src/app/model/deelplan-checklist.model";
import { IDossier } from "src/app/model/dossier.model";
import { HttpClientService } from "src/app/services/posts.service";
import { AuthService } from "src/app/services/auth.service";
@Component({
    selector: "app-dossier-view",
    templateUrl: "./dossier-view.component.html",
    styleUrls: ["./dossier-view.component.scss"],
})
export class DossierViewComponent implements OnInit {
    @Input() checklist!: IDeelplanChecklist;

    progressTextLabel: string = "Voortgang";
    openOpdrachtLabel: string = "Openstaande opdrachten";

    dossierAlerts = 22;
    progressValue = 17;

    dossiers = Array<IDossier>();
    deelplannen = Array<IDeelplan>();
    checklisten = Array<IDeelplanChecklist>();
    dossiersLoaded = false;

    notification: number = 1;

    constructor(public post: HttpClientService, private route: Router, public authService: AuthService) {}

    ngOnInit(): void {
        this.dossiers = [
            {
                id: 12345,
                naam: "Steiner Test Dossier",
                kenmerk: "STI STA STEINER",
                locatie: "Steinerland",
                locatieStraat: "Steinerstraat",
                locatieStraatNummer: "75",
                postCode: "7556PE",
                deelplannen: [
                    {
                        id: 71427,
                        dossierId: 12345,
                        naam: "Steiner Test Deelplan",
                        deelplanChecklists: [
                            {
                                id: 19631,
                                deelplanId: 71427,
                                naam: "Steiner Test Deelplan Checklist",
                                voortgangPercentage: 50,
                                toonSamenvatting: false,
                                initieleDatum: new Date("2023-05-12"),
                            },
                        ],
                    },
                ],
                heeftOpdrachten: false,
            },
        ];
        this.dossiersLoaded = true;
        this.post.dossiers = this.dossiers;
    }
}
