<div class="wrapper">
    <div g2sLayout="column">
        <div class="title-div">
            <p class="text-header">{{ fotoOptieLabel }}</p>
        </div>
        <div class="text-div" (click)="openFileInput()">
            <p class="text">{{ fotoKiezenLabel }}</p>
            <input accept="image/png, image/jpg, image/jpeg" type="file" #imageFileInput style="display: none" multiple (change)="filesSelected($event)"/>
        </div>
        <img [src]="imageUrl" *ngIf="imageUrl" />
        <div class="text-div" (click)="openDocumentInput()">
            <p class="text">{{ documentKiezenLabel }}</p>
            <input type="file" accept="application/pdf" #pdfFileInput style="display: none" multiple (change)="filesSelected($event)"/>
        </div>
    </div>
</div>
