import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { IEntryData } from "../model/entryData.model";

@Injectable({
    providedIn: "root",
})
export class DocumentService {
    #entryData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public readonly entryData: Observable<IEntryData> = this.#entryData.asObservable();
    private readonly hostName = "";

    constructor(private httpClient: HttpClient) {}

    setEntryData(data: IEntryData): void {
        this.#entryData.next(data);
    }

    getMainFoldersAndFiles(
        dossierId: number,
        deelplanChecklistId?: number,
        showAll?: boolean,
        filteredBouwnummers?: number[],
        filteredLables?: number[],
        deelplanVraagId?: number,
        deelplanId?: number,
        checklistGroepId?: number,
        checklistId?: number,
        vraagId?: number,
        bouwnummerId?: number
    ) {
        let params = new HttpParams();
        if (filteredBouwnummers?.length) {
            params = params.set("bouwnummers", JSON.stringify(filteredBouwnummers));
        }

        if (filteredLables?.length) {
            params = params.set("labels", JSON.stringify(filteredLables));
        }

        // let url = `${this.hostName}/checklistoverview/getMainFoldersAndFiles/${dossierId}/${deelplanChecklistId}/${(showAll == false) ? 0 : 1}/${deelplanVraagId ? deelplanVraagId : 0}/${deelplanId ? deelplanId : 0}/${checklistGroepId ? checklistGroepId : 0}/${checklistId ? checklistId : 0}/${vraagId ? vraagId : 0}/${bouwnummerId ? bouwnummerId : 0}`;

        // return this.httpClient.get<[]>(url, {params});

        return of(files);
    }

    getDocumentsFiltersData(deelplanChecklistId?: number) {
        let url = `${this.hostName}/checklistoverview/getDocumentsFiltersData/${deelplanChecklistId}`;
        return this.httpClient.get<[]>(url);
    }

    getNestedFiles(
        dossierId: number,
        deelplanChecklistId: number,
        bescheidenMapId?: number,
        showAll?: boolean,
        deelplanVraagId?: number,
        deelplanId?: number,
        checklistGroepId?: number,
        checklistId?: number,
        vraagId?: number,
        bouwnummerId?: any
    ) {
        let url = `${
            this.hostName
        }/checklistoverview/getNestedFilesFromFolder/${dossierId}/${deelplanChecklistId}/${bescheidenMapId}/${
            showAll == false ? 0 : 1
        }/${deelplanVraagId ? deelplanVraagId : 0}/${deelplanId ? deelplanId : 0}/${
            checklistGroepId ? checklistGroepId : 0
        }/${checklistId ? checklistId : 0}/${vraagId ? vraagId : 0}/${bouwnummerId ? bouwnummerId : 0}`;

        return this.httpClient.get<[]>(url);
    }

    addFiles(deelplanChecklistId: number, formData: FormData) {
        let headers = new HttpHeaders();
        headers.set("Content-Type", "multipart/form-data");
        return this.httpClient.post(
            `${this.hostName}/checklistoverview/addScopeDocument/${deelplanChecklistId}`,
            formData,
            { headers: headers }
        );
    }

    getDocumentTicketCount(fileId: number, deelplanChecklistId: number, deelplanVraagId?: number, bouwnummer?: any) {
        let url = `${this.hostName}/checklistoverview/getDocumentTicketCount/${fileId}/${deelplanChecklistId}/${deelplanVraagId}/${bouwnummer}`;

        return this.httpClient.get<[]>(url);
    }
}

const files = {
    files: [
        [
            {
                id: "901040",
                dossierBescheidenMapId: null,
                description: "test__1_.pdf",
                casenumberRegistration: "",
                drawingNumber: "",
                version: "",
                dateLastChanges: "",
                author: "",
                filename: "test__1_.pdf",
                documentArchived: "0",
                dateProvided: null,
                monitoring: "Nee",
                status: "Juist",
                type: null,
                asBuiltDossier: "Nee",
                url: "https://2ts-test.go2sure.nl/dossiers/bescheiden_downloaden/901040/0",
                annotatorUrl: "https://2ts-test.go2sure.nl/pdfannotatornewstyle/index/901040/1/77090",
                bescheidenTicketKleurenTellingen: {
                    Groen: 0,
                    Rood: 0,
                    Oranje: 0,
                },
                categories: [],
                labelsTitles: [],
                document_version: "",
                judgement: "",
            },
            {
                id: "901050",
                dossierBescheidenMapId: null,
                description: "chepp.png",
                casenumberRegistration: "",
                drawingNumber: "",
                version: "",
                dateLastChanges: "30-01-2023 14:29",
                author: "",
                filename: "chepp.png",
                documentArchived: "0",
                dateProvided: null,
                monitoring: "Nee",
                status: "Juist",
                type: null,
                asBuiltDossier: "Nee",
                url: "https://2ts-test.go2sure.nl/dossiers/bescheiden_downloaden/901050/0",
                annotatorUrl: "https://2ts-test.go2sure.nl/pdfannotatornewstyle/index/901050/1/77090",
                bescheidenTicketKleurenTellingen: {
                    Groen: 0,
                    Rood: 0,
                    Oranje: 0,
                },
                categories: [],
                labelsTitles: [],
                document_version: "",
                judgement: "",
            },
        ],
    ],
    folders: [
        {
            id: "218030",
            parent_dossier_besch_map_id: null,
            dossier_id: "145028",
            naam: "Map A",
            is_archived_map: "0",
            aantal_documenten: "0",
            aantal_onderliggende_mappen: 0,
        },
    ],
};
