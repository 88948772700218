import { Component, Inject } from "@angular/core";
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";

@Component({
    selector: "app-alert-dialog",
    templateUrl: "./alert-dialog.component.html",
    styleUrls: ["./alert-dialog.component.scss"],
})
export class AlertDialogComponent {
    tempAlerts = Array.from({ length: 10 }).map((_, i) => `alert #${i}`);

    noAlertLabel: string = "Geen meldingen...";
    clearAlertLabel: string = "Verwijder alle notificaties";
    notificationTextLabel: string = "Notificaties";
    alertMessageTextLabel: string = "Dummy Message Text";

    constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: Array<String>) {}

    //alert data vanuit menu-bar
    alerts = this.data;

    closeDialog() {
        this.dialog.closeAll();
    }

    clearAllAlert() {}

    deleteSelectedAlert() {}
}
