import { DatePipe } from "@angular/common";
import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import * as moment from "moment";
import { IDeelplanChecklist } from "src/app/model/deelplan-checklist.model";
import { IHercontrole } from "src/app/model/hercontrole.model";
import { DataService } from "src/app/services/data.service";
import { DeelplanChecklistService } from "src/app/services/deelplan-checklist.service";
import { SnackbarService } from "src/app/services/snackbar.service";

@Component({
    selector: "app-anwser-hercontrole-dialog.component",
    templateUrl: "./answer-hercontrole-dialog.component.html",
    styleUrls: ["./answer-hercontrole-dialog.component.scss"],
})
export class AnswerHercontroleDialogComponent implements OnInit {
    // initialDate: Date = new Date();
    // starttijdDate: Date = new Date();
    // eindtijdDate: Date = new Date();

    starttijdFocussed = false;
    eindtijdFocussed = false;

    selectedDate!: IHercontrole;

    existingHercontroles: IHercontrole[] = [];

    translations: any;

    hercontroleForm = this.formBuilder.group({
        initialDate: { value: "" },
        starttijdDate: { value: "" },
        eindtijdDate: { value: "" },
    });

    constructor(
        public datePipe: DatePipe,
        private formBuilder: UntypedFormBuilder,
        private dialogRef: MatDialogRef<AnswerHercontroleDialogComponent>,
        private snackbar: SnackbarService,
        private dataService: DataService,
        private deelplanChecklistService: DeelplanChecklistService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public cdRef: ChangeDetectorRef
    ) {
        this.translations = data.translations;

        this.deelplanChecklistService.selectedDeelplanChecklist.subscribe({
            next: (deelplanChecklist: IDeelplanChecklist) => {
                this.deelplanChecklistService.getUniqueHercontroles(deelplanChecklist.id).subscribe({
                    next: (hercontroles) => {
                        for (let hercontrole of hercontroles) {
                            if (hercontrole.date && hercontrole.start && hercontrole.end) {
                                hercontrole.date = moment(hercontrole.date!, "DD-MM-YYYY").toDate();
                                hercontrole.start = moment(hercontrole.start!, "HH:mm").toDate();
                                hercontrole.end = moment(hercontrole.end!, "HH:mm").toDate();

                                this.existingHercontroles.push(hercontrole);
                            }
                        }
                    },
                });
            },
        });
    }

    ngOnInit(): void {
        this.hercontroleForm.controls["initialDate"].setValue(new Date());
        this.hercontroleForm.controls["starttijdDate"].setValue(
            this.datePipe.transform(new Date().setHours(8, 0, 0, 0), "HH:mm")
        );
        this.hercontroleForm.controls["eindtijdDate"].setValue(
            this.datePipe.transform(new Date().setHours(9, 0, 0, 0), "HH:mm")
        );

        this.cdRef.detectChanges();
    }

    changeStarttijd() {
        let startTime = this.hercontroleForm.controls["starttijdDate"].value;
        let endTime = this.hercontroleForm.controls["eindtijdDate"].value;

        let timeNumbers = startTime.split(":");

        let startDateTime = new Date(this.hercontroleForm.controls["initialDate"].value);
        startDateTime.setHours(+timeNumbers[0]);
        startDateTime.setMinutes(+timeNumbers[1]);

        timeNumbers = endTime.split(":");

        let endDateTime = new Date(this.hercontroleForm.controls["initialDate"].value);
        endDateTime.setHours(+timeNumbers[0]);
        endDateTime.setMinutes(+timeNumbers[1]);

        if (startDateTime > endDateTime) {
            this.hercontroleForm.controls["eindtijdDate"].setValue(this.datePipe.transform(startDateTime, "HH:mm"));
        }
    }

    changeEindtijd() {
        let startTime = this.hercontroleForm.controls["starttijdDate"].value;
        let endTime = this.hercontroleForm.controls["eindtijdDate"].value;

        let timeNumbers = startTime.split(":");

        let startDateTime = new Date(this.hercontroleForm.controls["initialDate"].value);
        startDateTime.setHours(+timeNumbers[0]);
        startDateTime.setMinutes(+timeNumbers[1]);

        timeNumbers = endTime.split(":");

        let endDateTime = new Date(this.hercontroleForm.controls["initialDate"].value);
        endDateTime.setHours(+timeNumbers[0]);
        endDateTime.setMinutes(+timeNumbers[1]);

        if (endDateTime < startDateTime) {
            this.hercontroleForm.controls["starttijdDate"].setValue(this.datePipe.transform(endDateTime, "HH:mm"));
        }
    }

    onDateSelected(hercontrole: IHercontrole) {
        this.dialogRef.close(hercontrole);
    }

    onSubmitHercontroleForm() {
        let timeNumbers = this.hercontroleForm.controls["starttijdDate"].value.split(":");
        let startDateTime = new Date(this.hercontroleForm.controls["initialDate"].value);
        startDateTime.setHours(+timeNumbers[0]);
        startDateTime.setMinutes(+timeNumbers[1]);

        timeNumbers = this.hercontroleForm.controls["eindtijdDate"].value.split(":");
        let endDateTime = new Date(this.hercontroleForm.controls["initialDate"].value);
        endDateTime.setHours(+timeNumbers[0]);
        endDateTime.setMinutes(+timeNumbers[1]);

        let newHercontrole: IHercontrole = {
            date: this.hercontroleForm.controls["initialDate"].value,
            start: startDateTime,
            end: endDateTime,
        };

        this.dialogRef.close(newHercontrole);
    }

    starttijdFocus(focussed: boolean) {
        this.starttijdFocussed = focussed;
    }

    eindtijdFocus(focussed: boolean) {
        this.eindtijdFocussed = focussed;
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
