import { Component } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

@Component({
    selector: "app-delegate-opdracht-dialog",
    templateUrl: "./delegate-opdracht-dialog.component.html",
    styleUrls: ["./delegate-opdracht-dialog.component.scss"],
})
export class DelegateOpdrachtDialogComponent {
    opdrachtDelegated: boolean = false;

    opdrachtDoorgevenTitleLabel: string = "Opdracht doorgeven";
    opdrachtDoorgevenAanTitleLabel: string = "Opdracht doorgeven aan:";

    toevoegenLabel: string = "Doorgeven";
    voornaam: string = "";
    achternaam: string = "";
    email: string = "";
    toelichting: string = "";

    emailTitleLabel: string = "*E-mail";
    voornaamTitleLabel: string = "*Voornaam";
    achternaamTitleLabel: string = "*Achternaam";
    toelichtingTitleLabel: string = "*Toelichting";
    eindcontroleTitleLable: string = "*Eindcontrole";

    uitvoerendeTitleLabel: string = "Uitvoerende";
    uitvoerendeNaam: string = "Test uitvoerende";
    expandTitleLabel: string = "Nieuwe uitvoerende toevoegen";

    emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$";

    constructor(private dialogRef: MatDialogRef<DelegateOpdrachtDialogComponent>) {}

    opdrachtDoorgeven() {
        this.dialogRef.close(true);
    }
}
