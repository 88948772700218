import { Component } from "@angular/core";
import { Location } from "@angular/common";

@Component({
    selector: "app-photos-overview-header",
    templateUrl: "./photos-overview-header.component.html",
    styleUrls: ["./photos-overview-header.component.scss"],
})
export class PhotosOverviewHeaderComponent {
    backButtonLabel = "Terug";

    constructor(private location: Location) {}

    goBack(): void {
        this.location.back();
    }
}
