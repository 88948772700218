<!-- <app-menu-bar-checklist [showRapportage]="false" [dossierId]="dossier.id"></app-menu-bar-checklist> -->
<div class="header">
    <mat-icon routerLink="/dashboard" class="home-icon">home</mat-icon>
</div>
<div class="tile-container" g2sLayout="column" g2sLayoutAlign="center center" g2sLayoutGap="35px">
    <div class="tile-row" g2sLayout="row" g2sLayoutAlign="center center" g2sLayoutGap="35px">
        <div matRipple (click)="onOpenDossierInformatie()" class="tile" g2sLayout="column" g2sLayoutAlign="center center">
            <img draggable="false" src="../../../../assets/dossier-information.svg" alt="Dossierinformatie" />
            <p>{{ dossierInformationTextLabel }}</p>
        </div>
        <div matRipple (click)="onOpenBetrokkenen()" class="tile" g2sLayout="column" g2sLayoutAlign="center center">
            <img draggable="false" src="../../../../assets/betrokkens.svg" alt="Betrokkenen" />
            <p>{{ betrokkenenTextLabel }}</p>
        </div>
    </div>
    <div class="tile-row" g2sLayout="row" g2sLayoutAlign="center center" g2sLayoutGap="35px">
        <div matRipple (click)="onOpenDeelplannen()" class="tile" g2sLayout="column" g2sLayoutAlign="center center">
            <img draggable="false" src="../../../../assets/deelplannen.svg" alt="Deelplannen" />
            <p>{{ deelplannenTextLabel }}</p>
        </div>
        <div matRipple (click)="onOpenMemo()" class="tile" g2sLayout="column" g2sLayoutAlign="center center">
            <img draggable="false" src="../../../../assets/memo.svg" alt="Memo" />
            <p>{{ memoTextLabel }}</p>
        </div>
    </div>
</div>
