import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IDeelplan } from "src/app/model/deelplan.model";
import { IDeelplanChecklist } from "src/app/model/deelplan-checklist.model";
import { IDossier } from "src/app/model/dossier.model";
import { IHoofdgroep } from "src/app/model/hoofdgroep.model";
import { IWaardeoordeel } from "src/app/model/waardeoordeel.model";
import { VraagType, WaardeoordeelColor } from "src/app/model/enum.model";
import { UploadPhotoDialogComponent } from "../upload-photo-dialog/upload-photo-dialog.component";
import { DatePipe } from "@angular/common";
import {
    MatLegacyDialog as MatDialog,
    MatLegacyDialogConfig as MatDialogConfig,
} from "@angular/material/legacy-dialog";
import { DeelplanOpdrachtService } from "src/app/services/deelplan-opdracht.service";
import { DeelplanVraagService } from "src/app/services/deelplan-vraag.service";
import { SelectJudgementDialogComponent } from "../select-judgement-dialog/select-judgement-dialog.component";
import { IDeelplanVraag } from "src/app/model/deelplan-vraag.model";

@Component({
    selector: "app-hoofdgroep-dialog",
    templateUrl: "./hoofdgroep-dialog.component.html",
    styleUrls: ["./hoofdgroep-dialog.component.scss"],
})
export class HoofdgroepDialogComponent implements OnInit {
    @Input() dossier!: IDossier;
    @Input() deelplan!: IDeelplan;
    @Input() deelplanChecklist!: IDeelplanChecklist;
    @Input() hoofdgroep!: IHoofdgroep;
    @Input() translations!: any;

    saveLabel: string = "Opslaan";

    selectedWaardeoordeel?: IWaardeoordeel;

    files: any[] = [];

    isSaving: boolean = false;
    displayLoadingScreen: boolean = false;

    toelichting: string = "";

    greenWaardeoordeelOptions: IWaardeoordeel[] = [];
    orangeWaardeoordeelOptions: IWaardeoordeel[] = [];
    redWaardeoordeelOptions: IWaardeoordeel[] = [];

    @Output("closeDialog") closeDialog: EventEmitter<any> = new EventEmitter();

    constructor(
        private dialog: MatDialog,
        private deelplanVraagService: DeelplanVraagService,
        private deelplanOpdrachtService: DeelplanOpdrachtService,
        public datePipe: DatePipe
    ) {}

    ngOnInit(): void {
        this.determineWaardeOordeelOptions();
    }

    // Determines the waardeoordeel options
    // the waardeoordeel options which are available should be present in each vraag for this hoofdgroep.
    // If a invulvraag is present or no waardeoordeel matches waardeoordelen from other vragen, no waardeoordeel options should be available
    determineWaardeOordeelOptions() {
        // Initialse the options as empty arrays
        let redWaardeoordeelOptions: IWaardeoordeel[] = [];
        let greenWaardeoordeelOptions: IWaardeoordeel[] = [];
        let orangeWaardeoordeelOptions: IWaardeoordeel[] = [];

        if (this.hoofdgroep.vragen) {
            // Set the options according to the waardeoordeel options of the first vraag
            greenWaardeoordeelOptions =
                this.hoofdgroep.vragen[0].waardeoordeelOptions!.filter(
                    (wo) => wo.color == "green" && !wo.leadsToHercontrole && !wo.leadsToHercontrole
                ) ?? [];

            redWaardeoordeelOptions =
                this.hoofdgroep.vragen[0].waardeoordeelOptions!.filter(
                    (wo) => wo.color == "red" && !wo.leadsToHercontrole && !wo.leadsToHercontrole
                ) ?? [];

            orangeWaardeoordeelOptions =
                this.hoofdgroep.vragen[0].waardeoordeelOptions!.filter(
                    (wo) => wo.color == "orange" && !wo.leadsToHercontrole && !wo.leadsToHercontrole
                ) ?? [];

            // Check if there are more vragen
            if (this.hoofdgroep.vragen.length > 1) {
                // Loop over each vraag except the first one for which we already have gathered the options
                for (var i: number = 1; i < this.hoofdgroep.vragen.length; i++) {
                    // Get the waardeoordeel options for each color and do an intersection with the existing green waardeoordeel options
                    let greenOptions =
                        this.hoofdgroep.vragen[i].waardeoordeelOptions?.filter(
                            (wo) => wo.color == "green" && !wo.leadsToHercontrole && !wo.leadsToHercontrole
                        ) ?? [];
                    greenWaardeoordeelOptions = greenWaardeoordeelOptions.filter(
                        (x) => greenOptions.filter((w) => w.id == x.id).length > 0
                    );

                    let redOptions =
                        this.hoofdgroep.vragen[i].waardeoordeelOptions?.filter(
                            (wo) => wo.color == "red" && !wo.leadsToHercontrole && !wo.leadsToHercontrole
                        ) ?? [];
                    redWaardeoordeelOptions = redWaardeoordeelOptions.filter(
                        (x) => redOptions.filter((w) => w.id == x.id).length > 0
                    );

                    let orangeOptions =
                        this.hoofdgroep.vragen[i].waardeoordeelOptions?.filter(
                            (wo) => wo.color == "orange" && !wo.leadsToHercontrole && !wo.leadsToHercontrole
                        ) ?? [];
                    orangeWaardeoordeelOptions = orangeWaardeoordeelOptions.filter(
                        (x) => orangeOptions.filter((w) => w.id == x.id).length > 0
                    );
                }
            }
        }

        this.greenWaardeoordeelOptions = greenWaardeoordeelOptions;
        this.orangeWaardeoordeelOptions = orangeWaardeoordeelOptions;
        this.redWaardeoordeelOptions = redWaardeoordeelOptions;
    }

    // Determines whether the save button should be enabled or disabled
    isSaveButtonDisabled() {
        // If there are any waardeoordeel options, we'll require a waardeoordeel AND a toelichting to be set
        if (this.greenWaardeoordeelOptions || this.orangeWaardeoordeelOptions || this.redWaardeoordeelOptions) {
            if (!this.selectedWaardeoordeel && !this.toelichting) {
                return true;
            }
            // We only require a toelichting
        } else if (!this.toelichting) {
            return true;
        }

        return false;
    }

    // Handles the save event
    async onSaveClicked() {
        this.isSaving = true;
        this.displayLoadingScreen = true;

        if (this.hoofdgroep.vragen) {
            // Loop over each vraag
            for (let deelplanVraag of this.hoofdgroep.vragen) {
                // Skip all vragen which are not a meerkeuzevraag or invulvraag
                if (deelplanVraag.type !== VraagType.Meerkeuzevraag && deelplanVraag.type !== VraagType.Invulvraag) {
                    continue;
                }

                // Undo the hercontrole
                deelplanVraag.hercontrole = undefined;

                // Set the waardeoordeel and toelichting
                deelplanVraag.waardeoordeel = this.selectedWaardeoordeel
                    ? this.selectedWaardeoordeel
                    : deelplanVraag.defaultWaardeoordeel;
                deelplanVraag.toelichting = this.toelichting;

                // Save a new vraag revision for the current deelplan vraag in the loop
                await new Promise((resolve, reject) => {
                    this.deelplanVraagService.saveChanges(deelplanVraag).subscribe({
                        next: async (response) => {
                            if (response) {
                                // Check if any files are set
                                // Loop over each file and upload these according to the deelplan vraag revision
                                if (this.files) {
                                    for (let file of this.files) {
                                        await new Promise((resolve, reject) => {
                                            this.deelplanVraagService
                                                .uploadFile(response.deelplanVraagRevisionId!, file.file)
                                                .subscribe({
                                                    next: (response) => {
                                                        resolve(true);
                                                    },
                                                    error: (error) => {
                                                        reject(false);
                                                    },
                                                });
                                        });
                                    }
                                }
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        },
                        error: (error) => {
                            reject(false);
                        },
                    });
                });
            }
        }

        this.isSaving = false;
        this.displayLoadingScreen = false;

        this.closeDialog.emit(this.hoofdgroep);
    }

    // Handles the waardeoordeel selection
    selectWaardeoordeel(waardeoordeelOptions: IWaardeoordeel[]) {
        let dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = "waardeoordeel-popup";
        dialogConfig.data = { waardeoordeelOpties: waardeoordeelOptions, ignoreFollowUpActions: true };
        if (this.files) {
            dialogConfig.data.files = this.files;
        }
        let dialogRef = this.dialog.open(SelectJudgementDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(async (data) => {
            if (data != null) {
                this.selectedWaardeoordeel = data.waardeoordeel;
            }
        });
    }

    // Handles the file upload action
    onOpenFileUpload() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = "upload-photo-dialog";
        let dialogRef = this.dialog.open(UploadPhotoDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe({
            next: (data) => {
                this.processFiles(Array.from(data.files));
            },
        });
    }

    // Proces files which are selected through the dropzone or via regular file selection
    processFiles(files: File[]) {
        let count = 0;

        for (let file of files) {
            // With each file processing, max. 12 files are allowed.
            // If we reached 12 files, we'll break the loop.
            if (count >= 12) {
                break;
            }

            // Skip the current file if size larger than 10MB
            if (file.size > 10485760) {
                continue;
            }

            // If we're dealing with an pdf
            // We'll use a placeholder for displaying instead of the original image
            if (file.type.includes("pdf")) {
                this.files.push({
                    file: file,
                    content: "/assets/pdf_placeholder.png",
                });
            } else {
                var reader = new FileReader();

                reader.onload = (event: any) => {
                    this.files.push({
                        file: file,
                        content: event.target.result,
                    });
                };

                reader.readAsDataURL(file);
            }

            count++;
        }
    }

    // Deletes the file at given index
    deleteFile(index: number) {
        this.files.splice(index, 1);
    }

    // Handles the event of clicking on a file
    onFileClicked(file: any) {
        if (file.file.type.includes("pdf")) {
            var fileURL = URL.createObjectURL(file.file);
            window.open(fileURL);
        } else {
            var image = new Image();
            image.src = file.content;

            var w = window.open("");
            w?.document.write(image.outerHTML);
        }
    }

    // Handles the event of dropping files in the dropzone
    onDropFiles(event: any) {
        this.processFiles(event.addedFiles);
    }
}
