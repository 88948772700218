import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl, FormGroupDirective, NgForm, Validators } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { GenericPopupComponent } from "src/app/components/generic-popup/generic-popup.component";
import { IDossier } from "src/app/model/dossier.model";
import { IKlantOrganisation } from "src/app/model/klant-organisation.model";
import { DossierService } from "src/app/services/dossier.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { AddNewUitvoerendeDialogComponent } from "../add-new-uitvoerende-dialog/add-new-uitvoerende-dialog.component";
import { IBetrokkene } from "src/app/model/betrokkene.model";

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
    selector: "app-search-betrokkene-dialog",
    templateUrl: "./search-betrokkene-dialog.component.html",
    styleUrls: ["./search-betrokkene-dialog.component.scss"],
})
export class SearchBetrokkeneDialogComponent implements OnInit {
    translations: any;
    dossier?: IDossier;
    klantOrganisation?: IKlantOrganisation;

    displayLoadingScreen: boolean = false;

    emailFormControl = new UntypedFormControl("", [Validators.required, Validators.email]);
    matcher = new MyErrorStateMatcher();

    constructor(
        private dialogRef: MatDialogRef<SearchBetrokkeneDialogComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dossierService: DossierService,
        private snackbarService: SnackbarService
    ) {
        this.translations = data.translations;
        this.dossier = data.dossier;
        this.klantOrganisation = data.klantOrganisation;
    }

    ngOnInit(): void {}

    async performSearch() {
        this.displayLoadingScreen = true;

        let betrokkene = await new Promise((resolve, reject) => {
            this.dossierService
                .findBetrokkeneByEmailAndKlantOrganisation(
                    this.dossier!.id,
                    this.emailFormControl.value,
                    this.klantOrganisation!.id
                )
                .subscribe({
                    next: (result) => {
                        resolve(result);
                    },
                });
        });

        setTimeout(() => {
            this.displayLoadingScreen = false;

            if (betrokkene) {
                (betrokkene as IBetrokkene).klantOrganisationBetrokkeneId = (betrokkene as IBetrokkene).id!;

                this.dialogRef.close(betrokkene);
            } else {
                this.snackbarService.basicSnackBar(
                    this.translations?.Chepp_ChecklistView_NoBetrokkenenFoundForGivenEmail,
                    2500
                );

                const dialogRef = this.dialog.open(GenericPopupComponent, {
                    width: "95vw",
                    maxWidth: "600px",
                    panelClass: "generic-dialog",
                    backdropClass: "pop-up-background-dim",
                    data: {
                        title: this.translations?.Chepp_ChecklistView_NoBetrokkenenFoundForGivenEmail,
                        continue: this.translations?.Yes,
                        cancel: this.translations?.No,
                        text: this.translations?.Chepp_ChecklistView_DoYouWantToAddANewBetrokkene,
                    },
                });

                dialogRef.beforeClosed().subscribe({
                    next: (result) => {
                        if (result) {
                            const dialogRef = this.dialog.open(AddNewUitvoerendeDialogComponent, {
                                disableClose: true,
                                data: {
                                    translations: this.translations,
                                    klantOrganisations: this.klantOrganisation,
                                    email: this.emailFormControl.value,
                                },
                            });

                            dialogRef.beforeClosed().subscribe({
                                next: (result) => {
                                    if (result) {
                                        this.dialogRef.close(result);
                                    }
                                },
                            });
                        }
                    },
                });
            }
        }, 250);
    }
}
