import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, retry } from "rxjs/operators";
import { IDeelplan } from "../model/deelplan.model";
import { IDeelplanChecklist } from "../model/deelplan-checklist.model";
import { IDeelplanVraag } from "../model/deelplan-vraag.model";
import { IDossier } from "../model/dossier.model";
import { IHoofdgroep } from "../model/hoofdgroep.model";
import { IResponse } from "../model/response.model";
import { SnackbarService } from "./snackbar.service";
import { AbstractService } from "./abstract.service";
import { TokenService } from "./token.service";
import { IBetrokkene } from "../model/betrokkene.model";
import { IKlantOrganisation } from "../model/klant-organisation.model";

@Injectable({
    providedIn: "root",
})
export class DossierService extends AbstractService {
    constructor(public http: HttpClient, public snackbar: SnackbarService, tokenService: TokenService) {
        super(http, tokenService, snackbar);
    }

    getDossier(dossierId: number, internOrExtern: string) {
        return this.get(`/chepp/checklist/v1/dossiers/${dossierId}/${internOrExtern}`).pipe(
            map((res) => res.returnData as IDossier)
        );
    }

    getAllBetrokkenen(dossierId: number) {
        return this.get(`/chepp/checklist/v1/dossiers/${dossierId}/betrokkenen`).pipe(
            map((res) => res.returnData as IBetrokkene[])
        );
    }

    getExterneBetrokkenen(dossierId: number, klantOrganisationId?: number) {
        return this.get(
            `/chepp/checklist/v1/dossiers/${dossierId}/betrokkenen/extern` +
                (klantOrganisationId ? `/${klantOrganisationId}` : ``)
        ).pipe(map((res) => res.returnData as IBetrokkene[]));
    }

    getInterneBetrokkenen(dossierId: number) {
        return this.get(`/chepp/checklist/v1/dossiers/${dossierId}/betrokkenen/intern`).pipe(
            map((res) => res.returnData as IBetrokkene[])
        );
    }

    getBetrokkeneOrganisations(dossierId: number) {
        return this.get(`/chepp/checklist/v1/dossiers/${dossierId}/external/organisations`).pipe(
            map((res) => res.returnData as IKlantOrganisation[])
        );
    }

    findBetrokkeneOrganisations(dossierId: number, searchString: string) {
        return this.post(`/chepp/checklist/v1/dossiers/${dossierId}/external/organisations`, {
            searchString: searchString,
        }).pipe(map((res) => res.returnData as IKlantOrganisation[]));
    }

    findBetrokkeneByEmailAndKlantOrganisation(dossierId: number, email: string, klantOrganisationId: number) {
        return this.post(
            `/chepp/checklist/v1/dossiers/${dossierId}/external/organisations/${klantOrganisationId}/betrokkenen`,
            {
                email: email,
            }
        ).pipe(map((res) => res.returnData as IBetrokkene));
    }

    addOrganisation(dossierId: number, klantOrganisation: IKlantOrganisation) {
        return this.put(`/chepp/checklist/v1/dossiers/${dossierId}/external/organisations`, {
            klantOrganisation: klantOrganisation,
        }).pipe(map((res) => res.returnData as IKlantOrganisation));
    }

    addBetrokkene(dossierId: number, klantOrganisationId: number, betrokkene: IBetrokkene) {
        return this.put(
            `/chepp/checklist/v1/dossiers/${dossierId}/external/organisations/${klantOrganisationId}/betrokkenen`,
            {
                betrokkene,
            }
        ).pipe(map((res) => res.returnData as IBetrokkene));
    }
}
