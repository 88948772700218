import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, retry } from "rxjs/operators";
import { IDeelplan } from "../model/deelplan.model";
import { IDeelplanChecklist } from "../model/deelplan-checklist.model";
import { IDeelplanVraag } from "../model/deelplan-vraag.model";
import { IDossier } from "../model/dossier.model";
import { IHoofdgroep } from "../model/hoofdgroep.model";
import { IResponse } from "../model/response.model";
import { SnackbarService } from "./snackbar.service";
import { AbstractService } from "./abstract.service";
import { TokenService } from "./token.service";
import { IHercontrole } from "../model/hercontrole.model";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class DeelplanChecklistService extends AbstractService {
    private deelplanChecklist$ = new BehaviorSubject<IDeelplanChecklist>({} as IDeelplanChecklist);
    selectedDeelplanChecklist = this.deelplanChecklist$.asObservable();

    constructor(public http: HttpClient, public snackbar: SnackbarService, tokenService: TokenService) {
        super(http, tokenService, snackbar);
    }

    getDeelplanChecklist(deelplanId: number, checklistId: number, internOrExtern: string) {
        return this.get(`/chepp/checklist/v1/deelplan-checklisten/${deelplanId}/${checklistId}/${internOrExtern}`).pipe(
            map((res) => res.returnData as IDeelplanChecklist)
        );
    }

    setDeelplanChecklist(deelplanChecklist: IDeelplanChecklist) {
        this.deelplanChecklist$.next(deelplanChecklist);
    }

    getUniqueHercontroles(deelplanChecklistId: number) {
        return this.get(`/chepp/checklist/v1/deelplan-checklisten/${deelplanChecklistId}/hercontroles`).pipe(
            map((res) => res.returnData as IHercontrole[])
        );
    }
}
