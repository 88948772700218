<app-loading-screen [displayLoadingScreen]="displayLoadingScreen"></app-loading-screen>

<div class="wrapper" g2sLayout.gt-md="row" g2sLayout.lt-md="column" g2sLayoutAlign.gt-sm="space-evenly start">
    <!-- LEFT PANEL :: START -->
    <div g2sLayout="column" g2sFlex.gt-sm="26" g2sFlex.lt-sm="100" class="left-panel">
        <ng-container *ngIf="aandachtspunten && aandachtspunten.length > 0">
            <div class="leftside-panel">
                <div class="leftside-panel-title">{{ translations?.Aandachtspunten }}</div>
                <div class="leftside-panel-overflow">
                    <div
                        *ngFor="let aandachtspunt of aandachtspunten"
                        g2sLayout="row"
                        g2sLayoutAlign="start center"
                        class="lefside-panel-aandachtspunt">
                        <mat-icon>priority_high</mat-icon>
                        <!-- <p>{{ aandachtspunt.text }}</p> -->
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="richtlijnen && richtlijnen.length > 0">
            <div class="leftside-panel">
                <div class="leftside-panel-title">{{ translations?.Richtlijnen }}</div>
                <div class="leftside-panel-overflow">
                    <div *ngFor="let richtlijn of richtlijnen" class="leftside-panel-richtlijnen">{{ richtlijn }}</div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="grondslagen && grondslagen.length > 0">
            <div class="leftside-panel">
                <div class="leftside-panel-title">{{ translations?.Grondslagen }}</div>
                <div class="leftside-panel-overflow">
                    <div *ngFor="let grondslag of grondslagen" class="leftside-panel-grondslagen">
                        <!-- <p>{{ grondslag.id }}</p>
                            <p>{{ grondslag.name }}</p> -->
                        <div class="leftside-panel-divider"></div>
                        <mat-accordion>
                            <mat-expansion-panel #f hideToggle>
                                <mat-expansion-panel-header class="expansion-header">
                                    <mat-panel-title g2sLayout="row" g2sLayoutAlign="center center">
                                        <ng-container>
                                            <mat-icon *ngIf="!f.expanded; else collapse" class="expansion-icon">
                                                expand_more
                                            </mat-icon>
                                        </ng-container>
                                        <ng-template #collapse>
                                            <mat-icon class="expansion-icon">expand_less</mat-icon>
                                        </ng-template>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <!-- <p>{{ grondslag.text }}</p> -->
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <!-- LEFT PANEL :: END -->

    <!-- MAIN PANEL :: START -->
    <div class="main-panel" g2sFlex.gt-sm="45" class="main-panel">
        <div class="main-panel-content">
            <ng-container *ngIf="mode == 'opdracht'">
                <app-opdracht-dialog
                    [dossier]="dossier"
                    [deelplan]="deelplan"
                    [deelplanChecklist]="deelplanChecklist"
                    [hoofdgroep]="hoofdgroep"
                    [deelplanVraag]="deelplanVraag!"
                    [deelplanOpdracht]="deelplanOpdracht!"
                    [translations]="translations"
                    (closeDialog)="closeDialog($event)"></app-opdracht-dialog>
            </ng-container>
            <ng-container
                *ngIf="mode == 'vraag' && (deelplanVraag!.type == 'Invulvraag' || deelplanVraag!.type == 'Meerkeuzevraag')">
                <app-invul-meerkeuze-vraag-dialog
                    [dossier]="dossier"
                    [deelplan]="deelplan"
                    [deelplanChecklist]="deelplanChecklist"
                    [hoofdgroep]="hoofdgroep"
                    [deelplanVraag]="deelplanVraag!"
                    [translations]="translations"
                    (closeDialog)="closeDialog($event)"></app-invul-meerkeuze-vraag-dialog>
            </ng-container>
            <ng-container *ngIf="mode == 'vraag' && deelplanVraag!.type == 'Handtekening'">
                <app-handtekening-vraag-dialog
                    [dossier]="dossier"
                    [deelplan]="deelplan"
                    [deelplanChecklist]="deelplanChecklist"
                    [hoofdgroep]="hoofdgroep"
                    [deelplanVraag]="deelplanVraag!"
                    [translations]="translations"
                    (closeDialog)="closeDialog($event)"></app-handtekening-vraag-dialog>
            </ng-container>
            <ng-container *ngIf="mode == 'vraag' && deelplanVraag!.type == 'Datum/tijdpicker'">
                <app-date-time-picker-vraag-dialog
                    [dossier]="dossier"
                    [deelplan]="deelplan"
                    [deelplanChecklist]="deelplanChecklist"
                    [hoofdgroep]="hoofdgroep"
                    [deelplanVraag]="deelplanVraag!"
                    [translations]="translations"
                    (closeDialog)="closeDialog($event)"></app-date-time-picker-vraag-dialog>
            </ng-container>
            <ng-container *ngIf="mode == 'hoofdgroep'">
                <app-hoofdgroep-dialog
                    [dossier]="dossier"
                    [deelplan]="deelplan"
                    [deelplanChecklist]="deelplanChecklist"
                    [hoofdgroep]="hoofdgroep"
                    [translations]="translations"
                    (closeDialog)="closeDialog($event)"></app-hoofdgroep-dialog>
            </ng-container>
        </div>
    </div>
    <!-- MAIN PANEL :: END -->

    <!-- MAIN PANEL :: START -->
    <div g2sFlex.gt-sm="26" g2sFlex.lt-sm="100" class="right-panel">
        <ng-container *ngIf="showRiskAndPriority">
            <div class="risk-priority-panel">
                <p class="risk-title">{{ riskAndPriorityTextLabel }}</p>
                <p class="slider-title">{{ effectLabel }}</p>
                <mat-slider class="slider-style" step="1" min="0" max="6"></mat-slider>
                <p class="slider-sub-title">{{ efffectMessageLabel }}</p>

                <p class="slider-title">{{ waarschijnlijkheidTitleLabel }}</p>
                <mat-slider class="slider-style" step="1" min="0" max="7"></mat-slider>
                <p class="waarschijnlijkheid-sub-title">{{ waarschijnlijkheidLabel }}</p>

                <p class="slider-title">{{ blootstellingTitleLabel }}</p>
                <mat-slider class="slider-style" step="1" min="0" max="6"></mat-slider>
                <p class="waarschijnlijkheid-sub-title">{{ blootstellingLabel }}</p>

                <hr class="risk-horizontal-divider" />
                <div g2sLayout="row">
                    <div g2sFlex="45">
                        <p class="main-title">{{ risicoLabel }}</p>
                        <p class="prioriteit-value">{{ risicoValueLabel }}</p>
                        <p class="sub-title">{{ risicoDescriptionLabel }}</p>
                    </div>
                    <div g2sFlex="45" class="prio-column">
                        <p class="main-title">{{ prioriteitLabel }}</p>
                        <p class="prioriteit-value">{{ prioriteitValueLabel }}</p>
                    </div>
                </div>
                <hr class="risk-horizontal-divider" />
                <p class="conclusion-title">{{ conclusieTitleLabel }}</p>
                <p class="sub-title">{{ conclusieLabel }}</p>
                <p class="conclusion-title">{{ termijnTitleLabel }}</p>
                <p class="sub-title">{{ termijnLabel }}</p>
            </div>
        </ng-container>

        <ng-container *ngIf="verantwoordingsteksten && verantwoordingsteksten.length > 0">
            <div class="rightside-panel">
                <div class="rightside-panel-title">{{ translations?.Verantwoordingsteksten }}</div>
                <textarea class="form-control" wrap="off" placeholder="Zoeken..."></textarea>
                <mat-icon class="search-icon">search</mat-icon>
                <div g2sLayout="row" g2sLayoutAlign="start center" class="verantwoording-button">
                    <mat-icon class="msg-icon">message</mat-icon>
                    <div class="text">{{ verantwoordingsteksten }}</div>
                </div>
                <div g2sLayout="row" g2sLayoutAlign="start center" class="verantwoording-button">
                    <mat-icon class="msg-icon">message</mat-icon>
                    <div class="text">{{ verantwoordingsteksten }}</div>
                </div>
                <div g2sLayout="row" g2sLayoutAlign="start center" class="verantwoording-button">
                    <mat-icon class="msg-icon">message</mat-icon>
                    <div class="text">{{ verantwoordingsteksten }}</div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
