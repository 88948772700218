<div class="wrapper">
    <div class="maatregel-header" g2sLayout="row">
        <p class="maatregel-header-text">{{ mode == 'create' ? maatregelAddTitleLabel : maatregelEditTitleLabel }}</p>
        <mat-icon matDialogClose matRipple class="close-button">close</mat-icon>
    </div>

    <div class="bewerk-maatregel">
        <hr class="maatregel-horizontal-divider" />
        <form #f="ngForm" g2sLayout="column">
            <input
                class="input"
                [(ngModel)]="standaardMaatregel"
                type="text"
                placeholder="Maatregel"
                name="standaardMaatregel" />
            <input
                class="input"
                [disabled]="true"
                [(ngModel)]="deadline"
                type="text"
                placeholder="Deadline"
                name="deadline" />
            <input class="input" [(ngModel)]="door" type="text" placeholder="Door" name="door" />
            <div matRipple (click)="openStandaardMaatregel()" class="standaard-field">
                <p class="maatregel-text">{{ standaardMaatregelLabel }}</p>
            </div>
            <button
                class="maatregel-opslaan"
                mat-flat-button
                matDialogClose
                [disabled]="!standaardMaatregel || !door"
                type="submit">
                {{saveLabel}}
            </button>
        </form>
    </div>
</div>
