import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { SignaturePadComponent } from "@almothafar/angular-signature-pad";
import { IDeelplanVraag } from "src/app/model/deelplan-vraag.model";
import { IDeelplan } from "src/app/model/deelplan.model";
import { IDeelplanChecklist } from "src/app/model/deelplan-checklist.model";
import { IDossier } from "src/app/model/dossier.model";
import { IHoofdgroep } from "src/app/model/hoofdgroep.model";
import * as moment from "moment";
import { DeelplanVraagService } from "src/app/services/deelplan-vraag.service";

@Component({
    selector: "app-handtekening-vraag-dialog",
    templateUrl: "./handtekening-vraag-dialog.component.html",
    styleUrls: ["./handtekening-vraag-dialog.component.scss"],
})
export class HandtekeningVraagDialogComponent implements OnInit {
    @Input() dossier!: IDossier;
    @Input() deelplan!: IDeelplan;
    @Input() deelplanChecklist!: IDeelplanChecklist;
    @Input() hoofdgroep!: IHoofdgroep;
    @Input() deelplanVraag!: IDeelplanVraag;
    @Input() translations!: any;

    saveLabel: string = "Opslaan";

    isSaving: boolean = false;
    displayLoadingScreen: boolean = false;

    handtekening: any;

    files: any[] = [];

    @ViewChild(SignaturePadComponent) signaturePad!: SignaturePadComponent;
    @Output("closeDialog") closeDialog: EventEmitter<any> = new EventEmitter();

    constructor(private cdRef: ChangeDetectorRef, private deelplanVraagService: DeelplanVraagService) {}

    ngOnInit(): void {
        // Set the initial size
        setTimeout(() => {
            this.resizeSignaturePad();
        }, 0);
    }

    // Clear signature
    clearSignaturePad() {
        this.signaturePad.clear();
    }

    // (Re-)sizes the signature pad
    resizeSignaturePad() {
        if (this.signaturePad) {
            let canvas = this.signaturePad.getCanvas();
            let ctx = canvas.getContext("2d");
            let dataUrl = canvas.toDataURL();

            this.signaturePad.clear();

            canvas.width = document.getElementById("sign_canvas")!.offsetWidth;
            canvas.height = 300;

            var img = document.createElement("img");
            img.src = dataUrl;

            img.onload = function () {
                ctx!.drawImage(img, 0, 0);
            };

            ctx!.restore();
        }
    }

    // Determines whether the save button should be enabled
    isSaveButtonDisabled() {
        if (!this.signaturePad || (this.signaturePad && this.signaturePad.isEmpty())) {
            return true;
        }

        return false;
    }

    // Handles the save action
    async onSaveClicked() {
        // Activate loading
        this.isSaving = this.displayLoadingScreen = true;

        // Saves the deelplan vraag in order to create a new revision
        await new Promise((resolve, reject) => {
            this.deelplanVraagService.saveChanges(this.deelplanVraag).subscribe({
                next: async (response) => {
                    if (response) {
                        this.deelplanVraag = response;
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                },
                error: (error) => {
                    reject(false);
                },
            });
        });

        // Converst the canvas to an image and save it to the just created
        // deelplan vraag revision
        let result = await new Promise<boolean>((resolve, reject) => {
            this.signaturePad.getCanvas().toBlob((signature) => {
                let file = new File(
                    [signature as Blob],
                    "signature_" +
                        moment(Date.now()).format("DD-MM-YYYY") +
                        "_" +
                        this.deelplanVraag.deelplanVraagRevisionId +
                        ".png"
                );

                this.deelplanVraagService.uploadFile(this.deelplanVraag.deelplanVraagRevisionId!, file).subscribe({
                    next: (response) => {
                        this.deelplanVraag.files = [response];
                        resolve(true);
                    },
                    error: (error) => {
                        reject(false);
                    },
                });
            });
        });

        this.isSaving = this.displayLoadingScreen = false;

        if (result) {
            this.closeDialog.emit(this.deelplanVraag);
        }
    }
}
