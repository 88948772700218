<div g2sFlex="4" g2sLayout="row" class="pdf-annotator-toolbar" g2sFlex.xs="55" g2sLayoutAlign="center center">
    <div class="tools">
        <div class="tool">
            <div
                class="rotate"
                (click)="rotate()"
                [class.disabled]="(allowRotate == false) || (editable == false)"></div>
        </div>
    </div>
</div>
<div
    id="canvas-container"
    (mousedown)="mouseDownHandler($event)"
    (mouseup)="mouseUpHandler($event)"
    (mousemove)="mouseMoveHandler($event)"
    (mouseout)="mouseUpHandler($event)"
    #canvasContainer>
    <canvas #canvas (wheel)="mouseWheelHandler($event)"></canvas>
</div>
<ng-container>
    <div (click)="close()" class="screen-close-icon"></div>
    <div class="paging">
        <div class="paging-navigation">
            <mat-icon class="nav-prev" [attr.disabled]="pageNumber === 1" (click)="openPreviousPage()">
                keyboard_arrow_left
            </mat-icon>
            <span class="current-page">
                <input
                    class="pageNumber"
                    matInput
                    (change)="goToPage($event)"
                    type="text"
                    min="1"
                    max="{{ totalPageNumber }}"
                    value="{{ pageNumber }}" />
            </span>
            <span class="current-page">/</span>
            <span class="current-page">{{ totalPageNumber }}</span>
            <mat-icon class="nav-next" (click)="openNextPage()">keyboard_arrow_right</mat-icon>
        </div>
    </div>
</ng-container>
<!-- <app-spinner *ngIf="loading"></app-spinner> -->
