<div class="custom-dialog-header" g2sLayout="row" g2sLayoutAlign="space-between center">
    <h3 mat-dialog-title>{{ pictureAsDocumentLabel }}</h3>
    <svg
        style="cursor: pointer"
        height="3vh"
        (click)="onCancel()"
        viewBox="0 0 512 512"
        width="3vh"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <style>
                .cls-1 {
                    fill: #fff;
                }
            </style>
        </defs>
        <path
            class="cls-1"
            d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
    </svg>
</div>
<div class="custom-dialog-body" mat-dialog-content>
    <form #f="ngForm" novalidate>
        <div g2sLayout="row" g2sLayoutAlign="center start">
            <div class="input-group" g2sFlex="95">
                <input
                    class="input-text"
                    name="document-name"
                    type="text"
                    required
                    ngModel
                    #name="ngModel"
                    [class.required]="name.invalid && (name.dirty || name.touched)" />
            </div>
        </div>
    </form>
</div>
<div class="custom-dialog-footer" mat-dialog-actions>
    <button class="custom-dialog-button-cancel" (click)="onCancel()">{{ btnCancelLabel }}</button>
    <button class="custom-dialog-button-confirm" (click)="f.form.valid && onSubmit(f)">{{ btnSaveLabel }}</button>
</div>
