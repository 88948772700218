<div class="wrapper">
    <div>
        <p class="notification-text">{{ notificationLabel }}</p>
    </div>
    <div class="button-group">
        <button matDialogClose matRipple (click)="onAnswerJa()" class="popup-answer">{{ jaAnswerLabel }}</button>
        <button matDialogClose matRipple (click)="onAnswerAnnuleren()" class="popup-answer">
            {{ annulerenAnswerLabel }}
        </button>
    </div>
</div>
