import { Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { IBouwnummer } from "src/app/model/bouwnummer.model";
import { ILabel } from "../../../model/label.model";

@Component({
    selector: "app-filters-dialog",
    templateUrl: "./filters-dialog.component.html",
    styleUrls: ["./filters-dialog.component.scss"],
})
export class FiltersDialogComponent {
    selectedBouwnummers: number[] = [];
    selectedLabels: number[] = [];
    bouwnummersTitleLabel: string = "Bouwnummers:";

    // G2S-1157
    noBouwnummersLabel: string = "Geen bouwnummers beschikbaar om mee te filteren.";
    noLabelsLabel: string = "Geen labels beschikbaar om mee te filteren.";
    filterTextLabel: string = "Filters";

    constructor(private dialogRef: MatDialogRef<FiltersDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.selectedBouwnummers = [...this.data.selectedBouwnummers];
        this.selectedLabels = [...this.data.selectedLabels];
    }

    selectBouwnummer(bownummer: IBouwnummer) {
        if (!this.selectedBouwnummers.includes(bownummer.id)) {
            this.selectedBouwnummers.push(bownummer.id);
        } else {
            this.selectedBouwnummers.splice(this.selectedBouwnummers.indexOf(bownummer.id), 1);
        }
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    onCancel() {
        this.selectedBouwnummers.length = 0;
        this.selectedLabels.length = 0;
        this.dialogRef.close();
    }

    filterDocuments() {
        this.dialogRef.close({
            bouwnummers: this.selectedBouwnummers,
            labels: this.selectedLabels,
        });
    }

    selectLabel(labelId: number) {
        if (!this.selectedLabels.includes(labelId)) {
            this.selectedLabels.push(labelId);
        } else {
            this.selectedLabels.splice(this.selectedLabels.indexOf(labelId), 1);
        }
    }

    deselectAllBouwnummers() {
        this.selectedBouwnummers.length = 0;
    }

    selectAllBouwnummers() {
        this.selectedBouwnummers.length = 0;

        this.data.bouwnummers.forEach((element: IBouwnummer) => {
            this.selectedBouwnummers.push(element.id);
        });
    }

    deselectAllLabels() {
        this.selectedLabels.length = 0;
    }

    selectAllLabels() {
        this.selectedLabels.length = 0;

        let categorisationsLabels = this.data.categories.map((element: any, index: number) => {
            return element.categorisationLabels;
        });

        categorisationsLabels.forEach((categorisationLabels: any) => {
            categorisationLabels.forEach((label: any) => {
                this.selectedLabels.push(label.id);
            });
        });
    }

    deselectCategoryLabels(id: number) {
        const categoryLabels = this.data.categories.find((item: { id: number }) => item.id === id).categorisationLabels;
        categoryLabels.forEach((label: any) => {
            if (this.selectedLabels.indexOf(label.id) !== -1) {
                this.selectedLabels.splice(this.selectedLabels.indexOf(label.id), 1);
            }
        });
    }

    selectCategoryLabels(id: number) {
        const categoryLabels = this.data.categories.find((item: { id: number }) => item.id === id).categorisationLabels;
        categoryLabels.forEach((label: ILabel) => {
            if (this.selectedLabels.indexOf(label.id) === -1) {
                this.selectedLabels.push(label.id);
            }
        });
    }
}
