<div class="wrapper">
    <div class="maatregel-header" g2sLayout="column">
        <div g2sLayout="row">
            <p class="header-text">{{ vervolgactieTitleLabel }}</p>
            <mat-icon (click)="closeDialog()" matRipple class="close-button">close</mat-icon>
        </div>
        <hr class="hercontrole-horizontal-divider" />
    </div>
    <div g2sLayout="column">
        <div class="content-container" (click)="openHercontrole()">
            <p class="hercontrole-title">{{hercontroleTitleLabel}}<mat-icon class="icons">calendar_month</mat-icon></p>
        </div>
        <div class="content-container" (click)="openVervolgOpdracht()">
            <p class="vervolgopdracht-title">
                {{ vervolgopdrachtTitleLabel }}<mat-icon class="icons">assignment</mat-icon>
            </p>
        </div>
    </div>
</div>
