import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, retry } from "rxjs/operators";
import { IDeelplan } from "../model/deelplan.model";
import { IDeelplanChecklist } from "../model/deelplan-checklist.model";
import { IDeelplanVraag } from "../model/deelplan-vraag.model";
import { IDossier } from "../model/dossier.model";
import { IHoofdgroep } from "../model/hoofdgroep.model";
import { IResponse } from "../model/response.model";
import { SnackbarService } from "./snackbar.service";
import { AbstractService } from "./abstract.service";
import { TokenService } from "./token.service";
import { DatePipe } from "@angular/common";
import { IDeelplanOpdracht } from "../model/deelplan-opdracht.model";
import { IFile } from "../model/file.model";

@Injectable({
    providedIn: "root",
})
export class DeelplanOpdrachtService extends AbstractService {
    constructor(public http: HttpClient, public snackbar: SnackbarService, tokenService: TokenService) {
        super(http, tokenService, snackbar);
    }

    createVervolgOpdracht(deelplanVraagRevisie: number, betrokkeneId: number, opdrachtText: string, gereedDatum: Date) {
        return this.put(`/chepp/checklist/v1/vragen/${deelplanVraagRevisie}/opdrachten`, {
            betrokkeneId: Number(betrokkeneId),
            opdrachtText: opdrachtText,
            gereedDatum: new DatePipe("nl_NL").transform(gereedDatum, "dd-MM-yyyy"),
        }).pipe(map((res) => res.returnData as IDeelplanOpdracht));
    }

    getOpdracht(deelplanOpdrachtId: number) {
        return this.get(`/chepp/checklist/v1/opdrachten/${deelplanOpdrachtId}`).pipe(
            map((res) => res.returnData as IDeelplanOpdracht)
        );
    }

    addAttachment(deelplanOpdrachtId: number, file: File) {
        let formData: FormData = new FormData();
        formData.append("file", file, file.name);

        let headers = new HttpHeaders();
        headers.append("Content-Type", "multipart/form-data");
        headers.append("Accept", "application/json");

        return this.put(`/chepp/checklist/v1/opdrachten/${deelplanOpdrachtId}/attachments`, formData, {}, headers).pipe(
            map((res) => res.returnData)
        );
    }

    getAttachment(deelplanOpdrachtId: number, deelplanOpdrachtAttachmentId: number, thumbnail: boolean = false) {
        return new Promise((resolve, reject) => {
            this.httpClient
                .get(
                    `/chepp/checklist/v1/opdrachten/${deelplanOpdrachtId}/attachments/${deelplanOpdrachtAttachmentId}` +
                        (thumbnail ? `/thumbnail` : ``),
                    {
                        headers: new HttpHeaders({
                            Authorization: `Bearer ${this.tokenService.getAccessToken()}`,
                        }),
                        responseType: "blob",
                        reportProgress: true,
                        observe: "events",
                    }
                )
                .subscribe(
                    (event: HttpEvent<Blob>) => {
                        if (event.type === HttpEventType.Response) {
                            const blob = new Blob([event.body!], { type: "application/octet-stream" });
                            resolve(blob);
                        }
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    saveChanges(deelplanOpdracht: IDeelplanOpdracht) {
        let data: any = {
            toelichting: deelplanOpdracht.toelichting ?? "",
            origineleBeantwoordingsDatum: new DatePipe("nl_NL").transform(Date.now(), "dd-MM-yyyy HH:mm:ss"),
        };

        if (deelplanOpdracht.waardeoordeel) {
            data.waardeoordeelId = deelplanOpdracht.waardeoordeel.id;
        }

        return this.patch(`/chepp/checklist/v1/opdrachten/${deelplanOpdracht.id}`, data).pipe(
            map((res) => res.returnData as IDeelplanOpdracht)
        );
    }

    uploadFile(deelplanOpdrachtId: number, file: File) {
        let formData: FormData = new FormData();
        formData.append("file", file, file.name);

        let headers = new HttpHeaders();
        headers.append("Content-Type", "multipart/form-data");
        headers.append("Accept", "application/json");

        return this.put(`/chepp/checklist/v1/opdrachten/${deelplanOpdrachtId}/files`, formData, {}, headers).pipe(
            map((res) => res.returnData)
        );
    }
}
