import { Component, Inject, OnInit } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { IDeelplanOpdracht } from "src/app/model/deelplan-opdracht.model";
import { IHercontrole } from "src/app/model/hercontrole.model";
import { IWaardeoordeel } from "src/app/model/waardeoordeel.model";
import { AnswerHercontroleDialogComponent } from "../../refactor-answer-dialog/answer-hercontrole-dialog/answer-hercontrole-dialog.component";
import { NewOpdrachtDialogComponent } from "../new-opdracht-dialog/new-opdracht-dialog.component";
import { HercontroleVervolgopdrachtDialogComponent } from "./hercontrole-vervolg-dialog/hercontrole-vervolg-dialog.component";

@Component({
    selector: "app-select-judgement-dialog",
    templateUrl: "./select-judgement-dialog.component.html",
    styleUrls: ["./select-judgement-dialog.component.scss"],
})
export class SelectJudgementDialogComponent {
    waardeoordeelText: string = "";
    waardeoordeelOpties: IWaardeoordeel[] = [];
    selectedWaardeoordeel!: IWaardeoordeel;

    ignoreFollowUpActions: boolean = false;

    translations: any;

    constructor(
        private dialogRef: MatDialogRef<SelectJudgementDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialog
    ) {
        if (data.ignoreFollowUpActions) {
            this.ignoreFollowUpActions = true;
        }

        this.translations = data.translations;

        if (!data.hoofdgroepNiveau && data.currentWaardeoordeel) {
            this.waardeoordeelText =
                this.translations?.Waardeoordeel + ": " + (data.currentWaardeoordeel as IWaardeoordeel).text;
        } else {
            this.waardeoordeelText = this.translations?.Chepp_ChecklistView_ChooseAWaardeoordeel;
        }

        this.waardeoordeelOpties = data.waardeoordeelOpties;

        if (this.waardeoordeelOpties.length == 1) {
            this.onClickWaardeoordeel(this.waardeoordeelOpties[0]);
        }
    }

    onClickWaardeoordeel(waardeoordeel: IWaardeoordeel) {
        this.selectedWaardeoordeel = waardeoordeel;

        if (this.ignoreFollowUpActions) {
            this.dialogRef.close({ waardeoordeel: waardeoordeel });
        } else {
            if (waardeoordeel.leadsToHercontrole && !waardeoordeel.leadsToVervolgopdracht) {
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = false;

                dialogConfig.panelClass = "vraag-hercontrole-dialog";

                dialogConfig.data = {
                    translations: this.translations,
                };

                let dialogRef = this.dialog.open(AnswerHercontroleDialogComponent, dialogConfig);
                dialogRef.afterClosed().subscribe((hercontrole: IHercontrole) => {
                    if (hercontrole != null) {
                        this.dialogRef.close({
                            data: hercontrole,
                            type: "hercontrole",
                            waardeoordeel: this.selectedWaardeoordeel,
                        });
                    }
                });
            } else if (!waardeoordeel.leadsToHercontrole && waardeoordeel.leadsToVervolgopdracht) {
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;

                dialogConfig.panelClass = "vraag-hercontrole-dialog";

                dialogConfig.data = this.data;

                let dialogRef = this.dialog.open(NewOpdrachtDialogComponent, dialogConfig);
                dialogRef.afterClosed().subscribe((createdOpdracht: IDeelplanOpdracht) => {
                    if (createdOpdracht != null) {
                        this.dialogRef.close({
                            data: createdOpdracht,
                            type: "vervolgopdracht",
                            waardeoordeel: this.selectedWaardeoordeel,
                        });
                    }
                });
            } else if (waardeoordeel.leadsToHercontrole && waardeoordeel.leadsToVervolgopdracht) {
                this.openHercontroleVervolg();
            } else {
                this.dialogRef.close({ waardeoordeel: waardeoordeel });
            }
        }
    }

    // Shows pop-up to choose between Vervolgopdracht or Hercontrole toevoegen
    openHercontroleVervolg() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = false;
        dialogConfig.data = this.data;
        let dialogRef = this.dialog.open(HercontroleVervolgopdrachtDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((followUpAction: any) => {
            if (followUpAction != null && this.selectedWaardeoordeel != null) {
                followUpAction.waardeoordeel = this.selectedWaardeoordeel;
                this.dialogRef.close(followUpAction);
            }
        });
    }
}
