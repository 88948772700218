import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, retry } from "rxjs/operators";
import { IDeelplan } from "../model/deelplan.model";
import { IDeelplanChecklist } from "../model/deelplan-checklist.model";
import { IDeelplanVraag } from "../model/deelplan-vraag.model";
import { IDossier } from "../model/dossier.model";
import { IHoofdgroep } from "../model/hoofdgroep.model";
import { IResponse } from "../model/response.model";
import { SnackbarService } from "./snackbar.service";
import { AbstractService } from "./abstract.service";
import { TokenService } from "./token.service";

@Injectable({
    providedIn: "root",
})
export class DeelplanService extends AbstractService {
    constructor(public http: HttpClient, public snackbar: SnackbarService, tokenService: TokenService) {
        super(http, tokenService, snackbar);
    }

    getDeelplan(deelplanId: number, internOrExtern: string) {
        return this.get(`/chepp/checklist/v1/deelplannen/${deelplanId}/${internOrExtern}`).pipe(map((res) => res.returnData as IDeelplan));
    }
}
