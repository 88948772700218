import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { IFolder } from "src/app/model/folder.model";
import { NestedTreeControl } from "@angular/cdk/tree";
import { Subscription } from "rxjs";
import { IDocument } from "src/app/model/document.model";
import { IEntryData } from "src/app/model/entryData.model";
import { DocumentService } from "src/app/services/document.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: "app-documents-overview",
    templateUrl: "./documents-overview.component.html",
    styleUrls: ["./documents-overview.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class DocumentsOverviewComponent implements OnInit, OnDestroy {
    dossierId?: number;
    deelplanId?: number;
    checklistId?: number;

    alive = true;
    loading = true;
    showAllDocuments: boolean = true;
    folders!: IFolder[];
    files!: IDocument[];
    folderTreeControl = new NestedTreeControl<IFolder>((node) => node.children);
    folderDataSource = new MatTreeNestedDataSource<IFolder>();
    docsServiceSubscription: Subscription = new Subscription();
    nestedDocsSubscription: Subscription = new Subscription();
    entryDataServiceSubscription: Subscription = new Subscription();
    isWki: number = 0;
    currentFolderLevelId: number = 0;
    currentFolderName: string = "Root";
    entryData!: IEntryData;
    hasChild = (_: number, node: IFolder) => !!node.children && node.children.length > 0;
    noChildren: any;
    editable: number = 0;
    bouwnummersFilter: number[] = [];
    labelsFilter = [];
    openedFiles: IDocument[] = [];

    overviewTextLabel: string = "Overzicht";

    noDocLabel: string = "Geen documenten";
    noRecDocumentLabel: string = "Geen aanbevolen documenten";
    noDocOnCurrentFiltersLabel: string = "Er zijn geen aanbevolen documenten op basis van de huidige filters";

    constructor(
        public DocumentService: DocumentService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        this.activatedRoute.params.subscribe((params) => {
            this.dossierId = params.dossierId;
            this.deelplanId = params.deelplanId;
            this.checklistId = params.checklistId;
        });
        const self = this;

        // G2S-1290
        // Added visibilitychange eventhandler in order to update
        // the opened document its ticket count
        document.addEventListener("visibilitychange", function () {
            // Check if any documents are opened
            if (!document.hidden && self.openedFiles && self.openedFiles.length > 0) {
                // Loop over each openend document
                self.openedFiles.forEach((file: IDocument) => {
                    // Call the get getDocumentTicketCount endpoint
                    self.DocumentService.getDocumentTicketCount(
                        file.id,
                        self.entryData?.deelplanChecklistId,
                        self.entryData?.deelplanVraagId,
                        self.entryData?.bouwnummerId
                    ).subscribe((result: any) => {
                        if (result) {
                            // Update the ticket count
                            if (!file.bescheidenTicketKleurenTellingen) {
                                file.bescheidenTicketKleurenTellingen = { Rood: 0, Oranje: 0, Groen: 0 };
                            }

                            file.bescheidenTicketKleurenTellingen.Rood = result.Rood;
                            file.bescheidenTicketKleurenTellingen.Oranje = result.Oranje;
                            file.bescheidenTicketKleurenTellingen.Groen = result.Groen;
                        }
                    });
                });
            }
        });
    }

    ngOnInit(): void {
        this.entryDataServiceSubscription = this.DocumentService.entryData.subscribe((entryData) => {
            this.entryData = entryData;
            this.isWki = this.entryData?.isWki ?? true;
            this.editable = this.entryData?.editable ?? true;
            if (this.entryData?.bouwnummerId != null) {
                this.bouwnummersFilter.push(this.entryData.bouwnummerId);
            }
        });

        //Get the top-level folders and files to display when the page initializes
        this.docsServiceSubscription = this.DocumentService.getMainFoldersAndFiles(
            this.entryData?.dossierId,
            this.entryData?.deelplanChecklistId,
            this.showAllDocuments,
            [],
            [],
            this.entryData?.deelplanVraagId,
            this.entryData?.deelplanId,
            this.entryData?.checklistGroepId,
            this.entryData?.checklistId,
            this.entryData?.vraagId,
            this.entryData?.bouwnummerId
        ).subscribe((documents: any) => {
            if (
                (typeof documents.files !== "undefined" && documents.files.length > 0) ||
                (typeof documents.folders !== "undefined" && documents.folders.length > 0)
            ) {
                this.folders = documents.folders;
                this.files = documents.files[0];
                this.folderDataSource.data = documents.folders;
                this.currentFolderLevelId = 0;
            }

            this.loading = false;
        });
    }

    onSwitchDocsType() {
        this.showAllDocuments = !this.showAllDocuments;
        this.updateList();
    }

    updateList() {
        let filteredBouwnummers = this.bouwnummersFilter;
        let filteredLables = this.labelsFilter;
        this.loading = true;
        let oldSelectedNode: HTMLElement = <HTMLElement>(
            document.getElementById("folder-node-" + this.currentFolderLevelId)
        );

        if (this.currentFolderLevelId !== 0) {
            if (oldSelectedNode.classList != null) {
                if (oldSelectedNode.classList.contains("tree-node-selected")) {
                    oldSelectedNode.classList.remove("tree-node-selected");
                }
            }
        }
        this.currentFolderLevelId = 0;
        this.currentFolderName = "Root";

        if (this.showAllDocuments) {
            filteredBouwnummers = [];
            filteredLables = [];
        }

        //Get the top-level files after everything else has been reset
        this.docsServiceSubscription = this.DocumentService.getMainFoldersAndFiles(
            this.entryData?.dossierId,
            this.entryData?.deelplanChecklistId,
            this.showAllDocuments,
            filteredBouwnummers,
            filteredLables,
            this.entryData?.deelplanVraagId,
            this.entryData?.deelplanId,
            this.entryData?.checklistGroepId,
            this.entryData?.checklistId,
            this.entryData?.vraagId,
            this.entryData?.bouwnummerId
        ).subscribe((documents: any) => {
            this.files = documents.files[0];
            this.loading = false;
        });
    }

    onNavigateTopFolder() {
        this.updateList();
    }

    onSelectFolder(mapId: number, folderName: string) {
        this.loading = true;
        let oldSelectedNode: HTMLElement = <HTMLElement>(
            document.getElementById("folder-node-" + this.currentFolderLevelId)
        );
        let newSelectedNode: HTMLElement = <HTMLElement>document.getElementById("folder-node-" + mapId);

        if (this.currentFolderLevelId !== 0) {
            if (oldSelectedNode.classList != null) {
                if (oldSelectedNode.classList.contains("tree-node-selected")) {
                    oldSelectedNode.classList.remove("tree-node-selected");
                }
            }
        }

        newSelectedNode.classList.add("tree-node-selected");

        //Keep track of the folder that the user just selected
        this.currentFolderLevelId = mapId;
        this.currentFolderName = folderName;

        //Get the nested files
        this.nestedDocsSubscription = this.DocumentService.getNestedFiles(
            this.entryData?.dossierId,
            this.entryData?.deelplanChecklistId,
            mapId,
            this.showAllDocuments,
            this.entryData?.deelplanVraagId,
            this.entryData?.deelplanId,
            this.entryData?.checklistGroepId,
            this.entryData?.checklistId,
            this.entryData?.vraagId,
            this.entryData?.bouwnummerId
        ).subscribe((nestedFiles: any) => {
            this.files = nestedFiles;
            this.loading = false;
        });
    }

    ngOnDestroy() {
        this.alive = false;
        this.docsServiceSubscription.unsubscribe();
        this.entryDataServiceSubscription.unsubscribe();
        this.nestedDocsSubscription.unsubscribe();
    }

    onSelectFile(file: IDocument) {
        this.router.navigate([`/pdf-annotator/${this.dossierId}/${this.deelplanId}/${this.checklistId}`]);
    }
}
