import { Component } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { IDeelplanOpdracht } from "src/app/model/deelplan-opdracht.model";
import { ConfirmDelegatedOpdrachtDialogComponent } from "./confirm-delegated-opdracht-dialog/confirm-delegated-opdracht-dialog.component";

@Component({
    selector: "app-delegated-opdracht-dialog",
    templateUrl: "./delegated-opdracht-dialog.component.html",
    styleUrls: ["./delegated-opdracht-dialog.component.scss"],
})
export class DelegatedOpdrachtDialogComponent {
    opdrachtDelegated: boolean = false;

    opdrachtTerughalenLabel: string = "Opdracht terughalen";
    opdrachtTitleLabel: string = "Opdracht";
    opdrachtOpmerkingLabel: string = "Please fix this WKI test opdracht";
    tempDummyDate = "12-12-2012";

    //dummy data
    voornaam: string = "GO";
    achternaam: string = "2Sure";
    email: string = "GO.2Sure@go2sure.nl";

    delegatedToLabel: string = "Deze opdracht is gedelegeerd aan:";
    retrieveLabel: string = `U kunt de opdracht terughalen door op de button 'Opdracht terughalen' te klikken.`;

    createdOpdracht!: IDeelplanOpdracht | null;

    constructor(private dialog: MatDialog, private dialogRef: MatDialogRef<DelegatedOpdrachtDialogComponent>) {}

    opdrachtTerughalen() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = "retrieve-maatregel-dialog";
        let dialogRef = this.dialog.open(ConfirmDelegatedOpdrachtDialogComponent);
        dialogRef.afterClosed().subscribe((confirm: boolean) => {
            if (confirm) {
                this.dialogRef.close();
            }
        });
    }
}
