<!-- menu bar -->
<app-menu-bar></app-menu-bar>
<div class="img-banner"></div>
<div g2sLayout="column" g2sLayoutAlign="center center">
    <div class="dashboard">
        <!-- knoppen voor het switchen van filter en search -->
        <mat-button-toggle-group g2sLayout="row" g2sLayoutAlign="space-between center">
            <mat-button-toggle class="dashboard-button" g2sFlex="49%" color="primary" (click)="filterSwitch()">
                <mat-icon>filter_list</mat-icon>{{ filterButtonLabel }}
            </mat-button-toggle>
            <mat-button-toggle class="dashboard-button" g2sFlex="49%" color="primary" (click)="searchSwitch()">
                <mat-icon>filter_list</mat-icon>{{ searchButtonLabel }}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <!-- checker of switch/filter weergeven moet worden -->
    <div *ngIf="state === stateEnum.Filter" class="dashboard">
        <app-dossier-filter></app-dossier-filter>
    </div>
    <!-- checker of switch/filter weergeven moet worden -->
    <div *ngIf="state === stateEnum.Search" class="dashboard">
        <app-dossier-search></app-dossier-search>
    </div>
    <!-- dossier view -->
    <app-dossier-view class="dashboard"></app-dossier-view>
</div>
