import { Component, Input } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { RapportageDialogComponent } from "./dialog/rapportage-dialog/rapportage-dialog.component";

@Component({
    selector: "app-menu-bar-checklist",
    templateUrl: "./menu-bar-checklist.component.html",
    styleUrls: ["./menu-bar-checklist.component.scss"],
})
export class MenuBarChecklistComponent {
    @Input() dossierId?: number;
    @Input() showRapportage?: boolean = false;

    constructor(public dialog: MatDialog) {}

    //opent rapportage pop-up dialog die rapportages meekrijgt als data
    openRapportage() {
        this.dialog.open(RapportageDialogComponent, {
            data: this.dossierId,
            restoreFocus: false,
            autoFocus: false,
        });
    }
}
