import { Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { DeelplanVraagService } from "src/app/services/deelplan-vraag.service";

@Component({
    selector: "app-standaard-opdrachten-dialog.ts",
    templateUrl: "./standaard-opdrachten-dialog.component.html",
    styleUrls: ["./standaard-opdrachten-dialog.component.scss"],
})
export class StandaardOpdrachtDialogComponent {
    standaardOpdrachtentitle: string = "Standaardopdrachten";

    // standaardOpdrachten: string = "Standaard opdracht\n";
    //opdrachten = Array.from({ length: 10 }).map((_, i) => `opdracht #${i}`);
    opdrachten: any[] = [];

    translations: any;

    constructor(
        private dialogRef: MatDialogRef<StandaardOpdrachtDialogComponent>,
        private deelplanVraagService: DeelplanVraagService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        dialogRef.disableClose = true;

        this.translations = this.data.translations;

        this.deelplanVraagService.selectedDeelplanVraag.subscribe({
            next: (deelplanVraag) => {
                if (deelplanVraag) {
                    this.deelplanVraagService.getDefaultOpdrachten(deelplanVraag.id).subscribe({
                        next: (opdrachten) => {
                            this.opdrachten = opdrachten;
                        },
                    });
                }
            },
        });
    }

    onclickStandaardOpdracht(opdracht: any) {
        this.dialogRef.close(opdracht.opdracht);
    }
}
