import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, retry } from "rxjs/operators";
import { IDeelplan } from "../model/deelplan.model";
import { IDeelplanChecklist } from "../model/deelplan-checklist.model";
import { IDeelplanVraag } from "../model/deelplan-vraag.model";
import { IDossier } from "../model/dossier.model";
import { IHoofdgroep } from "../model/hoofdgroep.model";
import { IResponse } from "../model/response.model";
import { SnackbarService } from "./snackbar.service";
import { AbstractService } from "./abstract.service";
import { TokenService } from "./token.service";

@Injectable({
    providedIn: "root",
})
export class HoofdgroepService extends AbstractService {
    constructor(public http: HttpClient, public snackbar: SnackbarService, tokenService: TokenService) {
        super(http, tokenService, snackbar);
    }

    getHoofdgroepen(deelplanId: number, checklistId: number, forChecklistWithOpdrachten: boolean = false) {
        return this.post(`/chepp/checklist/v1/hoofdgroepen`, {
            deelplanId: Number(deelplanId),
            checklistId: Number(checklistId),
            includeHoofdgroepColor: true,
            includeCanAnswerOnHoofdgroepLevel: true,
            forChecklistWithOpdrachten: forChecklistWithOpdrachten,
        }).pipe(map((res) => res.returnData as IHoofdgroep));
    }

    getHoofdgroep(
        deelplanId: number,
        checklistId: number,
        hoofdgroepId: number,
        forChecklistWithOpdrachten: boolean = false
    ) {
        return this.post(`/chepp/checklist/v1/hoofdgroepen/${hoofdgroepId}`, {
            deelplanId: Number(deelplanId),
            checklistId: Number(checklistId),
            includeHoofdgroepColor: true,
            includeCanAnswerOnHoofdgroepLevel: true,
            forChecklistWithOpdrachten: forChecklistWithOpdrachten,
        }).pipe(map((res) => res.returnData as IHoofdgroep[]));
    }
}
