import { Component, Inject } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";

@Component({
    selector: "app-wki-documents-filter-dialog.ts",
    templateUrl: "./wki-documents-filter-dialog.component.html",
    styleUrls: ["./wki-documents-filter-dialog.component.scss"],
})
export class WkiDocumentsFilterDialogComponent {
    headerTextLabel: string = "Filters";
    bouwnummerTitleLabel: string = "Bouwnummers:";
    labelsTitleLabel: string = "Labels:";
    subcategoryTitleLabel: string = "Brandveiligheid:";

    checked: boolean = false;

    bouwnummers = Array.from({ length: 6 }).map((_, i) => `bouwnummer #${i}`);
    bouwnummerLabel: string = "002";
    subcategory = Array.from({ length: 3 }).map((_, i) => `subcategory #${i}`);

    categories = [
        { name: "Brandveiligheid", labels: this.subcategory },
        { name: "Constructieve Veiligheid", labels: this.subcategory },
    ];

    subcategoryLabel: string = "Brandoverslagberekeningen";

    selectedBouwnummers: string[] = [];
    selectedSubcategory: string[] = [];

    constructor(
        private dialogRef: MatDialogRef<WkiDocumentsFilterDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        dialogRef.disableClose = true;
        // Check if not null
        if (data != null && data.selectedFilters != null) {
            // Check if not null and greater than 0
            if (
                data.selectedFilters.selectedBouwnummers != null &&
                data.selectedFilters.selectedBouwnummers.length > 0
            ) {
                // Set the active selected bouwnummers
                this.selectedBouwnummers = data.selectedFilters.selectedBouwnummers;
            }

            if (
                data.selectedFilters.selectedSubcategory != null &&
                data.selectedFilters.selectedSubcategory.length > 0
            ) {
                this.selectedSubcategory = data.selectedFilters.selectedSubcategory;
            }
        }
    }

    setAllBouwnummersActive() {
        if (this.bouwnummers != null && this.bouwnummers.length > 0) {
            this.bouwnummers.forEach((bouwnummer: string) => {
                // Check if not yet already present in the array of bouwnummers
                if (!this.selectedBouwnummers.includes(bouwnummer)) {
                    // If not add it
                    this.selectedBouwnummers.push(bouwnummer);
                }
            });
        }
    }

    bouwnummerLabelClick(bouwnummer: string) {
        if (this.selectedBouwnummers.includes(bouwnummer)) {
            // If the mat-button is already selected, remove it from the list
            this.selectedBouwnummers = this.selectedBouwnummers.filter((selected) => selected !== bouwnummer);
        } else {
            // If the mat-button is not selected, add it to the list
            this.selectedBouwnummers.push(bouwnummer);
        }
    }

    subcategoryLabelClick(subcategory: string) {
        if (this.selectedSubcategory.includes(subcategory)) {
            // If the mat-button is already selected, remove it from the list
            this.selectedSubcategory = this.selectedSubcategory.filter((selected) => selected !== subcategory);
        } else {
            // If the mat-button is not selected, add it to the list
            this.selectedSubcategory.push(subcategory);
        }
    }

    setAllBouwnummersInactive() {
        this.selectedBouwnummers = [];
    }

    setAllCategoriesActive() {
        this.categories.forEach((category) => {
            this.subcategory.forEach((subcategory) => {
                this.selectedSubcategory.push(subcategory);
            });
        });
    }

    setAllCategoriesInactive() {
        this.selectedSubcategory = [];
    }

    setSubcategoryActive() {
        if (this.subcategory != null && this.subcategory.length > 0) {
            this.subcategory.forEach((subcategory: string) => {
                // Check if not yet already present in the array of bouwnummers
                if (!this.selectedSubcategory.includes(subcategory)) {
                    // If not add it
                    this.selectedSubcategory.push(subcategory);
                }
            });
        }
    }

    setSubcategoryInactive() {
        this.selectedSubcategory = [];
    }

    onBouwnummerToggleChanged(event: any): void {
        // Check if the passed bouwnummer is not yet in the array of selected bouwnummers
        if (!this.selectedBouwnummers.includes(event.value)) {
            this.selectedBouwnummers.push(event.value);
        } else {
            // Get the index of the bouwnummer in the selected bouwnummers array
            let index = this.selectedBouwnummers.indexOf(event.value);

            // Check if valid index
            if (index != -1) {
                this.selectedBouwnummers.splice(index, 1);
            }
        }
    }

    onSubcategoryToggleChanged(event: any): void {
        if (!this.selectedSubcategory.includes(event.value)) {
            this.selectedSubcategory.push(event.value);
        } else {
            let index = this.selectedSubcategory.indexOf(event.value);
            if (index != -1) {
                this.selectedSubcategory.splice(index, 1);
            }
        }
    }

    determineIfSubcategoryIsSelected(subcategory: string): boolean {
        let checked: boolean = false;

        if (this.selectedSubcategory.includes(subcategory)) {
            checked = true;
        }
        return checked;
    }

    determineIfBouwnummerIsSelected(bouwnummer: string): boolean {
        let checked: boolean = false;

        if (this.selectedBouwnummers.includes(bouwnummer)) {
            checked = true;
        }
        return checked;
    }

    filterDocuments() {
        let selectedBouwnummers = this.selectedBouwnummers;
        let selectedSubcategory = this.selectedSubcategory;

        this.dialogRef.close({ selectedBouwnummers, selectedSubcategory });
    }
}
