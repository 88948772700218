<div class="component-wrapper">
    <div g2sLayout="column" g2sFlex="grow">
        <app-header [folder-name]="currentFolderName"> </app-header>
        <div class="docs-main-container" id="docsContainer" g2sLayout="column" g2sLayoutAlign="space-evenly center">
            <div class="breadcrumb-divider">
                <hr class="horizontal-divider" />
            </div>
            <div class="docs-filesystem-container" g2sLayout="row" g2sLayoutAlign="space-between start" cdkDropListGroup>
                <div class="docs-folder-container" g2sLayout="column" g2sLayoutAlign="start center" g2sFlex="19">
                    <div class="docs-top-folder-buttons" g2sLayout="column" g2sLayoutAlign=" center">
                        <div
                            class="docs-switch-container"
                            g2sLayout="row"
                            g2sLayoutAlign="space-around center"
                            *ngIf="isWki">
                            <p class="docs-switch-text">
                                {{showAllDocuments ? 'Alle documenten' : 'Aanbevolen documenten'}}
                            </p>
                            <mat-slide-toggle class="docs-switch" (change)="onSwitchDocsType()"></mat-slide-toggle>
                        </div>
                        <div
                            class="docs-navigate-top-container"
                            g2sLayout="row"
                            g2sLayoutAlign="center center"
                            (click)="onNavigateTopFolder()">
                            <mat-icon class="docs-navigate-top-icon">home</mat-icon>
                            <p class="docs-navigate-top-text">Overzicht</p>
                        </div>
                    </div>
                    <div class="folder-overflow-container" g2sFlex="100">
                        <mat-tree
                            [dataSource]="folderDataSource"
                            [treeControl]="folderTreeControl"
                            class="example-tree">
                            <mat-tree-node
                                id="folder-node-{{node.id}}"
                                *matTreeNodeDef="let node"
                                matTreeNodeToggle
                                (click)="onSelectFolder(node.id, node.naam)">
                                <button class="tree-icon-button" mat-icon-button #noChildren>
                                    <mat-icon style="color: #eaeef1" class="mat-icon-close-custom"> close </mat-icon>
                                </button>
                                <button class="docs-navigate-top-icon" mat-icon-button #noChildren>
                                    <mat-icon [ngStyle]="{'color': node.aantal_documenten > 0 ? '#336699' : '#838383'}">
                                        folder
                                    </mat-icon>
                                </button>
                                <p class="file-list">{{node.naam}}</p>
                            </mat-tree-node>
                            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                                <div
                                    class="mat-tree-node"
                                    id="folder-node-{{node.id}}"
                                    (click)="onSelectFolder(node.id, node.naam)">
                                    <button
                                        class="tree-icon-button"
                                        mat-icon-button
                                        [attr.aria-label]="'Toggle' + node.naam"
                                        matTreeNodeToggle>
                                        <mat-icon
                                            [ngStyle]="{'color': node.aantal_documenten > 0 ? '#FF9800' : '#FF9800'}">
                                            {{folderTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                        </mat-icon>
                                    </button>
                                    <button
                                        class="tree-icon-button"
                                        mat-icon-button
                                        [attr.aria-label]="'Toggle' + node.naam"
                                        matTreeNodeToggle>
                                        <mat-icon
                                            [ngStyle]="{'color': node.aantal_documenten > 0 ? '#FF9800' : '#FF9800'}">
                                            {{folderTreeControl.isExpanded(node) ? 'folder_open' : 'folder'}}
                                        </mat-icon>
                                    </button>
                                    <p style="font-size: 1.5vh">{{node.naam}}</p>
                                </div>
                                <div [class.tree-invisible]="!folderTreeControl.isExpanded(node)" role="group">
                                    <ng-container matTreeNodeOutlet></ng-container>
                                </div>
                            </mat-nested-tree-node>
                        </mat-tree>
                    </div>
                </div>
                <div g2sLayout="row wrap" g2sLayoutAlign="start start" g2sFlex class="mat-tab-container">
                    <mat-tab-group mat-stretch-tabs style="width: 100%">
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24">
                                    <defs>
                                        <clipPath id="clip-list_view_white">
                                            <rect width="24" height="24" />
                                        </clipPath>
                                    </defs>
                                    <g id="list_view_white" clip-path="url(#clip-list_view_white)">
                                        <g id="list_white" transform="translate(1 -3.4)">
                                            <g id="Group_3" data-name="Group 3" transform="translate(0 6.4)">
                                                <circle
                                                    id="Ellipse_1"
                                                    data-name="Ellipse 1"
                                                    cx="2.425"
                                                    cy="2.425"
                                                    r="2.425"
                                                    fill="#fff" />
                                                <circle
                                                    id="Ellipse_2"
                                                    data-name="Ellipse 2"
                                                    cx="2.425"
                                                    cy="2.425"
                                                    r="2.425"
                                                    transform="translate(0 6.674)"
                                                    fill="#fff" />
                                                <circle
                                                    id="Ellipse_3"
                                                    data-name="Ellipse 3"
                                                    cx="2.425"
                                                    cy="2.425"
                                                    r="2.425"
                                                    transform="translate(0 13.549)"
                                                    fill="#fff" />
                                                <g id="Group_2" data-name="Group 2" transform="translate(7.186 0.823)">
                                                    <path
                                                        id="Path_5"
                                                        data-name="Path 5"
                                                        d="M32.745,13.281H45.916a.456.456,0,0,0,.445-.445V10.545a.446.446,0,0,0-.445-.445H32.745a.446.446,0,0,0-.445.445v2.292A.446.446,0,0,0,32.745,13.281Z"
                                                        transform="translate(-32.3 -10.1)"
                                                        fill="#fff" />
                                                    <path
                                                        id="Path_6"
                                                        data-name="Path 6"
                                                        d="M45.916,40.2H32.745a.446.446,0,0,0-.445.445v2.292a.446.446,0,0,0,.445.445H45.916a.456.456,0,0,0,.445-.445V40.645A.446.446,0,0,0,45.916,40.2Z"
                                                        transform="translate(-32.3 -33.503)"
                                                        fill="#fff" />
                                                    <path
                                                        id="Path_7"
                                                        data-name="Path 7"
                                                        d="M45.916,71.1H32.745a.446.446,0,0,0-.445.445v2.291a.446.446,0,0,0,.445.445H45.916a.456.456,0,0,0,.445-.445V71.545A.446.446,0,0,0,45.916,71.1Z"
                                                        transform="translate(-32.3 -57.529)"
                                                        fill="#fff" />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                Lijst
                            </ng-template>
                            <ng-container *ngIf="files; else noFiles">
                                <div
                                    class="docs-files-container"
                                    g2sLayout="column wrap"
                                    g2sLayoutAlign="start start"
                                    g2sFlex>
                                    <app-documents-list
                                        [files]="files"
                                        [isWki]="isWki"
                                        g2sLayout="column"
                                        g2sLayoutAlign="start stretch"
                                        g2sFlex="23"></app-documents-list>
                                </div>
                            </ng-container>
                            <ng-template #noFiles>
                                <ng-container *ngIf="showAllDocuments else showFilteredDocuments">
                                    <div g2sLayout="row wrap" g2sLayoutAlign="center center" g2sFlex>
                                        <span class="no-documents">Geen documenten</span>
                                    </div>
                                </ng-container>
                                <ng-template #showFilteredDocuments>
                                    <div g2sLayout="row wrap" g2sLayoutAlign="center center" g2sFlex>
                                        <span class="no-documents"
                                            >Er zijn geen aanbevolen documenten op basis van de huidige filters</span
                                        >
                                    </div>
                                </ng-template>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="Thumbnails">
                            <ng-template mat-tab-label>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24">
                                    <defs>
                                        <clipPath id="clip-thumbnail_view_white">
                                            <rect width="24" height="24" />
                                        </clipPath>
                                    </defs>
                                    <g id="thumbnail_view_white" clip-path="url(#clip-thumbnail_view_white)">
                                        <g id="Group_1" data-name="Group 1" transform="translate(0 1)">
                                            <rect
                                                id="Rectangle_3"
                                                data-name="Rectangle 3"
                                                width="9"
                                                height="9"
                                                rx="1"
                                                transform="translate(2 12)"
                                                fill="#fff" />
                                            <rect
                                                id="Rectangle_5"
                                                data-name="Rectangle 5"
                                                width="9"
                                                height="9"
                                                rx="1"
                                                transform="translate(13 12)"
                                                fill="#fff" />
                                            <rect
                                                id="Rectangle_6"
                                                data-name="Rectangle 6"
                                                width="9"
                                                height="9"
                                                rx="1"
                                                transform="translate(2 1)"
                                                fill="#fff" />
                                            <rect
                                                id="Rectangle_7"
                                                data-name="Rectangle 7"
                                                width="9"
                                                height="9"
                                                rx="1"
                                                transform="translate(13 1)"
                                                fill="#fff" />
                                        </g>
                                    </g>
                                </svg>
                                Thumbnails
                            </ng-template>
                            <ng-container *ngIf="files; else noFiles">
                                <div
                                    class="docs-files-container"
                                    id="docsFilesContainer"
                                    g2sLayout="row wrap"
                                    g2sLayoutAlign="start start"
                                    g2sFlex>
                                    <app-documents-thumbnails
                                        [file]="file"
                                        *ngFor="let file of files"
                                        g2sLayout="row"
                                        g2sLayoutAlign="start stretch"
                                        g2sFlex="23"></app-documents-thumbnails>
                                </div>
                            </ng-container>
                            <ng-template #noFiles>
                                <div g2sLayout="row wrap" g2sLayoutAlign="center center" g2sFlex class="no-documents">
                                    <span>Geen aanbevolen documenten</span>
                                </div>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <app-spinner *ngIf="loading"></app-spinner> -->
