<form class="form-style" [formGroup]="passwordModal" (ngSubmit)="onSubmit()">
    <div g2sLayout="row" g2sLayoutAlign="space-between start">
        <h1 class="header-text" mat-dialog-title>{{ passwordDialogTitleText }}</h1>
        <mat-icon matRipple class="exit-button" (click)="closeDialog()" aria-hidden="false" aria-label="close">
            close
        </mat-icon>
    </div>
    <hr />
    <div mat-dialog-content>{{ passwordDialogContentText }}</div>
    <div g2sLayout="row" g2sLayoutAlign="end end" mat-dialog-actions>
        <mat-form-field g2sFlex appearance="fill">
            <mat-label>{{ emailText }}</mat-label>
            <input g2sFlex formControlName="email" autocomplete="off" matInput required />
        </mat-form-field>
        <button (click)="resetToken()" mat-flat-button g2sFill color="primary" type="submit" class="btn-block">
            {{ passwordResetBtnText }}
        </button>
        <button
            style="padding-left: 5px; padding-right: 5px; margin-top: 2px"
            (click)="openTokenDialog()"
            mat-button
            color="warn"
            type="button">
            {{ passwordTokenBtnText }}
        </button>
    </div>
</form>
