import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { SnackbarService } from "src/app/services/snackbar.service";

@Component({
    selector: "app-add-hercontroledatum-dialog",
    templateUrl: "./add-hercontroledatum-dialog.component.html",
    styleUrls: ["./add-hercontroledatum-dialog.component.scss"],
})
export class AddHercontroledatumDialogComponent implements OnInit {
    addButtonText = "+";

    initialDate: Date = new Date();
    starttijdDate: Date = new Date();
    eindtijdDate: Date = new Date();

    starttijdFocussed = false;
    eindtijdFocussed = false;

    //add-hercontroledatum-dialog translate binding
    titelTextLabel: string = "Nieuwe hercontrole toevoegen";
    hercontroleDatumTextLabel: string = "Hercontroledatum";
    startTijdTextLabel: string = "Starttijd";
    eindTijdTextLabel: string = "Eindtijd";

    snackbarChangedStartTimeLabel: string = "Tijd veranderd: Ingevulde starttijd is later dan eindtijd";
    snackbarChangeEndTimeLabel: string = "Tijd veranderd: Ingevulde eindtijd is eerder dan starttijd";

    constructor(
        public datePipe: DatePipe,
        private formBuilder: UntypedFormBuilder,
        private dialogRef: MatDialogRef<AddHercontroledatumDialogComponent>,
        private snackbar: SnackbarService
    ) {}

    hercontroleForm = this.formBuilder.group({
        initialDate: { value: "" },
        starttijdDate: { value: "" },
        eindtijdDate: { value: "" },
    });

    ngOnInit(): void {
        this.hercontroleForm.controls["initialDate"].setValue(this.initialDate);
        this.starttijdDate.setHours(0);
        this.starttijdDate.setMinutes(0);
        this.starttijdDate.setSeconds(0);
        this.eindtijdDate.setHours(23);
        this.eindtijdDate.setMinutes(59);
        this.eindtijdDate.setSeconds(59);
    }

    changeDate() {
        this.initialDate = this.hercontroleForm.controls["initialDate"].value;
    }

    changeStarttijd() {
        if (this.hercontroleForm.controls["starttijdDate"].value.value !== "") {
            let time: string = this.hercontroleForm.controls["starttijdDate"].value;
            let timeNumbers = time.split(":");
            this.starttijdDate.setHours(+timeNumbers[0]);
            this.starttijdDate.setMinutes(+timeNumbers[1]);

            if (this.starttijdDate > this.eindtijdDate) {
                this.snackbar.basicSnackBar(this.snackbarChangedStartTimeLabel, 3000);
                this.starttijdDate = new Date(this.eindtijdDate.getTime());
                this.hercontroleForm.controls["starttijdDate"].setValue(
                    this.datePipe.transform(this.starttijdDate, "HH:mm")
                );
            }
        }
    }

    changeEindtijd() {
        if (this.hercontroleForm.controls["eindtijdDate"].value.value !== "") {
            let time: string = this.hercontroleForm.controls["eindtijdDate"].value;
            let timeNumbers = time.split(":");
            this.eindtijdDate.setHours(+timeNumbers[0]);
            this.eindtijdDate.setMinutes(+timeNumbers[1]);

            if (this.eindtijdDate < this.starttijdDate) {
                this.snackbar.basicSnackBar(this.snackbarChangeEndTimeLabel, 3000);
                this.eindtijdDate = new Date(this.starttijdDate.getTime());
                this.hercontroleForm.controls["eindtijdDate"].setValue(
                    this.datePipe.transform(this.eindtijdDate, "HH:mm")
                );
            }
        }
    }

    onSubmitHercontroleForm() {
        let newHercontrole = {
            hercontroleDatum: this.initialDate,
            starttijdDate: this.starttijdDate,
            eindtijdDate: this.eindtijdDate,
        };
        let submitPressed = true;
        this.dialogRef.close({ newHercontrole, submitPressed });
    }

    starttijdFocus(focussed: boolean) {
        this.starttijdFocussed = focussed;
    }

    eindtijdFocus(focussed: boolean) {
        this.eindtijdFocussed = focussed;
    }
}
