import { Component, Input, ViewEncapsulation } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { Router } from "@angular/router";
import { IDocument } from "src/app/model/document.model";
import { WkiDocumentsFilterDialogComponent } from "./wki-documents-filter-dialog/wki-documents-filter-dialog.component";

@Component({
    selector: "app-wki-documents-overview-dialog.ts",
    templateUrl: "./wki-documents-overview-dialog.component.html",
    styleUrls: ["./wki-documents-overview-dialog.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class WkiDocumentsOverviewDialogComponent {
    @Input() filteredFile: any;
    @Input() folder: any;
    @Input() file: any;
    @Input() isWki!: number;

    documents = Array.from({ length: 6 }).map((_, i) => `document #${i}`);

    dossierId?: number;
    deelplanId?: number;
    checklistId?: number;

    versie: boolean = true;

    backLabel: string = "Terug";
    headerTextLabel: string = "Root";
    noDocLabel: string = "Geen documenten.";
    docLabel: string = "Alle documenten";
    noRecommendedDocLabel: string = "Er zijn geen aanbevolen documenten op basis van de huidige filters";
    recommendedDocHeaderLabel: string = "Aanbevolen documenten";

    documentFileName: string = "Document-file-name.pdf";

    greenStatusAmount: string = "1";
    yellowStatusAmount: string = "2";
    redStatusAmount: string = "3";
    documentStatusAmount: string = "7";

    folderName: string = "Test Folder";
    documentCount: string = "2";

    showAllDocuments: boolean = true;
    filenameTitleLabel: string = "Bestandsnaam";
    categoryTitleLabel: string = "Hoofdcategorie";
    labelsTitleLabel: string = "Labels";
    versionTitleLabel: string = "Versie";
    statusTitleLabel: string = "Status";

    filenameLabel: string = "brandcomp_.PDF";
    categoryLabel: string = "Brandveiligheid";
    labelsLabel: string = "Label11, Brandoverslagberekeningen";
    versionLabel: string = "1";

    selectedFilters: any;

    constructor(
        private dialog: MatDialog,
        private router: Router,
        private dialogRef: MatDialogRef<WkiDocumentsOverviewDialogComponent>
    ) {
        dialogRef.disableClose = true;
    }

    onSelectFile(file: IDocument) {
        this.router.navigate([`/pdf-annotator/${this.dossierId}/${this.deelplanId}/${this.checklistId}`]);
    }

    openFilter() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            selectedFilters: this.selectedFilters,
        };
        dialogConfig.autoFocus = false;
        dialogConfig.width = "100vw";
        dialogConfig.maxWidth = "100vw";
        dialogConfig.height = "100vh";
        dialogConfig.maxHeight = "100vh";
        dialogConfig.panelClass = "wki-doc-overview";

        let dialogRef = this.dialog.open(WkiDocumentsFilterDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((selectedFilters) => {
            if (selectedFilters != null && selectedFilters != "" && selectedFilters != undefined) {
                this.selectedFilters = selectedFilters;
            }
        });
    }
}
