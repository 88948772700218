import { Injectable } from "@angular/core";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";

@Injectable({ providedIn: "root" })
export class SnackbarService {
    constructor(private snackBar: MatSnackBar) {}
    //snackbar voor het laten zien van een error bericht
    errorSnackBar(message: string, duration?: number, buttonText?: string) {
        this.snackBar.open(message, buttonText, {
            duration: duration,
            panelClass: ["snackbar-error"],
            horizontalPosition: "center",
            verticalPosition: "bottom",
        });
    }
    //een basic snackbar
    basicSnackBar(message: string, duration?: number, buttonText?: string) {
        this.snackBar.open(message, buttonText, {
            duration: duration,
            panelClass: ["snackbar-custom"],
            horizontalPosition: "center",
            verticalPosition: "bottom",
        });
    }

    //een basic snackbar
    succesSnackBar(message: string, duration?: number, buttonText?: string) {
        this.snackBar.open(message, buttonText, {
            duration: duration,
            panelClass: ["snackbar-succes"],
            horizontalPosition: "center",
            verticalPosition: "bottom",
        });
    }
}
