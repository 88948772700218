<div class="wrapper">
    <div class="betrokkenen-header" g2sLayout="row">
        <p class="betrokkenen-header-text">{{ opdrachtDoorgevenTitleLabel }}</p>
        <mat-icon matDialogClose matRipple class="close-button">close</mat-icon>
    </div>
    <div class="form-container">
        <hr class="divider" />
        <p>{{ opdrachtDoorgevenAanTitleLabel }}</p>
        <form #f="ngForm" g2sLayout="column">
            <div class="uitvoerende" g2sLayout="column">
                <p class="uitvoerende-title-text">{{ uitvoerendeTitleLabel }}</p>
                <select class="uitvoerende-select" name="uitvoerendeNaam">
                    <option value="{{ uitvoerendeNaam }}">{{ uitvoerendeNaam }}</option>
                </select>
            </div>
            <mat-expansion-panel #panel class="hoofdgroepCollapse" hideToggle>
                <mat-expansion-panel-header class="centerHeader">
                    <ng-container *ngIf="!panel.expanded; else less">
                        <div style="width: 100%" g2sLayout="column">
                            <p class="expand-title">{{ expandTitleLabel }}</p>
                            <span class="material-icons"> expand_more </span>
                        </div>
                    </ng-container>
                    <ng-template #less>
                        <div style="width: 100%" g2sLayout="column">
                            <span class="material-icons"> expand_less </span>
                        </div>
                    </ng-template>
                </mat-expansion-panel-header>
                <p class="input-title">{{ emailTitleLabel }}</p>
                <input
                    class="input"
                    [(ngModel)]="email"
                    type="email"
                    placeholder="E-mail"
                    name="email"
                    required
                    [pattern]="emailPattern" />
                <p class="input-title">{{ voornaamTitleLabel }}</p>
                <input class="input" [(ngModel)]="voornaam" type="text" placeholder="Voornaam" name="voornaam" />
                <p class="input-title">{{ achternaamTitleLabel }}</p>
                <input class="input" [(ngModel)]="achternaam" type="text" placeholder="Achternaam" name="achternaam" />
            </mat-expansion-panel>
            <p class="input-title">{{ toelichtingTitleLabel }}</p>
            <textarea
                [(ngModel)]="toelichting"
                name="toelichting"
                placeholder="Toelichting"
                class="omschrijving-textarea"></textarea>
            <p class="input-title">{{ eindcontroleTitleLable }}</p>
            <mat-slide-toggle color="primary"></mat-slide-toggle>
            <button
                (click)="opdrachtDoorgeven()"
                class="doorgeven"
                mat-flat-button
                matDialogClose
                type="submit"
                [disabled]="(!f.valid || !email || !voornaam || !achternaam || !toelichting) || (!uitvoerendeNaam || !toelichting)">
                {{ toevoegenLabel }}
            </button>
        </form>
    </div>
</div>
