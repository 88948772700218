<app-loading-screen [displayLoadingScreen]="displayLoadingScreen"></app-loading-screen>

<div class="header-title-container" g2sLayout="row" g2sLayoutAlign="start center">
    <p class="title">{{ translations?.Opdracht }}</p>

    <div g2sFlex g2sLayoutAlign="end center">
        <!-- <ng-container *ngIf="dossier?.heeftOpdrachten && vraag.type === VraagType.Meerkeuzevraag">
                <mat-icon (click)="openOpdrachtDoorgeven()" matRipple class="opdracht-icon">send</mat-icon>
            </ng-container> -->
        <mat-icon matRipple mat-dialog-close class="close-button">close</mat-icon>
    </div>
</div>

<span class="color-red">{{ deelplanOpdracht.gereedDatum }}</span>
<p class="title-black">{{ deelplanOpdracht.opdrachtText }}</p>

<div g2sLayout="row" class="img-container" g2sLayoutGap="5px">
    <ng-container *ngFor="let attachment of deelplanOpdracht.attachments; let i = index">
        <div
            (click)="onAttachmentClick(attachment)"
            class="file"
            [style.background-image]="'url(' + attachment.thumbnail + ')'"></div>
    </ng-container>
</div>

<div class="divider"></div>

<div g2sLayout="row" g2sLayoutAlign="end center">
    <button (click)="onOpenFileUpload()" matRipple g2sLayout="row" g2sLayoutAlign="center center" class="round-buttons">
        <mat-icon>add_a_photo</mat-icon>
    </button>
</div>

<div g2sLayout="row" class="img-container">
    <ngx-dropzone
        g2sFlex
        class="dropzone"
        accept="image/jpeg,image/jpg,image/png,application/pdf"
        (change)="onDropFiles($event)"
        g2sLayoutGap="5px">
        <ng-container *ngIf="files.length > 0; else dropzoneplaceholder">
            <ng-container *ngFor="let file of files; let i = index">
                <div
                    (click)="$event.stopPropagation();onFileClicked(file)"
                    class="file"
                    [style.background-image]="'url(' + file.content + ')'">
                    <mat-icon (click)="$event.stopPropagation();deleteFile(i)" class="delete-icon">delete</mat-icon>
                </div>
            </ng-container>
        </ng-container>
        <ng-template #dropzoneplaceholder>
            <ngx-dropzone-label>{{ translations?.Chepp_ChecklistView_ClickOrDragFilesInHereToAdd }}</ngx-dropzone-label>
        </ng-template>
    </ngx-dropzone>
</div>

<textarea class="toelichting-tekst" [(ngModel)]="deelplanOpdracht.toelichting" placeholder="Toelichting..."></textarea>

<div class="button-container" g2sLayout="row" g2sLayoutAlign="space-between center">
    <ng-container *ngIf="deelplanOpdracht.waardeoordeelOptions && getGreenWaardeoordeelOptions()">
        <button
            [ngClass]="{
                                    'button-inactive': (selectedWaardeoordeel !== undefined && selectedWaardeoordeel.color !== 'green') || (!selectedWaardeoordeel && deelplanOpdracht.defaultWaardeoordeel && deelplanOpdracht.defaultWaardeoordeel.color !== 'green') }"
            (click)="selectGreenWaardeoordeel()"
            g2sFlex="100"
            mat-flat-button
            class="buttonGroen">
            <mat-icon style="font-weight: 1000; transform: scale(1.3)">check</mat-icon>
        </button>
    </ng-container>
    <ng-container *ngIf="deelplanOpdracht.waardeoordeelOptions && getOrangeWaardeoordeelOptions()">
        <button
            [ngClass]="{
                                        'button-inactive': (selectedWaardeoordeel !== undefined && selectedWaardeoordeel.color !== 'orange') || (!selectedWaardeoordeel && deelplanOpdracht.defaultWaardeoordeel && deelplanOpdracht.defaultWaardeoordeel.color !== 'orange') }"
            (click)="selectOrangeWaardeoordeel()"
            g2sFlex="100"
            mat-flat-button
            class="buttonOranje">
            <mat-icon style="transform: scale(1.15)">warning</mat-icon>
        </button>
    </ng-container>
    <ng-container *ngIf="deelplanOpdracht.waardeoordeelOptions && getRedWaardeoordeelOptions()">
        <button
            [ngClass]="{'button-inactive': (selectedWaardeoordeel !== undefined && selectedWaardeoordeel.color !== 'red' || (!selectedWaardeoordeel && deelplanOpdracht.defaultWaardeoordeel && deelplanOpdracht.defaultWaardeoordeel.color !== 'red')) }"
            (click)="selectRedWaardeoordeel()"
            g2sFlex="100"
            mat-flat-button
            class="buttonRood">
            <mat-icon style="font-weight: 1000; transform: scale(1.3)">close</mat-icon>
        </button>
    </ng-container>
</div>

<ng-container *ngIf="selectedWaardeoordeel; else showDefaultWaardeoordeel">
    <div g2sLayout="row" g2sLayoutAlign="start center">
        <div class="{{ selectedWaardeoordeel.color }}-dot"></div>
        <p class="status-text">{{ selectedWaardeoordeel.text }}</p>
    </div>
</ng-container>
<ng-template #showDefaultWaardeoordeel>
    <div g2sLayout="row" g2sLayoutAlign="start center">
        <div class="standard-dot"></div>
        <p class="status-text">{{ deelplanOpdracht.defaultWaardeoordeel?.text }}</p>
    </div>
</ng-template>

<button [disabled]="isSaveButtonDisabled()" (click)="onSaveClicked()" class="save-button" mat-flat-button>
    <ng-container *ngIf="!isSaving; else saving">{{ saveLabel }}</ng-container>
    <ng-template #saving>
        <mat-spinner [diameter]="25" class="saving-spinner" color="accent"></mat-spinner>
    </ng-template>
</button>
