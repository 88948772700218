import { Component, Inject } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { StandaardMaatregelDialogComponent } from "./standaard-maatregel-dialog/standaard-maatregel-dialog.component";

@Component({
    selector: "app-maatregel-dialog.ts",
    templateUrl: "./new-maatregel-dialog.component.html",
    styleUrls: ["./new-maatregel-dialog.component.scss"],
})
export class NewMaatregelDialogComponent {
    saveLabel: string = "Opslaan";
    maatregelAddTitleLabel: string = "Maatregel toevoegen";
    maatregelEditTitleLabel: string = "Maatregel bewerken";
    maatregel: string = "";
    deadline: string = "";
    door: string = "";
    standaardMaatregelLabel: string = "Selecteer uit standaard maatregel";
    standaardMaatregel: string = "";

    //track wheter maatregel being added or edited
    mode: "create" | "edit" = "create";

    constructor(
        private dialog: MatDialog,
        private dialogRef: MatDialogRef<NewMaatregelDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        dialogRef.disableClose = true;
        this.mode = data.mode;
    }

    openStandaardMaatregel() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = "new-maatregel-dialog";
        let dialogRef = this.dialog.open(StandaardMaatregelDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((standaardMaatregel: string) => {
            if (standaardMaatregel != null) {
                this.standaardMaatregel += standaardMaatregel;
            }
        });
    }
}
