<div class="wrapper">
    <div class="standaard-header" g2sLayout="row">
        <p class="header-text">{{ standaardMaatregel }}</p>
        <mat-icon matDialogClose matRipple class="exit-button">close</mat-icon>
    </div>
    <div class="overflow-div">
        <div *ngFor="let maatregel of maatregelen" class="standaard-dialog-body">
            <div matRipple (click)="onClickStandaardMaatregel()" class="standaard-maatregel-item">
                {{ standaardMaatregel }}
            </div>
        </div>
    </div>
</div>
