import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { IDeelplanOpdracht } from "src/app/model/deelplan-opdracht.model";
import { IDeelplanVraag } from "src/app/model/deelplan-vraag.model";
import { IDeelplan } from "src/app/model/deelplan.model";
import { IDeelplanChecklist } from "src/app/model/deelplan-checklist.model";
import { IDossier } from "src/app/model/dossier.model";
import { ChecklistMemberType, VraagType } from "src/app/model/enum.model";
import { IHoofdgroep } from "src/app/model/hoofdgroep.model";
import { OpdrachtDialogComponent } from "../opdracht-dialog/opdracht-dialog.component";

@Component({
    selector: "app-checklist-member-wrapper-dialog",
    templateUrl: "./checklist-member-wrapper-dialog.component.html",
    styleUrls: ["./checklist-member-wrapper-dialog.component.scss"],
})
export class ChecklistMemberWrapperDialogComponent implements OnInit {
    @ViewChild(OpdrachtDialogComponent) deelplanOpdrachtDialogComponent?: OpdrachtDialogComponent;

    mode?: ChecklistMemberType;

    dossier: IDossier;
    deelplan: IDeelplan;
    deelplanChecklist: IDeelplanChecklist;
    hoofdgroep: IHoofdgroep;
    deelplanVraag?: IDeelplanVraag;
    deelplanOpdracht?: IDeelplanOpdracht;

    // saveText = "Opslaan";
    // opslaanText = "Succesvol opgeslagen";
    // attentionTextLabel: string = "Aandachtspunten";
    // guidelinesTextLabel: string = "Richtlijnen";
    // grondslagTextLabel: string = "Grondslagen";
    // hercontroleFromTextLabel: string = "Van";
    // hercontroleTillTextLabel: string = "Tot";
    // hercontroleTextLabel: string = "Hercontrole:";
    // verantwoordingstekstLabel: string = "Verantwoordingsteksten";
    
    maatregelButtonTextLabel: string = "M";
    riskAndPriorityTextLabel: string = "Risico en prioriteit";
    termijnTitleLabel: string = "Termijn:";
    conclusieTitleLabel: string = "Conclusie:";
    prioriteitLabel: string = "Prioriteit:";
    risicoLabel: string = "Risico:";
    effectLabel: string = "Effect:";
    waarschijnlijkheidTitleLabel: string = "Waarschijnlijkheid:";
    blootstellingTitleLabel: string = "Blootstelling:";
    efffectMessageLabel = "";
    waarschijnlijkheidLabel = "";
    blootstellingLabel = "";
    risicoValueLabel: string = "10000";
    risicoDescriptionLabel: string = "Zeer Hoog";
    prioriteitValueLabel: string = "P1";
    conclusieLabel: string = "Overweeg stopzetten van de activiteit";
    termijnLabel: string = "Direct";

    displayLoadingScreen: boolean = false;
    isSaving: boolean = false;

    aandachtspunten = [];
    richtlijnen = [];
    grondslagen = [];
    verantwoordingsteksten = [];
    showRiskAndPriority: boolean = false;
    // DUMMY DATA:
    // aandachtspunten = [
    //     { text: "Dit is een test met prio 5", priority: 5 },
    //     { text: "Dit is een test met prio 4", priority: 4 },
    //     { text: "Dit is een test met prio 5", priority: 5 },
    //     { text: "Dit is een test met prio 1", priority: 1 },
    //     { text: "Dit is een test met prio 2", priority: 2 },
    // ];

    // richtlijnen = ["test.pdf", "document.pdf", "richtlijn.pdf"];

    // grondslagen = [
    //     {
    //         id: "1.2",
    //         name: "Grondslag test 1",
    //         text: "Dit is een test grondslag artikel om te testen hoe de tekst werkt",
    //     },
    //     {
    //         id: "1.45",
    //         name: "Grondslag test 2",
    //         text: "Dit is een test grondslag artikel om te testen hoe de tekst werkt\nDit is een test grondslag artikel om te testen hoe de tekst werkt\nDit is een test grondslag artikel om te testen hoe de tekst werkt",
    //     },
    // ];

    // verantwoordingsteksten: string = "Standaard bij niet van toepassing (automatisch)";

    translations: any;

    constructor(
        private dialogRef: MatDialogRef<ChecklistMemberWrapperDialogComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.mode = data.mode;
        this.dossier = data.dossier;
        this.deelplan = data.deelplan;
        this.deelplanChecklist = data.deelplanChecklist;
        this.hoofdgroep = data.hoofdgroep;
        this.deelplanVraag = data.deelplanVraag;
        this.deelplanOpdracht = data.deelplanOpdracht;
        this.translations = data.translations;
    }

    ngOnInit(): void {}

    public get VraagType(): typeof VraagType {
        return VraagType;
    }

    closeDialog(result: any) {
        this.dialogRef.close(result);
    }
}
