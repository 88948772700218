import { Injectable } from "@angular/core";
import * as Crypto from "crypto-js";

const ACCESS_TOKEN: string = "access_token";
const REFRESH_TOKEN: string = "refresh_token";
const CODE_VERIFIER: string = "code_verifier";
const STATE: string = "state";

@Injectable({
    providedIn: "root",
})
export class TokenService {
    constructor() {}

    generateState() {
        let state = Crypto.lib.WordArray.random(40).toString();
        this.saveState(state);
        return state;
    }

    generateCodeChallenge() {
        let codeVerifier = Crypto.lib.WordArray.random(64);
        this.saveCodeVerifier(codeVerifier.toString());

        let codeVerifierHash = Crypto.SHA256(codeVerifier.toString()).toString(Crypto.enc.Base64);
        let codeChallenge = codeVerifierHash.replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");

        return codeChallenge;
    }

    getAccessToken(): string | null {
        return localStorage.getItem(ACCESS_TOKEN);
    }

    getRefreshToken(): string | null {
        return localStorage.getItem(REFRESH_TOKEN);
    }

    getCodeVerifier(): string | null {
        return localStorage.getItem(CODE_VERIFIER);
    }

    getState(): string | null {
        return localStorage.getItem(STATE);
    }

    saveAccessToken(token: string): void {
        localStorage.setItem(ACCESS_TOKEN, token);
    }

    saveRefreshToken(refreshToken: string): void {
        localStorage.setItem(REFRESH_TOKEN, refreshToken);
    }

    saveState(state: string): void {
        localStorage.setItem(STATE, state);
    }

    saveCodeVerifier(codeVerifier: string): void {
        localStorage.setItem(CODE_VERIFIER, codeVerifier);
    }

    removeAccessToken(): void {
        localStorage.removeItem(ACCESS_TOKEN);
    }

    removeRefreshToken(): void {
        localStorage.removeItem(REFRESH_TOKEN);
    }

    removeState(): void {
        localStorage.removeItem(STATE);
    }

    removeCodeVerifier(): void {
        localStorage.removeItem(CODE_VERIFIER);
    }

    clear(): void {
        this.removeAccessToken();
        this.removeCodeVerifier();
        this.removeRefreshToken();
        this.removeState();
    }
}
