import { Component } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

@Component({
    selector: "app-delete-dialog.ts",
    templateUrl: "./delete-maatregel-dialog.component.html",
    styleUrls: ["./delete-maatregel-dialog.component.scss"],
})
export class DeleteMaatregelDialogComponent {
    standaardOpdrachten: string = "Standaard maatregel";
    verwijderText: string = "Weet u zeker dat u dit maatregel wilt verwijderen?";
    jaAnswerLabel: string = "Ja";
    annulerenAnswerLabel: string = "Annuleren";

    constructor(private dialogRef: MatDialogRef<DeleteMaatregelDialogComponent>) {
        dialogRef.disableClose = true;
    }
}
