<app-loading-screen [displayLoadingScreen]="displayLoadingScreen"></app-loading-screen>

<div class="header-title-container" g2sLayout="row" g2sLayoutAlign="start center">
    <p class="title">{{ deelplanVraag.type }}</p>
    <ng-container *ngIf="deelplanVraag && deelplanVraag.hercontrole">
        <div class="createdhercontrole">
            {{ translations?.Hercontrole }}: {{ deelplanVraag.hercontrole.date }} ({{
            translations?.HercontroleFrom.toLowerCase() }}: {{ deelplanVraag.hercontrole.start }} {{
            translations?.HercontroleTo.toLowerCase() }}: {{ deelplanVraag.hercontrole.end }})
        </div>
    </ng-container>

    <div g2sFlex g2sLayoutAlign="end center" g2sLayoutGap="10px">
        <!-- <mat-icon matRipple class="dots" (click)="onOpenModal()" color="primary">more_horiz</mat-icon> -->
        <mat-icon matRipple mat-dialog-close class="close-button">close</mat-icon>
    </div>
</div>
<p class="subtitle">{{ deelplanVraag.name }}</p>

<div class="divider"></div>

<div g2sLayout="row" g2sLayoutAlign="end center">
    <button (click)="onOpenFileUpload()" matRipple g2sLayout="row" g2sLayoutAlign="center center" class="round-buttons">
        <mat-icon>add_a_photo</mat-icon>
    </button>
</div>

<div g2sLayout="row" class="img-container">
    <ngx-dropzone
        g2sFlex
        class="dropzone"
        accept="image/jpeg,image/jpg,image/png,application/pdf"
        (change)="onDropFiles($event)"
        g2sLayoutGap="5px">
        <ng-container *ngIf="files.length > 0; else dropzoneplaceholder">
            <ng-container *ngFor="let file of files; let i = index">
                <div
                    (click)="$event.stopPropagation();onFileClicked(file)"
                    class="file"
                    [style.background-image]="'url(' + file.content + ')'">
                    <mat-icon (click)="$event.stopPropagation();deleteFile(i)" class="delete-icon">delete</mat-icon>
                </div>
            </ng-container>
        </ng-container>
        <ng-template #dropzoneplaceholder>
            <ngx-dropzone-label>{{ translations?.Chepp_ChecklistView_ClickOrDragFilesInHereToAdd }}</ngx-dropzone-label>
        </ng-template>
    </ngx-dropzone>
</div>

<ng-container *ngIf="createdOpdracht">
    <p class="vervolgopdracht-title">{{ translations?.Vervolgopdracht }}:</p>
    <div (click)="onReopenOpdracht()" class="createdopdracht-popup">
        <div g2sFlex="90" g2sLayout="column">
            <div g2sLayout="row">
                <p g2sFlex="15" class="vervolgopdracht">{{ translations?.Deadline }}:</p>
                <p g2sFlex="85" class="data-text">{{ datePipe.transform(createdOpdracht.deadline, 'dd-MM-yyyy') }}</p>
            </div>
            <div g2sLayout="row">
                <p g2sFlex="15" class="vervolgopdracht">{{ translations?.Uitvoerende }}:</p>
                <p g2sFlex="85" class="data-text">{{ createdOpdracht.betrokkene!.name }}</p>
            </div>
            <div g2sLayout="row">
                <p g2sFlex="15" class="vervolgopdracht">{{ translations?.Chepp_ChecklistView_OpdrachtText }}:</p>
                <p g2sFlex="85" class="data-text">
                    {{ createdOpdracht.omschrijving.length > 15 ? (createdOpdracht.omschrijving | slice : 0 : 15) +
                    '...' : createdOpdracht.omschrijving }}
                </p>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="createdHercontrole">
    <mat-card g2sLayout="row" class="hercontrole-popup">
        <div g2sFlex="90" g2sLayout="column">
            <p class="hercontrole-title">{{ translations?.Chepp_ChecklistView_NewHercontrole }}:</p>
            <div g2sLayout="row">
                <span class="capitalize"
                    >{{ createdHercontrole.date | date : 'EEEE, dd-MM-yyyy' }} {{
                    translations?.HercontroleFrom.toLowerCase() }} {{ createdHercontrole.start | date : 'HH:mm' }} {{
                    translations?.HercontroleTo.toLowerCase() }} {{ createdHercontrole.end | date : 'HH:mm' }}</span
                >
            </div>
        </div>
        <div g2sFlex g2sLayout="row" g2sLayoutAlign="end center">
            <button class="delete-button">
                <mat-icon (click)="onDeleteCreatedHercontrole()">delete</mat-icon>
            </button>
        </div>
    </mat-card>
</ng-container>
<!-- mat expansion panel displays when there is content of deelplanVraag.toelichting  -->
<ng-container class="toelichting" *ngIf="previousToelichting != '' && deelplanVraag.toelichting ">
    <mat-expansion-panel #panel class="toelichting-Collapse" hideToggle>
        <mat-expansion-panel-header class="centerHeader">
            <ng-container *ngIf="!panel.expanded; else opened">
                <p class="expand-text">{{ translations?.Chepp_ChecklistView_ActualClarification }}</p>
                <mat-icon class="expand-icon">expand_more</mat-icon>
            </ng-container>
            <ng-template #opened>
                <p class="expand-text">{{ translations?.Chepp_ChecklistView_ActualClarification }}</p>
                <mat-icon class="expand-icon">expand_less</mat-icon>
            </ng-template>
        </mat-expansion-panel-header>
        <mat-card class="toelichting-card" hideToggle>
            <mat-panel-title style="color: white">{{ previousToelichting }}</mat-panel-title>
        </mat-card>
    </mat-expansion-panel>
</ng-container>

<textarea class="toelichting-tekst" [(ngModel)]="toelichting" placeholder="Toelichting..."></textarea>

<ng-container *ngIf="deelplanVraag.type == 'Meerkeuzevraag'">
    <div class="button-container" g2sLayout="row" g2sLayoutAlign="space-between center">
        <ng-container *ngIf="deelplanVraag.waardeoordeelOptions && getGreenWaardeoordeelOptions()">
            <button
                [ngClass]="{
                                'button-inactive': (selectedWaardeoordeel !== undefined && selectedWaardeoordeel.color !== 'green') || (!selectedWaardeoordeel && defaultWaardeoordeel && defaultWaardeoordeel.color !== 'green') }"
                (click)="selectGreenWaardeoordeel()"
                g2sFlex="100"
                mat-flat-button
                class="buttonGroen">
                <mat-icon style="font-weight: 1000; transform: scale(1.3)">check</mat-icon>
            </button>
        </ng-container>
        <ng-container *ngIf="deelplanVraag.waardeoordeelOptions && getOrangeWaardeoordeelOptions()">
            <button
                [ngClass]="{
                                    'button-inactive': (selectedWaardeoordeel !== undefined && selectedWaardeoordeel.color !== 'orange') || (!selectedWaardeoordeel && defaultWaardeoordeel && defaultWaardeoordeel.color !== 'orange') }"
                (click)="selectOrangeWaardeoordeel()"
                g2sFlex="100"
                mat-flat-button
                class="buttonOranje">
                <mat-icon style="transform: scale(1.15)">warning</mat-icon>
            </button>
        </ng-container>
        <ng-container *ngIf="deelplanVraag.waardeoordeelOptions && getRedWaardeoordeelOptions()">
            <button
                [ngClass]="{'button-inactive': (selectedWaardeoordeel !== undefined && selectedWaardeoordeel.color !== 'red') || (!selectedWaardeoordeel && defaultWaardeoordeel && defaultWaardeoordeel.color !== 'red')}"
                (click)="selectRedWaardeoordeel()"
                g2sFlex="100"
                mat-flat-button
                class="buttonRood">
                <mat-icon style="font-weight: 1000; transform: scale(1.3)">close</mat-icon>
            </button>
        </ng-container>
    </div>
</ng-container>

<ng-container *ngIf="deelplanVraag.type === VraagType.Meerkeuzevraag">
    <ng-container *ngIf="selectedWaardeoordeel; else showDefaultWaardeoordeel">
        <div g2sLayout="row" g2sLayoutAlign="start center">
            <div class="{{ selectedWaardeoordeel.color }}-dot"></div>
            <p class="status-text">{{ selectedWaardeoordeel.text }}</p>
        </div>
    </ng-container>
    <ng-template #showDefaultWaardeoordeel>
        <div g2sLayout="row" g2sLayoutAlign="start center">
            <div class="standard-dot"></div>
            <p class="status-text">{{ defaultWaardeoordeel?.text }}</p>
        </div>
    </ng-template>
</ng-container>

<button [disabled]="isSaveButtonDisabled()" (click)="onSaveClicked()" class="save-button" mat-flat-button>
    <ng-container *ngIf="!isSaving; else saving">{{ translations?.Save }}</ng-container>
    <ng-template #saving>
        <mat-spinner [diameter]="25" class="saving-spinner" color="accent"></mat-spinner>
    </ng-template>
</button>
